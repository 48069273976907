export enum Visibilities {
  DEFAULT = 'default',
  IMPACT_TEAM = 'impact_team',
  ASCEND_TEAM = 'ascend_team',
}

export enum Statuses {
  NOT_STARTED = 'not_started',
  ON_TRACK = 'on_track',
  AT_RISK = 'at_risk',
  POSTPONED = 'postponed',
  BLOCKED = 'blocked',
  COMPLETED = 'completed',
  EXCEEDED = 'exceeded',
  DELETED = 'deleted',
}

export enum MeasurementTypes {
  CURRENCY = 'currency',
  PERCENTAGE = 'percentage',
  NUMERICAL = 'numerical',
  TEXT = 'text',
}
