import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_COMMENT } from 'constants/api';
import { generateURL } from 'helpers';
import qs from 'query-string';
import { storeName } from '../config';
import { CommentsResponse } from '../interfaces';

export const getComments = createAsyncThunk<
  CommentsResponse,
  {
    threadId: number;
    nextCursor?: string | null;
    previousCursor?: string | null;
    pageSize?: number;
  }
>(
  `${storeName}/getComments`,
  async (
    { threadId, nextCursor = null, previousCursor = null, pageSize = null },
    thunkAPI,
  ) =>
    api.axios
      .get<CommentsResponse>(
        `${generateURL(API_COMMENT, { threadId })}?${qs.stringify(
          { cursor: nextCursor || previousCursor, pageSize },
          { skipNull: true },
        )}`,
      )
      .then((res) => res.data)
      .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
