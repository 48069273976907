import {
  Box,
  Dialog as MuiDialog,
  DialogContent as MUIDialogContent,
  List as MUIList,
  ListItem as MUIListItem,
  ListItemText as MUIListItemText,
  styled,
} from '@mui/material';

export const Dialog = styled(MuiDialog)({
  '.MuiDialog-container > .MuiPaper-root': {
    maxWidth: '560px',
    overflowY: 'visible',
  },
});

export const DialogContent = styled(MUIDialogContent)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const SearchWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const List = styled(MUIList)(() => ({
  flex: 1,
  marginRight: '-20px',
  paddingRight: '20px',
}));

export const ListItem = styled(MUIListItem)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  borderRadius: '4px',
  '&:not(:last-child)': {
    marginBottom: theme.spacing(0.5),
  },
}));

export const ListItemText = styled(MUIListItemText)(({ theme }) => ({
  '.MuiListItemText-secondary': {
    color: theme.palette.common.black,
  },
}));

export const Position = styled(Box)({
  '&:before': {
    content: '"|"',
    padding: '0 8px',
  },
});

Position.defaultProps = {
  component: 'span',
};
