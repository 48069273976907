import { styled, ListItem as MUIListItem } from '@mui/material';
import StyledLabel from 'components/Label/Label.styled';
import palette from 'styles/theme/palette';

export const ListItem = styled(MUIListItem)({
  background: palette.grey['100'],
  padding: '6px 16px',
  borderRadius: '4px',
  '&:not(:first-of-type)': {
    marginTop: '8px',
  },
  '.MuiListItemAvatar-root': {
    minWidth: 'unset',
    marginRight: '8px',
  },
  '.MuiListItemIcon-root': {
    minWidth: 'unset',
  },
  '.MuiCheckbox-root': {
    padding: 0,
    marginRight: '13px',
    '.MuiSvgIcon-root': {
      fontSize: '24px',
    },
  },
  '.MuiListItemText-root': {
    margin: 0,
  },
});

export const SecondaryItem = styled('span')({
  padding: '0 8px',

  '&:first-of-type': {
    padding: '0 8px 0 0',
  },
});

export const SecondaryBlock = styled('span')({
  display: 'flex',
  alignItems: 'center',
});

export const CountryLabel = styled(StyledLabel)({
  background: palette.blue['200'],
  color: palette.common.black,
  marginRight: '4px',
});
