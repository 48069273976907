import {
  URL_MY_SCORECARDS,
  URL_MY_TEAM,
  URL_MY_TEAM_DIRECT_REPORTS,
  URL_MY_TEAM_SUBORDINATES,
  URL_REVIEW_CYCLES,
  URL_FEEDBACK_REQUESTS,
  URL_DEPARTMENT_OVERVIEW,
  URL_CREATE_SCORECARD_TEMPLATE,
  URL_LIST,
  URL_EDIT_SCORECARD_TEMPLATE,
  URL_EDIT_SCORECARD,
  URL_CAREER_ROADMAP,
  URL_USER_MANAGEMENT,
  URL_ACTUAL,
  URL_HISTORY,
  URL_CAREER_ROADMAP_DEPARTMENT,
  URL_CAREER_ROADMAP_SELECT,
  URL_COMMON_GOALS,
  URL_MY_TEAM_INACTIVE_EMPLOYEES,
  URL_MY_TEAM_DELETED_SCORECARDS,
  URL_MY_OKR,
  URL_MY_TEAM_OKR,
  URL_CREATE_OBJECTIVE,
  URL_EDIT_OBJECTIVE,
  URL_VIEW_OBJECTIVE,
  URL_VIEW_USER_OBJECTIVE,
  URL_OKR_TEAM_OVERVIEW,
  URL_CREATE_USER_OBJECTIVE,
  URL_EDIT_USER_OBJECTIVE,
} from 'constants/urls';
import { ROLE_EMPLOYEE, ROLE_CAREER_MENTOR } from 'constants/roles';
import MyScorecards from 'pages/MyScorecards';
import {
  myTeamTexts,
  reviewCyclesTexts,
  scorecardsTexts,
  feedbackRequestsTexts,
  departmentOverviewTexts,
  careerRoadmapTexts,
  okrTexts,
} from 'i18n';
import ReviewCycles from 'pages/ReviewCycles';
import FeedbackRequests from 'pages/FeedbackRequests';
import DepartmentOverview from 'pages/DepartmentOverview';
import {
  DirectReports,
  Subordinates,
  InactiveEmployees,
  DeletedScorecards,
} from 'pages/MyTeam';
import {
  CreateScorecardTemplate,
  EditScorecardTemplate,
} from 'pages/ScorecardTemplate';
import EditScorecard from 'pages/Scorecard';
import { Departments, Roadmap } from 'pages/CareerRoadmap';
import UserManagementPage from 'pages/UserManagement';
import { Permissions } from 'constants/permissions';
import common from 'i18n/common';
import CommonGoalsEdit from 'pages/CommonGoals';
import MyOkrsPage from 'pages/MyOkrs';
import ObjectiveDetailsPage from 'pages/ObjectiveDetails';
import OkrUserList from 'pages/OkrUserList';
import { AppRouteItem } from './interfaces';

const {
  REVIEW_CYCLE_VIEW,
  USERS_MANAGEMENT,
  CAREER_ROADMAP_VIEW,
  SCORECARD_DEPARTMENT_OVERVIEW,
  COMMON_GOAL_MANAGE,
  MARS_OKR_MANAGE,
  MARS_OKR_MY_SUBORDINATES_TEAM_VIEW,
  MARS_OKR_GLOBAL_TEAM_VIEW,
} = Permissions;

const routes: AppRouteItem[] = [
  {
    path: URL_REVIEW_CYCLES,
    title: reviewCyclesTexts.title,
    permissions: [REVIEW_CYCLE_VIEW],
    Component: ReviewCycles,
    routes: [
      {
        path: URL_LIST,
        permissions: [REVIEW_CYCLE_VIEW],
      },
      {
        path: URL_CREATE_SCORECARD_TEMPLATE,
        permissions: [REVIEW_CYCLE_VIEW],
        Component: CreateScorecardTemplate,
      },
      {
        path: URL_EDIT_SCORECARD_TEMPLATE,
        permissions: [REVIEW_CYCLE_VIEW],
        Component: EditScorecardTemplate,
      },
      {
        path: URL_COMMON_GOALS,
        permissions: [COMMON_GOAL_MANAGE],
        Component: CommonGoalsEdit,
      },
    ],
  },
  {
    path: URL_MY_SCORECARDS,
    title: scorecardsTexts.title,
    roles: [ROLE_EMPLOYEE],
    Component: MyScorecards,
    routes: [
      {
        path: URL_LIST,
        roles: [ROLE_EMPLOYEE],
      },
      {
        path: URL_EDIT_SCORECARD,
        roles: [ROLE_EMPLOYEE],
        Component: EditScorecard,
      },
    ],
  },
  {
    path: URL_MY_TEAM,
    title: myTeamTexts.title,
    roles: [ROLE_CAREER_MENTOR],
    routes: [
      {
        path: URL_MY_TEAM_DIRECT_REPORTS,
        title: myTeamTexts.directReports,
        roles: [ROLE_CAREER_MENTOR],
        Component: DirectReports,
        routes: [
          {
            path: URL_LIST,
            roles: [ROLE_CAREER_MENTOR],
          },
          {
            path: URL_EDIT_SCORECARD,
            roles: [ROLE_CAREER_MENTOR],
            Component: EditScorecard,
          },
        ],
      },
      {
        path: URL_MY_TEAM_SUBORDINATES,
        title: myTeamTexts.subordinates,
        roles: [ROLE_CAREER_MENTOR],
        Component: Subordinates,
        routes: [
          {
            path: URL_LIST,
            roles: [ROLE_CAREER_MENTOR],
          },
          {
            path: URL_EDIT_SCORECARD,
            roles: [ROLE_CAREER_MENTOR],
            Component: EditScorecard,
          },
        ],
      },
      {
        path: URL_MY_TEAM_INACTIVE_EMPLOYEES,
        title: myTeamTexts.inactiveEmployees,
        roles: [ROLE_CAREER_MENTOR],
        Component: InactiveEmployees,
        routes: [
          {
            path: URL_LIST,
            roles: [ROLE_CAREER_MENTOR],
          },
          {
            path: URL_EDIT_SCORECARD,
            roles: [ROLE_CAREER_MENTOR],
            Component: EditScorecard,
          },
        ],
      },
      {
        path: URL_MY_TEAM_DELETED_SCORECARDS,
        title: myTeamTexts.deletedScorecards,
        roles: [ROLE_CAREER_MENTOR],
        Component: DeletedScorecards,
        routes: [
          {
            path: URL_LIST,
            roles: [ROLE_CAREER_MENTOR],
          },
          {
            path: URL_EDIT_SCORECARD,
            roles: [ROLE_CAREER_MENTOR],
            Component: EditScorecard,
          },
        ],
      },
    ],
  },
  {
    path: URL_FEEDBACK_REQUESTS,
    title: feedbackRequestsTexts.title,
    roles: [ROLE_EMPLOYEE],
    routes: [
      {
        path: URL_ACTUAL,
        title: common.actual,
        roles: [ROLE_EMPLOYEE],
        Component: FeedbackRequests,
      },
      {
        path: URL_HISTORY,
        title: common.history,
        roles: [ROLE_EMPLOYEE],
        Component: FeedbackRequests,
      },
    ],
  },
  {
    path: URL_DEPARTMENT_OVERVIEW,
    title: departmentOverviewTexts.title,
    permissions: [SCORECARD_DEPARTMENT_OVERVIEW],
    Component: DepartmentOverview,
    routes: [
      {
        path: URL_LIST,
        permissions: [SCORECARD_DEPARTMENT_OVERVIEW],
      },
      {
        path: URL_EDIT_SCORECARD,
        permissions: [SCORECARD_DEPARTMENT_OVERVIEW],
        Component: EditScorecard,
      },
    ],
  },
  {
    path: URL_CAREER_ROADMAP,
    title: careerRoadmapTexts.title,
    permissions: [CAREER_ROADMAP_VIEW],
    routes: [
      {
        path: URL_CAREER_ROADMAP_SELECT,
        Component: Departments,
        permissions: [CAREER_ROADMAP_VIEW],
      },
      {
        path: URL_CAREER_ROADMAP_DEPARTMENT,
        Component: Roadmap,
        permissions: [CAREER_ROADMAP_VIEW],
      },
    ],
  },
  {
    path: URL_USER_MANAGEMENT,
    permissions: [USERS_MANAGEMENT],
    Component: UserManagementPage,
  },
  {
    path: URL_MY_OKR,
    title: okrTexts.myOKRs,
    permissions: [MARS_OKR_MANAGE],
    routes: [
      {
        path: URL_LIST,
        permissions: [MARS_OKR_MANAGE],
        Component: MyOkrsPage,
      },
      {
        path: URL_CREATE_OBJECTIVE,
        Component: ObjectiveDetailsPage,
        permissions: [MARS_OKR_MANAGE],
      },
      {
        path: URL_EDIT_OBJECTIVE,
        Component: ObjectiveDetailsPage,
        permissions: [MARS_OKR_MANAGE],
      },
      {
        path: URL_VIEW_OBJECTIVE,
        Component: ObjectiveDetailsPage,
        permissions: [MARS_OKR_MANAGE],
      },
    ],
  },
  {
    path: URL_MY_TEAM_OKR,
    title: okrTexts.myTeam,
    permissions: [MARS_OKR_MY_SUBORDINATES_TEAM_VIEW, MARS_OKR_MANAGE],
    routes: [
      {
        path: URL_MY_TEAM_DIRECT_REPORTS,
        title: myTeamTexts.directReports,
        permissions: [MARS_OKR_MY_SUBORDINATES_TEAM_VIEW, MARS_OKR_MANAGE],
        Component: OkrUserList,
        routes: [
          {
            path: URL_LIST,
            permissions: [MARS_OKR_MY_SUBORDINATES_TEAM_VIEW, MARS_OKR_MANAGE],
          },
        ],
      },
      {
        path: URL_MY_TEAM_SUBORDINATES,
        title: myTeamTexts.subordinates,
        permissions: [MARS_OKR_MY_SUBORDINATES_TEAM_VIEW, MARS_OKR_MANAGE],
        Component: OkrUserList,
        routes: [
          {
            path: URL_LIST,
            permissions: [MARS_OKR_MY_SUBORDINATES_TEAM_VIEW, MARS_OKR_MANAGE],
          },
        ],
      },
      {
        path: URL_VIEW_USER_OBJECTIVE,
        permissions: [MARS_OKR_MY_SUBORDINATES_TEAM_VIEW, MARS_OKR_MANAGE],
        Component: ObjectiveDetailsPage,
      },
    ],
  },
  {
    path: URL_OKR_TEAM_OVERVIEW,
    title: okrTexts.teamOverview,
    permissions: [MARS_OKR_GLOBAL_TEAM_VIEW, MARS_OKR_MANAGE],
    routes: [
      {
        path: URL_LIST,
        permissions: [MARS_OKR_GLOBAL_TEAM_VIEW, MARS_OKR_MANAGE],
        Component: OkrUserList,
      },
      {
        path: URL_VIEW_USER_OBJECTIVE,
        permissions: [MARS_OKR_GLOBAL_TEAM_VIEW, MARS_OKR_MANAGE],
        Component: ObjectiveDetailsPage,
      },
      {
        path: URL_CREATE_USER_OBJECTIVE,
        permissions: [MARS_OKR_GLOBAL_TEAM_VIEW, MARS_OKR_MANAGE],
        Component: ObjectiveDetailsPage,
      },
      {
        path: URL_EDIT_USER_OBJECTIVE,
        permissions: [MARS_OKR_GLOBAL_TEAM_VIEW, MARS_OKR_MANAGE],
        Component: ObjectiveDetailsPage,
      },
    ],
  },
];

export default routes;
