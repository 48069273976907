import { createSlice, Reducer } from '@reduxjs/toolkit';
import addCommonCases from 'store/addCommonCases';
import { cloneDeep } from 'lodash-es';
import { summaryGet } from './actions';
import { storeName, summaryInitialState } from './config';

export * from './interfaces';
export * from './actions';
export * from './selectors';

const summarySlice = createSlice({
  name: storeName,
  initialState: cloneDeep(summaryInitialState),
  reducers: {},
  extraReducers(builder) {
    addCommonCases(builder, summaryInitialState).addCase(
      summaryGet.fulfilled,
      (state, action) => {
        Object.assign(state, {
          ...action.payload,
        });
      },
    );
  },
});

export default summarySlice.reducer as Reducer<typeof summaryInitialState>;
