import { Button, Typography, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useGoalField } from 'hooks/GoalsAndSubgoalsForm';
import {
  isCuSatGoal,
  scrollToActiveGoalInNavigation,
  GOALS_AND_SUBGOALS_ID_NAME,
  GOAL_NAVIGATION_TITLES_ID_NAME,
} from 'helpers';
import EmptyState from 'components/EmptyState';
import GoalTitle from './GoalTitle.component';
import Goal from './Goal.component';
import {
  FormHeader,
  FormBody,
  FormWrapper,
  GoalTitlesContainer,
  GoalDetailsContainer,
  GoalTitles,
  FromHeaderWrapper,
  ScrollHider,
} from './GoalsAndSubgoalsForm.styled';
import AddCommonGoalButton from './AddCommonGoalButton.component';
import SubgoalsTotalWeightError from './SubgoalsTotalWeightError.component';
import GoalsAndSubgoalsFormProps from './interfaces/GoalsAndSubgoalFormProps';

function GoalsAndSubgoalsForm({
  canAddGoals,
  hasRestrictionForTemplateGoal,
  isActiveOwnScorecard,
  isCommonGoalEdit,
}: GoalsAndSubgoalsFormProps) {
  const [activeSubGoal, setActiveSubGoal] = useState<number | null>(0);

  const { goals, addGoal, deleteGoal, activeGoal, onGoalChange } =
    useGoalField();

  useEffect(() => {
    scrollToActiveGoalInNavigation(activeGoal);
  }, [activeGoal]);

  useEffect(() => {
    const className = 'editPage';
    document.documentElement.classList.add(className);

    return () => {
      document.documentElement.classList.remove(className);
    };
  }, []);

  useEffect(() => {
    if (!goals[activeGoal]) {
      onGoalChange(0)();
      setActiveSubGoal(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCommonGoalEdit, goals]);

  const handleAddGoalClick = () => {
    setActiveSubGoal(0);
    addGoal();
  };

  const { items, titles } = goals.reduce(
    (acc: { items: JSX.Element[]; titles: JSX.Element[] }, goal, index) => {
      const handleChange = onGoalChange(index);
      const active = activeGoal === index;
      const navigationSubgoalsListId = `goal_${index}_subgoals`;
      acc.titles.push(
        <GoalTitle
          onChange={handleChange}
          active={active}
          index={index}
          key={goal.key}
          navigationSubgoalsListId={navigationSubgoalsListId}
          setActiveSubGoal={setActiveSubGoal}
          isCommonGoalEdit={isCommonGoalEdit}
        />,
      );
      acc.items.push(
        <Goal
          isActiveOwnScorecard={isActiveOwnScorecard}
          remove={deleteGoal}
          activeGoal={activeGoal}
          onSubgoalChange={handleChange}
          isRemoveDisabled={goals.length <= 1}
          index={index}
          key={goal.key}
          hasRestrictionForTemplateGoal={hasRestrictionForTemplateGoal}
          navigationSubgoalsListId={navigationSubgoalsListId}
          activeSubGoal={activeSubGoal}
          setActiveSubGoal={setActiveSubGoal}
          isCuSat={isCuSatGoal(goal)}
          isCommonGoalEdit={isCommonGoalEdit}
        />,
      );
      return acc;
    },
    { items: [], titles: [] },
  );
  return (
    <FormWrapper id={GOALS_AND_SUBGOALS_ID_NAME}>
      <FromHeaderWrapper>
        <ScrollHider />
        <FormHeader>
          <Typography variant="heading3">
            <FormattedMessage
              id="pages.reviewCycles.formTitle"
              defaultMessage="Goals And Subgoals Information"
            />
          </Typography>
          <Stack direction="row" gap={1}>
            <SubgoalsTotalWeightError />
            {canAddGoals && (
              <>
                {!isCommonGoalEdit && (
                  <AddCommonGoalButton onAddGoal={addGoal} />
                )}
                <Button
                  variant="contained"
                  disabled={!isCommonGoalEdit && goals.length > 14}
                  onClick={handleAddGoalClick}
                  data-testid="add-goal-button"
                >
                  <FormattedMessage
                    id="pages.reviewCycles.addGoal"
                    defaultMessage="Add Goal"
                  />
                </Button>
              </>
            )}
          </Stack>
        </FormHeader>
      </FromHeaderWrapper>
      {items.length === 0 && (
        <EmptyState
          title={
            <FormattedMessage
              id="pages.reviewCycles.noGoals"
              defaultMessage="There are no Goals in this Category."
            />
          }
        />
      )}
      {items.length > 0 && (
        <FormBody>
          <GoalTitlesContainer>
            <GoalTitles
              id={GOAL_NAVIGATION_TITLES_ID_NAME}
              data-testid="goals-subgoals-navigation"
            >
              {titles}
            </GoalTitles>
          </GoalTitlesContainer>
          <GoalDetailsContainer data-testid="goals-subgoals-details">
            {items}
          </GoalDetailsContainer>
        </FormBody>
      )}
    </FormWrapper>
  );
}

export default GoalsAndSubgoalsForm;
