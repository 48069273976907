export default function RootNodeIcon() {
  return (
    <svg
      width="97"
      height="70"
      viewBox="0 0 97 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.335938" width="96" height="64" rx="12" fill="#CCD3DF" />
      <rect x="16.3359" y="16" width="64" height="12" rx="4" fill="#E6E9EF" />
      <rect x="16.3359" y="36" width="41" height="12" rx="4" fill="#E6E9EF" />
      <circle
        cx="48"
        cy="64"
        r="5"
        fill="#CCD3DF"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
}
