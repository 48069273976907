import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function StatusBackIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2862 16.1979C13.5999 16.4563 13.8764 16.7889 13.9673 17.1989C13.9877 17.2909 13.9987 17.3869 13.9987 17.4868V18.394V18.9989H12.1987H4.79873H2.99873V17.4868C2.99873 17.3869 3.00978 17.2909 3.03018 17.1989C3.12104 16.7889 3.39756 16.4563 3.7113 16.1979C4.09549 15.8816 4.57555 15.6352 5.10659 15.4277C6.16867 15.0128 7.43237 14.765 8.49873 14.765C9.56509 14.765 10.8288 15.0128 11.8909 15.4277C12.4219 15.6352 12.902 15.8816 13.2862 16.1979ZM1.19873 18.9989V20.7989H2.99873H13.9987H15.7987V18.9989V18.394V17.4868C15.7987 16.1587 14.9933 15.2719 14.4304 14.8085C13.8425 14.3243 13.1692 13.9946 12.5458 13.7511C11.2911 13.261 9.80582 12.965 8.49873 12.965C7.19165 12.965 5.70639 13.261 4.45166 13.7511C3.82822 13.9946 3.15497 14.3243 2.56704 14.8085C2.00422 15.2719 1.19873 16.1587 1.19873 17.4868V18.9989ZM8.49873 3.20113C6.17938 3.20113 4.25429 5.07991 4.25429 7.42049C4.25429 9.76108 6.17938 11.6399 8.49873 11.6399C10.8181 11.6399 12.7432 9.76108 12.7432 7.42049C12.7432 5.07991 10.8181 3.20113 8.49873 3.20113ZM8.49873 5.00113C7.15594 5.00113 6.05429 6.09148 6.05429 7.42049C6.05429 8.74951 7.15594 9.83986 8.49873 9.83986C9.84152 9.83986 10.9432 8.74951 10.9432 7.42049C10.9432 6.09148 9.84152 5.00113 8.49873 5.00113ZM18.4404 7.0006L19.4397 8.10613L17.0694 10.7286L22.5013 10.7286V12.2712L17.1158 12.2712L19.4861 14.8936L18.4869 15.9991L14.397 11.4742L18.4404 7.0006Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
