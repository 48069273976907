import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { alpha } from '@mui/system/colorManipulator';
import { Dialog } from 'components/Dialog';

export const LoaderHolder = styled(Box)(({ theme }) => ({
  position: 'absolute',
  zIndex: 1000,
  inset: 0,
  background: alpha(theme.palette.common.white, 0.6),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export default styled(Dialog)({
  '.MuiDialog-container > .MuiPaper-root': {
    maxWidth: '560px',
  },
});
