import { debounce } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const objectiveTab = 'objectiveDetails';
const blockOffset = 168;

export default function useSidebarNavigation(scrollableElementId: string) {
  const [tabs, setTabs] = useState([objectiveTab]);
  const [activeTab, setActiveTab] = useState(objectiveTab);
  const [searchParams] = useSearchParams();
  const initialKeyResultIndex = searchParams.get('keyResultIndex');

  const scrollToTab = useCallback(
    (tab: string) => {
      const element = document.getElementById(scrollableElementId);
      const isLastTab = tabs[tabs.length - 1] === tab;
      const tabElement = document.getElementById(tab);
      if (element && tabElement) {
        const tabElementHeight = tabElement.offsetHeight / 2; // center the element
        element.scrollTo({
          top: isLastTab
            ? tabElement?.offsetTop
            : (tabElement?.offsetTop || 0) - tabElementHeight,
          behavior: 'smooth',
        });
      }
    },
    [scrollableElementId, tabs],
  );

  useEffect(() => {
    if (initialKeyResultIndex) {
      const keyResultTab = `keyResult-${initialKeyResultIndex}`;
      setActiveTab(keyResultTab);
      scrollToTab(keyResultTab);
    }
  }, [initialKeyResultIndex, scrollToTab]);

  useEffect(() => {
    const element = document.getElementById(scrollableElementId);
    if (element) {
      const handleScroll = () => {
        const scrollPosition = element.scrollTop;
        for (let i = 0; i < tabs.length; i += 1) {
          const tabElement = document.getElementById(tabs[i]) as HTMLElement;
          const tabElementTop = (tabElement?.offsetTop || 0) - blockOffset;
          if (tabElementTop >= scrollPosition) {
            setActiveTab(tabElement.id);
            break;
          }
        }
      };
      element.addEventListener('scroll', debounce(handleScroll, 30));

      return () => element.removeEventListener('scroll', handleScroll);
    }

    return () => {};
  }, [scrollableElementId, tabs]);

  return {
    tabs,
    setTabs,
    activeTab,
    setActiveTab,
    scrollToTab,
    objectiveTab,
  };
}
