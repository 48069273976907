import { FormattedMessage, useIntl } from 'react-intl';
import { commonTexts, errorTexts, formTexts, reviewCyclesTexts } from 'i18n';
import { Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import {
  createCommonGoalsCategory,
  getCommonGoalsCategories,
  selectCommonGoals,
  updateCommonGoalsCategory,
  deleteCommonGoalsCategory,
} from 'store/commonGoals';
import { Button, SelectChangeEvent, Tooltip, Typography } from '@mui/material';
import { EditIcon, RemoveIcon } from 'components/Icons';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from 'store';
import { Add } from '@mui/icons-material';
import { CommonGoalCategory } from 'store/commonGoals/interfaces/CommonGoalCategory';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { showToast } from 'store/toast';
import { usePreventDialogClosing } from 'hooks';
import { FormProvider, useForm } from 'react-hook-form';
import ReactHookFormField from 'components/ReactHookFormField';
import Select from 'components/Select';
import { withUnsavedConfirm } from 'helpers';
import { Wrapper, ButtonsWrapper, IconButton } from './CommonGoals.styled';

const MAX_NAME_LENGTH = 30;

export default function CommonGoalsCategorySelect({
  onChange,
  isGoalsFormDirty,
}: {
  onChange: (value: CommonGoalCategory | null) => void;
  isGoalsFormDirty: boolean;
}) {
  const dispatch = useAppDispatch();

  const {
    items,
    categories,
    loadingCategories,
    deletingCategories,
    updatingCategories,
  } = useSelector(selectCommonGoals);
  const { formatMessage } = useIntl();

  const [selectedCategory, setSelectedCategory] =
    useState<CommonGoalCategory | null>(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isCreatingNewCategory, setIsCreatingNewCategory] = useState(false);
  const [isDeleteCategoryOpen, setIsDeleteCategoryOpen] = useState(false);

  const selectedCategoryGoals = useMemo(() => {
    const goals =
      items.find((item) => item.id === selectedCategory?.id)?.goals || [];
    return goals.map((goal) => goal.name);
  }, [items, selectedCategory]);

  const form = useForm({
    defaultValues: { name: '' },
    mode: 'onChange',
  });

  const { watch, setValue, setError, clearErrors } = form;

  const categoryName = watch('name');

  const editButtonsDisabled =
    selectedCategory?.isService || loadingCategories || !selectedCategory;
  const saveDisabled =
    !categoryName ||
    categoryName.length > MAX_NAME_LENGTH ||
    (!isCreatingNewCategory && selectedCategory?.name === categoryName) ||
    updatingCategories ||
    deletingCategories;

  const selectCategory = (value: CommonGoalCategory | null) => {
    setSelectedCategory(value);
    onChange(value);
  };

  const onCategoryChange = (e: SelectChangeEvent<number>) => {
    withUnsavedConfirm({
      check: isGoalsFormDirty,
      func: () => {
        selectCategory(
          categories.find((item) => item.id === e.target.value) || null,
        );
      },
    });
  };

  const onCategoryEdit = () => {
    withUnsavedConfirm({
      check: isGoalsFormDirty,
      func: () => {
        setIsEditMode(true);
        setValue('name', selectedCategory?.name || '');
      },
    });
  };

  const onCreateCategoryClick = () => {
    withUnsavedConfirm({
      check: isGoalsFormDirty,
      func: () => {
        setIsCreatingNewCategory(true);
        setIsEditMode(true);
        setValue('name', '');
      },
    });
  };

  const onDeleteClick = () => {
    setIsDeleteCategoryOpen(true);
  };

  const onRemoveCategoryClick = async () => {
    try {
      if (selectedCategory) {
        await dispatch(
          deleteCommonGoalsCategory({ id: selectedCategory.id }),
        ).unwrap();
        const newCategories = await dispatch(
          getCommonGoalsCategories(),
        ).unwrap();
        setIsDeleteCategoryOpen(false);
        selectCategory(newCategories.items[0]);
        dispatch(
          showToast({
            severity: 'success',
            message: {
              ...reviewCyclesTexts.deleteCommonGoalCategoryToast,
              values: {
                name: selectedCategory.name,
              },
            },
          }),
        );
      }
    } catch (e) {
      setIsDeleteCategoryOpen(false);
      dispatch(
        showToast({
          severity: 'error',
          autoHideDuration: null,
          message: errorTexts.errorAcknowledgement,
        }),
      );
    }
  };

  const onCancel = () => {
    setIsEditMode(false);
    setIsCreatingNewCategory(false);
    setValue('name', '');
    clearErrors();
  };

  const initialCategoriesLoad = async () => {
    try {
      const intialCategories = await dispatch(
        getCommonGoalsCategories(),
      ).unwrap();
      selectCategory(intialCategories?.items?.[0]);
    } catch (e) {
      dispatch(
        showToast({
          severity: 'error',
          autoHideDuration: null,
          message: errorTexts.errorAcknowledgement,
        }),
      );
    }
  };

  const { onCloseHandler: editOnClose } = usePreventDialogClosing(onCancel);
  const { onCloseHandler: deleteOnClose } = usePreventDialogClosing(() =>
    setIsDeleteCategoryOpen(false),
  );

  const onSave = async () => {
    try {
      if (isCreatingNewCategory) {
        const newCategory = await dispatch(
          createCommonGoalsCategory({
            name: categoryName,
          }),
        ).unwrap();
        await dispatch(getCommonGoalsCategories());
        selectCategory(newCategory);
        dispatch(
          showToast({
            severity: 'success',
            message: {
              ...reviewCyclesTexts.createCommonGoalCategoryToast,
              values: {
                name: categoryName,
              },
            },
          }),
        );
      } else {
        const updatedCategory = await dispatch(
          updateCommonGoalsCategory({
            name: categoryName,
            id: selectedCategory?.id || 0,
          }),
        ).unwrap();
        await dispatch(getCommonGoalsCategories());
        selectCategory(updatedCategory);
        dispatch(
          showToast({
            severity: 'success',
            message: {
              ...reviewCyclesTexts.renameCommonGoalCategoryToast,
              values: {
                name: selectedCategory?.name,
                newName: updatedCategory.name,
              },
            },
          }),
        );
      }
      setIsEditMode(false);
      setIsCreatingNewCategory(false);
      setValue('name', '');
      clearErrors();
    } catch (e: any) {
      if (e?.errors?.name?.isUniqueConstraint) {
        setError('name', {
          type: 'manual',
          message: e?.errors?.name?.isUniqueConstraint,
        });
      }
    }
  };

  useEffect(() => {
    initialCategoriesLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (!selectedCategory && categories.length > 0) {
      selectCategory(categories[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  return (
    <Wrapper>
      <Stack
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        direction="row"
        spacing={2}
      >
        <Stack alignItems="center" direction="row" spacing={1}>
          <Typography variant="body1">
            <FormattedMessage {...reviewCyclesTexts.commonGoalsCategory} />
          </Typography>
          <Select
            value={selectedCategory?.id || ''}
            onChange={onCategoryChange}
            sx={{ width: 320 }}
            options={categories}
            placeholder="Select category"
          />
          <ButtonsWrapper>
            <Tooltip
              title={<FormattedMessage {...reviewCyclesTexts.renameCategory} />}
            >
              <span>
                <IconButton
                  onClick={onCategoryEdit}
                  disabled={editButtonsDisabled}
                  size="large"
                >
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              title={<FormattedMessage {...reviewCyclesTexts.deleteCategory} />}
            >
              <span>
                <IconButton
                  disabled={editButtonsDisabled}
                  onClick={onDeleteClick}
                  size="large"
                >
                  <RemoveIcon />
                </IconButton>
              </span>
            </Tooltip>
          </ButtonsWrapper>
        </Stack>
        <Button
          onClick={onCreateCategoryClick}
          size="medium"
          startIcon={<Add />}
          variant="outlined"
          disabled={loadingCategories}
        >
          <FormattedMessage {...reviewCyclesTexts.createNewCategory} />
        </Button>
      </Stack>
      {isDeleteCategoryOpen && (
        <ConfirmationDialog
          title={
            <FormattedMessage {...reviewCyclesTexts.deleteCategoryModal} />
          }
          content={
            <FormattedMessage
              {...reviewCyclesTexts.deleteCategoryMessage}
              values={{
                goals: (
                  <ul>
                    {selectedCategoryGoals.map((goal) => (
                      <li key={goal}>{goal}</li>
                    ))}
                  </ul>
                ),
                hasGoals: !!selectedCategoryGoals?.length,
                name: selectedCategory?.name,
                br: <br />,
              }}
            />
          }
          submitText={<FormattedMessage {...commonTexts.delete} />}
          onClose={deleteOnClose}
          onSubmit={onRemoveCategoryClick}
          isSubmitting={deletingCategories}
        />
      )}
      {isEditMode && (
        <ConfirmationDialog
          title={
            <FormattedMessage
              {...(isCreatingNewCategory
                ? reviewCyclesTexts.createNewCategoryModal
                : reviewCyclesTexts.renameCategoryModal)}
            />
          }
          content={
            <FormProvider {...form}>
              <ReactHookFormField
                name="name"
                maxLength={MAX_NAME_LENGTH}
                rules={{
                  validate: (value) =>
                    value.trim() !== '' ||
                    formatMessage(formTexts.errorRequired),
                  required: formatMessage(formTexts.errorRequired),
                }}
                required
                type="text"
                label={formatMessage(formTexts.name)}
                placeholder={formatMessage({
                  id: 'pages.reviewCycles.categoryNamePlaceholder',
                  defaultMessage: 'Enter category name',
                })}
              />
            </FormProvider>
          }
          submitText={
            <FormattedMessage
              {...(isCreatingNewCategory
                ? commonTexts.create
                : commonTexts.save)}
            />
          }
          onClose={editOnClose}
          onSubmit={onSave}
          isSubmitting={updatingCategories}
          submitDisabled={saveDisabled}
        />
      )}
    </Wrapper>
  );
}
