/* eslint-disable no-underscore-dangle */
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import {
  OKR_OBJECTIVE_CREATE,
  OKR_OBJECTIVE_CREATE_FOR_USER,
} from 'constants/api';
import { showOLDialog } from 'store/optimisticLocking';
import dayjs from 'dayjs';
import { generateURL } from 'helpers';
import { CreateOkrObjectivePayload, OkrObjectiveResponse } from '../interfaces';
import { storeName } from '../config';

export const createOkrObjective = createAsyncThunk<
  OkrObjectiveResponse,
  { data: CreateOkrObjectivePayload; periodId: string; userId?: string }
>(
  `${storeName}/createOkrObjective`,
  async ({ data, periodId, userId }, thunkAPI) =>
    api.axios
      .post<OkrObjectiveResponse>(
        generateURL(
          userId ? OKR_OBJECTIVE_CREATE_FOR_USER : OKR_OBJECTIVE_CREATE,
          { periodId, userId: userId || '' },
        ),
        data,
      )
      .then((res) => res.data)
      .catch((e) => {
        // optimistic locking error handling
        if (e.response?.status === 412) {
          thunkAPI.dispatch(
            showOLDialog({
              type: 'scorecard',
              params: {
                name: e.response?.data?.__meta?.lastActivity?.actor.fullName,
                date: dayjs(
                  e.response?.data?.__meta?.lastActivity?.doneAt,
                ).format('HH:mm DD.MM.YYYY'),
              },
            }),
          );
        }

        return thunkAPI.rejectWithValue({
          ...e.response?.data,
          response: {
            status: e.response?.status,
          },
        });
      }),
);
