import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function RemoveIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
      <path
        d="M8.12766 18.9H15.8723V9.2496H8.12766V18.9ZM5 6.0996V3.9996H8.4994L9.49311 3H14.5069L15.5006 3.9996H19V6.0996H5ZM8.12766 21C7.5486 21 7.05611 20.796 6.65021 20.388C6.24511 19.9792 6.04255 19.4832 6.04255 18.9V7.1496H17.9574V18.9C17.9574 19.4832 17.7549 19.9792 17.3498 20.388C16.9439 20.796 16.4514 21 15.8723 21H8.12766ZM8.12766 18.9H15.8723H8.12766Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
