import { Theme } from '@mui/material';
import shadows from '../shadows';

export default {
  styleOverrides: {
    root({ theme }: { theme: Theme }) {
      return {
        '[class*="SelectMenuSize"] &': {
          boxShadow: shadows[0],
          background: theme.palette.common.white,
        },
      };
    },
  },
};
