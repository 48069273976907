import { useEffect } from 'react';

type SubscriptionListener<T> = (message: string, data: T) => void;

export default function useSubscribe<T = unknown>(
  event: string,
  callback: SubscriptionListener<T>,
) {
  useEffect(() => {
    PubSub.subscribe(event, callback);
    return () => {
      PubSub.unsubscribe(event);
    };
  }, [callback, event]);
}
