import { createAsyncThunk } from '@reduxjs/toolkit';
import { ScorecardsActionStoreArgument } from 'store/interfaces';
import api from 'api';
import { API_SCORECARD_DELETE } from 'constants/api';
import { generateURL } from 'helpers';

export const deleteScorecard = createAsyncThunk<
  {},
  {
    id: number;
    store: ScorecardsActionStoreArgument;
  }
>('scorecards/delete', async ({ id }, thunkAPI) =>
  api.axios
    .delete(generateURL(API_SCORECARD_DELETE, { id }))
    .then(({ data }) => ({ data }))
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
