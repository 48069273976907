import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_COMMON_GOALS_BY_CATEGORY_ID } from 'constants/api';
import { generateURL } from 'helpers';
import { TemplateGoal } from 'store/interfaces';
import { storeName } from '../config';
import { CommonGoalsByCategory } from '../interfaces/CommonGoalsByCategory';

export const updateCategoryGoals = createAsyncThunk<
  CommonGoalsByCategory,
  { id?: number; goals: TemplateGoal[] }
>(`${storeName}/updateCommonGoal`, async ({ id, goals }, thunkAPI) =>
  api.axios
    .put<CommonGoalsByCategory>(
      generateURL(API_COMMON_GOALS_BY_CATEGORY_ID, { id: id || '' }),
      { goals },
    )
    .then((res) => res.data)
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
