import { FeedbackRequestRO } from 'store/interfaces';
import {
  FeedbackRequestFormValues,
  RequestSubgoalFormValues,
} from 'pages/FeedbackRequests/interfaces';

export default function parseFeedbackRequestFormData({
  feedback,
}: FeedbackRequestRO): FeedbackRequestFormValues {
  return {
    id: feedback.id,
    goalHeaders: feedback.feedbackGoalHeaders.map(
      ({ id, comment, feedbackSubgoalHeaders }) => ({
        id,
        comment: comment || '',
        subgoals: feedbackSubgoalHeaders.map(
          ({ assessmentScale, id: subgoalId, comment: subgoalComment }) => {
            const subgoal: RequestSubgoalFormValues = { id: subgoalId };

            if (assessmentScale?.id) {
              subgoal.assessmentScaleId = assessmentScale.id;
            }
            if (subgoalComment) {
              subgoal.comment = subgoalComment;
            }

            return subgoal;
          },
        ),
      }),
    ),
  };
}
