import { lazy, memo, Suspense } from 'react';
import { useDispatch } from 'react-redux';
import { Stack, IconButton } from '@mui/material';
import {
  EditIcon,
  DeleteOutlineIcon,
  CopyToClipboardIcon,
} from 'components/Icons';
import PubSub from 'pubsub-js';
import { RoadmapEvents } from 'constants/roadmap';
import { openEditDrawer } from 'store/roadmap';
import { useNodeId, useNodes } from 'reactflow';
import { useIntl } from 'react-intl';
import { useDialogState } from 'hooks';
import PageLoader from '../PageLoader';

const DeleteNode = lazy(() =>
  import('pages/CareerRoadmap/Modals').then((module) => ({
    default: module.DeleteNode,
  })),
);

function NodeMenu() {
  const id = useNodeId();
  const nodes = useNodes();

  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] =
    useDialogState();

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const handleOpenEditDrawer = (): void => {
    const node = nodes.find((item) => item.id === id);
    if (node) {
      dispatch(openEditDrawer(node));
    }
  };

  const handleCopyNode = (): void => {
    PubSub.publish(RoadmapEvents.COPY_NODE, id);
  };

  return (
    <>
      <Stack
        direction="row"
        sx={(theme) => ({
          position: 'absolute',
          top: '0.8rem',
          right: '0.8rem',
          zIndex: 3,
          padding: '0.1rem',
          borderRadius: '6px',
          background: theme.palette.common.white,
        })}
      >
        <IconButton
          size="small"
          color="primary"
          onClick={handleOpenEditDrawer}
          aria-label={formatMessage({
            id: 'pages.canvas.nodes.edit',
            defaultMessage: 'Edit item',
          })}
        >
          <EditIcon />
        </IconButton>

        <IconButton
          size="small"
          color="primary"
          onClick={handleCopyNode}
          aria-label={formatMessage({
            id: 'pages.canvas.nodes.copy',
            defaultMessage: 'Copy',
          })}
        >
          <CopyToClipboardIcon />
        </IconButton>

        <IconButton
          size="small"
          color="primary"
          onClick={openDeleteDialog}
          aria-label={formatMessage({
            id: 'pages.common.delete',
            defaultMessage: 'Delete',
          })}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </Stack>

      <Suspense fallback={<PageLoader open />}>
        <DeleteNode open={isDeleteDialogOpen} onClose={closeDeleteDialog} />
      </Suspense>
    </>
  );
}

NodeMenu.displayName = 'NodeMenu';

export default memo(NodeMenu);
