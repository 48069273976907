import { createAsyncThunk } from '@reduxjs/toolkit';
import { FeedbackRequestRO, Pagination } from 'store/interfaces';
import api from 'api';
import { RootState } from 'store';
import qs from 'query-string';
import {
  API_FEEDBACK_REQUEST_OWN,
  API_FEEDBACK_REQUEST_OWN_HISTORY,
} from 'constants/api';
import { storeName } from '../config';
import { FilterType } from '../interfaces/FeedbackRequestsState';

export const getFeedbackRequestsList = createAsyncThunk<
  {
    list: FeedbackRequestRO[];
    pagination: Pagination;
  },
  {
    loadMore?: boolean;
    isHistory?: boolean;
    filters?: Record<string, FilterType>;
  }
>(`${storeName}/list`, async ({ loadMore, isHistory, filters }, thunkAPI) => {
  const {
    feedbackRequests: {
      pagination: { page },
    },
  } = thunkAPI.getState() as RootState;
  const pageToLoad = loadMore ? page + 1 : 1;
  const reviewCycleIds =
    filters?.reviewCycleIds === 'all' || !isHistory
      ? null
      : filters?.reviewCycleIds;
  return api.axios
    .get(
      `${
        isHistory ? API_FEEDBACK_REQUEST_OWN_HISTORY : API_FEEDBACK_REQUEST_OWN
      }?${qs.stringify(
        {
          page: pageToLoad,
          reviewCycleIds,
          userName: (isHistory && filters?.userName) || null,
        },
        { skipNull: true },
      )}`,
    )
    .then((res) => res.data)
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data));
});
