import { DepartmentFilters, DepartmentState, ViewMode } from './interfaces';

export const storeName = 'scorecardsDepartment' as const;

export type DepartmentStoreName = typeof storeName;

export const initialFilters: DepartmentFilters = {
  reviewCycleIds: null,
  countryIds: null,
  unitIds: null,
  userName: '',
  careerMentorIds: null,
  scorecardStatuses: null,
  viewMode: ViewMode.ActiveEmployees,
};

export const departmentInitialState: DepartmentState = {
  items: [],
  loading: false,
  filters: { ...initialFilters },
  pagination: {
    limit: 0,
    page: 1,
    total: 0,
  },
  init: false,
  fullData: {},
  careerMentorOptions: [],
};
