import { useSelector } from 'react-redux';
import { selectOptions, updateOptions } from 'store/options';
import { ExtraMultiplier } from 'store/interfaces';
import { useEffect, useMemo } from 'react';
import { getExtraMultiplierTooltip } from 'helpers';
import { useAppDispatch } from 'store';
import { useIntl } from 'react-intl';

export default function useExtraMultiplierOptions(): ExtraMultiplier[] {
  const { extraMultipliers } = useSelector(selectOptions);
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!extraMultipliers.length) {
      dispatch(updateOptions('extraMultipliers'));
    }
  }, [dispatch, extraMultipliers.length]);
  return useMemo<ExtraMultiplier[]>(
    () =>
      extraMultipliers.map((extraMultiplier) => {
        const message = getExtraMultiplierTooltip(extraMultiplier);
        if (message) {
          return { ...extraMultiplier, tooltip: formatMessage(message) };
        }
        return extraMultiplier;
      }),
    [extraMultipliers, formatMessage],
  );
}
