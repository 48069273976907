import { useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { GoalField } from 'components/GoalsAndSubgoalsForm/interfaces';

export default function useGoalTitle(index: number) {
  const inputValue = useWatch<GoalField>({
    name: `goals.${index}.name`,
  }) as string;

  const weightValue = useWatch<GoalField>({
    name: `goals.${index}.weight`,
  }) as string;

  const weight = parseInt(weightValue, 10) || 0;
  const { formatMessage } = useIntl();
  const title = formatMessage(
    {
      id: 'pages.reviewCycles.goalName',
      defaultMessage: `{inputValueLength, plural,
        =0 { Goal {num}}
        other {{inputValue}} 
      }`,
    },
    {
      num: index + 1,
      inputValueLength: inputValue.length,
      inputValue,
    },
  );
  return {
    title,
    weight,
  };
}
