import {
  Avatar,
  Divider,
  IconButton,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import Checkbox from 'components/Checkbox';
import { resizeImage } from 'helpers';
import StyledLabel from 'components/Label/Label.styled';
import { useMemo } from 'react';
import Tooltip, { OverflowTip } from 'components/Tooltip';
import { DeleteOutlineIcon } from 'components/Icons';
import {
  CountryLabel,
  ListItem,
  SecondaryBlock,
  SecondaryItem,
} from './UserListItem.styled';
import { UserListItemProps } from './interfaces';

export default function UserListItem({
  recipient,
  unit: commonUnit,
  withCheckbox,
  checked,
  onChange,
  onRemove,
  disabled,
  sx,
  ...props
}: UserListItemProps) {
  const { user, location, unit, position } = recipient;
  const secondary = useMemo(
    () => (
      <SecondaryBlock>
        <SecondaryItem>{user?.email}</SecondaryItem>
        {position ? (
          <>
            <Divider
              sx={{ height: 12, alignSelf: 'center' }}
              orientation="vertical"
              flexItem
            />
            <SecondaryItem sx={{ maxWidth: location && unit ? 200 : 340 }}>
              <OverflowTip>{position?.name}</OverflowTip>
            </SecondaryItem>
          </>
        ) : null}
      </SecondaryBlock>
    ),
    [location, position, unit, user?.email],
  );
  return (
    <Tooltip
      title={
        disabled ? (
          <FormattedMessage
            id="pages.reviewCycles.alreadyPublished"
            defaultMessage="This template was already published onto this person"
          />
        ) : null
      }
      placement="top"
    >
      <ListItem
        {...props}
        sx={sx}
        aria-disabled={disabled}
        selected={checked}
        key={recipient.id}
      >
        {withCheckbox ? (
          <ListItemIcon>
            <Checkbox onChange={onChange} checked={checked} />
          </ListItemIcon>
        ) : null}
        <ListItemAvatar>
          <Avatar
            alt={user?.fullName}
            src={resizeImage(user?.avatar || null)}
          />
        </ListItemAvatar>
        <ListItemText
          disableTypography={false}
          primaryTypographyProps={{ variant: 'body4' }}
          secondaryTypographyProps={{ variant: 'body6' }}
          primary={user?.fullName}
          secondary={secondary}
        />
        {location?.country?.name ? (
          <ListItemIcon>
            <CountryLabel label={location?.country?.name} />
          </ListItemIcon>
        ) : null}
        {commonUnit?.name || unit?.name ? (
          <ListItemIcon>
            <StyledLabel label={commonUnit?.name || unit?.name} />
          </ListItemIcon>
        ) : null}
        {onRemove ? (
          <ListItemIcon sx={{ marginLeft: '8px' }}>
            <IconButton color="primary" onClick={() => onRemove(recipient.id)}>
              <DeleteOutlineIcon sx={{ width: 16, height: 16 }} />
            </IconButton>
          </ListItemIcon>
        ) : null}
      </ListItem>
    </Tooltip>
  );
}
