import { invisibleCharRegex } from 'constants/regexes';

export default function getLengthOfInvisibleChars(
  inputString: string | number,
): number {
  if (typeof inputString === 'number') return 0;

  const matches = inputString.match(invisibleCharRegex);
  // Need return length of the joined array because some character is represented as surrogate pairs
  // and has length 2 instead of 1
  return matches ? matches.join('').length : 0;
}
