import { createAsyncThunk } from '@reduxjs/toolkit';
import { FeedbackRequestRO } from 'store/interfaces';
import api from 'api';
import { API_SCORECARD_FEEDBACK_CANCEL } from 'constants/api';
import { generateURL } from 'helpers';
import { storeName } from '../config';

export const cancelFeedback = createAsyncThunk<
  FeedbackRequestRO,
  { id: number; goalId: number },
  {
    rejectValue: { errors: any };
  }
>(`${storeName}/cancelFeedback`, async ({ id, goalId }, thunkAPI) =>
  api.axios
    .put<FeedbackRequestRO>(
      generateURL(API_SCORECARD_FEEDBACK_CANCEL, { id: id as number, goalId }),
    )
    .then((res) => res.data)
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
