import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_COMMENT_BY_ID } from 'constants/api';
import { generateURL } from 'helpers';
import { updateCommentsThread } from 'store/okrObjectives';
import { storeName } from '../config';
import { Thread } from '../interfaces';

export const deleteComment = createAsyncThunk<
  { id: number },
  { thread: Thread; commentId: number; objId: number; keyId?: number },
  { rejectValue: { id: number } }
>(
  `${storeName}/deleteComment`,
  ({ thread, commentId, objId, keyId }, thunkAPI) =>
    api.axios
      .delete<{ id: number }>(
        generateURL(API_COMMENT_BY_ID, { threadId: thread.id, commentId }),
      )
      .then((res) => {
        thunkAPI.dispatch(
          updateCommentsThread({
            thread,
            change: -1,
            objId,
            keyId,
          }),
        );
        return res.data;
      })
      .catch(() => ({ id: commentId })),
);
