import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  styled,
  ButtonGroup as MUIButtonGroup,
} from '@mui/material';

export const MainButton = styled(Button)({
  justifyContent: 'flex-start',
});

export const Loader = styled(CircularProgress)({
  display: 'block',
  width: '20px',
});

export const CollapseMenuItem = styled(Accordion)(({ theme }) => ({
  ':not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
  },
  '&.MuiAccordion-root': {
    boxShadow: 'none',
  },
  '&, &.Mui-expanded': {
    margin: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

export const MenuItemSummary = styled(AccordionSummary)(({ theme }) => ({
  flexDirection: 'row-reverse',
  gap: theme.spacing(0.5),
  padding: theme.spacing(0.75),
  paddingLeft: theme.spacing(0.75),
  '&.MuiAccordionSummary-root': {
    borderBottom: 'none',
  },
  '&.Mui-expanded': {
    minHeight: 'unset',
  },
  '.MuiAccordionSummary-content': {
    color: theme.palette.grey.A500,
    margin: 0,
  },
  '.MuiSvgIcon-root': {
    color: theme.palette.common.black,
  },
}));

export const MenuItemDetails = styled(AccordionDetails)({
  padding: 0,
});

export const ButtonGroup = styled(MUIButtonGroup)(
  ({ color = 'primary', theme }) => {
    const childrenColor = color !== 'inherit' ? color : 'primary';

    return {
      width: 'unset',
      position: 'relative',
      '&:hover .MuiButtonGroup-grouped:not(.Mui-disabled)': {
        backgroundColor: theme.palette[childrenColor].main,
        borderColor: theme.palette[childrenColor].main,
        color: theme.palette[childrenColor].contrastText,
        '&:last-of-type': {
          borderLeftColor: theme.palette[childrenColor].contrastText,
        },
      },
    };
  },
);
