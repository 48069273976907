import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_THREAD_SUBSCRIPTION } from 'constants/api';
import { generateURL } from 'helpers';
import { storeName } from '../config';
import { Subscription } from '../interfaces';

export const unsubscribeFromThread = createAsyncThunk<
  Subscription,
  { threadId: number },
  { rejectValue: Subscription }
>(`${storeName}/unsubscribeFromThread`, ({ threadId }, thunkAPI) =>
  api.axios
    .delete<Subscription>(
      generateURL(API_THREAD_SUBSCRIPTION, { threadId }),
      {},
    )
    .then((res) => res.data)
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data))
    .then((res) => res)
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
