import { useScorecardContext } from 'contexts';
import { useSelector } from 'react-redux';
import { selectData } from 'store/scorecards';
import { Scorecard } from 'store/interfaces';
import { useMemo } from 'react';

export default function useScorecardData() {
  const { id, store } = useScorecardContext();
  const { data = null, loading = false } =
    useSelector(selectData(store, id)) || {};
  return useMemo<Scorecard & { loading: boolean }>(
    () => ({
      ...(data as Scorecard),
      loading,
    }),
    [data, loading],
  );
}
