import { styled, Typography, Box } from '@mui/material';

export const TextFieldContainer = styled(Box)(() => ({
  position: 'relative',
  '&.withCounter': {
    '.MuiFormControl-root .MuiInputBase-root.MuiInputBase-multiline': {
      paddingBottom: '25px',
    },
  },
  'input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button':
    {
      WebkitAppearance: 'none',
      margin: 0,
    },
}));

export const MaxLengthText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  position: 'absolute',
  bottom: theme.spacing(0.5),
  right: theme.spacing(0.5),
  color: theme.palette.grey.A500,
}));
