import { useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { GoalField } from 'components/GoalsAndSubgoalsForm/interfaces';

export default function useSubGoalTitle(index: number, subIndex: number) {
  const key =
    `goals.${index}.subgoals.${subIndex}` as `goals.${number}.subgoals.${number}`;
  const inputValue = useWatch<GoalField>({
    name: `${key}.name`,
  }) as string;

  const weightValue = useWatch<GoalField>({
    name: `${key}.weight`,
  }) as string;
  const weight = parseInt(weightValue, 10) || 0;
  const { formatMessage } = useIntl();
  const title = formatMessage(
    {
      id: 'pages.reviewCycles.subGoalName',
      defaultMessage: `{inputValueLength, plural,
        =0 { Subgoal {num}}
        other {{inputValue}} 
      }`,
    },
    {
      num: subIndex + 1,
      inputValueLength: inputValue.length,
      inputValue,
    },
  );
  return {
    title,
    weight,
  };
}
