import { useFieldArray, useFormContext } from 'react-hook-form';
import { useCallback, useState } from 'react';
import {
  FormGoal,
  GoalField,
} from 'components/GoalsAndSubgoalsForm/interfaces';
import {
  scrollToActiveGoalDetails,
  scrollToActiveGoalInNavigation,
} from 'helpers';
import { useSelector } from 'react-redux';
import { selectOptions } from 'store/options';
import useCreateNewGoal from './useCreateNewGoal';

export default function useGoalField() {
  const { paramTypes } = useSelector(selectOptions);
  const createNewGoal = useCreateNewGoal(paramTypes);
  const {
    formState: { submitCount, errors },
    getValues,
    clearErrors,
  } = useFormContext<GoalField>();
  const [activeGoal, setActiveGoal] = useState(0);
  const onGoalChange = useCallback(
    (value: number) => () => {
      if (value !== activeGoal) {
        setActiveGoal(value);
      }
    },
    [activeGoal],
  );
  const entity = getValues('entity');
  const isTemplate = entity === 'template';
  const {
    fields: goals,
    append: appendGoal,
    remove: removeGoal,
  } = useFieldArray<GoalField, 'goals', 'key'>({
    name: 'goals',
    keyName: 'key',
  });

  const addGoal = useCallback(
    (defaultValue?: Partial<FormGoal>) => {
      const newGoal = createNewGoal(goals.length, isTemplate, defaultValue);
      appendGoal(newGoal);
      setActiveGoal(goals.length);
      scrollToActiveGoalDetails();
      scrollToActiveGoalInNavigation(goals.length);
    },
    [createNewGoal, goals.length, isTemplate, appendGoal],
  );
  const deleteGoal = useCallback(
    (index: number) => {
      clearErrors([
        `goals.${index}.name` as `goals.${number}.name`,
        `goals.${index}.weight` as `goals.${number}.weight`,
      ]);
      removeGoal(index);
      setActiveGoal(index > 0 ? index - 1 : 0);
    },
    [clearErrors, removeGoal, setActiveGoal],
  );
  return {
    goals,
    addGoal,
    deleteGoal,
    submitCount,
    errors,
    activeGoal,
    onGoalChange,
    isTemplate,
  };
}
