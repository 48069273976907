import { nanoid } from 'nanoid';
import type { Edge, Node } from 'reactflow';
import { cloneDeep, get } from 'lodash-es';
import NodeNormalizer from './nodeNormalizer';

class UpdatedNode {
  private nodeIndex: number = 0;

  constructor(
    private nodesList: Node[],
    private newNode: Node,
    private edges: Edge[],
  ) {}

  private findNodeIndex = (): void => {
    this.nodeIndex = this.nodesList.findIndex(
      (node) => node.id === this.newNode.id,
    );
  };

  private checkIfEdgesPositionChanged = (): boolean => {
    const prevNode = this.nodesList[this.nodeIndex];

    const isTargetChanged =
      prevNode.targetPosition !== this.newNode.targetPosition &&
      get(this.newNode, 'data.edges.isTargetVisible', false);

    const isSourceChanged =
      prevNode.sourcePosition !== this.newNode.sourcePosition &&
      get(this.newNode, 'data.edges.isSourceVisible', false);

    return isTargetChanged || isSourceChanged;
  };

  private updateEdgesVisibility = (): void => {
    this.edges = this.edges.filter((item) => {
      if (item.source === this.newNode.id) {
        return get(this.newNode, 'data.edges.isSourceVisible', false);
      }

      if (item.target === this.newNode.id) {
        return get(this.newNode, 'data.edges.isTargetVisible', false);
      }

      return true;
    });
  };

  private updateEdgesPosition = (
    oldNodeId: string,
    newNodeId: string,
  ): void => {
    this.edges = this.edges.map<Edge>((item) => {
      if (item.source === oldNodeId) {
        return { ...item, source: newNodeId };
      }

      if (item.target === oldNodeId) {
        return { ...item, target: newNodeId };
      }

      return { ...item };
    }, []);
  };

  updateNode = (): UpdatedNode => {
    this.findNodeIndex();
    this.newNode = NodeNormalizer.normalize(this.newNode);

    // This will remove all unused edges
    this.updateEdgesVisibility();

    // At the moment, there is no other option to update connections between nodes except to delete
    // previous connections and update to new ones with a new node ID.
    // It is necessary to replace the node id because the library hard caches the state
    // and otherwise the connections will remain visually unchanged
    if (this.checkIfEdgesPositionChanged()) {
      const oldNodeId = this.newNode.id;
      this.newNode.id = nanoid();

      this.updateEdgesPosition(oldNodeId, this.newNode.id);
    }

    this.nodesList[this.nodeIndex] = this.newNode;
    return this;
  };

  getNodes = (): Node[] => this.nodesList;

  getEdges = (): Edge[] => this.edges;
}

export default class UpdatedNodeBuilder {
  private static nodesList: Node[] | null = null;

  private static newNode: Node | null = null;

  private static edges: Edge[] | null = null;

  private static refresh(): void {
    UpdatedNodeBuilder.nodesList = null;
    UpdatedNodeBuilder.nodesList = null;
    UpdatedNodeBuilder.edges = null;
  }

  static setNodesList(nodesList: Node[]): typeof UpdatedNodeBuilder {
    UpdatedNodeBuilder.nodesList = cloneDeep(nodesList);
    return UpdatedNodeBuilder;
  }

  static setNewNode(newNode: Node): typeof UpdatedNodeBuilder {
    UpdatedNodeBuilder.newNode = cloneDeep(newNode);
    return UpdatedNodeBuilder;
  }

  static setEdges(edges: Edge[]): typeof UpdatedNodeBuilder {
    UpdatedNodeBuilder.edges = cloneDeep(edges);
    return UpdatedNodeBuilder;
  }

  static build(): UpdatedNode {
    if (
      !UpdatedNodeBuilder.nodesList ||
      !UpdatedNodeBuilder.newNode ||
      !UpdatedNodeBuilder.edges
    ) {
      throw new Error(
        'It looks like you forgot to call setNodesList/setNewNode/setEdges methods or passed the wrong data',
      );
    }

    const result = new UpdatedNode(
      UpdatedNodeBuilder.nodesList,
      UpdatedNodeBuilder.newNode,
      UpdatedNodeBuilder.edges,
    ).updateNode();

    UpdatedNodeBuilder.refresh();

    return result;
  }
}
