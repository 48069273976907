import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function FlagIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M5 21V4H14L14.4 6H20V16H13L12.6 14H7V21H5ZM14.65 14H18V8H12.75L12.35 6H7V12H14.25L14.65 14Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
