import { useCallback } from 'react';
import {
  SuccessCallback,
  useActionPipeline,
  useDialogState,
  useToast,
} from 'hooks';
import { useIntl } from 'react-intl';
import { commonTexts } from 'i18n';
import { changeStatus } from 'store/scorecards';
import { SCORECARD_ACTION_GOALS_CONFIRM } from 'constants/scorecards';
import { useScorecardContext } from 'contexts';

export default function useSubmitForReview() {
  const { id, store } = useScorecardContext();
  const [
    isSubmitForReviewDialogActive,
    openSubmitForReviewDialog,
    onSubmitForReviewDialogClose,
  ] = useDialogState();

  const onSubmitForReviewClick = useCallback(() => {
    openSubmitForReviewDialog();
  }, [openSubmitForReviewDialog]);

  const { formatMessage } = useIntl();
  const showMessage = useToast();
  const onSubmitForReviewSuccess = useCallback<SuccessCallback>(() => {
    showMessage({
      severity: 'success',
      message: formatMessage(commonTexts.success),
    });
  }, [showMessage, formatMessage]);

  const submitForReviewPipeline = useActionPipeline(
    onSubmitForReviewSuccess,
    null,
    onSubmitForReviewDialogClose,
  );

  const onSubmitForReviewSubmit = useCallback(() => {
    submitForReviewPipeline(
      changeStatus({
        id,
        store,
        action: SCORECARD_ACTION_GOALS_CONFIRM,
      }),
    );
  }, [id, submitForReviewPipeline, store]);
  return {
    onSubmitForReviewClick,
    isSubmitForReviewDialogActive,
    onSubmitForReviewDialogClose,
    onSubmitForReviewSubmit,
  };
}
