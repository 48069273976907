import { useCallback, useEffect, useMemo, useState } from 'react';
import { WorkRecord } from 'store/interfaces';
import api from 'api';
import { cycleGetList, publishTemplate } from 'store/reviewCycles';
import { commonTexts } from 'i18n';
import { useIntl } from 'react-intl';
import { debounce } from 'debounce';
import { API_SCORECARD_TEMPLATE_RECOM_RECORDS } from 'constants/api';
import { generateURL } from 'helpers';
import {
  useActionPipeline,
  SuccessCallback,
  CatchCallback,
  useToast,
} from 'hooks';
import { sortUsers } from 'helpers/users';

export default function usePublishValues(
  id: number,
  searchValue: string,
  onClose: () => void,
  name: string,
) {
  const debouncedSetFilter = useMemo(
    () => debounce((fn: (v: string) => void, val: string) => fn(val), 300),
    [],
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [workRecordsToInclude, setIncludedItems] = useState<{ id: number }[]>(
    [],
  );

  const [filterString, setFilterString] = useState('');
  const [recipients, setRecipients] = useState<WorkRecord[]>([]);
  const [loading, setLoading] = useState(false);
  const { formatMessage } = useIntl();
  useEffect(() => {
    const newStr = searchValue.toLowerCase().trim();
    if (newStr !== filterString) {
      debouncedSetFilter.clear();
      debouncedSetFilter(setFilterString, newStr);
    }
  }, [searchValue, filterString, debouncedSetFilter]);
  useEffect(() => {
    setLoading(true);
    api.axios
      .get<{ list: WorkRecord[] }>(
        generateURL(API_SCORECARD_TEMPLATE_RECOM_RECORDS, { id }),
      )
      .then((res) => {
        setRecipients(sortUsers(res.data.list));
        setIncludedItems(
          res.data.list.map(({ id: recipientId }) => ({ id: recipientId })),
        );
        setLoading(false);
      });
    return () => debouncedSetFilter.clear();
  }, [debouncedSetFilter, id]);
  const filteredItems = useMemo(
    () =>
      recipients.filter(
        ({ user: { fullName, email } }) =>
          !filterString.length ||
          fullName.toLowerCase().trim().includes(filterString) ||
          email.toLowerCase().trim().includes(filterString),
      ),
    [filterString, recipients],
  );
  const getCheckboxProps = useCallback(
    (item: WorkRecord) => {
      const includedIndex = workRecordsToInclude.findIndex(
        (wr) => wr.id === item.id,
      );
      const onChange = () => {
        const newIncluded = [...workRecordsToInclude];
        if (includedIndex > -1) {
          newIncluded.splice(includedIndex, 1);
        } else {
          newIncluded.push({ id: item.id });
        }
        setIncludedItems(newIncluded);
      };
      return {
        checked: includedIndex >= 0,
        onChange,
      };
    },
    [workRecordsToInclude, setIncludedItems],
  );
  const selectAll = () => {
    setIncludedItems(
      filteredItems.map(({ id: recipientId }) => ({ id: recipientId })),
    );
  };
  const uncheckAll = () => {
    setIncludedItems(
      workRecordsToInclude.filter(
        (wr) =>
          filteredItems.findIndex(
            ({ id: filteredId }) => filteredId === wr.id,
          ) < 0,
      ),
    );
  };
  const showMessage = useToast();
  const onPublishSuccess = useCallback<SuccessCallback>(
    (action, dispatch) => {
      dispatch(cycleGetList());
      showMessage({
        severity: 'success',
        message: formatMessage(commonTexts.published, {
          templateName: name,
        }),
      });
      onClose();
    },
    [formatMessage, name, onClose, showMessage],
  );
  const onPublishCatch = useCallback<CatchCallback>(
    ({ payload }) => {
      const { errors: resErrors } = payload || {};
      if (resErrors) {
        setErrors(
          Object.values(resErrors).reduce((acc: string[], obj) => {
            acc.push(...Object.values(obj));
            return acc;
          }, []),
        );
      }
    },
    [setErrors],
  );
  const actionPipeline = useActionPipeline(onPublishSuccess, onPublishCatch);
  const onPublish = useCallback(() => {
    actionPipeline(
      publishTemplate({
        templateId: id,
        workRecords: workRecordsToInclude,
      }),
    );
  }, [actionPipeline, id, workRecordsToInclude]);
  return {
    errors,
    loading,
    onPublish,
    recipients: filteredItems,
    getCheckboxProps,
    selectAll,
    uncheckAll,
    total: recipients.length,
    selected: workRecordsToInclude.length,
    submitDisabled: workRecordsToInclude.length === 0,
  };
}
