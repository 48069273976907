import { defineMessages } from 'react-intl';

export default defineMessages({
  myOKRs: {
    id: 'pages.okr.myOKRs',
    defaultMessage: 'My OKRs',
  },
  myTeam: {
    id: 'pages.okr.myTeam',
    defaultMessage: 'My Team',
  },
  settings: {
    id: 'pages.okr.settings',
    defaultMessage: 'OKR Settings',
  },
  objectives: {
    id: 'pages.okr.objectives',
    defaultMessage: 'Objectives ({count})',
  },
  addObjective: {
    id: 'pages.okr.addObjective',
    defaultMessage: 'Add Objective',
  },
  emptyObjectives: {
    id: 'pages.okr.emptyObjectives',
    defaultMessage: 'No objectives yet',
  },
  objectiveDetails: {
    id: 'pages.okr.objectiveDetails',
    defaultMessage: 'Objective Details',
  },
  appearsAsKeyResult: {
    id: 'pages.okr.appearsAsKeyResult',
    defaultMessage: 'Contributes to a key result in',
  },
  ownedBy: {
    id: 'pages.okr.ownedBy',
    defaultMessage: 'owned by',
  },
  reviewCycle: {
    id: 'pages.okr.reviewCycle',
    defaultMessage: 'Review Cycle',
  },
  description: {
    id: 'pages.okr.description',
    defaultMessage: 'Description',
  },
  weight: {
    id: 'pages.okr.weight',
    defaultMessage: 'Weight',
  },
  attainment: {
    id: 'pages.okr.attainment',
    defaultMessage: 'Attainment',
  },
  visibleBy: {
    id: 'pages.okr.visibleBy',
    defaultMessage: 'Visible by',
  },
  keyResultDetails: {
    id: 'pages.okr.keyResultDetails',
    defaultMessage: 'Key Result Details',
  },
  dueDate: {
    id: 'pages.okr.dueDate',
    defaultMessage: 'Due Date',
  },
  status: {
    id: 'pages.okr.status',
    defaultMessage: 'Status',
  },
  assignedTo: {
    id: 'pages.okr.assignedTo',
    defaultMessage: 'Assigned to',
  },
  progress: {
    id: 'pages.okr.progress',
    defaultMessage: 'Progress',
  },
  referenceValue: {
    id: 'pages.okr.referenceValue',
    defaultMessage: 'Reference Value',
  },
  target: {
    id: 'pages.okr.target',
    defaultMessage: 'Target',
  },
  result: {
    id: 'pages.okr.result',
    defaultMessage: 'Result',
  },
  agreedAttainment: {
    id: 'pages.okr.agreedAttainment',
    defaultMessage: 'Agreed Attainment',
  },
  notStarted: {
    id: 'pages.okr.notStarted',
    defaultMessage: 'Not Started',
  },
  onTrack: {
    id: 'pages.okr.onTrack',
    defaultMessage: 'On Track',
  },
  completed: {
    id: 'pages.okr.completed',
    defaultMessage: 'Completed',
  },
  exceeded: {
    id: 'pages.okr.exceeded',
    defaultMessage: 'Exceeded',
  },
  atRisk: {
    id: 'pages.okr.atRisk',
    defaultMessage: 'At Risk',
  },
  blocked: {
    id: 'pages.okr.blocked',
    defaultMessage: 'Blocked',
  },
  postponed: {
    id: 'pages.okr.postponed',
    defaultMessage: 'Postponed',
  },
  newObjective: {
    id: 'pages.okr.newObjective',
    defaultMessage: 'New Objective',
  },
  totalKeyResultsWeight: {
    id: 'pages.okr.totalKeyResultsWeight',
    defaultMessage: 'Total Key Results Weight (%): ',
  },
  label: {
    id: 'pages.okr.label',
    defaultMessage: 'Label',
  },
  visibility: {
    id: 'pages.okr.visibility',
    defaultMessage: 'Visibility',
  },
  objectiveNamePlaceholder: {
    id: 'pages.okr.objectiveNamePlaceholder',
    defaultMessage: 'Please provide an Objective name',
  },
  objectiveDescriptionPlaceholder: {
    id: 'pages.okr.objectiveDescriptionPlaceholder',
    defaultMessage: 'Objective description (optional)',
  },
  keyResultDescriptionPlaceholder: {
    id: 'pages.okr.keyResultDescriptionPlaceholder',
    defaultMessage: 'Key Result description (optional)',
  },
  keyResultNamePlaceholder: {
    id: 'pages.okr.keyResultNamePlaceholder',
    defaultMessage: 'Please provide a Key Result name',
  },
  measurementType: {
    id: 'pages.okr.measurementType',
    defaultMessage: 'Measurement type',
  },
  currency: {
    id: 'pages.okr.currency',
    defaultMessage: 'Currency ($)',
  },
  percentage: {
    id: 'pages.okr.percentage',
    defaultMessage: 'Percentage (%)',
  },
  numerical: {
    id: 'pages.okr.numerical',
    defaultMessage: 'Numerical value',
  },
  text: {
    id: 'pages.okr.text',
    defaultMessage: 'Text description',
  },
  addKeyResult: {
    id: 'pages.okr.addKeyResult',
    defaultMessage: 'Add Key Result',
  },
  default: {
    id: 'pages.okr.default',
    defaultMessage: 'Default',
  },
  impactTeam: {
    id: 'pages.okr.impactTeam',
    defaultMessage: 'Impact Team',
  },
  ascendTeam: {
    id: 'pages.okr.ascendTeam',
    defaultMessage: 'Ascend Team',
  },
  deleted: {
    id: 'pages.okr.deleted',
    defaultMessage: 'Deleted',
  },
  newKeyResult: {
    id: 'pages.okr.newKeyResult',
    defaultMessage: 'New key result',
  },
  autoUpdateAttainmentFromChildBinding: {
    id: 'pages.okr.autoUpdateAttainmentFromChildBinding',
    defaultMessage:
      'Automatically update <b>Attainment</b> based on assignee data',
  },
  autoUpdateAttainmentFromChildBindingLabel: {
    id: 'pages.okr.autoUpdateAttainmentFromChildBindingLabel',
    defaultMessage: 'Automatically update Attainment based on assignee data',
  },
  keyResults: {
    id: 'pages.okr.keyResults',
    defaultMessage: 'Key Results',
  },
  owner: {
    id: 'pages.okr.owner',
    defaultMessage: 'Owner',
  },
  notAssigned: {
    id: 'pages.okr.notAssigned',
    defaultMessage: 'Not assigned',
  },
  directReports: {
    id: 'pages.okr.directReports',
    defaultMessage: 'Direct reports',
  },
  subordinates: {
    id: 'pages.okr.subordinates',
    defaultMessage: 'Subordinates',
  },
  editAttainments: {
    id: 'pages.okr.editAttainments',
    defaultMessage: 'Edit attainments',
  },
  editAttainment: {
    id: 'pages.okr.editAttainment',
    defaultMessage: 'Edit attainment',
  },
  agreedAttainmentShort: {
    id: 'pages.okr.agreedAttainmentShort',
    defaultMessage: 'Agreed Attain.',
  },
  totalScore: {
    id: 'pages.okr.totalScore',
    defaultMessage: 'Total Score',
  },
  objectivesAttainment: {
    id: 'pages.okr.objectivesAttainment',
    defaultMessage: 'Objectives Attainment',
  },
  keyResultsStatuses: {
    id: 'pages.okr.keyResultsStatuses',
    defaultMessage: 'Key Results Statuses',
  },
  teamOverview: {
    id: 'pages.okr.teamOverview',
    defaultMessage: 'Team Overview',
  },
  noUsersFound: {
    id: 'pages.okr.noUsersFound',
    defaultMessage: 'No users found. Please try another search criteria.',
  },
  assignee: {
    id: 'pages.okr.assignee',
    defaultMessage: 'Assignee',
  },
  searchAssignee: {
    id: 'pages.okr.searchAssignee',
    defaultMessage: 'Search assignee',
  },
  pendingKeyResults: {
    id: 'pages.okr.pendingKeyResults',
    defaultMessage: 'Pending Key Results',
  },
  pendingKeyResultsN: {
    id: 'pages.okr.pendingKeyResultsN',
    defaultMessage: 'Pending key results ({count})',
  },
  keyResult: {
    id: 'pages.okr.keyResult',
    defaultMessage: 'Key Result',
  },
  assignedBy: {
    id: 'pages.okr.assignedBy',
    defaultMessage: 'Assigned by',
  },
  pendingKeyResultDeclined: {
    id: 'pages.okr.pendingKeyResultDeclined',
    defaultMessage: "You've declined a {name} Key Result.",
  },
  acceptAsNew: {
    id: 'pages.okr.acceptAsNew',
    defaultMessage: 'Accept as new',
  },
  linkToExisting: {
    id: 'pages.okr.linkToExisting',
    defaultMessage: 'Link to existing',
  },
  contributesToKeyResult: {
    id: 'pages.okr.contributesToKeyResult',
    defaultMessage: 'Contributes to Key Result',
  },
  selectFromPending: {
    id: 'pages.okr.selectFromPending',
    defaultMessage: 'Select from pending requests',
  },
  linkObjective: {
    id: 'pages.okr.linkObjective',
    defaultMessage: 'Link Objective',
  },
  objectiveName: {
    id: 'pages.okr.objectiveName',
    defaultMessage: 'Objective name',
  },
  noPendingKeyResults: {
    id: 'pages.okr.noPendingKeyResults',
    defaultMessage: 'There are no pending key results',
  },
  linkPendingKeyResultToExisting: {
    id: 'pages.okr.LinkPendingKeyResultToExisting',
    defaultMessage: 'Link the pending key result to an existing objective',
  },
  linkNewAndPending: {
    id: 'pages.okr.linkNewAndPending',
    defaultMessage: 'Link the new objective and pending key results',
  },
  keyResultAccepted: {
    id: 'pages.okr.keyResultAccepted',
    defaultMessage: '{objective} successfully linked to {kr}',
  },
  keyResultAcceptError: {
    id: 'pages.okr.keyResultAcceptError',
    defaultMessage: 'Failed to link the objective to the key result',
  },
  contributesTo: {
    id: 'pages.okr.contributesTo',
    defaultMessage: 'Contributes to',
  },
  as: {
    id: 'pages.okr.as',
    defaultMessage: 'as',
  },
  objective: {
    id: 'pages.okr.objective',
    defaultMessage: 'Objective',
  },
  addAComment: {
    id: 'pages.okr.addAComment',
    defaultMessage: 'Add a comment...',
  },
  noComments: {
    id: 'pages.okr.noComments',
    defaultMessage: 'No comments yet',
  },
  objectivesProgress: {
    id: 'pages.okr.objectivesProgress',
    defaultMessage: 'Objectives Progress',
  },
  removeObjective: {
    id: 'pages.okr.removeObjective',
    defaultMessage: 'Remove Objective',
  },
  objectiveAlreadyLinkedTooltip: {
    id: 'pages.okr.objectiveAlreadyLinkedTooltip',
    defaultMessage:
      "This Objective is already linked to a <bold>{parentKeyResult}</bold>. You can't link already linked Objective.",
  },
  exceedTotalWeightError: {
    id: 'pages.okr.exceedTotalWeightError',
    defaultMessage:
      'Total Objectives weight should be equal to 100%, please go through Objectives weights and adjust them.',
  },
  updatedKeyResultDescription: {
    id: 'pages.scorecards.updatedKeyResultDescription',
    defaultMessage: 'Key Result:{br}<i>{name}</i>',
  },
  deletedKeyResultDescription: {
    id: 'pages.scorecards.deletedKeyResultDescription',
    defaultMessage: 'Key Result <i>{name}</i> deleted',
  },
  createdKeyResultDescription: {
    id: 'pages.scorecards.createdKeyResultDescription',
    defaultMessage: 'Key Result created:',
  },
  updatedObjectiveDescription: {
    id: 'pages.scorecards.updatedObjectiveDescription',
    defaultMessage: 'Objective:{br}<i>{name}</i>',
  },
  deletedObjectiveDescription: {
    id: 'pages.scorecards.deletedObjectiveDescription',
    defaultMessage: 'Objective <i>{name}</i> deleted',
  },
  createdObjectiveDescription: {
    id: 'pages.scorecards.createdObjectiveDescription',
    defaultMessage: 'Objective created:',
  },
});
