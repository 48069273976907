import { LogoProps } from '../interfaces';

export default function LogoMarsIcon({ textColor, width = 62 }: LogoProps) {
  return (
    <svg width={width} viewBox="0 0 62 18" fill="none">
      <path
        d="M14.552 17V3.68L9.104 17H8.288L2.864 3.68V17H0.872V0.991999H3.728L8.696 13.184L13.688 0.991999H16.544V17H14.552ZM31.7743 17L30.3583 13.448H22.2463L20.8303 17H18.6223L25.0543 0.991999H27.5263L33.9823 17H31.7743ZM26.3023 2.768L22.8223 11.672H29.7583L26.3023 2.768ZM45.3391 17L41.2591 10.64H38.0671V17H36.0751V0.991999H42.5071C45.4351 0.991999 47.5231 2.864 47.5231 5.816C47.5231 8.696 45.5551 10.28 43.3711 10.472L47.6911 17H45.3391ZM45.4591 5.816C45.4591 4.016 44.1631 2.768 42.2671 2.768H38.0671V8.888H42.2671C44.1631 8.888 45.4591 7.616 45.4591 5.816ZM49.7167 14.744L50.8927 13.232C51.9487 14.408 53.6767 15.512 55.9327 15.512C58.7887 15.512 59.7007 13.976 59.7007 12.752C59.7007 10.832 57.7567 10.304 55.6447 9.728C53.0767 9.056 50.2207 8.336 50.2207 5.192C50.2207 2.552 52.5727 0.751999 55.7167 0.751999C58.1647 0.751999 60.0607 1.568 61.4047 2.984L60.2047 4.448C59.0047 3.128 57.3247 2.528 55.5727 2.528C53.6527 2.528 52.2847 3.56 52.2847 5.072C52.2847 6.68 54.1327 7.16 56.1727 7.712C58.7887 8.408 61.7647 9.224 61.7647 12.584C61.7647 14.912 60.1807 17.288 55.8607 17.288C53.0767 17.288 51.0367 16.232 49.7167 14.744Z"
        fill={textColor}
      />
    </svg>
  );
}
