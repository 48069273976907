import { createAsyncThunk } from '@reduxjs/toolkit';
import { FeedbackRequestRO } from 'store/interfaces';
import api from 'api';
import { API_SCORECARD_FEEDBACK_UPDATE } from 'constants/api';
import { generateURL } from 'helpers';
import { FeedbackRequestFormValues } from 'pages/FeedbackRequests/interfaces';
import { storeName } from '../config';

export const updateFeedback = createAsyncThunk<
  FeedbackRequestRO,
  FeedbackRequestFormValues,
  {
    rejectValue: { errors: any };
  }
>(`${storeName}/updateFeedback`, async ({ id, ...data }, thunkAPI) =>
  api.axios
    .patch<FeedbackRequestRO>(
      generateURL(API_SCORECARD_FEEDBACK_UPDATE, { id: id as number }),
      data,
    )
    .then((res) => res.data)
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
