import { FeedbackRequestsState } from './interfaces';

export const feedbackRequestsInitialState: FeedbackRequestsState = {
  items: [],
  loading: false,
  pagination: {
    limit: 0,
    page: 0,
    total: 0,
  },
  filters: {
    reviewCycleIds: 'all',
  },
};

export const storeName = 'feedbackRequests';
