import {
  Box,
  BoxProps,
  Container as MUIContainer,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { FormHTMLAttributes } from 'react';

const HEADER_HEIGHT = 112;

export const Container = styled(MUIContainer)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  '&.hidden': {
    visibility: 'hidden',
    height: 0,
    overflow: 'hidden',
    maxHeight: 0,
    padding: 0,
  },
  '& .hasStickyHeader': {
    marginTop: `${HEADER_HEIGHT}px`,
  },
}));

export const PageHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(1.5),
  marginBottom: theme.spacing(1),
  maxHeight: `${HEADER_HEIGHT}px`,
  '.stickyHeaderContent': {
    display: 'none',
  },
  '&.sticky': {
    display: 'flex',
    alignItems: 'center',
    boxShadow: theme.shadows[26],
    width: '100vw',
    top: '64px',
    position: 'fixed',
    zIndex: 1000,
    margin: 0,
    left: 0,
    padding: `${theme.spacing(0.75)} 0`,
    background: theme.palette.common.white,
    '.MuiBreadcrumbs-root': {
      display: 'none',
    },
    '.secondaryPageTitle': {
      ...theme.typography.heading4,
    },
    '.MuiButton-root': {
      padding: '6px 16px',
      '&[class*="outlined"]': {
        padding: '4px 14px',
      },
    },
    '.stickyHeaderContent': {
      display: 'block',
    },
    '& > .MuiBox-root': {
      maxWidth: theme.breakpoints.values.lg,
      padding: `0 ${theme.spacing(1.5)}`,
    },
  },
}));

export const StickyPageHeaderBorder = styled(Box)(({ theme }) => ({
  zIndex: 1000,
  background: theme.palette.grey.A200,
  display: 'none',
  height: '16px',
  '&.visible': {
    display: 'block',
  },
}));

export const PageTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
}));

export const PageTitle = styled(Typography)(() => ({
  marginRight: 'auto',
}));

PageTitle.defaultProps = {
  variant: 'heading1',
};

export const SecondaryPageTitle = styled(Stack)(({ theme }) => ({
  ...theme.typography['heading1.2'],
  marginRight: 'auto',
  alignItems: 'center',
}));

SecondaryPageTitle.defaultProps = {
  className: 'secondaryPageTitle',
  direction: 'row',
};

type FormBoxProps = BoxProps & FormHTMLAttributes<HTMLFormElement>;

export const MainForm = styled(Box)<FormBoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
}));

MainForm.defaultProps = {
  noValidate: true,
  autoComplete: 'off',
  component: 'form',
  className: 'mainForm',
};
