import { createSlice, type Reducer } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash-es';
import { roadmapCommonInitialState, storeName } from './config';

export * from './interfaces';
export * from './selectors';

const common = createSlice({
  name: storeName,
  initialState: cloneDeep(roadmapCommonInitialState),
  reducers: {
    openEditDrawer: (state, action) => {
      state.editDrawer = {
        isOpen: true,
        node: cloneDeep(action.payload),
      };
    },
    closeEditDrawer: (state) => {
      state.editDrawer.isOpen = false;
    },
    openDetailsDrawer: (state, action) => {
      state.detailsDrawer = {
        isOpen: true,
        node: cloneDeep(action.payload),
      };
    },
    closeDetailsDrawer: (state) => {
      state.detailsDrawer.isOpen = false;
    },
    toggleFiltersDrawer: (state) => {
      state.isFiltersDrawerOpen = !state.isFiltersDrawerOpen;
    },
    toggleNewNodeDrawer: (state) => {
      state.isNewNodeDrawerOpen = !state.isNewNodeDrawerOpen;
    },
    toggleEditMode: (state) => {
      state.isEditMode = !state.isEditMode;
    },
    updateSeniorityFilter: (state, action) => {
      state.currentSeniority = action.payload;
    },
    toggleActiveFilter: (state, action) => {
      if (state.currentFilter?.name !== action.payload.name) {
        state.currentFilter = action.payload;
      } else {
        state.currentFilter = null;
      }
    },
    resetFilters: (state) => {
      state.currentSeniority = null;
      state.currentFilter = null;
    },
  },
});

export const {
  openDetailsDrawer,
  closeDetailsDrawer,
  openEditDrawer,
  closeEditDrawer,
  toggleFiltersDrawer,
  toggleNewNodeDrawer,
  toggleEditMode,
  updateSeniorityFilter,
  toggleActiveFilter,
  resetFilters,
} = common.actions;

export default common.reducer as Reducer<typeof roadmapCommonInitialState>;
