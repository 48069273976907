import { useAppDispatch } from 'store';
import { useCallback, useState } from 'react';
import { isFulfilled, AsyncThunkAction, PayloadAction } from '@reduxjs/toolkit';
import { ValidationRejectAction } from 'store/interfaces';

export type SuccessCallback<T extends {} = {}> =
  | ((
      action: PayloadAction<T> & { meta: any },
      dispatch: ReturnType<typeof useAppDispatch>,
    ) => void)
  | (() => void);

export type CatchCallback =
  | ((
      action: ValidationRejectAction,
      dispatch: ReturnType<typeof useAppDispatch>,
    ) => void)
  | (() => void);

export type FinalCallback =
  | ((dispatch: ReturnType<typeof useAppDispatch>) => void)
  | (() => void);

export default function useActionPipeline<T = {}>(
  onSuccess: SuccessCallback<T>,
  onCatch?: CatchCallback | null,
  onFinal?: FinalCallback,
) {
  const dispatch = useAppDispatch();
  const [processing, setProcessing] = useState(false);
  return useCallback(
    (action: AsyncThunkAction<any, any, {}>) => {
      if (!processing) {
        dispatch(action)
          .then((resAction) => {
            setProcessing(true);
            if (isFulfilled(resAction)) {
              onSuccess(resAction, dispatch);
            } else if (onCatch) {
              onCatch(resAction as ValidationRejectAction, dispatch);
            }
            if (onFinal) {
              onFinal(dispatch);
            }
          })
          .finally(() => setProcessing(false));
      }
    },
    [dispatch, onCatch, onFinal, onSuccess, processing],
  );
}
