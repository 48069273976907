import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_COMMENT_BY_ID } from 'constants/api';
import { generateURL } from 'helpers';
import { storeName } from '../config';
import { Comment } from '../interfaces';

export const editComment = createAsyncThunk<
  Comment,
  { threadId: number; content: string; commentId: number }
>(`${storeName}/editComment`, ({ threadId, content, commentId }, thunkAPI) =>
  api.axios
    .put<Comment>(generateURL(API_COMMENT_BY_ID, { threadId, commentId }), {
      content,
    })
    .then((res) => res.data)
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data))
    .then((res) => res)
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
