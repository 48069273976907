import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const selectCommonGoals = createSelector(
  (state: RootState) => state.commonGoals,
  (data) => data,
);

export const selectCommonGoalCategories = createSelector(
  selectCommonGoals,
  (data) => data.categories,
);
