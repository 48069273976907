import {
  Avatar,
  Button,
  IconButton,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import { useSuggestedUsers } from 'hooks';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { AddIcon } from 'components/Icons';
import { getUserPosition, resizeImage } from 'helpers';
import { OverflowTip } from 'components/Tooltip';
import { User } from 'store/interfaces';
import { Box, Stack } from '@mui/system';
import ContentLoader from 'components/ContentLoader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { List, ListItem, Position } from './UserSelectDialog.styled';

export default function SuggestedUsers({
  addUser,
  addAllSuggestedUsers,
  selectedUsers,
}: {
  addUser: (user: User) => void;
  addAllSuggestedUsers: (users: User[]) => void;
  selectedUsers: User[];
}) {
  const [isSuggested, setIsSuggested] = useState(false);
  const { users, loadMore, isFetching, hasMore, isLastPage } =
    useSuggestedUsers();

  const visibleUsers = users?.filter(
    (user) => !selectedUsers.find(({ id }) => id === user.id),
  );

  const handleSuggest = () => {
    loadMore();
    setIsSuggested(true);
  };

  return (
    <Box>
      {!isSuggested && (
        <Stack
          sx={{ marginY: '16px' }}
          justifyContent="center"
          alignItems="center"
        >
          <Button onClick={handleSuggest} sx={{ width: 'fit-content' }}>
            <FormattedMessage
              id="pages.scorecards.suggestReviewers"
              defaultMessage="Suggest Reviewers from Project"
            />
          </Button>
        </Stack>
      )}
      {isSuggested && (
        <Typography sx={{ marginTop: '16px' }} variant="body2">
          <FormattedMessage
            id="pages.scorecards.suggestedReviewers"
            defaultMessage="Suggested reviewers"
          />
        </Typography>
      )}
      {!!visibleUsers.length && isSuggested && !isFetching && (
        <Box sx={{ marginTop: '16px' }}>
          <List>
            <InfiniteScroll
              hasMore={hasMore}
              dataLength={visibleUsers.length}
              loader={<ContentLoader />}
              next={loadMore}
              scrollableTarget="setReviewersModal"
            >
              {visibleUsers.map((user) => (
                <ListItem
                  key={user.id}
                  id={`selected-user-${user.id}`}
                  secondaryAction={
                    <IconButton onClick={() => addUser(user)}>
                      <AddIcon color="primary" />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={user.fullName}
                      src={resizeImage(user.avatar)}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    disableTypography={false}
                    primaryTypographyProps={{ variant: 'h8' }}
                    secondaryTypographyProps={{ variant: 'body6' }}
                    primary={user.fullName}
                    secondary={
                      <OverflowTip>
                        {user.email}
                        <Position>{getUserPosition(user)}</Position>
                      </OverflowTip>
                    }
                  />
                </ListItem>
              ))}
            </InfiniteScroll>
          </List>
          {isLastPage && (
            <Stack justifyContent="center" alignItems="center">
              <Button
                startIcon={<AddIcon />}
                onClick={() => addAllSuggestedUsers(visibleUsers)}
                sx={{ marginY: '16px', width: 'fit-content' }}
              >
                <FormattedMessage
                  id="pages.scorecards.addAllSuggested"
                  defaultMessage="Add All Suggested Reviewers"
                />
              </Button>
            </Stack>
          )}
        </Box>
      )}
      {isFetching && <ContentLoader />}
      {!visibleUsers.length && isSuggested && !isFetching && (
        <Typography sx={{ marginY: '16px' }} align="center" variant="body2">
          <FormattedMessage
            id="pages.scorecards.noSuggestedReviewers"
            defaultMessage="No reviewers to suggest or everyone is already{br} added into the reviewers list"
            values={{
              br: <br />,
            }}
          />
        </Typography>
      )}
    </Box>
  );
}
