import { Stack, PaperProps } from '@mui/material';
import { PropsWithChildren } from 'react';
import { NodeWrapperStyled } from './NodeWrapper.styled';

export default function NodeWrapper({
  children,
  ...props
}: PropsWithChildren<PaperProps>) {
  return (
    <NodeWrapperStyled elevation={0} tabIndex={0} {...props}>
      <Stack spacing={0.2} direction="row" alignItems="flex-start">
        {children}
      </Stack>
    </NodeWrapperStyled>
  );
}
