export default function findIndexFromIndex<T extends any>(
  start: number,
  array: T[],
  condition: (item: T) => boolean,
): number {
  const endIndex = array.length - 1;
  let nextIndex = start + 1;
  for (let count = 0; count < array.length; count += 1) {
    if (nextIndex > endIndex) {
      nextIndex = 0;
    }
    if (condition(array[nextIndex])) {
      return nextIndex;
    }
    nextIndex += 1;
  }
  return -1;
}
