import { OkrMyTeamFilters, OkrMyTeamState, OkrTeamType } from './interfaces';

export const initialFilters: OkrMyTeamFilters = {
  okrReviewCycleIds: null,
  teamType: OkrTeamType.direct,
};

export const okrMyTeamInitialState: OkrMyTeamState = {
  users: [],
  usersPagination: {
    page: 0,
    limit: 50,
    total: 0,
  },
  totalScore: 0,
  usersLoading: false,
  filters: { ...initialFilters },
  init: false,
};

export const storeName = 'okrMyTeam';
