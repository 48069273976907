import { useCallback, useEffect } from 'react';
import { Transition } from 'history';
import useBlocker from './useBlocker';
import useSetHasDirtyForm from './useSetHasDirtyForm';

export default function usePrompt(message: string, when = true) {
  useSetHasDirtyForm(when);

  const blocker = useCallback(
    (tx: Transition) => {
      // eslint-disable-next-line no-alert
      if (window.confirm(message)) tx.retry();
    },
    [message],
  );

  const promptBeforeUnload = useCallback(
    (e: BeforeUnloadEvent) => {
      // eslint-disable-next-line no-alert
      if (when && !window.confirm(message)) {
        e.preventDefault();
        e.returnValue = message;
        return message;
      }
      return undefined;
    },
    [message, when],
  );

  useEffect(() => {
    window.addEventListener('beforeunload', promptBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', promptBeforeUnload);
    };
  }, [promptBeforeUnload]);

  useBlocker(blocker, when);
}
