import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_MAILING_SETTINGS } from 'constants/api';
import { MailingSettings } from 'store/interfaces';
import { storeName } from '../config';

export const getMailingSettings = createAsyncThunk<MailingSettings>(
  `${storeName}/getMailingSettings`,
  async (data, thunkApi) =>
    api.axios
      .get(API_MAILING_SETTINGS)
      .then((res) => res.data)
      .catch((e) => thunkApi.rejectWithValue(e.response?.data)),
);
