import { useFormContext } from 'react-hook-form';
import {
  FormGoal,
  GoalField,
  FormSubGoal,
} from 'components/GoalsAndSubgoalsForm/interfaces';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useNotEmptyValidation } from '../form';

export default function useUniqueNameValidation(
  name: 'goals' | `goals.${number}.subgoals`,
  index: number,
) {
  const { getValues } = useFormContext<GoalField>();
  const { formatMessage } = useIntl();
  const notEmptyValidationRule = useNotEmptyValidation();
  return {
    notEmpty: notEmptyValidationRule,
    uniqueName: useCallback(
      (value = '') => {
        const trimmed = value.trim();
        const [entity, items] = getValues(['entity', name]) as [
          string,
          (FormGoal | FormSubGoal)[],
        ];
        const type = name.includes('.subgoals') ? 'subgoal' : entity;
        const repeatingIndexes = items?.reduce((acc: number[], item, idx) => {
          if (item.name.trim() === trimmed && index !== idx) {
            acc.push(idx);
          }
          return acc;
        }, []);
        if (repeatingIndexes?.length > 0) {
          return formatMessage(
            {
              id: 'components.GoalForm.uniqueNameError',
              defaultMessage: `{type, select,
              subgoal {Subgoal}
              other {Goal}
            } with such name already exists in this {type, select,
              template {template}
              scorecard {scorecard}
              other {goal}
            }.`,
            },
            { type },
          );
        }
        return true;
      },
      [formatMessage, getValues, index, name],
    ),
  };
}
