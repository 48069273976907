import { createSlice, Reducer } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash-es';
import { hideToast, showToast } from './actions';
import { storeName, toastInitialState } from './config';
import addCommonCases from '../addCommonCases';

export * from './interfaces';
export * from './actions';

const toastSlice = createSlice({
  name: storeName,
  initialState: cloneDeep(toastInitialState),
  reducers: {},
  extraReducers(builder) {
    addCommonCases(builder, toastInitialState)
      .addCase(showToast, (state, action) => {
        Object.assign(state, {
          ...action.payload,
          open: true,
          hideOnClickAway: action.payload.hideOnClickAway ?? true,
        });
      })
      .addCase(hideToast, (state) => {
        Object.assign(state, {
          open: false,
        });
      });
  },
});

export default toastSlice.reducer as Reducer<typeof toastInitialState>;
