import { SyntheticEvent, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { commonTexts } from 'i18n';
import { UIOption } from 'store/options/interfaces/UIOption';

type FilterOption = {
  options: UIOption[];
  onOptionSelect: (value: string | number | null) => void;
};

export default function useFilters(
  options: Record<string, FilterOption>,
  hasAll: boolean = true,
) {
  const { formatMessage } = useIntl();

  const additionalOption: UIOption = useMemo(
    () => ({
      id: 'all',
      name: formatMessage(commonTexts.all),
    }),
    [formatMessage],
  );

  const filterOptions: Record<string, UIOption[]> = useMemo(
    () =>
      Object.keys(options).reduce(
        (acc, key) => ({
          ...acc,
          [key]: hasAll
            ? [additionalOption, ...options[key].options]
            : options[key].options,
        }),
        {},
      ),
    [additionalOption, hasAll, options],
  );

  const createFilterOnChangeHandler =
    (key: keyof typeof options) =>
    (e: SyntheticEvent, option: UIOption | null) => {
      const optionValue = !option || option?.id === 'all' ? null : option.id;
      const { onOptionSelect } = options[key];

      onOptionSelect(optionValue);
    };

  return {
    options: filterOptions,
    createFilterOnChangeHandler,
  };
}
