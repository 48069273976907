import { FEEDBACK_STATUS_DECLINED } from 'constants/feedbacks';
import { useScorecardData } from 'hooks';

export default function useIsSelfAssessmentFeedback(userId: number) {
  const { feedbackRequests } = useScorecardData();

  const feedbackRequest = feedbackRequests.find(
    ({ user }) => user.id === userId,
  );

  return feedbackRequest?.status === FEEDBACK_STATUS_DECLINED;
}
