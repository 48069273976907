import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/system';
import { ViewTypeIconProps } from 'components/Icons/interfaces';

export default function StandardViewTypeIcon({
  isActive,
  ...props
}: ViewTypeIconProps) {
  const theme = useTheme();
  return (
    <SvgIcon {...props} viewBox="0 0 32 32">
      <rect
        width="32"
        height="32"
        rx="4"
        fill={isActive ? theme.palette.common.white : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 10H13V13H10L10 10ZM8 10C8 8.89543 8.89543 8 10 8H13C14.1046 8 15 8.89543 15 10V13C15 14.1046 14.1046 15 13 15H10C8.89543 15 8 14.1046 8 13V10ZM19 10H22V13H19V10ZM17 10C17 8.89543 17.8954 8 19 8H22C23.1046 8 24 8.89543 24 10V13C24 14.1046 23.1046 15 22 15H19C17.8954 15 17 14.1046 17 13V10ZM13 19H10L10 22H13V19ZM10 17C8.89543 17 8 17.8954 8 19V22C8 23.1046 8.89543 24 10 24H13C14.1046 24 15 23.1046 15 22V19C15 17.8954 14.1046 17 13 17H10ZM19 19H22V22H19V19ZM17 19C17 17.8954 17.8954 17 19 17H22C23.1046 17 24 17.8954 24 19V22C24 23.1046 23.1046 24 22 24H19C17.8954 24 17 23.1046 17 22V19Z"
        fill={isActive ? theme.palette.blue['500'] : theme.palette.grey.A500}
      />
    </SvgIcon>
  );
}
