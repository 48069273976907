import { createAsyncThunk } from '@reduxjs/toolkit';
import { DeleteSuccessResponse } from 'store/interfaces';
import api from 'api';
import { updateOptions } from 'store/options';
import { API_REVIEW_CYCLE } from 'constants/api';
import { storeName } from '../config';

export const cycleDelete = createAsyncThunk<
  DeleteSuccessResponse,
  { id: number }
>(`${storeName}/delete`, async ({ id }, thunkAPI) =>
  api.axios
    .delete<DeleteSuccessResponse>(`${API_REVIEW_CYCLE}/${id}`)
    .then((res) => {
      thunkAPI.dispatch(updateOptions('reviewCycles'));
      return res.data;
    })
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
