import { SimpleEntity } from './SimpleEntity';

export enum AssessmentScaleGroupAlias {
  NPS = 'nps',
  PM_SCALE = 'pm_scale',
  STANDARD = 'standard',
  TEXT = 'raw_text',
  TEN_GRADE = '10_grade_scale',
  GRANULAR = 'granular_scale',
}

export const assessmentScaleGroupIdMap: Record<
  AssessmentScaleGroupAlias,
  number
> = {
  [AssessmentScaleGroupAlias.STANDARD]: 1,
  [AssessmentScaleGroupAlias.PM_SCALE]: 34,
  [AssessmentScaleGroupAlias.TEXT]: 36,
  [AssessmentScaleGroupAlias.NPS]: 37,
  [AssessmentScaleGroupAlias.TEN_GRADE]: 38,
  [AssessmentScaleGroupAlias.GRANULAR]: 39,
};

export interface ScorecardExternalSubgoalRO {
  id: number;
  name: string;
  description: string;
  orderRank: number;
  assessmentScaleGroup: SimpleEntity & { alias: AssessmentScaleGroupAlias };
}
