import { CommonState } from './interfaces';

export const storeName = 'roadmapCommonState';

export const roadmapCommonInitialState: CommonState = {
  isEditMode: false,
  isNewNodeDrawerOpen: false,
  isFiltersDrawerOpen: false,
  currentFilter: null,
  currentSeniority: null,
  editDrawer: {
    isOpen: false,
    node: null,
  },
  detailsDrawer: {
    node: null,
    isOpen: false,
  },
};
