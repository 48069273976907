import {
  BackdropProps,
  CircularProgress,
  Backdrop,
  BackdropComponentsPropsOverrides,
} from '@mui/material';

export default function PageLoader(
  props: BackdropProps & BackdropComponentsPropsOverrides,
) {
  return (
    <Backdrop {...props}>
      <CircularProgress />
    </Backdrop>
  );
}
