import { createSlice, Reducer } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash-es';
import { resetState } from 'store/actions';
import { changeFilter, getUserList } from './actions';
import { storeName, okrTeamOverviewInitialState } from './config';
import { clearFilters } from './actions/clearFilters';

export * from './interfaces';
export * from './actions';
export * from './selectors';

const objectives = createSlice({
  name: storeName,
  initialState: cloneDeep(okrTeamOverviewInitialState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetState, (state) => {
        const newState = cloneDeep(okrTeamOverviewInitialState);
        newState.filters = state.filters;
        Object.assign(state, newState);
      })
      .addCase(changeFilter, (state, { payload: { key, value } }) => {
        Object.assign(state, {
          filters: { ...state.filters, [key]: value },
          init: true,
        });
      })
      .addCase(clearFilters, (state) => {
        state.filters = okrTeamOverviewInitialState.filters;
      })
      .addCase(getUserList.pending, (state) => {
        if (state.usersPagination.page === 0) {
          state.usersLoading = true;
        }
      })
      .addCase(getUserList.fulfilled, (state, { payload }) => {
        state.usersPagination = payload.pagination;
        if (payload.pagination.page === 1) {
          state.users = payload.list;
        } else {
          state.users = [...state.users, ...payload.list];
        }
        state.usersLoading = false;
      })
      .addCase(getUserList.rejected, (state) => {
        state.usersLoading = false;
      });
  },
});

export default objectives.reducer as Reducer<
  typeof okrTeamOverviewInitialState
>;
