import {
  API_COUNTRY_STATS,
  API_OPTIONS,
  API_REVIEW_CYCLES_OPTIONS,
  API_SCORECARD_ASSESSMENT_GROUPS_OPTIONS,
  API_SCORECARD_MULTIPLIERS_OPTIONS,
  API_SCORECARD_PARAM_TYPE_OPTIONS,
} from 'constants/api';

export default function getOptionURL(name: string) {
  switch (name) {
    case 'extraMultipliers':
      return API_SCORECARD_MULTIPLIERS_OPTIONS;
    case 'assessmentScaleGroups':
      return API_SCORECARD_ASSESSMENT_GROUPS_OPTIONS;
    case 'reviewCycles':
      return API_REVIEW_CYCLES_OPTIONS;
    case 'countries':
      return API_COUNTRY_STATS;
    case 'paramTypes':
      return API_SCORECARD_PARAM_TYPE_OPTIONS;
    default:
      return `${API_OPTIONS}/${name}`;
  }
}
