import { RootState } from 'store';
import { DepartmentStoreName } from 'store/scorecards/department/config';
import { DirectReportStoreName } from 'store/scorecards/direct/config';
import { SubordinateStoreName } from 'store/scorecards/subordinates/config';
import {
  DepartmentFilters,
  DirectReportFilters,
  SubordinateFilters,
  ScorecardStores,
} from 'store/interfaces';

export const selectSlice = (name: ScorecardStores) => (state: RootState) =>
  state[name];

export const selectPagination = (name: ScorecardStores) => (state: RootState) =>
  state[name].pagination;

export const selectFilters =
  <S extends ScorecardStores>(name: S) =>
  (state: RootState) =>
    state[name].filters as S extends DepartmentStoreName
      ? DepartmentFilters
      : S extends DirectReportStoreName
      ? DirectReportFilters
      : SubordinateFilters;

export const selectData =
  (name: ScorecardStores, id: number) => (state: RootState) =>
    state[name]?.fullData?.[id];

export const selectListData =
  (name: ScorecardStores, id: number) => (state: RootState) =>
    state[name].items.find((item) => item.id === id);

export const selectCareerMentorOptions =
  (name: SubordinateStoreName | DepartmentStoreName | DirectReportStoreName) =>
  (state: RootState) =>
    state[name].careerMentorOptions;
