import { useIntl } from 'react-intl';
import { useUniqueNameValidation } from 'hooks/GoalsAndSubgoalsForm';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { formTexts } from 'i18n';
import FieldHolder from './FieldHolder.component';
import { FieldHolderWrapper } from './GoalsAndSubgoalsForm.styled';

export default function SubGoalName({
  parentFieldName,
  name,
  isCuSat,
  canEdit,
  isCustom,
  index,
  placeholder,
  dataTestId,
  selectOnFocus,
}: {
  parentFieldName: 'goals' | `goals.${number}.subgoals`;
  name: string;
  isCuSat: boolean;
  canEdit: boolean;
  isCustom: boolean;
  index: number;
  placeholder: string;
  dataTestId?: string;
  selectOnFocus?: boolean;
}) {
  const { formatMessage } = useIntl();
  const validationRules = useUniqueNameValidation(parentFieldName, index);
  const { trigger } = useFormContext();

  useEffect(() => {
    trigger(name);
  }, [name, trigger]);

  return (
    <FieldHolderWrapper>
      <FieldHolder
        name={name}
        disabled={isCuSat || !canEdit}
        maxLength={256}
        rules={{
          required: formatMessage(formTexts.errorRequired),
          validate: validationRules,
        }}
        type="growing-text"
        label={formatMessage(formTexts.name)}
        placeholder={placeholder}
        isCustomGoal={isCustom}
        fieldProps={{
          size: 'medium',
        }}
        selectOnFocus={selectOnFocus}
        dataTestId={dataTestId}
      />
    </FieldHolderWrapper>
  );
}

SubGoalName.defaultProps = {
  dataTestId: 'goal-name-field',
};
