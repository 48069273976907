import { Suspense, lazy } from 'react';
import PageLoader from 'components/PageLoader';
import { AppRouteComponentProps } from 'components/AppRoutes';
import { ReactFlowProvider } from 'reactflow';

const RoadmapComponent = lazy(() => import('./Roadmap.component'));
const DepartmentComponent = lazy(() => import('./Departments.component'));
const DrawersComponent = lazy(() => import('./Drawers'));

export function Roadmap(props: AppRouteComponentProps) {
  return (
    <Suspense fallback={<PageLoader open />}>
      <ReactFlowProvider>
        <RoadmapComponent {...props} />
        <DrawersComponent />
      </ReactFlowProvider>
    </Suspense>
  );
}

export function Departments(props: AppRouteComponentProps) {
  return (
    <Suspense fallback={<PageLoader open />}>
      <DepartmentComponent {...props} />
      <DrawersComponent />
    </Suspense>
  );
}
