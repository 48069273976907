import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_LOGOUT } from 'constants/api';
import { setIsLoggedIn } from 'helpers';
import { resetState } from 'store/actions';
import { storeName } from '../config';

export const logout = createAsyncThunk(
  `${storeName}/logout`,
  async (data, thunkApi) =>
    api.axios
      .post(API_LOGOUT)
      .then(() => {
        setIsLoggedIn(false);
        return true;
      })
      .catch((e) => thunkApi.rejectWithValue(e.response?.data))
      .finally(() => {
        thunkApi.dispatch(resetState());
      }),
);
