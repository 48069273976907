import { VERSION } from 'constants/appInfo';

export const REDUX_STATE_SAVE_TO_LOCALSTORAGE = [
  'scorecardsDirect.filters',
  'scorecardsSubordinates.filters',
  'scorecardsOwn.filters',
  'scorecardsDepartment.filters',
  'okrObjectives.filters',
  'okrMyTeam.filters',
  'okrTeamOverview.filters',
];

export const REDUX_STATE_NAMESPACE = `v${VERSION}`;
