import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { commonTexts } from 'i18n';
import Dialog from './ConfirmationDialog.styled';
import { DialogContent, DialogTitle, DialogActions } from '../Dialog';
import { ConfirmationDialogProps } from './interfaces';
import Loader from './Loader';

export default function ConfirmationDialog({
  onClose,
  onSubmit,
  content,
  title,
  submitText,
  cancelText,
  isSubmitting = false,
  submitDisabled = false,
}: ConfirmationDialogProps) {
  return (
    <Dialog
      fullWidth
      open
      onClose={onClose}
      disableEscapeKeyDown={isSubmitting}
    >
      <DialogTitle onClose={onClose}>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          data-testid="cancel-button"
        >
          {cancelText || <FormattedMessage {...commonTexts.cancel} />}
        </Button>
        <Button
          onClick={onSubmit}
          variant="contained"
          data-testid="delete-button"
          disabled={submitDisabled}
        >
          {submitText || <FormattedMessage {...commonTexts.delete} />}
        </Button>
      </DialogActions>
      {isSubmitting && <Loader />}
    </Dialog>
  );
}
