import { AuditLog, AuditLogRO } from 'store/interfaces';

export default function extractAuditLog<T>(
  auditLogs: AuditLogRO[],
  predicate?:
    | ((value: AuditLogRO, index: number, array: AuditLogRO[]) => boolean)
    | keyof T,
): AuditLog | null {
  let logs = (auditLogs || []).slice();

  if (predicate) {
    logs = logs.filter(
      typeof predicate === 'function'
        ? predicate
        : (auditLog) => predicate in auditLog.dataChangePayload,
    );
  }

  if (!logs.length) {
    return null;
  }

  const latestAuditLog: AuditLogRO = logs.sort(
    (a, b) => new Date(b.doneAt).getTime() - new Date(a.doneAt).getTime(),
  )[0];

  return {
    updatedAt: latestAuditLog.doneAt,
    updatedBy: latestAuditLog.actor,
  };
}
