import { Chip, styled, Theme } from '@mui/material';

const StyledLabel = styled(Chip)(({ theme }: { theme: Theme }) => ({
  padding: '4px 8px',
  borderRadius: 4,
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  height: 'auto',
  '.MuiChip-label': {
    padding: 0,
    ...theme.typography.subheadingSmall,
  },
}));

export default StyledLabel;
