import { defineMessages } from 'react-intl';

export default defineMessages({
  somethingWentWrongContactSupport: {
    id: 'error.somethingWentWrongContactSupport',
    defaultMessage: `Oh, no!
    {br}
    Something went wrong. Please try again or fill <feedbackButton>Feedback form</feedbackButton>.
    {br}
    {br}
    Details:
    {br}
    Error Code: {code}
    {br}
    Message: {description}`,
  },
  errorAcknowledgement: {
    id: 'error.errorAcknowledgement',
    defaultMessage: 'Oh, no! Something went wrong',
  },
  refreshPage: {
    id: 'error.refreshPage',
    defaultMessage: 'Please refresh the page',
  },
  noPermission: {
    id: 'error.noPermission',
    defaultMessage:
      'MARS failed to identify your permissions.{br}Please reach MARS Support team to solve the matter.',
  },
  noInternet: {
    id: 'error.noInternet',
    defaultMessage:
      'Internet connection lost.{br}Please check your internet connection and try again.',
  },
  page403Text: {
    id: 'error.page403Text',
    defaultMessage:
      'Your role does not allow you to view this page. Please reach your People Partner or MARS team for further assistance.',
  },
  page404Text: {
    id: 'error.page404Text',
    defaultMessage: "The page you were looking for doesn't exist.",
  },
});
