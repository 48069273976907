import { Box, styled } from '@mui/material';

export const Wrapper = styled(Box)(() => ({
  '.MuiOutlinedInput-root.MuiInputBase-multiline.inputSize-large': {
    padding: 0,
  },
  '.MuiOutlinedInput-input.MuiInputBase-inputMultiline': {
    minHeight: '24px',
    lineHeight: '22px',
    marginBottom: 0,
  },
  '.MuiOutlinedInput-root.MuiInputBase-multiline.inputSize-large ': {
    padding: '7px 12px',
  },
}));
