import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { OKR_REPORTING_PERIOD_OPTIONS } from 'constants/api';
import { storeName } from '../config';
import { OkrReviewCycle } from '../interfaces';

export const updateOkrReviewCycles = createAsyncThunk(
  `${storeName}/updateOkrReviewCycles`,
  () =>
    api.axios
      .get(OKR_REPORTING_PERIOD_OPTIONS)
      .then((res) => (res.data as { list: OkrReviewCycle[] })?.list || [])
      .catch(() => [])
      .then((res) => res),
);
