import { IconButton } from '@mui/material';
import { useIntl } from 'react-intl';
import { useNodeId } from 'reactflow';
import { MoveIcon } from '../Icons';

export default function DragHandler() {
  const nodeId = useNodeId();
  const { formatMessage } = useIntl();

  return (
    <IconButton
      size="small"
      className={nodeId ? `handler-${nodeId}` : undefined}
      aria-label={formatMessage({ id: 'common.move', defaultMessage: 'Move' })}
      sx={(theme) => ({
        position: 'absolute',
        left: '-1rem',
        top: '-1rem',
        borderRadius: '50%',
        border: `1px solid ${theme.palette.grey[500]}`,
        backgroundColor: theme.palette.common.white,
        cursor: 'grab',

        '&:hover, &:active': {
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.common.white,
        },

        '&:active': {
          cursor: 'grabbing',
        },
      })}
    >
      <MoveIcon />
    </IconButton>
  );
}
