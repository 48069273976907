import { Theme } from '@mui/material';

export default {
  styleOverrides: {
    root({ theme }: { theme: Theme }) {
      return {
        minHeight: 'unset' as const,
        padding: theme.spacing(0),
      };
    },
  },
};
