import { useSelector } from 'react-redux';
import { selectPermissions } from 'store/profile';
import { Permissions } from 'constants/permissions';

export default function useUserPermissions() {
  const permissions = useSelector(selectPermissions);
  const canViewReviewCycles = permissions?.find(
    (permission) => permission.name === Permissions.REVIEW_CYCLE_VIEW,
  );
  const canManageReviewCycles = permissions?.find(
    (permission) => permission.name === Permissions.REVIEW_CYCLE_MANAGE,
  );
  const canManageUsers = permissions?.find(
    (permission) => permission.name === Permissions.USERS_MANAGEMENT,
  );
  const canEditTemplates = permissions?.find(
    (permission) => permission.name === Permissions.SCORECARD_TEMPLATE_EDIT,
  );
  const canPublishTemplates = permissions?.find(
    (permission) => permission.name === Permissions.SCORECARD_TEMPLATE_PUBLISH,
  );
  const canViewTemplateRecipients = permissions?.find(
    (permission) =>
      permission.name === Permissions.SCORECARD_TEMPLATE_RECIPIENTS_VIEW,
  );
  const canDeleteScorecards = permissions?.find(
    (permission) => permission.name === Permissions.SCORECARD_DELETE,
  );
  const canManageCareerRoadmap = permissions?.find(
    (permission) => permission.name === Permissions.CAREER_ROADMAP_MANAGE,
  );
  const canDevDebug = permissions?.find(
    (permission) => permission.name === Permissions.DEV_DEBUG,
  );
  const canManageCommonGoals = permissions?.find(
    (permission) => permission.name === Permissions.COMMON_GOAL_MANAGE,
  );
  const canViewOkrs = permissions?.find(
    (permission) => permission.name === Permissions.MARS_OKR_MANAGE,
  );
  const canExportScorecards = permissions?.find(
    (permission) => permission.name === Permissions.SCORECARD_EXPORT,
  );

  return {
    canViewReviewCycles,
    canManageReviewCycles,
    canManageUsers,
    canEditTemplates,
    canPublishTemplates,
    canViewTemplateRecipients,
    canDeleteScorecards,
    canManageCareerRoadmap,
    canDevDebug,
    canManageCommonGoals,
    canViewOkrs,
    canExportScorecards,
  };
}
