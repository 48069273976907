import { UIOption } from 'store/options';
import { FilterOptionsState } from '@mui/material';

export default function filterAutocompleteOptions(
  options: UIOption[],
  params: FilterOptionsState<UIOption>,
): UIOption[] {
  const filtered = options.filter(({ name }) =>
    name?.toLowerCase()?.includes(params.inputValue?.toLowerCase()?.trim()),
  );

  if (params.inputValue.trim() !== '') {
    filtered.push({
      id: params.inputValue,
      name: `Add "${params.inputValue}"`,
    });
  }

  return filtered;
}
