import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/system';
import { ViewTypeIconProps } from 'components/Icons/interfaces';

export default function ExpandableViewTypeIcon({
  isActive,
  ...props
}: ViewTypeIconProps) {
  const theme = useTheme();
  return (
    <SvgIcon {...props} viewBox="0 0 32 32">
      <rect
        width="32"
        height="32"
        rx="4"
        fill={isActive ? theme.palette.common.white : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 9C8.44772 9 8 9.44772 8 10C8 10.5523 8.44772 11 9 11H23C23.5523 11 24 10.5523 24 10C24 9.44772 23.5523 9 23 9H9ZM9 13C8.44772 13 8 13.4477 8 14C8 14.5523 8.44772 15 9 15H23C23.5523 15 24 14.5523 24 14C24 13.4477 23.5523 13 23 13H9ZM8 18C8 17.4477 8.44772 17 9 17H23C23.5523 17 24 17.4477 24 18C24 18.5523 23.5523 19 23 19H9C8.44772 19 8 18.5523 8 18ZM9 21C8.44772 21 8 21.4477 8 22C8 22.5523 8.44772 23 9 23H23C23.5523 23 24 22.5523 24 22C24 21.4477 23.5523 21 23 21H9Z"
        fill={isActive ? theme.palette.blue['500'] : theme.palette.grey.A500}
      />
    </SvgIcon>
  );
}
