import { DirectReportState, DirectReportFilters } from './interfaces';

export const initialFilters: DirectReportFilters = {
  reviewCycleIds: null,
  userName: '',
  careerMentorIds: null,
  scorecardStatuses: null,
};

export const directReportScorecardInitialState: DirectReportState = {
  init: false,
  items: [],
  loading: false,
  filters: { ...initialFilters },
  pagination: {
    limit: 0,
    page: 1,
    total: 0,
  },
  fullData: {},
  careerMentorOptions: [],
};

export const storeName = 'scorecardsDirect' as const;

export type DirectReportStoreName = typeof storeName;
