import { Theme } from '@mui/material';
import { CheckedRadioIcon } from 'components/Icons';

export default {
  defaultProps: {
    checkedIcon: <CheckedRadioIcon />,
  },
  styleOverrides: {
    root({ theme }: { theme: Theme }) {
      return {
        padding: '7px 0',
        marginRight: 5,
        '&:hover': {
          color: theme.palette.primary.light,
          backgroundColor: 'transparent',
        },
        '&:pressed': {
          color: theme.palette.primary.dark,
        },
        '&.MuiRadio-colorError': {
          color: theme.palette.error.main,
        },
        '& .MuiSvgIcon-root': {
          fontSize: '1.3rem',
        },
      };
    },
  },
};
