import { InfoIcon, ErrorIcon } from 'components/Icons';
import { MessageWithIconProps } from './interfaces';
import { MessageWithIconContainer } from './MessageWithIcon.styled';

export default function MessageWithIcon({
  message,
  messageVariant,
  iconSize,
  ...props
}: MessageWithIconProps) {
  return (
    <MessageWithIconContainer
      messageVariant={messageVariant}
      iconSize={iconSize}
      {...props}
    >
      {messageVariant === 'error' ? <ErrorIcon /> : <InfoIcon />}
      {message}
    </MessageWithIconContainer>
  );
}

MessageWithIcon.defaultProps = {
  variant: 'body2',
  iconSize: 17,
  messageVariant: 'info',
};
