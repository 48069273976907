/* eslint-disable no-underscore-dangle */
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { OKR_OBJECTIVE_BY_ID } from 'constants/api';
import { showOLDialog } from 'store/optimisticLocking';
import dayjs from 'dayjs';
import { generateURL } from 'helpers';
import { CreateOkrObjectivePayload, OkrObjectiveResponse } from '../interfaces';
import { storeName } from '../config';

export const editOkrObjective = createAsyncThunk<
  OkrObjectiveResponse,
  { data: Partial<CreateOkrObjectivePayload>; periodId: string; id: string }
>(`${storeName}/editOkrObjective`, async ({ data, periodId, id }, thunkAPI) =>
  api.axios
    .put<OkrObjectiveResponse>(
      generateURL(OKR_OBJECTIVE_BY_ID, { periodId, id }),
      data,
    )
    .then((res) => res.data)
    .catch((e) => {
      // optimistic locking error handling
      if (e.response?.status === 412) {
        thunkAPI.dispatch(
          showOLDialog({
            type: 'scorecard',
            params: {
              name: e.response?.data?.__meta?.lastActivity?.actor.fullName,
              date: dayjs(
                e.response?.data?.__meta?.lastActivity?.doneAt,
              ).format('HH:mm DD.MM.YYYY'),
            },
          }),
        );
      }

      return thunkAPI.rejectWithValue({
        ...e.response?.data,
        response: {
          status: e.response?.status,
        },
      });
    }),
);
