import { Error404, Error403 } from 'components/Errors';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';
import { Container } from './PageContainer.styled';
import { PageContainerProps } from './interfaces';

export default function PageContainerComponent({
  hasAccess = false,
  redirect,
  children,
  visibleForPathname,
  notFound,
  maxWidth = 'lg',
  noVerticalPadding,
}: PageContainerProps) {
  const { pathname } = useLocation();
  const renderContent = () => {
    if (!hasAccess) {
      return <Error403 redirect={redirect} />;
    }

    if (notFound) {
      return <Error404 redirect={redirect} />;
    }

    return children;
  };
  return (
    <Container
      maxWidth={maxWidth}
      className={cx({
        hidden: visibleForPathname && visibleForPathname !== pathname,
        noVerticalPadding,
      })}
    >
      {renderContent()}
    </Container>
  );
}
