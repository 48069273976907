import { createSlice, Reducer } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash-es';
import {
  addComment,
  deleteComment,
  editComment,
  getComments,
  getThread,
  subscribeToThread,
  unsubscribeFromThread,
  setLastCommentId,
} from './actions';
import { storeName, commentsInitialState } from './config';
import addCommonCases from '../addCommonCases';

export * from './interfaces';
export * from './actions';
export * from './selectors';

const comments = createSlice({
  name: storeName,
  initialState: cloneDeep(commentsInitialState),
  reducers: {},
  extraReducers: (builder) => {
    addCommonCases(builder, commentsInitialState)
      .addCase(getComments.fulfilled, (state, action) => {
        if (!action.meta.arg.previousCursor && !action.meta.arg.nextCursor) {
          state.items = action.payload.list.reverse();
          state.loading = false;
          state.pagination = action.payload.pagination;
        } else if (action.meta.arg.previousCursor) {
          state.items = [...state.items, ...action.payload.list.reverse()];
          state.updating = false;
          state.pagination.previousCursor =
            action.payload.pagination.previousCursor;
        } else {
          state.items = [...action.payload.list.reverse(), ...state.items];
          state.updating = false;
          state.pagination.nextCursor =
            action.payload.pagination.nextCursor || state.pagination.nextCursor;
        }
      })
      .addCase(getComments.pending, (state, action) => {
        if (!action.meta.arg.previousCursor && !action.meta.arg.nextCursor) {
          state.loading = true;
        } else {
          state.updating = true;
        }
      })
      .addCase(addComment.fulfilled, (state, action) => {
        state.items = [action.payload, ...state.items];
        state.lastAddedCommentId = action.payload.id;
        state.adding = false;
      })
      .addCase(addComment.pending, (state) => {
        state.adding = true;
      })
      .addCase(editComment.fulfilled, (state, action) => {
        const index = state.items.findIndex(
          (item) => item.id === action.payload.id,
        );

        if (index !== -1) {
          state.items[index] = action.payload;
        }
        state.editing = false;
      })
      .addCase(editComment.pending, (state) => {
        state.editing = true;
      })
      .addCase(deleteComment.fulfilled, (state, action) => {
        const index = state.items.findIndex(
          (item) => item.id === action.meta.arg.commentId,
        );

        if (index !== -1) {
          state.items.splice(index, 1);
        }
        state.deleting = false;
      })
      .addCase(deleteComment.pending, (state) => {
        state.deleting = true;
      })
      .addCase(getThread.fulfilled, (state, action) => {
        state.thread = action.payload;
        state.loading = false;
      })
      .addCase(getThread.pending, (state) => {
        state.loading = true;
      })
      .addCase(subscribeToThread.fulfilled, (state, action) => {
        state.subscription = action.payload;
      })
      .addCase(unsubscribeFromThread.fulfilled, (state) => {
        state.subscription = null;
      })
      .addCase(setLastCommentId, (state, action) => {
        state.lastAddedCommentId = action.payload;
      });
  },
});

export default comments.reducer as Reducer<typeof commentsInitialState>;
