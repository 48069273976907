import { canLoadMore } from 'helpers';
import { useCallback, useState } from 'react';
import { Pagination } from 'store/interfaces';

type OwnProps =
  | {
      limit: number;
    }
  | undefined;

export default function useInfiniteScroll<T>(
  { limit }: OwnProps = { limit: 15 },
) {
  const [list, setList] = useState<T[]>([]);
  const [pagination, setPagination] = useState({
    page: 0,
    limit,
    total: 30,
  });

  const isInitialLoad = pagination.page === 0;
  const hasMore = canLoadMore(pagination);
  const isLastPage = list.length === pagination.total;

  const setValues = useCallback((newList: T[], newPagination: Pagination) => {
    setList(newList);
    setPagination(newPagination);
  }, []);

  return {
    list,
    setList,
    nextPage: pagination.page + 1,
    setValues,
    pagination,
    setPagination,
    hasMore,
    limit,
    isInitialLoad,
    isLastPage,
  };
}
