import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ScorecardFeedbackGoalModerateDto,
  Scorecard,
  ScorecardsActionStoreArgument,
} from 'store/interfaces';
import api from 'api';
import { API_SCORECARD_FEEDBACK_MODERATE } from 'constants/api';
import { generateURL } from 'helpers';
import { updateFullData } from './updateFullData';

export const moderateFeedback = createAsyncThunk<
  Scorecard,
  {
    id: number;
    data: ScorecardFeedbackGoalModerateDto;
    store: ScorecardsActionStoreArgument;
  }
>('scorecards/moderateFeedback', async ({ id, data, store }, thunkAPI) =>
  api.axios
    .patch<Scorecard>(
      generateURL(API_SCORECARD_FEEDBACK_MODERATE, { id }),
      data,
    )
    .then((res) => {
      thunkAPI.dispatch(updateFullData({ id, store }));
      return res.data;
    })
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
