import { Node } from 'reactflow';
import { NodeData } from 'store/interfaces';
import { omit } from 'lodash-es';
import { NodeTypesEnum } from 'constants/roadmap';
import { Dispatch, SetStateAction } from 'react';

export default function deleteNode(
  setNodes: Dispatch<SetStateAction<Node<NodeData>[]>>,
  node: Node<NodeData>,
) {
  if (node.type === NodeTypesEnum.GROUP) {
    setNodes((prev) =>
      prev.reduce<Node<NodeData>[]>((acc, item) => {
        if (item.id === node.id) {
          return acc;
        }

        if (item.parentNode === node.id) {
          acc.push(omit(item, ['parentNode', 'expandParent']));
          return acc;
        }

        acc.push(item);
        return acc;
      }, []),
    );

    return;
  }

  setNodes((prev) => prev.filter(({ id }) => node.id !== id));
}
