export const SCORECARD_STATUS_GOALS_PREPARED = 'goals_prepared';
export const SCORECARD_STATUS_GOALS_APPROVED = 'goals_approved';
export const SCORECARD_STATUS_FEEDBACK_COLLECTED = 'feedback_collected';
export const SCORECARD_STATUS_FEEDBACK_REQUESTED = 'feedback_requested';
export const SCORECARD_STATUS_ACTIVE = 'active';
export const SCORECARD_STATUS_COMPLETED = 'completed';
export const SCORECARD_STATUS_READY_FOR_REVIEW = 'ready_for_review';
export const SCORECARD_STATUS_DELETED = 'deleted';

export const FEEDBACK_REQUEST_GOAL_STATUS_PENDING = 'pending';
export const FEEDBACK_REQUEST_GOAL_STATUS_ACCEPTED = 'accepted';

export const SCORECARD_TEMPLATE_STATUS_PUBLISHED = 'published';

export const FEEDBACK_NOT_REQUESTED_STATUSES = [
  SCORECARD_STATUS_ACTIVE,
  SCORECARD_STATUS_GOALS_PREPARED,
  SCORECARD_STATUS_GOALS_APPROVED,
];

export const NOT_READY_FOR_REVIEW_STATUSES = [
  SCORECARD_STATUS_ACTIVE,
  SCORECARD_STATUS_GOALS_PREPARED,
  SCORECARD_STATUS_GOALS_APPROVED,
  SCORECARD_STATUS_FEEDBACK_REQUESTED,
  SCORECARD_STATUS_FEEDBACK_COLLECTED,
];

export const SCORECARD_ACTION_ASSESSMENT_COMPLETE =
  'action_assessment_complete';
export const SCORECARD_ACTION_ASSESSMENT_REVIEW = 'action_assessment_review';
export const SCORECARD_ACTION_ASSESSMENT_REQUEST = 'action_assessment_request';
export const SCORECARD_ACTION_GOALS_CONFIRM = 'action_goals_confirm';
export const SCORECARD_ACTION_GOALS_APPROVE = 'action_goals_approve';
export const SCORECARD_ACTION_GOALS_REDRAFT = 'action_goals_redraft';
export const SCORECARD_ACTION_GOALS_IN_PROGRESS_REVERT =
  'action_in_progress_goals_revert';

export const SCORECARD_COMMON_GOAL_TYPE_REGULAR = 'regular';
export const SCORECARD_COMMON_GOAL_TYPE_CUSAT = 'common_cusat';
export const SCORECARD_GOAL_TYPE_CUSAT = 'cusat';

export const CALCULATE_SELF_ASSESSMENT_PARAMETER_TYPE =
  'goal:regular:include_self-assessment';

export const ALLOW_SCORECARD_OWNER_EDITS_PARAMETER_TYPE =
  'goal:regular:allow_owner_edit';

export const ALLOW_SCORECARD_COMMENT_PARAMETER_TYPE =
  'goal:regular:feedback:allow_comment';
export const ENABLE_SELF_ASSESSMENT_PARAMETER_TYPE =
  'goal:regular:enable_self-assessment';
