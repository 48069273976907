import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import {
  OKR_PENDING_KEY_RESULTS,
  OKR_PENDING_KEY_RESULTS_FOR_USER,
} from 'constants/api';
import { generateURL } from 'helpers';
import { storeName } from '../config';
import { OkrPendingKeyResult } from '../interfaces';

export const updateOkrPendingKeyResults = createAsyncThunk(
  `${storeName}/updateOkrPendingKeyResults`,
  ({ periodId, userId }: { periodId: string; userId?: string }) =>
    api.axios
      .get(
        generateURL(
          userId ? OKR_PENDING_KEY_RESULTS_FOR_USER : OKR_PENDING_KEY_RESULTS,
          { periodId, userId: userId || '' },
        ),
      )
      .then((res) => (res.data as { list: OkrPendingKeyResult[] })?.list || [])
      .catch(() => [])
      .then((res) => res),
);
