import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ArrowIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M9.6 18L8 16.5L12.8 12L8 7.5L9.6 6L16 12L9.6 18Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
