export function formatErrorDescription(error: unknown) {
  if (!error) {
    return '-';
  }
  if (typeof error === 'string') {
    return error;
  }
  if (error && typeof error === 'object') {
    // ErrorEvent returns [object ErrorEvent]
    return error.toString().startsWith('[object') ? '-' : error.toString();
  }
  return '-';
}
