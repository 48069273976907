import { createAsyncThunk } from '@reduxjs/toolkit';
import { CycleTemplate } from 'store/interfaces';
import api from 'api';
import { API_SCORECARD_TEMPLATE } from 'constants/api';
import { storeName } from '../config';

export const templateGet = createAsyncThunk<CycleTemplate, { id: number }>(
  `${storeName}/getTemplate`,
  async ({ id }, thunkAPI) =>
    api.axios
      .get<CycleTemplate>(`${API_SCORECARD_TEMPLATE}/${id}`)
      .then((res) => res.data)
      .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
