import {
  createTheme,
  responsiveFontSizes,
  PaletteOptions,
} from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';

import components from './components';
import './fonts.css';
import './index.css';
import palette from './palette';
import typography from './typography';
import shadows from './shadows';

const theme = responsiveFontSizes(
  createTheme({
    spacing: 16,
    breakpoints: {
      values: {
        xs: 0,
        sm: 696,
        md: 1200,
        lg: 1440,
        xl: 1920,
      },
    },
    palette: palette as PaletteOptions,
    typography: typography as TypographyOptions,
    components,
  }),
);

theme.shadows.push(...shadows);

export default theme;
