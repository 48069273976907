import { Tooltip } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { getCommonGoalsList, selectCommonGoals } from 'store/commonGoals';
import { TemplateGoal } from 'store/interfaces';
import { useWatch, useFormContext } from 'react-hook-form';
import { isCuSatGoal, parseParams } from 'helpers';
import { SCORECARD_COMMON_GOAL_TYPE_CUSAT } from 'constants/scorecards';
import OneLineTextWithTooltip from 'components/OneLineTextWithTooltip';
import SuperButton, { GroupedMenuItem } from 'components/SuperButton';
import { InfoIcon } from 'components/Icons';
import { CommonGoalsByCategory } from 'store/commonGoals/interfaces/CommonGoalsByCategory';
import {
  CommonGoalMenuSubItem,
  TitleWrapper,
  TitleName,
} from './GoalsAndSubgoalsForm.styled';
import { AddCommonGoalButtonProps, FormGoal, GoalField } from './interfaces';

function AddCommonGoalButton({ onAddGoal }: AddCommonGoalButtonProps) {
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const dispatch = useAppDispatch();
  const { items, loading } = useSelector(selectCommonGoals);
  const goals = useWatch({ name: 'goals' }) as FormGoal[];
  const { getValues } = useFormContext<GoalField>();
  const entity = getValues('entity');
  const isTemplate = entity === 'template';
  const isCuSatAdded = useMemo(
    () => !!goals.find(({ type }) => type === SCORECARD_COMMON_GOAL_TYPE_CUSAT),
    [goals],
  );
  useEffect(() => {
    dispatch(getCommonGoalsList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderMenuItem = useCallback(
    (
      option: string | CommonGoalsByCategory,
      index: number,
      hideMenu: () => void,
    ) => {
      const menuItem = option as CommonGoalsByCategory;

      const handleMenuItemClick = (selected: string | TemplateGoal) => {
        hideMenu();
        const { id, country, unit, description, params, ...goalForm } =
          selected as TemplateGoal;
        const {
          allowScorecardOwnerEdits,
          allowScorecardComment,
          selfAssessmentSetup,
        } = parseParams(params);

        const newGoal = {
          ...goalForm,
          // BE sends countries array which is not expected by the API.
          countries: undefined,
          description: description ?? undefined,
          weight: 0,
          allowScorecardOwnerEdits: isTemplate
            ? allowScorecardOwnerEdits ?? false
            : true,
          allowScorecardComment: allowScorecardComment ?? false,
          selfAssessmentSetup,
          subgoals: goalForm.subgoals.map(
            ({ id: subgoalId, assessmentScaleGroup, ...subgoalForm }) => ({
              ...subgoalForm,
              assessmentScaleGroupId: assessmentScaleGroup?.id,
            }),
          ),
          params: undefined,
        } as FormGoal;
        if (isCuSatGoal(newGoal)) {
          Object.assign(newGoal, {
            fromGoalTemplateId: id,
          });
        }
        onAddGoal(newGoal);
      };

      return (
        <GroupedMenuItem<TemplateGoal>
          key={menuItem.id}
          name={menuItem.name}
          options={menuItem.goals}
          expanded={expandedIndex === index}
          onClick={() =>
            setExpandedIndex((current) => (current === index ? -1 : index))
          }
          renderSubItem={(subItem) => {
            const { id, name, subgoals } = subItem as TemplateGoal;
            const isDisabledCuSat =
              isCuSatGoal(subItem as TemplateGoal) && isCuSatAdded;
            const result = (
              <CommonGoalMenuSubItem
                key={`${menuItem.id}-${id}`}
                disabled={isDisabledCuSat}
                onClick={
                  isDisabledCuSat
                    ? (e) => e.stopPropagation()
                    : () => handleMenuItemClick(subItem)
                }
              >
                <TitleWrapper>
                  <TitleName sx={{ marginLeft: '32px' }}>
                    <OneLineTextWithTooltip variant="body2" text={name} />
                  </TitleName>
                  {!isDisabledCuSat && (
                    <Tooltip
                      placement="right"
                      title={subgoals.map(
                        ({ name: subgoalName }, subgoalIndex) => (
                          <div key={subgoalName}>
                            <div>{subgoalName}</div>
                            {subgoalIndex < subgoals.length - 1 && <br />}
                          </div>
                        ),
                      )}
                    >
                      <InfoIcon color="info" />
                    </Tooltip>
                  )}
                </TitleWrapper>
              </CommonGoalMenuSubItem>
            );
            if (isDisabledCuSat) {
              return (
                <Tooltip
                  key={`${menuItem.id}-${id}-tooltip`}
                  placement="right"
                  title={
                    <FormattedMessage
                      id="pages.scorecards.cuSatExists"
                      defaultMessage="Customer Satisfaction goal already exists"
                    />
                  }
                >
                  {result}
                </Tooltip>
              );
            }
            return result;
          }}
        />
      );
    },
    [expandedIndex, isCuSatAdded, isTemplate, onAddGoal],
  );

  return (
    <SuperButton<CommonGoalsByCategory>
      loading={loading}
      disabled={!items?.length}
      size="medium"
      variant="outlined"
      options={items}
      title={
        <FormattedMessage
          id="pages.reviewCycles.addCommonGoal"
          defaultMessage="Add Common Goal"
        />
      }
      renderMenuItem={renderMenuItem}
      fullWidth
      popperProps={{
        placement: 'bottom-end',
        sx: {
          zIndex: 1499,
        },
      }}
      dataTestId="add-common-goal-button"
    />
  );
}

export default AddCommonGoalButton;
