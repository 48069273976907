import { Theme } from '@mui/material';

export default {
  defaultProps: {
    divider: true,
  },
  styleOverrides: {
    root({ theme }: { theme: Theme }) {
      return {
        fontSize: theme.typography.pxToRem(16),
        lineHeight: 1.375,
        borderColor: theme.palette.grey.A400,
        '.SelectMenuSize-small &': {
          padding: '5px 10px',
        },
        '.SelectMenuSize-medium &': {
          padding: '7px 10px',
        },
        '.SelectMenuSize-large &': {
          padding: '9px 10px',
        },
        '&:hover, &.Mui-focusVisible': {
          background: theme.palette.blue['100'],
        },
        '&:active, &.Mui-selected': {
          background: theme.palette.blue['200'],
        },
        '&.Mui-disabled': {
          color: theme.palette.grey['600'],
          background: theme.palette.grey['300'],
          opacity: 1,
        },
        '&.MuiButtonBase-root': {
          fontSize: theme.typography.pxToRem(16),
        },
      };
    },
    divider: {
      '&:last-of-type': {
        borderBottomWidth: 0,
      },
    },
  },
};
