import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/system';
import { ViewTypeIconProps } from 'components/Icons/interfaces';

export default function ExpandedViewTypeIcon({
  isActive,
  ...props
}: ViewTypeIconProps) {
  const theme = useTheme();
  return (
    <SvgIcon {...props} viewBox="0 0 32 32">
      <rect
        width="32"
        height="32"
        rx="4"
        fill={isActive ? theme.palette.common.white : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 10H21C21.5523 10 22 10.4477 22 11V12C22 12.5523 21.5523 13 21 13H11C10.4477 13 10 12.5523 10 12V11C10 10.4477 10.4477 10 11 10ZM8 11C8 9.34315 9.34315 8 11 8H21C22.6569 8 24 9.34315 24 11V12C24 13.6569 22.6569 15 21 15H11C9.34315 15 8 13.6569 8 12V11ZM11 19H21C21.5523 19 22 19.4477 22 20V21C22 21.5523 21.5523 22 21 22H11C10.4477 22 10 21.5523 10 21V20C10 19.4477 10.4477 19 11 19ZM8 20C8 18.3431 9.34315 17 11 17H21C22.6569 17 24 18.3431 24 20V21C24 22.6569 22.6569 24 21 24H11C9.34315 24 8 22.6569 8 21V20Z"
        fill={isActive ? theme.palette.blue['500'] : theme.palette.grey.A500}
      />
    </SvgIcon>
  );
}
