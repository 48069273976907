/* eslint-disable no-underscore-dangle */
import { useForm } from 'react-hook-form';
import { FormValues } from 'components/ScorecardDetails/interfaces';
import {
  formatScorecardData,
  parseScorecardData,
  getScorecardStates,
  isFormDirty,
} from 'helpers';
import { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import commonMessages from 'i18n/common';
import { patchScorecard, selectListData } from 'store/scorecards';
import {
  useToast,
  usePagePermissions,
  useResetAfterSubmit,
  usePrompt,
} from 'hooks';
import { Scorecard, ScorecardStores } from 'store/interfaces';
import { commonTexts } from 'i18n';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';

export default function useScorecardFormValues(
  scorecard: Scorecard,
  store: ScorecardStores,
) {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { goals, id, status } = scorecard;
  const { version } = useSelector(selectListData(store, id)) || {};
  const {
    isFeedbackRequested,
    isFeedbackCollected,
    isReadyForReview,
    isActive,
  } = getScorecardStates(status);

  const form = useForm<FormValues>({
    defaultValues: {
      goals: parseScorecardData(goals),
      id,
      totalWeight: 0,
      entity: 'scorecard',
    },
    mode: 'onChange',
    criteriaMode: 'all',
  });
  const {
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    formState,
  } = form;
  const isDirty = isFormDirty(formState);

  useResetAfterSubmit(form);
  usePrompt(formatMessage(commonTexts.unsavedChangesPrompt), isDirty);

  useEffect(() => {
    goals.forEach(({ weight }, index) => {
      setValue(`goals.${index}.weight`, weight);
    });
  }, [setValue, goals]);
  const goalsFields = watch('goals');
  const showMessage = useToast();
  const canSubmit =
    isDirty && goalsFields?.length && !Object.keys(errors).length;
  const { isOwnScorecardsPage } = usePagePermissions();
  const withRestrictions =
    isOwnScorecardsPage ||
    isFeedbackCollected ||
    isFeedbackRequested ||
    isReadyForReview;

  const isActiveOwnScorecard = isActive && isOwnScorecardsPage;

  const onSubmit = useCallback(
    async (data: FormValues) => {
      try {
        const response = await dispatch(
          patchScorecard({
            id,
            store,
            data: formatScorecardData(
              data,
              version as number,
              withRestrictions,
              isActiveOwnScorecard,
            ),
          }),
        ).unwrap();
        parseScorecardData(response.goals).forEach((goal, index) => {
          setValue(`goals.${index}`, goal);
        });

        showMessage({
          severity: 'success',
          message: formatMessage(commonMessages.changesSavedSuccessfully),
        });
      } catch (error: any) {
        //
      }
    },
    [
      dispatch,
      id,
      store,
      version,
      withRestrictions,
      isActiveOwnScorecard,
      showMessage,
      formatMessage,
      setValue,
    ],
  );

  const onSubmitClick = useMemo(
    () => handleSubmit(onSubmit),
    [handleSubmit, onSubmit],
  );
  return {
    onSubmitClick,
    form,
    canSubmit,
  };
}
