import { useWatch } from 'react-hook-form';
import TextWithLinks from 'components/TextWithLinks';
import { DisabledFormFieldWithLinksProps } from './interfaces/DisabledFormFieldWithLinksProps';
import {
  DisabledField,
  DisabledFieldContainer,
  DisabledFieldLabel,
  DisabledFieldLabelRequired,
} from './GoalsAndSubgoalsForm.styled';

export default function DisabledFormFieldWithLinks(
  props: DisabledFormFieldWithLinksProps,
) {
  const { label, required, name, type, dataTestId } = props;
  const value = useWatch({ name });

  return (
    <DisabledFieldContainer>
      <DisabledFieldLabel>
        {label}
        {required && (
          <DisabledFieldLabelRequired component="span">
            *
          </DisabledFieldLabelRequired>
        )}
      </DisabledFieldLabel>
      <DisabledField type={type} data-testid={dataTestId}>
        <TextWithLinks>{value}</TextWithLinks>
      </DisabledField>
    </DisabledFieldContainer>
  );
}
