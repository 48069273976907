import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import {
  API_ALL_CAREER_MENTORS_OPTIONS,
  API_SUBORDINATE_CAREER_MENTORS_OPTIONS,
} from 'constants/api';
import {
  SubordinateStoreName,
  storeName as subordinateStoreName,
} from 'store/scorecards/subordinates/config';
import { User } from 'store/interfaces';
import { DepartmentStoreName } from 'store/scorecards/department/config';

type Stores = DepartmentStoreName | SubordinateStoreName;

export const careerMentorsGet = createAsyncThunk<
  { list: User[] },
  { store: Stores }
>(`scorecards/careerMentorsGet`, async ({ store }, thunkApi) => {
  const url =
    store === subordinateStoreName
      ? API_SUBORDINATE_CAREER_MENTORS_OPTIONS
      : API_ALL_CAREER_MENTORS_OPTIONS;
  return api.axios
    .get(url)
    .then((res) => res.data)
    .catch((e) => thunkApi.rejectWithValue(e.response?.data));
});
