import { Theme } from '@mui/material';

export default {
  styleOverrides: {
    root({ theme }: { theme: Theme }) {
      return {
        borderTop: `1px solid ${theme.palette.grey['300']}`,
        padding: 20,
        '>:not(:first-of-type)': {
          marginLeft: 20,
        },
      };
    },
  },
};
