import { OkrObjectivesFilters, OkrObjectivesState } from './interfaces';

export const initialFilters: OkrObjectivesFilters = {
  okrReviewCycleIds: null,
};

export const okrObjectivesInitialState: OkrObjectivesState = {
  items: [],
  loadedReportingPeriod: null,
  totalScore: 0,
  loading: false,
  filters: { ...initialFilters },
  user: null,
  init: false,
};

export const storeName = 'okrObjectives';
