export default function GroupNodeIcon() {
  return (
    <svg
      width="97"
      height="64"
      viewBox="0 0 97 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.17188"
        y="0.5"
        width="95"
        height="63"
        rx="11.5"
        fill="#CCD3DF"
      />
      <rect x="16.6719" y="16" width="30" height="14" rx="4" fill="#E6E9EF" />
      <rect x="20.6719" y="19" width="22" height="8" rx="4" fill="#768691" />
      <rect x="50.6719" y="16" width="30" height="14" rx="4" fill="#E6E9EF" />
      <rect x="54.6719" y="19" width="22" height="8" rx="4" fill="#768691" />
      <rect x="16.6719" y="34" width="30" height="14" rx="4" fill="#E6E9EF" />
      <rect x="20.6719" y="37" width="22" height="8" rx="4" fill="#768691" />
      <rect x="50.6719" y="34" width="30" height="14" rx="4" fill="#E6E9EF" />
      <rect x="54.6719" y="37" width="22" height="8" rx="4" fill="#768691" />
      <rect
        x="1.17188"
        y="0.5"
        width="95"
        height="63"
        rx="11.5"
        stroke="#CCD3DF"
      />
    </svg>
  );
}
