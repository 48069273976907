import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { formTexts } from 'i18n';
import { isNaN } from 'lodash-es';

export default function useNumberValidator(
  max: number,
  min: number = 0,
  isToLocaleString = false,
) {
  const { formatMessage } = useIntl();

  return useCallback(
    (value: string | number = 0) => {
      if (value === '') {
        return true;
      }
      const numberValue = Number(value);
      if (isNaN(numberValue)) {
        return formatMessage(formTexts.incorrectFormat);
      }

      if (numberValue < min) {
        return formatMessage(formTexts.mustBeGreaterOrEqual, {
          minValue: isToLocaleString ? min.toLocaleString() : min,
        });
      }

      if (numberValue > max) {
        return formatMessage(formTexts.mustBeLessOrEqual, {
          maxValue: isToLocaleString ? max.toLocaleString() : max,
        });
      }

      return true;
    },
    [formatMessage, max, min, isToLocaleString],
  );
}
