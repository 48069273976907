import { styled } from '@mui/material/styles';
import { Button as MuiButton, Theme } from '@mui/material';
import { HEADER_HEIGHT } from 'components/Header';

export const Background = styled('div')(({ theme }) => ({
  justifyContent: 'center',
  backgroundColor: theme.palette.purple['700'],
  height: '100vh',
  marginTop: `-${HEADER_HEIGHT}px`,
}));

export const Container = styled('div')(({ theme }: { theme: Theme }) => ({
  color: theme.palette.common.white,
  display: 'flex',
  columnGap: '110px',
  paddingTop: '40vh',
}));

export const LogInForm = styled('div')(({ theme }: { theme: Theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: '35px 40px',
  borderRadius: '8px',
  color: theme.palette.common.black,
  width: '100%',
  maxWidth: '364px',
}));

export const Button = styled(MuiButton)({
  padding: '12px 12px',
  width: '100%',
  fontSize: '16px',
});

export const LoginContainer = styled('div')({
  flexBasis: '0',
  flexGrow: '1',
  maxWidth: '100%',
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'flex-start',
  marginTop: '33px',
});

export const Block = styled('div')({
  flexBasis: '0',
  flexGrow: '1',
  maxWidth: '100%',
});

export const ButtonContainer = styled('div')({
  paddingTop: '30px',
});
