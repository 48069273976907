import { styled, Box, Typography, CircularProgress } from '@mui/material';
import { InfoIcon } from 'components/Icons';

export const Icon = styled(InfoIcon)(({ theme }) => ({
  color: theme.palette.grey['600'],
}));

export const VersionsWrapper = styled(Box)({
  display: 'flex',
  padding: '0 8px',
});

export const VersionInfo = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.grey.A300,
  borderRadius: '4px',
  padding: '4px 8px',
  ':first-of-type': {
    marginRight: 8,
  },
  ':last-of-type': {
    marginLeft: 8,
  },
  span: {
    marginLeft: 4,
  },
}));

export const Progress = styled(CircularProgress)({
  marginLeft: 10,
});
