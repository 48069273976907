import { Theme } from '@mui/material';
import { ComponentsOverrides } from '@mui/material/styles/overrides';

export default {
  styleOverrides: {
    root: {
      borderRadius: '4px',
    },
    sizeSmall: {
      maxWidth: 32,
      maxHeight: 32,
      width: 32,
      height: 32,
    },
  } as ComponentsOverrides<Theme>['MuiIconButton'],
};
