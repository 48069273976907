import { useEffect } from 'react';
import { setHasDirtyForm } from 'store/uiState';
import { useAppDispatch } from 'store';

export default function useSetHasDirtyForm(isDirty: boolean) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setHasDirtyForm(isDirty));
  }, [dispatch, isDirty]);

  useEffect(
    () => () => {
      dispatch(setHasDirtyForm(false));
    },
    [dispatch],
  );
}
