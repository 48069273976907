import { RootState } from 'store';
import { OkrObjectiveResponse } from './interfaces';

export const selectObjectives = (state: RootState) =>
  (state.okrObjectives.items as OkrObjectiveResponse[]) || [];
export const selectObjectivesLoading = (state: RootState) =>
  state.okrObjectives.loading;
export const selectObjectiveById = (state: RootState, id: number | null) =>
  state.okrObjectives.items?.find((objective) => objective.id === id) || null;
export const selectObjectivesLoadedReportingPeriod = (state: RootState) =>
  state.okrObjectives.loadedReportingPeriod;

export const selectObjectivesFilters = (state: RootState) =>
  state.okrObjectives.filters;

export const selectObjectivesTotalScore = (state: RootState) =>
  state.okrObjectives.totalScore;

export const selectObjectivesUser = (state: RootState) =>
  state.okrObjectives.user;
