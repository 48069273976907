import { useMatch } from 'react-router-dom';
import {
  URL_MY_SCORECARDS,
  URL_MY_TEAM,
  URL_DEPARTMENT_OVERVIEW,
  URL_FEEDBACK_REQUESTS,
} from 'constants/urls';
import { useMemo } from 'react';

export default function usePagePermissions() {
  const isOwnScorecardsPage = useMatch(`${URL_MY_SCORECARDS}/*`) !== null;
  const isMyTeamPage = useMatch(`${URL_MY_TEAM}/*`) !== null;
  const isDepartmentOverviewPage =
    useMatch(`${URL_DEPARTMENT_OVERVIEW}/*`) !== null;
  const hasFullAccess = isMyTeamPage || isDepartmentOverviewPage;
  const isFeedbackRequestsPage = useMatch(URL_FEEDBACK_REQUESTS) !== null;
  return useMemo(
    () => ({
      isOwnScorecardsPage,
      isMyTeamPage,
      isDepartmentOverviewPage,
      hasFullAccess,
      isFeedbackRequestsPage,
    }),
    [
      hasFullAccess,
      isDepartmentOverviewPage,
      isMyTeamPage,
      isOwnScorecardsPage,
      isFeedbackRequestsPage,
    ],
  );
}
