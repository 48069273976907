import { ValidationRejectAction } from 'store/interfaces';
import { UseFormSetError } from 'react-hook-form/dist/types/form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { FieldPath } from 'react-hook-form/dist/types/path';

const handleResponseErrors =
  <T extends FieldValues>(
    setError: UseFormSetError<T>,
  ): ((action: ValidationRejectAction) => void) =>
  ({ payload }: ValidationRejectAction) => {
    const { errors } = payload || {};

    if (errors) {
      Object.keys(errors).forEach((errName) => {
        setError(errName as FieldPath<T>, errors[errName]);
        // While setting the BE error 'react-hook-form' also set an additional error:
        // ref: undefined.
        // Perhaps it happens because we're using unregistered inputs.
        // This error will prevent form resubmission.
        // Could be fixed by something like this:
        // clearErrors(`${errName}.ref` as FieldPath<T>);
      });
    }
  };

export default handleResponseErrors;
