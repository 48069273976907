import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_COMMON_GOAL_CATEGORY_BY_ID } from 'constants/api';
import { generateURL } from 'helpers';
import { storeName } from '../config';

export const deleteCommonGoalsCategory = createAsyncThunk<null, { id: number }>(
  `${storeName}/deleteCommonGoalsCategory`,
  async ({ id }, thunkAPI) =>
    api.axios
      .delete(generateURL(API_COMMON_GOAL_CATEGORY_BY_ID, { id }))
      .then((res) => res.data)
      .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
