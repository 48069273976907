import { Typography, Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { errorTexts, commonTexts } from 'i18n';
import { NavLink } from 'react-router-dom';
import { EmptyStateWrapper, EmptyStateContainer } from 'components/EmptyState';
import { useRootRedirect } from 'hooks';
import { ErrorPageProps } from './interfaces';

export default function Error403({ redirect }: ErrorPageProps) {
  const defaultRootRedirect = useRootRedirect();
  return (
    <EmptyStateWrapper mt={3}>
      <EmptyStateContainer>
        <Typography variant="heading1">403</Typography>
        <Typography variant="body1" mt={0.5}>
          <FormattedMessage {...errorTexts.page403Text} />
        </Typography>
        <Button
          variant="contained"
          component={NavLink}
          to={redirect || defaultRootRedirect}
        >
          <FormattedMessage {...commonTexts.home} />
        </Button>
      </EmptyStateContainer>
    </EmptyStateWrapper>
  );
}
