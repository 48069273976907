import { FeedbackRequestRO } from 'store/interfaces';
import {
  FEEDBACK_REQUEST_STATUS_DRAFT,
  FEEDBACK_REQUEST_STATUS_PENDING,
} from 'constants/feedbacks';

export default function isRequestVisible({ status }: FeedbackRequestRO) {
  return (
    status === FEEDBACK_REQUEST_STATUS_DRAFT ||
    status === FEEDBACK_REQUEST_STATUS_PENDING
  );
}
