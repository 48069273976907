import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { OKR_OBJECTIVE_BY_ID } from 'constants/api';
import { generateURL } from 'helpers';
import { OkrObjectiveResponse } from '../interfaces';
import { storeName } from '../config';

export const getObjectiveData = createAsyncThunk<
  OkrObjectiveResponse,
  {
    id: string;
    periodId: string;
  }
>(`${storeName}/getObjectiveData`, async ({ id, periodId }, thunkAPI) => {
  const url = generateURL(OKR_OBJECTIVE_BY_ID, { id, periodId });
  return api.axios
    .get<OkrObjectiveResponse>(url)
    .then(({ data }) => data)
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data));
});
