import axios from 'axios';
import getOptionURL from './getOptionURL';

export default function createApi() {
  const axiosInstance = axios.create();

  return {
    axios: axiosInstance,
    getOptionURL,
  };
}
