import { Theme } from '@mui/material';
import { ComponentsProps } from '@mui/material/styles/props';

declare module '@mui/material/FormControl' {
  interface FormControlPropsSizeOverrides {
    large: true;
  }
}

declare module '@mui/material/InputBase' {
  interface InputBasePropsSizeOverrides {
    large: true;
  }
}
export default {
  defaultProps: {
    variant: 'standard',
  } as ComponentsProps['MuiFormControl'],
  styleOverrides: {
    root({ theme }: { theme: Theme }) {
      return {
        display: 'flex',
        '.MuiInputLabel-outlined': {
          ...theme.typography.body4,
          position: 'static',
          transform: 'none',
          marginBottom: 4,
        },
        '.MuiInputLabel-asterisk': {
          ...theme.typography.body4,
          color: theme.palette.error.main,
        },
        '.MuiFormHelperText-root': {
          marginTop: 4,
        },
        '.MuiFormHelperText-root.Mui-error': {
          display: 'block',
          marginTop: 4,
          whiteSpace: 'pre',
        },
        [`
        .MuiFormControlLabel-root + .MuiFormHelperText-root.Mui-error,
        .MuiFormGroup-root.Mui-error + .MuiFormHelperText-root.Mui-error
        `]: {
          marginTop: -3,
        },
        '.MuiOutlinedInput-notchedOutline': {
          top: 0,
          legend: {
            display: 'none',
          },
        },
        '.MuiOutlinedInput-input, .MuiAutocomplete-input.MuiAutocomplete-input':
          {
            padding: '8px 10px',
            fontSize: '14px',
            lineHeight: '22px',
            height: 'unset',
            '::placeholder': {
              color: theme.palette.grey['600'],
              opacity: 1,
            },
            ':focus::placeholder': {
              opacity: 0,
            },
            '&.Mui-disabled': {
              color: theme.palette.common.black,
              WebkitTextFillColor: 'unset',
            },
            '&.MuiSelect-select': {
              boxSizing: 'border-box',
              '&.inputSize-small': {
                minHeight: '32px',
              },
              '&.inputSize-medium': {
                minHeight: '36px',
              },
              '&.inputSize-large': {
                minHeight: '40px',
              },
            },
            '&.inputSize-small': {
              padding: '6px 10px',
              fontSize: 14,
            },
            '&.inputSize-medium': {
              padding: '8px 10px',
              lineHeight: '22px',
              fontSize: 14,
            },
            '&.inputSize-large': {
              lineHeight: '24px',
              padding: '8px 12px',
              fontSize: 16,
            },
            '&.MuiInputBase-inputMultiline': {
              padding: 0,
            },
          },
        '.MuiInputBase-inputMultiline': {
          minHeight: '66px',
        },
        '.MuiOutlinedInput-root': {
          background: theme.palette.common.white,
          '&.Mui-disabled': {
            background: theme.palette.grey['300'],
          },
          '&, &.Mui-disabled:hover': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.grey.A400,
            },
          },
          '&, &.Mui-disabled': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.grey.A400,
            },
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey.A400,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
          },
          '&.Mui-error': {
            '&:hover, &.Mui-focused': {
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.error.main,
              },
            },
          },
          '&.MuiInputBase-adornedStart': {
            paddingLeft: '8px',
          },
          '&.MuiInputBase-adornedEnd': {
            paddingRight: '8px',
          },
          '.MuiInputAdornment-root .MuiIconButton-root': {
            padding: 0,
            margin: 0,
          },
          '&.MuiInputBase-multiline': {
            '&.inputSize-small': {
              padding: '6px 10px',
            },
            '&.inputSize-medium': {
              padding: '8px 10px',
            },
            '&.inputSize-large': {
              padding: '10px 12px',
              '.MuiOutlinedInput-input': {
                fontSize: 16,
              },
            },
          },
        },
      };
    },
  },
};
