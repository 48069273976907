import { Button, Grid, Stack, Typography } from '@mui/material';
import { useIntl, FormattedMessage } from 'react-intl';
import { formTexts } from 'i18n';
import {
  useErrorClearing,
  useGoalTitle,
  useGoalValues,
} from 'hooks/GoalsAndSubgoalsForm';
import { AddIcon, DeleteOutlineIcon } from 'components/Icons';
import {
  ACTIVE_GOAL_ID_NAME,
  SelfAssessmentSetups,
  scrollToActiveGoalInNavigation,
  scrollToActiveSubgoalDetails,
  validateWeight,
} from 'helpers';
import { useWatch } from 'react-hook-form';
import MessageWithIcon from 'components/MessageWithIcon';
import OneLineTextWithTooltip from 'components/OneLineTextWithTooltip';
import { GoalProps } from './interfaces';
import SubGoal from './SubGoal.component';
import {
  DeleteButton,
  GoalWithSubgoalsWrapper,
  TitleName,
  TitleWeight,
  TitleWrapper,
  TitleWithAction,
  DeleteButtonWrapper,
  FieldHolderWrapper,
  AddSubgoalButtonContainer,
  AddSubgoalButtonInNavigationContainer,
  StyledInfoTooltip,
  SelfAssessmentLabel,
} from './GoalsAndSubgoalsForm.styled';
import SubGoalPortal from './SubGoalPortal.component';
import SubGoalTitle from './SubGoalTitle.component';
import FieldHolder from './FieldHolder.component';
import NameField from './NameField.component';

const selfAssessmentOptions = [
  {
    id: SelfAssessmentSetups.request,
    label: (
      <SelfAssessmentLabel>
        <FormattedMessage {...formTexts.enableSelfAssessment} />
      </SelfAssessmentLabel>
    ),
    tooltip: (
      <StyledInfoTooltip
        title={<FormattedMessage {...formTexts.enableSelfAssessmentTooltip} />}
      />
    ),
  },
  {
    id: SelfAssessmentSetups.calculate,
    label: (
      <SelfAssessmentLabel>
        <FormattedMessage {...formTexts.calculateSelfAssessment} />
      </SelfAssessmentLabel>
    ),
    tooltip: (
      <StyledInfoTooltip
        title={
          <FormattedMessage {...formTexts.calculateSelfAssessmentTooltip} />
        }
      />
    ),
  },
  {
    id: SelfAssessmentSetups.disable,
    label: (
      <SelfAssessmentLabel>
        <FormattedMessage {...formTexts.disableSelfAssessment} />
      </SelfAssessmentLabel>
    ),
    tooltip: (
      <StyledInfoTooltip
        title={<FormattedMessage {...formTexts.disableSelfAssessmentTooltip} />}
      />
    ),
  },
];

export default function Goal({
  index,
  remove,
  isRemoveDisabled,
  activeGoal,
  onSubgoalChange,
  navigationSubgoalsListId,
  hasRestrictionForTemplateGoal,
  activeSubGoal,
  setActiveSubGoal,
  isActiveOwnScorecard,
  isCuSat,
  isCommonGoalEdit,
}: GoalProps) {
  const { name, subgoals, addSubGoal, deleteSubgoal } = useGoalValues(index);
  useErrorClearing(index);
  const active = activeGoal === index;
  const { formatMessage } = useIntl();
  const onDelete = () => remove(index);

  const { title, weight } = useGoalTitle(index);

  const [isCustom] = useWatch({
    name: [`goals.${index}.isCustom`],
  });
  const [allowScorecardOwnerEdits] = useWatch({
    name: [`goals.${index}.allowScorecardOwnerEdits`],
  });
  const [goalName] = useWatch({
    name: [`goals.${index}.name`],
  });

  const canDeleteSubgoal = subgoals.length > 1 && !isCuSat;
  const canDeleteCuSat =
    isCuSat &&
    (!hasRestrictionForTemplateGoal || (isActiveOwnScorecard && isCustom));
  const canEdit =
    !hasRestrictionForTemplateGoal ||
    (isActiveOwnScorecard && allowScorecardOwnerEdits);
  const canEditSubgoalWeights =
    !isActiveOwnScorecard || (isActiveOwnScorecard && allowScorecardOwnerEdits);
  const canDeleteGoal =
    (isCommonGoalEdit && !isCuSat) ||
    (!isRemoveDisabled && (canEdit || canDeleteCuSat));

  const showAllowScorecardOwnerEdits = !isCuSat && !isActiveOwnScorecard;
  const showAllowComment = !isCuSat;

  const { items, titles } = subgoals.reduce(
    (acc: { items: JSX.Element[]; titles: JSX.Element[] }, item, subIndex) => {
      acc.items.push(
        <SubGoal
          key={item.key}
          remove={deleteSubgoal}
          index={subIndex}
          goalIndex={index}
          isCuSat={isCuSat}
          canEdit={canEdit}
          canEditWeight={canEditSubgoalWeights}
          canDelete={canDeleteSubgoal}
          active={subIndex === activeSubGoal}
          isCommonGoalEdit={isCommonGoalEdit}
        />,
      );
      acc.titles.push(
        <SubGoalTitle
          key={item.key}
          onClick={() => {
            onSubgoalChange();
            scrollToActiveSubgoalDetails(index, subIndex);
            if (subIndex !== activeSubGoal) {
              setActiveSubGoal(null);
            }
          }}
          index={index}
          subIndex={subIndex}
          active={active && activeSubGoal === subIndex}
          isCuSat={isCuSat}
        />,
      );
      return acc;
    },
    { items: [], titles: [] },
  );
  const addButton = canEdit && !isCuSat && (
    <Button
      size="small"
      startIcon={<AddIcon />}
      disabled={subgoals.length > 14}
      onClick={() => {
        setActiveSubGoal(null);
        onSubgoalChange();
        addSubGoal();
        scrollToActiveGoalInNavigation(activeGoal);
      }}
      data-testid="add-subgoal-button"
    >
      <FormattedMessage
        id="pages.reviewCycles.addSubgoal"
        defaultMessage="Add Subgoal"
      />
    </Button>
  );
  return (
    <>
      <SubGoalPortal
        navigationSubgoalsListId={navigationSubgoalsListId}
        index={index}
      >
        {titles}
        {addButton && (
          <AddSubgoalButtonInNavigationContainer>
            {addButton}
          </AddSubgoalButtonInNavigationContainer>
        )}
      </SubGoalPortal>
      {active && (
        <GoalWithSubgoalsWrapper id={ACTIVE_GOAL_ID_NAME}>
          <Stack mb={isCuSat ? 2.5 : 1.5}>
            <Typography variant="subheadingSmall" mb="18px">
              <FormattedMessage
                id="pages.reviewCycles.goalInformation"
                defaultMessage="Goal Information"
              />
            </Typography>
            <TitleWithAction
              sx={{ marginBottom: isCommonGoalEdit ? '8px' : '0px' }}
            >
              <TitleWrapper>
                <TitleName>
                  <OneLineTextWithTooltip variant="heading3" text={title} />
                </TitleName>
                {!isCommonGoalEdit && (
                  <TitleWeight>
                    <Typography variant="heading3">{weight}%</Typography>
                  </TitleWeight>
                )}
              </TitleWrapper>
              <Stack alignItems="center" justifyContent="flex-end">
                {canDeleteGoal && (
                  <DeleteButtonWrapper withDivider={!isCommonGoalEdit}>
                    <DeleteButton onClick={onDelete}>
                      <DeleteOutlineIcon />
                    </DeleteButton>
                  </DeleteButtonWrapper>
                )}
              </Stack>
            </TitleWithAction>
            {!isCommonGoalEdit && (
              <MessageWithIcon
                mt="2px"
                mb={1}
                message={formatMessage({
                  id: 'pages.reviewCycles.goalsTotalWeightDescription',
                  defaultMessage:
                    'The total weight for all goals in the Scorecard should be equal to 100%',
                })}
              />
            )}
            <NameField
              name={`${name}.name`}
              parentFieldName="goals"
              isCuSat={isCuSat}
              canEdit={canEdit}
              isCustom={isCustom}
              index={index}
              placeholder={formatMessage({
                id: 'pages.reviewCycles.nameGoalPlaceholder',
                defaultMessage: 'Ex., Collaboration',
              })}
              selectOnFocus={goalName === formTexts.untitledGoal.defaultMessage}
              dataTestId="goal-name-field"
            />
            {!isCommonGoalEdit && (
              <FieldHolderWrapper direction="row" alignItems="end" gap={2}>
                <FieldHolder
                  fieldProps={{ sx: { maxWidth: '140px' }, size: 'medium' }}
                  name={`${name}.weight`}
                  rules={{
                    required: formatMessage(formTexts.errorRequired),
                    validate: (value: string) =>
                      validateWeight(value, formatMessage),
                  }}
                  type="text"
                  label={formatMessage(formTexts.weight)}
                  selectOnFocus
                  dataTestId="goal-weight-field"
                  isCustomGoal={isCustom}
                />
              </FieldHolderWrapper>
            )}
            <FieldHolderWrapper>
              <FieldHolder
                name={`${name}.description`}
                maxLength={1000}
                type="textarea"
                disabled={!canEdit || isCuSat}
                label={formatMessage(formTexts.description)}
                isCustomGoal={isCustom}
                hasLinks
                fieldProps={{
                  size: 'medium',
                }}
              />
            </FieldHolderWrapper>
            <Grid container spacing={2}>
              {!isCuSat && (
                <Grid item md={6} lg={5}>
                  <Typography variant="subheadingSmall" mb="18px">
                    <FormattedMessage
                      id="pages.reviewCycles.selfAssessmentSetup"
                      defaultMessage="Self-Assessment setup"
                    />
                  </Typography>
                  <FieldHolder
                    sx={{ maxWidth: 340, display: 'block' }}
                    disabled={!canEdit}
                    name={`${name}.selfAssessmentSetup`}
                    type="radio"
                    label={formatMessage(formTexts.enableSelfAssessment)}
                    options={selfAssessmentOptions}
                    labelPlacement="end"
                    isCustomGoal={isCustom}
                  />
                </Grid>
              )}
              {!isCuSat && (
                <Grid item xs={7} md={6}>
                  <Typography variant="subheadingSmall" mb="18px">
                    <FormattedMessage
                      id="pages.reviewCycles.goalSetup"
                      defaultMessage="Goal setup"
                    />
                  </Typography>
                  {showAllowComment && (
                    <Stack direction="row">
                      <FieldHolder
                        disabled={!canEdit}
                        name={`${name}.allowScorecardComment`}
                        type="toggle"
                        label={formatMessage(formTexts.allowComment)}
                        isCustomGoal={isCustom}
                      />
                      <StyledInfoTooltip
                        title={formatMessage(formTexts.allowCommentTooltip)}
                      />
                    </Stack>
                  )}
                  {showAllowScorecardOwnerEdits && (
                    <Stack sx={{ marginTop: '16px' }} direction="row">
                      <FieldHolder
                        disabled={!canEdit}
                        name={`${name}.allowScorecardOwnerEdits`}
                        type="toggle"
                        label={formatMessage(
                          formTexts.allowScorecardOwnerEdits,
                        )}
                        isCustomGoal={isCustom}
                      />
                      <StyledInfoTooltip
                        title={formatMessage(
                          formTexts.allowScorecardOwnerEditsTooltip,
                        )}
                      />
                    </Stack>
                  )}
                </Grid>
              )}
            </Grid>
          </Stack>
          <Stack>
            <Typography variant="subheadingSmall">
              <FormattedMessage
                id="pages.reviewCycles.subgoalInformation"
                defaultMessage="Subgoals Information"
              />
            </Typography>
            <MessageWithIcon
              mt="4px"
              mb={1}
              message={formatMessage({
                id: 'pages.reviewCycles.subgoalsTotalWeightDescription',
                defaultMessage:
                  'The total weight for all subgoals for the given goal should be equal to 100%',
              })}
            />
            {items}
            {addButton && (
              <AddSubgoalButtonContainer>{addButton}</AddSubgoalButtonContainer>
            )}
          </Stack>
        </GoalWithSubgoalsWrapper>
      )}
    </>
  );
}
