import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_OPTIONS } from 'constants/api';
import qs from 'query-string';
import { storeName } from '../config';
import { Option } from '../interfaces';

export const updateUserScopedUnits = createAsyncThunk(
  `${storeName}/updateUserScopedUnits`,
  () => {
    const url = `${API_OPTIONS}/units?${qs.stringify(
      { userScoped: true },
      { skipNull: true },
    )}`;

    return api.axios
      .get(url)
      .then(
        (res) =>
          (res.data as { items: Option[] })?.items?.sort((a, b) =>
            a.name.localeCompare(b.name),
          ) || [],
      )
      .catch(() => [])
      .then((res) => res);
  },
);
