import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_REVIEW_CYCLES_OPTIONS } from 'constants/api';
import qs from 'query-string';
import { storeName } from '../config';
import { Option } from '../interfaces';

export const updateUserScopedReviewCycles = createAsyncThunk(
  `${storeName}/updateUserScopedReviewCycles`,
  () => {
    const url = `${API_REVIEW_CYCLES_OPTIONS}?${qs.stringify({
      userScoped: true,
    })}`;

    return api.axios
      .get(url)
      .then((res) => (res.data as { items: Option[] })?.items || [])
      .catch(() => [])
      .then((res) => res);
  },
);
