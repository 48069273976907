import { KeyboardArrowDown } from '@mui/icons-material';
import { Box, Stack, useTheme } from '@mui/system';
import { LogoStar, LogoMars, LogoOkrs } from 'components/Icons';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'store';
import { HeaderMode, selectHeaderMode, setHeaderMode } from 'store/uiState';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { OKR_ROUTE, URL_MY_OKR } from 'constants/urls';
import { useUserPermissions } from 'hooks';
import { HeaderMenuItem, StyledSwitchMenu } from './Header.styled';

function Logo({ isOkrs }: { isOkrs: boolean }) {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap="8px"
      sx={{ marginRight: '8px' }}
    >
      <LogoStar logoColor={theme.palette.common.black} />
      {isOkrs ? (
        <LogoOkrs textColor={theme.palette.grey['700']} />
      ) : (
        <LogoMars textColor={theme.palette.grey['700']} />
      )}
    </Stack>
  );
}

export default function MenuSwitch() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { canViewOkrs } = useUserPermissions();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const headerMode = useSelector(selectHeaderMode);
  const open = Boolean(anchorEl);
  const isOKRs = headerMode === HeaderMode.OKRs;
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const switchIsOKRs = (value: boolean) => {
    dispatch(setHeaderMode(value ? HeaderMode.OKRs : HeaderMode.Scorecards));
    if (value) {
      navigate(URL_MY_OKR);
    } else {
      navigate('/');
    }
    handleClose();
  };

  useEffect(() => {
    if (location.pathname.includes(OKR_ROUTE)) {
      dispatch(setHeaderMode(HeaderMode.OKRs));
    } else {
      dispatch(setHeaderMode(HeaderMode.Scorecards));
    }
  }, [canViewOkrs, dispatch, location.pathname]);

  if (!canViewOkrs && headerMode === HeaderMode.Scorecards) {
    return <Logo isOkrs={false} />;
  }

  return (
    <Box>
      <Stack
        direction="row"
        id="menu-logo"
        role="presentation"
        onClick={handleClick}
        sx={{ cursor: 'pointer' }}
      >
        <Logo isOkrs={isOKRs} />
        <KeyboardArrowDown
          sx={{
            transform: anchorEl ? 'rotate(180deg)' : 'none',
            position: 'relative',
            right: '6px',
          }}
          color="action"
        />
      </Stack>
      <StyledSwitchMenu
        onClose={handleClose}
        anchorEl={anchorEl}
        id="menu-type-switch"
        open={open}
      >
        <HeaderMenuItem selected={!isOKRs} onClick={() => switchIsOKRs(false)}>
          Scorecards
        </HeaderMenuItem>
        <HeaderMenuItem selected={isOKRs} onClick={() => switchIsOKRs(true)}>
          OKRs
        </HeaderMenuItem>
      </StyledSwitchMenu>
    </Box>
  );
}
