import { useIntl } from 'react-intl';
import { useFieldArray, useForm } from 'react-hook-form';
import { useCallback, useMemo, useState } from 'react';
import { useOkrStaticOptions, usePrompt } from 'hooks';
import {
  isFormDirty,
  prepareOkrObjectiveData,
  transformObjectiveResponse,
} from 'helpers';
import { commonTexts } from 'i18n';
import { ObjectiveFormFields } from 'pages/ObjectiveDetails/interfaces';
import { useAppDispatch } from 'store';
import {
  createOkrObjective,
  deleteOkrObjective,
  editOkrObjective,
} from 'store/okrObjectives';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
  URL_MY_OKR,
  URL_OKR_TEAM_OVERVIEW,
  URL_VIEW_OBJECTIVE,
  URL_VIEW_USER_OBJECTIVE,
} from 'constants/urls';
import { ToastProps, showToast } from 'store/toast';

export default function useOkrObjectiveForm({
  defaultValues,
  isCreateMode,
}: {
  defaultValues?: ObjectiveFormFields;
  isCreateMode: boolean;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { visibilityOptions, statusOptions } = useOkrStaticOptions();
  const [isDeleting, setIsDeleting] = useState(false);

  const form = useForm<ObjectiveFormFields>({
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues: { ...defaultValues },
  });
  const {
    handleSubmit,
    formState: { errors },
    formState,
    setValue,
    reset,
    getValues,
    control,
  } = form;
  const {
    fields: keyResults,
    append: appendKeyResult,
    remove: removeKeyResult,
  } = useFieldArray({
    control,
    name: 'keyResults',
  });
  const isDirty = isFormDirty(formState);
  const { reportingPeriod, userId } = useParams();

  usePrompt(formatMessage(commonTexts.unsavedChangesPrompt), isDirty);

  const canSubmit = isDirty && !Object.keys(errors).length;

  const onSuccess = useCallback(
    async (id: number) => {
      // timeout to prevent unsaved changes prompt
      setTimeout(() => {
        if (!userId) {
          navigate(
            generatePath(`/${URL_MY_OKR}/${URL_VIEW_OBJECTIVE}`, {
              id: String(id),
              reportingPeriod,
            }),
          );
        } else {
          navigate(
            generatePath(
              `/${URL_OKR_TEAM_OVERVIEW}/${URL_VIEW_USER_OBJECTIVE}`,
              {
                id: String(id),
                reportingPeriod,
                userId,
              },
            ),
          );
        }
      }, 0);
    },
    [navigate, reportingPeriod, userId],
  );

  const partialEdit = useCallback(
    async (
      partialData: Partial<ObjectiveFormFields>,
      successToast: ToastProps,
    ) => {
      try {
        const data = getValues();
        const requestData = prepareOkrObjectiveData(
          { ...data, ...partialData },
          visibilityOptions,
          statusOptions,
        );
        const response = await dispatch(
          editOkrObjective({
            data: requestData,
            id: data.id as string,
            periodId: String(data.label),
          }),
        ).unwrap();

        if (response?.id) {
          reset(
            transformObjectiveResponse(
              response,
              String(data.label),
              visibilityOptions,
              statusOptions,
            ),
          );
          dispatch(showToast(successToast));
        }
      } catch (error) {
        reset(defaultValues);
      }
    },
    [
      defaultValues,
      dispatch,
      getValues,
      reset,
      statusOptions,
      visibilityOptions,
    ],
  );

  const onSubmit = useCallback(
    async (data: ObjectiveFormFields) => {
      try {
        const requestData = prepareOkrObjectiveData(
          data,
          visibilityOptions,
          statusOptions,
        );
        const request = isCreateMode
          ? dispatch(
              createOkrObjective({
                data: requestData,
                periodId: String(data.label),
                userId: userId as string,
              }),
            )
          : dispatch(
              editOkrObjective({
                data: requestData,
                id: data.id as string,
                periodId: String(data.label),
              }),
            );
        const response = await request.unwrap();
        if (isCreateMode && response?.id) {
          reset();
          onSuccess(response.id);
          dispatch(
            showToast({
              severity: 'success',
              message: {
                id: 'pages.okr.objectiveCreated',
                defaultMessage: 'Objective created',
              },
            }),
          );
          return response;
        }

        onSuccess(response.id);
        dispatch(
          showToast({
            severity: 'success',
            message: {
              id: 'pages.okr.objectiveCreated',
              defaultMessage: 'Objective saved',
            },
          }),
        );
        reset(
          transformObjectiveResponse(
            response,
            String(data.label),
            visibilityOptions,
            statusOptions,
          ),
        );
        return response;
      } catch (error: any) {
        dispatch(
          showToast({
            severity: 'error',
            message: error.description ||
              error.message || {
                id: 'pages.okr.objectiveNotCreated',
                defaultMessage: 'Objective not created',
              },
          }),
        );
        return null;
      }
    },
    [
      dispatch,
      isCreateMode,
      onSuccess,
      reset,
      statusOptions,
      userId,
      visibilityOptions,
    ],
  );
  const onSubmitClick = useMemo(
    () => handleSubmit(onSubmit),
    [handleSubmit, onSubmit],
  );

  const handleDelete = useCallback(async () => {
    const data = getValues();
    setIsDeleting(true);
    await dispatch(
      deleteOkrObjective({
        id: data.id as string,
        periodId: String(data.label),
      }),
    ).unwrap();
    setIsDeleting(false);
    if (!userId) {
      navigate(`/${URL_MY_OKR}`);
    } else {
      navigate(`/${URL_OKR_TEAM_OVERVIEW}`);
    }
    dispatch(
      showToast({
        severity: 'success',
        message: {
          id: 'pages.okr.objectiveDeleted',
          defaultMessage: 'Objective deleted',
        },
      }),
    );
  }, [dispatch, getValues, navigate, userId]);

  return {
    form,
    onSubmitClick,
    formatMessage,
    canSubmit,
    setValue,
    keyResults,
    appendKeyResult,
    removeKeyResult,
    partialEdit,
    handleDelete,
    isDeleting,
  };
}
