import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_GET_THREAD_INFO } from 'constants/api';
import { generateURL } from 'helpers';
import { storeName } from '../config';
import { Thread } from '../interfaces';

export const getThread = createAsyncThunk<Thread, { threadId: number }>(
  `${storeName}/getThread`,
  async ({ threadId }, thunkAPI) =>
    api.axios
      .get<Thread>(generateURL(API_GET_THREAD_INFO, { threadId }))
      .then((res) => res.data)
      .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
