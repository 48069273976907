import Button from '@mui/material/Button';
import { useIntl } from 'react-intl';
import EmptyState from 'components/EmptyState';
import { commonTexts, errorTexts } from 'i18n';

import { ScreenWrapper } from './UnhandledErrorScreen.styled';

export default function UnhandledErrorScreen({
  resetErrorBoundary,
}: {
  resetErrorBoundary: () => void;
}) {
  const { formatMessage } = useIntl();
  return (
    <EmptyState
      WrapperComponent={ScreenWrapper}
      title={formatMessage(errorTexts.errorAcknowledgement)}
      description={formatMessage(errorTexts.refreshPage)}
    >
      <Button variant="contained" onClick={resetErrorBoundary}>
        {formatMessage(commonTexts.refreshPage)}
      </Button>
    </EmptyState>
  );
}
