import { useCallback } from 'react';
import {
  SuccessCallback,
  useActionPipeline,
  useDialogState,
  useToast,
} from 'hooks';
import { useIntl } from 'react-intl';
import { commonTexts } from 'i18n';
import { changeStatus } from 'store/scorecards';
import { SCORECARD_ACTION_ASSESSMENT_REVIEW } from 'constants/scorecards';
import { useScorecardContext } from 'contexts';

export default function useShareForReview() {
  const { id, store } = useScorecardContext();
  const [
    isShareForReviewDialogActive,
    openShareForReviewDialog,
    onShareForReviewDialogClose,
  ] = useDialogState();

  const onShareForReviewClick = useCallback(() => {
    openShareForReviewDialog();
  }, [openShareForReviewDialog]);

  const { formatMessage } = useIntl();
  const showMessage = useToast();
  const onSendForFeedbackSuccess = useCallback<SuccessCallback>(() => {
    showMessage({
      severity: 'success',
      message: formatMessage(commonTexts.success),
    });
  }, [showMessage, formatMessage]);

  const sendForFeedbackPipeline = useActionPipeline(
    onSendForFeedbackSuccess,
    null,
    onShareForReviewDialogClose,
  );

  const onShareForReviewSubmit = useCallback(() => {
    sendForFeedbackPipeline(
      changeStatus({ id, store, action: SCORECARD_ACTION_ASSESSMENT_REVIEW }),
    );
  }, [id, sendForFeedbackPipeline, store]);
  return {
    onShareForReviewClick,
    isShareForReviewDialogActive,
    onShareForReviewDialogClose,
    onShareForReviewSubmit,
  };
}
