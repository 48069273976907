import { createAsyncThunk } from '@reduxjs/toolkit';
import { ReviewCycleItem } from 'store/interfaces';
import api from 'api';
import { API_REVIEW_CYCLE } from 'constants/api';
import { storeName } from '../config';

export const cycleGet = createAsyncThunk<
  Omit<ReviewCycleItem, 'scorecardTemplates'>,
  { id: number }
>(`${storeName}/get`, async ({ id }, thunkAPI) =>
  api.axios
    .get<ReviewCycleItem>(`${API_REVIEW_CYCLE}/${id}`)
    .then((res) => res.data)
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
