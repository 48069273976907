import { useMemo } from 'react';
import { FeedbackFullHeader } from 'store/interfaces';
import { useScorecardData } from 'hooks';

export default function useCategorizedFeedbacks() {
  const {
    feedbacks,
    workRecord: {
      user: { id: userId },
    },
  } = useScorecardData();
  return useMemo(
    () =>
      feedbacks.reduce<FeedbackFullHeader[]>((acc, item) => {
        const { user } = item;
        if (user?.id === userId) {
          acc.unshift(item);
        } else {
          acc.push(item);
        }
        return acc;
      }, []),
    [feedbacks, userId],
  );
}
