import { createAsyncThunk } from '@reduxjs/toolkit';
import { Scorecard, ScorecardsActionStoreArgument } from 'store/interfaces';
import api from 'api';
import { API_SCORECARD_FULL_INFO, API_SCORECARD_OWN } from 'constants/api';
import { generateURL } from 'helpers';
import { storeName as ownScorecardsStoreName } from 'store/scorecards/own/config';

export const updateFullData = createAsyncThunk<
  Scorecard,
  {
    id: number;
    store: ScorecardsActionStoreArgument;
  }
>('scorecards/updateFullData', async ({ id, store }, thunkAPI) => {
  const url =
    store === ownScorecardsStoreName
      ? `${API_SCORECARD_OWN}/${id}`
      : generateURL(API_SCORECARD_FULL_INFO, { id });
  return api.axios
    .get<Scorecard>(url)
    .then(({ data }) => data)
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data));
});
