declare module '@mui/material/TextField' {
  interface TextFieldPropsSizeOverrides {
    large: true;
  }

  interface BaseTextFieldProps {
    selectOnFocus?: boolean;
  }
}

export default {
  defaultProps: {
    InputLabelProps: {
      shrink: true,
    },
  },
};
