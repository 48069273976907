import { Theme } from '@mui/material';
import shadows from '../shadows';

export default {
  styleOverrides: {
    root({ theme }: { theme: Theme }) {
      return {
        '&': {
          boxShadow: shadows[0],
        },

        '.MuiPickersArrowSwitcher-spacer': {
          width: theme.spacing(1),
        },
        '.MuiMonthPicker-root': {
          minHeight: '280px',
        },
        '.PrivatePickersYear-yearButton, .PrivatePickersMonth-root': {
          borderRadius: '2px',
          fontSize: theme.typography.pxToRem(16),
          fontWeight: 400,
          textTransform: 'capitalize',

          ':hover': {
            backgroundColor: theme.palette.grey[200],
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
          },
        },
        '.MuiPickersDay-today:before': {
          zIndex: 0,
          content: '""',
          alignItems: 'center',
          backgroundColor: theme.palette.grey.A400,
          borderRadius: '50%',
          color: theme.palette.text.primary,
          display: 'inline-flex',
          height: '24px',
          justifyContent: 'center',
          left: '50%',
          position: 'absolute',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          width: '24px',
        },
        '.MuiPickersDay-root': {
          backgroundColor: theme.palette.grey['200'],
          borderRadius: 0,
          color: theme.palette.common.black,
          fontSize: theme.typography.pxToRem(14),
          lineHeight: 1.43,
          margin: '0 1px',
          position: 'relative',

          '&:hover, &:focus-visible': {
            backgroundColor: theme.palette.blue['200'],
          },
          '.MuiPickersDay-today': {
            border: 'none',
          },
          '.MuiPickersDay-dayOutsideMonth': {
            color: theme.palette.grey.A400,
          },
          '.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
          },
        },
        '.MuiPickersDay-weekend': {
          color: theme.palette.error.main,
        },
      };
    },
  },
};
