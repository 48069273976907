import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo } from 'react';
import { usePrompt, useResetAfterSubmit, useToast } from 'hooks';
import { formatGoalsData, isFormDirty, parseParams } from 'helpers';
import { commonTexts } from 'i18n';
import commonMessages from 'i18n/common';
import {
  getCommonGoalsList,
  selectCommonGoals,
  updateCategoryGoals,
} from 'store/commonGoals';
import { CommonGoalsFormFields } from 'pages/CommonGoals/interfaces';
import { FormGoal } from 'components/GoalsAndSubgoalsForm/interfaces';
import { useAppDispatch } from 'store';
import { TemplateGoal } from 'store/interfaces';
import error from 'i18n/error';
import { CommonGoalCategory } from 'store/commonGoals/interfaces/CommonGoalCategory';

export default function useCommonGoalsFormValues(
  selectedCategory: CommonGoalCategory | null,
) {
  const dispatch = useAppDispatch();
  const showMessage = useToast();
  const { formatMessage } = useIntl();
  const { items } = useSelector(selectCommonGoals);

  const form = useForm<CommonGoalsFormFields>({
    mode: 'onChange',
    criteriaMode: 'all',
  });
  const {
    handleSubmit,
    formState: { errors },
    formState,
    reset,
    setValue,
  } = form;
  const isDirty = isFormDirty(formState);

  useResetAfterSubmit(form);
  usePrompt(formatMessage(commonTexts.unsavedChangesPrompt), isDirty);

  const canSubmit = isDirty && !Object.keys(errors).length;
  const onSubmit = useCallback(
    async (data: CommonGoalsFormFields) => {
      const goalsFormatted = formatGoalsData(data.goals, true);

      dispatch(
        updateCategoryGoals({
          id: data.category?.id,
          goals: goalsFormatted as TemplateGoal[],
        }),
      ).then((response) => {
        if (response.meta.requestStatus === 'rejected') {
          const message = formatMessage(error.errorAcknowledgement);
          showMessage({ severity: 'error', message });
          return;
        }
        const message = formatMessage(commonMessages.changesSavedSuccessfully);
        showMessage({ severity: 'success', message });
      });
    },
    [dispatch, formatMessage, showMessage],
  );
  const onSubmitClick = useMemo(
    () => handleSubmit(onSubmit),
    [handleSubmit, onSubmit],
  );

  useEffect(() => {
    const commonValues = {
      entity: 'template' as const,
      goals: [],
      category: selectedCategory,
    };

    if (selectedCategory) {
      const newGoals =
        (items
          .find((item) => item.id === selectedCategory.id)
          ?.goals?.map((item) => {
            const {
              allowScorecardComment,
              selfAssessmentSetup,
              allowScorecardOwnerEdits = false,
            } = parseParams(item.params);

            return {
              ...item,
              allowScorecardComment,
              selfAssessmentSetup,
              allowScorecardOwnerEdits,
              subgoals: item.subgoals?.map((subgoal) => ({
                ...subgoal,
                assessmentScaleGroup: {
                  id: subgoal.assessmentScaleGroup?.id,
                  name: subgoal.assessmentScaleGroup?.name,
                },
                assessmentScaleGroupId: subgoal.assessmentScaleGroup?.id,
              })),
            };
          }) as FormGoal[]) || [];
      if (!newGoals) {
        setValue('goals', []);
        reset(commonValues);
        return;
      }
      setValue('goals', newGoals);
      reset({ ...commonValues, goals: newGoals });
    } else {
      reset(commonValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, items, reset]);
  useEffect(() => {
    dispatch(getCommonGoalsList());
  }, [dispatch]);

  return {
    form,
    onSubmitClick,
    formatMessage,
    canSubmit,
    setValue,
  };
}
