import { createSlice, isAnyOf, type Reducer } from '@reduxjs/toolkit';
import { cloneDeep, omit } from 'lodash-es';
import addCommonCases from 'store/addCommonCases';
import { roadmapCanvasInitialState, storeName } from './config';
import {
  getCanvasData,
  updateRoadmap,
  createNewFilter,
  deleteFilter,
  updateFilter,
} from './actions';

export * from './interfaces';
export * from './actions';
export * from './selectors';

const canvas = createSlice({
  name: storeName,
  initialState: cloneDeep(roadmapCanvasInitialState),
  reducers: {},
  extraReducers: (builder) => {
    addCommonCases(builder, roadmapCanvasInitialState)
      .addCase(getCanvasData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCanvasData.rejected, (state) => {
        state.isLoading = false;
      });

    builder.addMatcher(
      isAnyOf(
        getCanvasData.pending,
        updateRoadmap.pending,
        deleteFilter.pending,
        updateFilter.pending,
        createNewFilter.pending,
      ),
      (state) => {
        state.isUpdating = true;
      },
    );

    builder.addMatcher(
      isAnyOf(
        getCanvasData.fulfilled,
        updateRoadmap.fulfilled,
        deleteFilter.fulfilled,
        updateFilter.fulfilled,
        createNewFilter.fulfilled,
      ),
      (state, action) => {
        state.isLoading = false;
        state.isUpdating = false;
        state.currentDepartment = omit(action.payload, 'schemaData');
        state.data = action.payload.schemaData;
      },
    );

    builder.addMatcher(
      isAnyOf(
        getCanvasData.rejected,
        updateRoadmap.rejected,
        deleteFilter.rejected,
        updateFilter.rejected,
        createNewFilter.rejected,
      ),
      (state) => {
        state.isUpdating = false;
      },
    );
  },
});

export default canvas.reducer as Reducer<typeof roadmapCanvasInitialState>;
