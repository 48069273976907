import { useCallback, useMemo } from 'react';
import {
  SuccessCallback,
  useActionPipeline,
  useDialogState,
  useToast,
} from 'hooks';
import { useIntl } from 'react-intl';
import { commonTexts } from 'i18n';
import { changeStatus, selectData } from 'store/scorecards';
import {
  SCORECARD_ACTION_ASSESSMENT_REQUEST,
  SCORECARD_COMMON_GOAL_TYPE_CUSAT,
} from 'constants/scorecards';
import { useSelector } from 'react-redux';
import { findGoalsWithoutFeedbackRequests } from 'helpers';
import { useScorecardContext } from 'contexts';

export default function useSendForFeedback() {
  const { id, store } = useScorecardContext();
  const { formatMessage } = useIntl();
  const showMessage = useToast();
  const item = useSelector(selectData(store, id));
  const { goals = [], feedbackRequests = [] } = item?.data || {};
  const [
    sendForFeedbackDialogActive,
    openSendForFeedbackDialog,
    onSendForFeedbackDialogClose,
  ] = useDialogState();
  const unassignedGoals = useMemo(
    () => findGoalsWithoutFeedbackRequests(goals, feedbackRequests),
    [goals, feedbackRequests],
  );
  const hasOnlyCuastGoals = useMemo(
    () =>
      goals.length === 1 && goals[0].type === SCORECARD_COMMON_GOAL_TYPE_CUSAT,
    [goals],
  );
  const onSendForFeedbackClick = useCallback(() => {
    if (unassignedGoals.length > 0) {
      showMessage({
        severity: 'error',
        message: formatMessage(
          {
            id: 'pages.scorecards.sendForFeedbackNoReviewersError',
            defaultMessage: `To request feedback, you need to set reviewers for the following goals in the Scorecard:{br}{goals}`,
          },
          {
            br: '\n',
            goals: unassignedGoals.map((n) => `• ${n}`).join(`\n`),
          },
        ),
      });
    } else if (hasOnlyCuastGoals) {
      showMessage({
        severity: 'error',
        message: formatMessage({
          id: 'pages.scorecards.sendForFeedbackOnlyCusatError',
          defaultMessage:
            'Please add a goal with setting reviewers to request feedback and continue the performance review process.',
        }),
      });
    } else {
      openSendForFeedbackDialog();
    }
  }, [
    formatMessage,
    hasOnlyCuastGoals,
    openSendForFeedbackDialog,
    showMessage,
    unassignedGoals,
  ]);

  const onSendForFeedbackSuccess = useCallback<SuccessCallback>(() => {
    showMessage({
      severity: 'success',
      message: formatMessage(commonTexts.success),
    });
  }, [showMessage, formatMessage]);

  const sendForFeedPipeline = useActionPipeline(
    onSendForFeedbackSuccess,
    null,
    onSendForFeedbackDialogClose,
  );

  const onSendForFeedbackSubmit = useCallback(() => {
    sendForFeedPipeline(
      changeStatus({ id, store, action: SCORECARD_ACTION_ASSESSMENT_REQUEST }),
    );
  }, [sendForFeedPipeline, id, store]);
  return {
    onSendForFeedbackClick,
    sendForFeedbackDialogActive,
    onSendForFeedbackDialogClose,
    onSendForFeedbackSubmit,
  };
}
