import { FeedbackRequestRO, ScorecardGoal } from 'store/interfaces';
import {
  SCORECARD_COMMON_GOAL_TYPE_CUSAT,
  SCORECARD_GOAL_TYPE_CUSAT,
} from 'constants/scorecards';
import { parseParams } from 'helpers';

export default function findGoalsWithoutFeedbackRequests(
  goals: ScorecardGoal[],
  feedbackRequests: FeedbackRequestRO[],
): string[] {
  const allRequestedGoals = feedbackRequests.reduce(
    (acc: number[], { requestGoals }) => {
      requestGoals.forEach(({ targetScorecardGoal }) => {
        const id =
          typeof targetScorecardGoal === 'number'
            ? targetScorecardGoal
            : targetScorecardGoal.id;
        if (!acc.includes(id)) {
          acc.push(id);
        }
      });
      return acc;
    },
    [],
  );
  return goals.reduce((acc: string[], { id, name, type, params }) => {
    const { calculateSelfAssessment } = parseParams(params);
    const calculateSelfAssessmentValue = calculateSelfAssessment ?? false;
    if (
      !allRequestedGoals.includes(id) &&
      type !== SCORECARD_GOAL_TYPE_CUSAT &&
      type !== SCORECARD_COMMON_GOAL_TYPE_CUSAT &&
      !calculateSelfAssessmentValue
    ) {
      acc.push(name);
    }
    return acc;
  }, []);
}
