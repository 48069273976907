import { Theme } from '@mui/material';

export default {
  styleOverrides: {
    root({ theme }: { theme: Theme }) {
      return {
        marginLeft: 0,
        marginRight: 0,
        '&.Mui-error': {
          ...theme.typography.body4,
          color: theme.palette.error.main,
        },
      };
    },
  },
};
