import { createAsyncThunk } from '@reduxjs/toolkit';
import { CycleTemplate, CycleTemplateDTO } from 'store/interfaces';
import api from 'api';
import { API_SCORECARD_TEMPLATE } from 'constants/api';
import { storeName } from '../config';

export const templateUpdate = createAsyncThunk<CycleTemplate, CycleTemplateDTO>(
  `${storeName}/updateTemplate`,
  async ({ id, reviewCycleId, status, ...data }, thunkAPI) =>
    api.axios
      .put<CycleTemplate>(`${API_SCORECARD_TEMPLATE}/${id}`, data)
      .then((res) => res.data)
      .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
