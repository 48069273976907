import { Node } from 'reactflow';
import { CanvasFilter, NodeData, UIOption } from 'store/interfaces';
import { NodeTypesEnum } from 'constants/roadmap';
import { intersectionBy, isString } from 'lodash-es';

export default function applyFilters(
  nodes: Node<NodeData>[],
  currentFilter: CanvasFilter | null,
  currentSeniority: UIOption | null,
): Node<NodeData>[] {
  if (!currentFilter && !currentSeniority) return nodes;

  const filteredSkills = currentFilter
    ? nodes.filter((node) => {
        if (node.type === NodeTypesEnum.GROUP) return true;

        return (
          intersectionBy(node.data.tags ?? [], currentFilter.data, (item) =>
            isString(item) ? item : item.value,
          ).length > 0
        );
      })
    : nodes;

  // This step will hide all empty parent nodes
  return filteredSkills.filter((node) => {
    if (node.type === NodeTypesEnum.GROUP) {
      return filteredSkills.some((child) => child.parentNode === node.id);
    }

    if (currentSeniority) {
      return (node.data?.seniority ?? 0) <= (currentSeniority!.id as number);
    }

    return true;
  });
}
