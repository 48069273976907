import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ResendIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M14.64 7.36L8 12.42L4.63 9.08L17.63 4.34L15.51 10.16C15.9995 10.0535 16.499 9.99986 17 10C17.2364 9.98479 17.4736 9.98479 17.71 10L21 1L1 8.27L10 17.27C10 17.17 10 17.08 10 16.98C9.98479 16.7436 9.98479 16.5064 10 16.27C10.0497 15.7628 10.157 15.2629 10.32 14.78L9.58 14L14.64 7.36Z"
        fill="currentColor"
      />
      <path
        d="M22.5097 16.9997C22.5065 15.5971 21.9656 14.249 20.9983 13.2332C20.031 12.2174 18.711 11.6112 17.3102 11.5394C15.9093 11.4676 14.5343 11.9358 13.4682 12.8474C12.4022 13.759 11.7263 15.0447 11.5797 16.4397C11.5698 16.6263 11.5698 16.8132 11.5797 16.9997C11.5717 18.4489 12.137 19.8425 13.1523 20.8766C14.1676 21.9106 15.5507 22.5013 16.9997 22.5197V20.6897C16.1901 20.6963 15.4015 20.432 14.7594 19.9389C14.1172 19.4457 13.6584 18.7521 13.4558 17.9682C13.2532 17.1843 13.3185 16.3551 13.6413 15.6126C13.9641 14.8701 14.5259 14.2568 15.2373 13.8703C15.9487 13.4837 16.769 13.3461 17.5676 13.4793C18.3662 13.6126 19.0974 14.0089 19.6448 14.6055C20.1922 15.202 20.5245 15.9644 20.5888 16.7715C20.6532 17.5786 20.4458 18.384 19.9997 19.0597V19.1297L18.8597 17.9997V21.6497H22.5097L21.3297 20.4697C22.1119 19.484 22.5288 18.2579 22.5097 16.9997Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
