import { createAsyncThunk } from '@reduxjs/toolkit';
import { Scorecard, ScorecardsActionStoreArgument } from 'store/interfaces';
import api from 'api';
import { API_SCORECARD_DELETED } from 'constants/api';

export const restoreDeleted = createAsyncThunk<
  Scorecard,
  {
    id: number;
    store: ScorecardsActionStoreArgument;
  }
>(`scorecards/restoreDeleted`, async ({ id }, thunkAPI) =>
  api.axios
    .patch<Scorecard>(`${API_SCORECARD_DELETED}/${id}`)
    .then((res) => res.data)
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
