import { useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { History, Transition } from 'history';
import type { Navigator } from 'react-router';

type ExtendedNavigator = Navigator & Pick<History, 'block'>;

export default function useBlocker(
  blocker: (tx: Transition) => void,
  when = true,
) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;

    const unblock = (navigator as unknown as ExtendedNavigator).block(
      (tx: Transition) => {
        const autoUnblockingTx = {
          ...tx,
          retry() {
            unblock();
            tx.retry();
          },
        };
        blocker(autoUnblockingTx);
      },
    );

    // eslint-disable-next-line consistent-return
    return unblock;
  }, [navigator, blocker, when]);
}
