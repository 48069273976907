import { SyntheticEvent, useCallback } from 'react';
import {
  SuccessCallback,
  useActionPipeline,
  useDialogState,
  useToast,
} from 'hooks';
import { useIntl } from 'react-intl';
import { scorecardsTexts } from 'i18n';
import {
  changePage,
  restoreDeleted,
  selectListData,
  selectPagination,
  updateList,
} from 'store/scorecards';
import { useScorecardContext } from 'contexts';
import { useSelector } from 'react-redux';
import { ScorecardListItem } from 'store/interfaces';
import { useAppDispatch } from 'store';

export default function useRestoreScorecard() {
  const dispatch = useAppDispatch();
  const { id, store, isArchived, isDeleted } = useScorecardContext();
  const [
    isRestoreScorecardDialogActive,
    openRestoreScorecardDialog,
    onRestoreScorecardDialogClose,
  ] = useDialogState(false, true);
  const listData =
    useSelector(selectListData(store, id)) || ({} as ScorecardListItem);
  const { page, limit, total } = useSelector(selectPagination(store));
  const { workRecord } = listData;

  const onRestoreScorecardClick = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      openRestoreScorecardDialog();
    },
    [openRestoreScorecardDialog],
  );

  const { formatMessage } = useIntl();
  const showMessage = useToast();
  const onRestoreScorecardSuccess = useCallback<SuccessCallback>(() => {
    if (page * limit >= total - 1 && page > 1) {
      dispatch(changePage({ store, page: page - 1 }));
    }
    dispatch(updateList({ store, isArchived, isDeleted }));
    showMessage({
      severity: 'success',
      message: formatMessage(scorecardsTexts.restoreScorecardToast, {
        name: workRecord?.user?.fullName,
      }),
    });
  }, [
    page,
    limit,
    total,
    dispatch,
    store,
    isArchived,
    isDeleted,
    showMessage,
    formatMessage,
    workRecord?.user?.fullName,
  ]);

  const revertToActivePipeline = useActionPipeline(
    onRestoreScorecardSuccess,
    null,
    onRestoreScorecardDialogClose,
  );

  const onRestoreScorecardSubmit = useCallback(() => {
    revertToActivePipeline(
      restoreDeleted({
        id,
        store,
      }),
    );
  }, [id, revertToActivePipeline, store]);
  return {
    onRestoreScorecardClick,
    isRestoreScorecardDialogActive,
    onRestoreScorecardDialogClose,
    onRestoreScorecardSubmit,
  };
}
