import { createAsyncThunk } from '@reduxjs/toolkit';
import { CycleTemplate, CycleTemplateDTO } from 'store/interfaces';
import api from 'api';
import { API_SCORECARD_TEMPLATE } from 'constants/api';
import { storeName } from '../config';

export const templateCreate = createAsyncThunk<CycleTemplate, CycleTemplateDTO>(
  `${storeName}/addTemplate`,
  async (data, thunkAPI) =>
    api.axios
      .post<CycleTemplate>(API_SCORECARD_TEMPLATE, data)
      .then((res) => res.data)
      .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
