import { FormProvider } from 'react-hook-form';
import {
  MainForm,
  PageHeader,
  PageTitleWrapper,
  SecondaryPageTitle,
} from 'components/PageContainer';
import { Breadcrumbs, Button, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { commonTexts, reviewCyclesTexts } from 'i18n';
import GoalsAndSubgoalsForm from 'components/GoalsAndSubgoalsForm';
import {
  useHasScrolledDistanceToShowStickyHeader,
  useCommonGoalsFormValues,
} from 'hooks';
import cx from 'classnames';
import { Box } from '@mui/system';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CommonGoalCategory } from 'store/commonGoals/interfaces/CommonGoalCategory';
import PageLoader from 'components/PageLoader';
import { useSelector } from 'react-redux';
import { selectCommonGoals } from 'store/commonGoals';
import CommonGoalsCategorySelect from './CommonGoalsCategorySelect';

export default function CommonGoalsForm() {
  const navigate = useNavigate();

  const [selectedCategory, setSelectedCategory] =
    useState<CommonGoalCategory | null>(null);
  const { form, onSubmitClick, canSubmit } =
    useCommonGoalsFormValues(selectedCategory);
  const { updating, loading, loadingCategories } =
    useSelector(selectCommonGoals);
  const {
    watch,
    formState: { isDirty },
  } = form;
  const showLoader = updating || loading || loadingCategories;

  const onBackClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);
  const onCategoryChange = useCallback((value: CommonGoalCategory | null) => {
    setSelectedCategory(value);
  }, []);
  const category = watch('category');

  const { hasScrolledDistance } = useHasScrolledDistanceToShowStickyHeader();

  return (
    <FormProvider {...form}>
      <Box
        className={cx({
          hasStickyHeader: hasScrolledDistance,
        })}
      />
      <PageHeader
        className={cx({
          sticky: hasScrolledDistance,
        })}
      >
        <Breadcrumbs>
          <Typography variant="body2" display="flex" component="div">
            <FormattedMessage {...reviewCyclesTexts.title} />
          </Typography>
          <FormattedMessage {...reviewCyclesTexts.commonGoals} />
        </Breadcrumbs>
        <PageTitleWrapper>
          <SecondaryPageTitle data-testid="page-title">
            <FormattedMessage {...reviewCyclesTexts.manageCommonGoals} />
          </SecondaryPageTitle>
          <Stack direction="row">
            <Button size="large" variant="outlined" onClick={onBackClick}>
              <FormattedMessage {...commonTexts.back} />
            </Button>
            <Button
              size="large"
              variant="contained"
              onClick={onSubmitClick}
              sx={{ marginLeft: 1 }}
              disabled={!canSubmit}
            >
              <FormattedMessage {...commonTexts.save} />
            </Button>
          </Stack>
        </PageTitleWrapper>
      </PageHeader>
      <CommonGoalsCategorySelect
        isGoalsFormDirty={isDirty}
        onChange={onCategoryChange}
      />
      <MainForm>
        <PageLoader open={showLoader} />
        <GoalsAndSubgoalsForm
          canAddGoals={!category?.isService}
          hasRestrictionForTemplateGoal={false}
          isActiveOwnScorecard={false}
          currentCategoryId={category?.id}
          isCommonGoalEdit
        />
      </MainForm>
    </FormProvider>
  );
}
