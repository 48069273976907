import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_USER_MANAGEMENT_ASSIGN_ROLES } from 'constants/api';
import { generateURL } from 'helpers';
import { storeName } from '../config';
import { UpdateRolesResponse } from '../interfaces/UpdateRolesResponse';

export const updateUserRoles = createAsyncThunk<
  UpdateRolesResponse,
  { roles: { id: number; params?: { [key: string]: number[] } }[]; id: number }
>(`${storeName}/update`, async ({ roles, id }, thunkAPI) =>
  api.axios
    .put<UpdateRolesResponse>(
      generateURL(API_USER_MANAGEMENT_ASSIGN_ROLES, { id }),
      {
        roles,
      },
    )
    .then((res) => res.data)
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
