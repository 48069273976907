import { Typography, styled, Box } from '@mui/material';
import blackListProps from 'styles/helpers/blackListProps';
import { MessageWithIconProps } from './interfaces';

export const MessageWithIconContainer = styled(Typography, {
  shouldForwardProp: blackListProps(['messageVariant', 'iconSize']),
})<Omit<MessageWithIconProps, 'message'>>(
  ({ theme, messageVariant, iconSize }) => ({
    display: 'flex',
    color:
      messageVariant === 'error'
        ? theme.palette.error.main
        : theme.palette.common.black,
    textTransform: 'none',
    alignItems: 'center',
    '.MuiSvgIcon-root': {
      fontSize: iconSize,
      marginRight: theme.spacing(0.5),
      color:
        messageVariant === 'error'
          ? theme.palette.error.main
          : theme.palette.grey['600'],
    },
  }),
);

export const MessageBanner = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  height: '36px',
  backgroundColor: '#f5e2e3',
  display: 'flex',
  paddingLeft: theme.spacing(1),
  alignItems: 'center',
  '& .MuiSvgIcon-root': {
    color: theme.palette.red['500'],
  },
}));
