import { LogoProps } from '../interfaces';

export default function LogoStarIcon({ logoColor, width = 72 }: LogoProps) {
  return (
    <svg width={width} viewBox="0 0 72 24" fill="none">
      <path
        d="M32.5728 15.6421C33.6468 16.7847 35.3269 17.7863 37.4974 17.7863C39.6678 17.7863 40.6245 16.6902 40.6245 15.6646C40.6245 14.2656 38.9669 13.8473 37.0777 13.3569C34.5342 12.7272 31.547 11.982 31.547 8.69519C31.547 6.13115 33.8107 4.15039 37.2191 4.15039C39.6468 4.15039 41.6067 4.9196 43.0536 6.31858L41.4893 8.34582C40.2755 7.13277 38.6194 6.57498 36.9844 6.57498C35.3494 6.57498 34.3477 7.34419 34.3477 8.50926C34.3477 9.67432 35.9346 10.1182 37.7786 10.583C40.3462 11.2352 43.4041 12.0284 43.4041 15.4082C43.4041 17.9947 41.5841 20.2094 37.3815 20.2094C34.5101 20.2094 32.4329 19.2077 31.0566 17.7383L32.5743 15.6406L32.5728 15.6421Z"
        fill={logoColor}
      />
      <path
        d="M45.7182 17.3198V10.8167H43.8516V8.67253H45.7182V6.0815L48.1684 5.10986V8.67253H50.4562V10.8167H48.1684V16.714C48.1684 17.4832 48.5415 18.0425 49.2424 18.0425C49.7087 18.0425 50.1524 17.8566 50.3389 17.6467L50.9225 19.488C50.4788 19.9078 49.7553 20.2107 48.6122 20.2107C46.7215 20.2107 45.7182 19.1851 45.7182 17.3213"
        fill={logoColor}
      />
      <path
        d="M60.0102 18.3923C59.1469 19.5109 57.8398 20.2111 56.3928 20.2111C53.4988 20.2111 51.375 18.0204 51.375 14.3138C51.375 10.6072 53.4748 8.39404 56.3928 8.39404C57.7932 8.39404 59.1228 9.0463 60.0102 10.2354V8.67444H62.4605V19.9307H60.0102V18.3923ZM60.0102 12.0992C59.4507 11.2595 58.2594 10.5847 57.0922 10.5847C55.1549 10.5847 53.9185 12.1231 53.9185 14.3138C53.9185 16.5045 55.1549 18.0429 57.0922 18.0429C58.2594 18.0429 59.4492 17.3907 60.0102 16.551V12.0992Z"
        fill={logoColor}
      />
      <path
        d="M64.8535 8.67291H67.3038V10.3283C68.1446 9.25619 69.4983 8.4165 70.9678 8.4165C71.5229 8.4165 71.9997 8.57544 71.9997 8.57544V11C71.9997 11 71.5349 10.7706 70.3602 10.7706C69.1854 10.7706 67.7941 11.4694 67.3038 12.2626V19.9307H64.8535V8.67291Z"
        fill={logoColor}
      />
      <path
        d="M23.9278 0C12.5926 9.83931 11.4826 9.83931 0.147405 0L0 0.146945C9.87014 11.4467 9.87014 12.5548 0 23.8531L0.147405 24C11.4826 14.1607 12.5926 14.1607 23.9278 24L24.0752 23.8531C14.2051 12.5548 14.2051 11.4467 24.0752 0.146945L23.9278 0Z"
        fill={logoColor}
      />
    </svg>
  );
}
