import { memo } from 'react';
import type { NodeProps } from 'reactflow';
import type { NodeData } from 'store/interfaces';
import { isEqual } from 'lodash-es';
import { useSelector } from 'react-redux';
import { selectDetailsDrawerState } from 'store/roadmap';
import palette from 'styles/theme/palette';
import NodeWrapper from './NodeWrapper';
import NodeMenu from './NodeMenu.component';
import NodeContent from './NodeContent';
import Handlers from './Handlers';
import DragHandler from './DragHandler.component';

function SkillNode({
  id,
  data,
  type,
  sourcePosition,
  targetPosition,
  isConnectable,
}: NodeProps<NodeData>) {
  const selectedNode = useSelector(selectDetailsDrawerState);

  return (
    <>
      <NodeWrapper
        sx={{
          boxShadow:
            selectedNode?.id === id
              ? `0 0 0 2px ${palette.primary.main}`
              : undefined,
        }}
      >
        <NodeContent data={data} type={type} />
        {isConnectable && (
          <>
            <DragHandler />
            <NodeMenu />
          </>
        )}
      </NodeWrapper>
      <Handlers
        edges={data.edges}
        sourcePosition={sourcePosition}
        targetPosition={targetPosition}
        isConnectable={isConnectable}
      />
    </>
  );
}

SkillNode.displayName = 'SkillNode';

export default memo(
  SkillNode,
  (prev, next) =>
    isEqual(prev.data, next.data) &&
    prev.targetPosition === next.targetPosition &&
    prev.isConnectable === next.isConnectable &&
    prev.sourcePosition === next.sourcePosition,
);
