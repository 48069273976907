import { useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';

export default function usePersistScrollPositionAfterBackFromOutlet(
  parentPathname: string,
) {
  const { pathname } = useLocation();
  const SCROLL_POSITION_KEY_NAME = 'parentScrollYPosition';

  const scrollY = useRef(0);

  useEffect(() => {
    if (pathname === parentPathname) {
      const persistedScrollY = localStorage.getItem(SCROLL_POSITION_KEY_NAME);
      if (persistedScrollY) {
        setTimeout(() => {
          window.scrollTo({ top: +persistedScrollY });
          localStorage.removeItem(SCROLL_POSITION_KEY_NAME);
        }, 0);
      }
    }
    return () => {
      if (pathname === parentPathname) {
        localStorage.setItem(
          SCROLL_POSITION_KEY_NAME,
          scrollY.current.toString(),
        );
      }
    };
  }, [parentPathname, pathname]);

  useEffect(() => {
    const scrollTracker = () => {
      scrollY.current = window.scrollY;
    };

    window.addEventListener('scroll', scrollTracker);

    return () => {
      window.removeEventListener('scroll', scrollTracker);
      localStorage.removeItem(SCROLL_POSITION_KEY_NAME);
    };
  }, []);
}
