/* eslint-disable no-underscore-dangle */
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { OKR_PRECISE_ATTAINMENTS } from 'constants/api';
import { showOLDialog } from 'store/optimisticLocking';
import dayjs from 'dayjs';
import { generateURL } from 'helpers';
import { OkrObjectiveResponse } from 'store/okrObjectives';
import { storeName } from '../config';

export const preciseTeamOverviewAttainments = createAsyncThunk<
  OkrObjectiveResponse,
  {
    data: {
      keyResults: { id: string | number; agreedAttainmentScore: number }[];
      expectedVersion: number;
    };
    periodId: string;
    id: string;
  }
>(
  `${storeName}/preciseTeamOverviewAttainments`,
  async ({ data, periodId, id }, thunkAPI) =>
    api.axios
      .patch<OkrObjectiveResponse>(
        generateURL(OKR_PRECISE_ATTAINMENTS, { periodId, id }),
        data,
      )
      .then((res) => res.data)
      .catch((e) => {
        // optimistic locking error handling
        if (e.response?.status === 412) {
          thunkAPI.dispatch(
            showOLDialog({
              type: 'scorecard',
              params: {
                name: e.response?.data?.__meta?.lastActivity?.actor.fullName,
                date: dayjs(
                  e.response?.data?.__meta?.lastActivity?.doneAt,
                ).format('HH:mm DD.MM.YYYY'),
              },
            }),
          );
        }

        return thunkAPI.rejectWithValue({
          ...e.response?.data,
          response: {
            status: e.response?.status,
          },
        });
      }),
);
