import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import api from 'api';
import { generateURL } from 'helpers';
import { API_SCORECARD_ASSESSMENT_SCALES_OPTIONS } from 'constants/api';
import { storeName } from '../config';
import { Option } from '../interfaces';

export const updateAssessmentScales = createAsyncThunk<{
  [key: number]: Option[];
}>(`${storeName}/updateAssessmentScales`, (data, thunkAPI) => {
  const {
    options: { assessmentScaleGroups },
  } = thunkAPI.getState() as RootState;
  return Promise.all(
    assessmentScaleGroups.map(({ id }) =>
      api.axios
        .get(generateURL(API_SCORECARD_ASSESSMENT_SCALES_OPTIONS, { id }))
        .then((res) => res.data?.items || [])
        .catch(() => []),
    ),
  ).then((res) =>
    res.reduce((acc: { [key: number]: Option[] }, items, index) => {
      Object.assign(acc, { [assessmentScaleGroups[index].id]: items });
      return acc;
    }, {}),
  );
});
