import { styled, Switch as MUISwitch } from '@mui/material';

export const Switch = styled(MUISwitch)(({ theme }) => ({
  width: 38,
  height: 22,
  padding: 0,
  marginRight: 8,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 3,
    transitionDuration: '300ms',
    color: theme.palette.grey[700],
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: theme.palette.blue[500],
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.common.white,
      },
    },
    '&:hover + .MuiSwitch-track': {
      border: `1px solid ${theme.palette.blue[400]}`,
    },
    '&.Mui-focusVisible + .MuiSwitch-track': {
      border: `1px solid ${theme.palette.blue[400]}`,
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey.A400,
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: theme.palette.grey[300],
      border: `1px solid ${theme.palette.grey.A400}`,
      opacity: 1,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
    boxShadow: 'none',
  },
  '& .MuiSwitch-track': {
    borderRadius: 12,
    border: `1px solid ${theme.palette.grey.A400}`,
    backgroundColor: theme.palette.common.white,
  },
}));
