import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import MessageWithIcon from 'components/MessageWithIcon';
import { GoalField } from './interfaces';

export default function SubgoalsTotalWeightError() {
  const {
    formState: { errors },
  } = useFormContext<GoalField>();
  const { formatMessage } = useIntl();
  const { goals = [] } = errors;
  const subgoalsWeightError =
    Object.values(goals).findIndex(
      (error: any) => error && typeof error.subgoalsWeight !== 'undefined',
    ) > -1;

  if (!subgoalsWeightError) {
    return null;
  }
  return (
    <MessageWithIcon
      messageVariant="error"
      variant="body3"
      iconSize={22}
      mr={0.5}
      message={formatMessage({
        id: 'pages.reviewCycles.totalWeightError',
        defaultMessage: 'Subgoals total weight for some goals is not 100%',
      })}
    />
  );
}
