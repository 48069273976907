import { createSlice, Reducer } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash-es';
import { hideOLDialog, showOLDialog } from './actions';
import { storeName, olDialogInitialState } from './config';
import addCommonCases from '../addCommonCases';

export * from './interfaces';
export * from './actions';

const olDialogSlice = createSlice({
  name: storeName,
  initialState: cloneDeep(olDialogInitialState),
  reducers: {},
  extraReducers(builder) {
    addCommonCases(builder, olDialogInitialState)
      .addCase(showOLDialog, (state, action) => {
        Object.assign(state, {
          ...action.payload,
          open: true,
        });
      })
      .addCase(hideOLDialog, (state) => {
        Object.assign(state, {
          open: false,
        });
      });
  },
});

export default olDialogSlice.reducer as Reducer<typeof olDialogInitialState>;
