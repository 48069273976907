import { useSidebarNavigation } from 'hooks';
import { createContext, useContext, PropsWithChildren, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { RootState } from 'store';
import { OkrObjectiveResponse, selectObjectiveById } from 'store/okrObjectives';
import { selectReviewCycleById } from 'store/okrReviewCycles';
import { OkrReviewCycleStatuses } from 'store/okrReviewCycles/interfaces/OkrReviewCycle';

interface OkrObjectiveContextValues {
  tabs: string[];
  setTabs: (tabs: string[]) => void;
  scrollToTab: (tab: string) => void;
  activeTab: string;
  setActiveTab: (tab: string) => void;
  objectiveTab: string;
  isEditMode: boolean;
  isEditable: boolean;
  isBinding: boolean;
  isCreateMode: boolean;
  reportingPeriodId: string;
  reportingPeriodEndDate: string;
  reportingPeriodName: string;
  reportingPeriodStatus: OkrReviewCycleStatuses;
  objective: OkrObjectiveResponse | null;
}

const OkrObjectiveContext = createContext<OkrObjectiveContextValues>(
  {} as unknown as OkrObjectiveContextValues,
);

export function useOkrObjectiveContext() {
  return useContext<OkrObjectiveContextValues>(OkrObjectiveContext);
}

export function OkrObjectiveProvider({
  children,
  scrollableElementId,
  isEditMode,
  isCreateMode,
  isEditable,
}: PropsWithChildren<{
  scrollableElementId: string;
  isEditMode: boolean;
  isEditable: boolean;
  isCreateMode: boolean;
}>) {
  const sidebarNavigation = useSidebarNavigation(scrollableElementId);
  const { reportingPeriod, id } = useParams();
  const [searchParams] = useSearchParams();
  const bindingId = searchParams.get('bindingId');
  const objectiveId = bindingId || id;
  const reportingPeriodData = useSelector((state: RootState) =>
    selectReviewCycleById(state, reportingPeriod as string),
  );
  const objective = useSelector((state: RootState) =>
    selectObjectiveById(state, Number(objectiveId)),
  );
  const contextValue = useMemo<OkrObjectiveContextValues>(
    () => ({
      ...sidebarNavigation,
      isEditMode,
      isEditable,
      isBinding: Boolean(bindingId),
      isCreateMode,
      reportingPeriodId: String(reportingPeriodData?.id),
      reportingPeriodEndDate: reportingPeriodData?.endAt || '',
      reportingPeriodName: reportingPeriodData?.name || '',
      reportingPeriodStatus:
        reportingPeriodData?.status || OkrReviewCycleStatuses.CLOSED,
      objective,
    }),
    [
      sidebarNavigation,
      isEditMode,
      isCreateMode,
      isEditable,
      reportingPeriodData?.id,
      reportingPeriodData?.endAt,
      reportingPeriodData?.name,
      reportingPeriodData?.status,
      objective,
      bindingId,
    ],
  );
  return (
    <OkrObjectiveContext.Provider value={contextValue}>
      {children}
    </OkrObjectiveContext.Provider>
  );
}
