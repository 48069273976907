import { createSlice, isAnyOf, Reducer } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash-es';
import {
  getCommonGoalsList,
  updateCategoryGoals,
  getCommonGoalsCategories,
  createCommonGoalsCategory,
  updateCommonGoalsCategory,
  deleteCommonGoalsCategory,
} from './actions';
import { storeName, commonGoalsInitialState } from './config';
import addCommonCases from '../addCommonCases';

export * from './interfaces';
export * from './actions';
export * from './selectors';

const commonGoals = createSlice({
  name: storeName,
  initialState: cloneDeep(commonGoalsInitialState),
  reducers: {},
  extraReducers: (builder) => {
    addCommonCases(builder, commonGoalsInitialState)
      .addCase(getCommonGoalsList.fulfilled, (state, action) => {
        state.items = action.payload.items;
      })
      .addCase(getCommonGoalsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCategoryGoals.fulfilled, (state, action) => {
        const categoryId = action.meta.arg.id;

        const category = state.items.find((item) => item.id === categoryId);

        if (category) {
          category.goals = action.payload.goals;
        }
      })
      .addCase(updateCategoryGoals.pending, (state) => {
        state.updating = true;
      })
      .addCase(getCommonGoalsCategories.fulfilled, (state, action) => {
        state.categories = action.payload.items;
      })
      .addCase(getCommonGoalsCategories.pending, (state) => {
        state.loadingCategories = true;
      })
      .addCase(createCommonGoalsCategory.fulfilled, (state, action) => {
        state.categories = [action.payload, ...state.categories];
        state.items = [
          ...state.items,
          {
            ...action.payload,
            goals: [],
          },
        ];
      })
      .addCase(createCommonGoalsCategory.pending, (state) => {
        state.updatingCategories = true;
      })
      .addCase(updateCommonGoalsCategory.fulfilled, (state, action) => {
        const index = state.categories.findIndex(
          (item) => item.id === action.payload.id,
        );

        if (index !== -1) {
          state.categories[index] = action.payload;
        }
      })
      .addCase(updateCommonGoalsCategory.pending, (state) => {
        state.updatingCategories = true;
      })
      .addCase(deleteCommonGoalsCategory.pending, (state) => {
        state.deletingCategories = true;
      })
      .addCase(deleteCommonGoalsCategory.fulfilled, (state, action) => {
        const index = state.categories.findIndex(
          (item) => item.id === action.meta.arg.id,
        );

        if (index !== -1) {
          state.categories.splice(index, 1);
        }
      });
    builder.addMatcher(
      isAnyOf(getCommonGoalsList.fulfilled, getCommonGoalsList.rejected),
      (state) => {
        state.loading = false;
      },
    );
    builder.addMatcher(
      isAnyOf(
        getCommonGoalsCategories.fulfilled,
        getCommonGoalsCategories.rejected,
        createCommonGoalsCategory.fulfilled,
        createCommonGoalsCategory.rejected,
        updateCommonGoalsCategory.fulfilled,
        updateCommonGoalsCategory.rejected,
        deleteCommonGoalsCategory.fulfilled,
        deleteCommonGoalsCategory.rejected,
      ),
      (state) => {
        state.loadingCategories = false;
      },
    );
    builder.addMatcher(
      isAnyOf(
        createCommonGoalsCategory.fulfilled,
        createCommonGoalsCategory.rejected,
        updateCommonGoalsCategory.fulfilled,
        updateCommonGoalsCategory.rejected,
      ),
      (state) => {
        state.updatingCategories = false;
      },
    );
    builder.addMatcher(
      isAnyOf(
        deleteCommonGoalsCategory.fulfilled,
        deleteCommonGoalsCategory.rejected,
      ),
      (state) => {
        state.deletingCategories = false;
      },
    );
    builder.addMatcher(
      isAnyOf(updateCategoryGoals.fulfilled, updateCategoryGoals.rejected),
      (state) => {
        state.updating = false;
      },
    );
  },
});

export default commonGoals.reducer as Reducer<typeof commonGoalsInitialState>;
