import { Typography } from '@mui/material';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

export default function TrimmedList({
  items,
  // Would be nice to refactor this to calculate item width automatically
  itemWidth,
}: {
  items: string[];
  itemWidth: number;
}) {
  const listRef = useRef<HTMLDivElement>(null);
  const [list, setList] = useState<ReactNode>('');

  const elementWidth = listRef?.current?.offsetWidth || 0;

  const updateList = useCallback((): ReactNode => {
    const itemsToDisplay = Math.floor(elementWidth / itemWidth);
    const itemsToTrim = items.length - itemsToDisplay;
    const trimmedItems = items.slice(0, itemsToDisplay);

    if (itemsToTrim <= 0) {
      setList(items.join(', '));
      return null;
    }

    setList(
      trimmedItems.length ? `${trimmedItems.join(', ')}, +${itemsToTrim}` : '',
    );
    return null;
  }, [elementWidth, itemWidth, items]);

  const onResize = useCallback(() => {
    updateList();
  }, [updateList]);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);
  useEffect(() => {
    updateList();
  }, [updateList]);
  return (
    <Typography sx={{ width: '100%' }} noWrap ref={listRef} variant="body2">
      {list}
    </Typography>
  );
}
