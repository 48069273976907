export const ROLE_EMPLOYEE = 'employee';
export const ROLE_ADMIN = 'admin';
export const ROLE_DIRECTOR = 'director';
export const ROLE_CAREER_MENTOR = 'career-mentor';
export const ROLE_PEOPLE_PARTNER = 'people-partner';
export const ROLE_REVOPS_MANAGER = 'revops-manager';
export const ROLE_DEPARTMENT_MANAGER = 'department-manager';
export const ROLE_OKR_SUPERADMIN = 'okr-super-admin';

export const ROADMAP_ACCESS_EMAILS: string[] = [
  'imykhailychenko@star.global',
  'ozmiiuk@star.global',
  'izabielin@star.global',
  'afilonenko@star.global',
];
