import { useState, useEffect, useMemo, useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CycleFormFields } from 'pages/ReviewCycles';
import {
  dayjs,
  formatCycleData,
  generateURL,
  handleResponseErrors,
} from 'helpers';
import api from 'api';
import { Option, selectOptions } from 'store/options';
import { useSelector, batch } from 'react-redux';
import { useIntl } from 'react-intl';
import { API_REVIEW_CYCLE_TEMPLATES_SHORT_LIST } from 'constants/api';
import { CatchCallback, SuccessCallback, useActionPipeline } from 'hooks';
import commonMessages from 'i18n/common';
import { showToast } from 'store/toast';
import { cycleCreate, cycleGetList, cycleUpdate } from 'store/reviewCycles';
import { DialogOnClose } from 'components/Dialog';

export default function useCycleFormValues(
  onCancel: DialogOnClose,
  defaultValues: CycleFormFields,
  isEdit: boolean,
) {
  const form = useForm({
    defaultValues,
  });
  const { formatMessage } = useIntl();
  const { setValue, clearErrors, watch, handleSubmit, setError } = form;

  const [templates, setTemplates] = useState<Option[]>([]);
  const { reviewCycles } = useSelector(selectOptions);
  const formCycleId = watch('cycleId');
  const startAt = watch('startAt');
  const endAt = watch('endAt');
  const currentTemplates = watch('templateIds');
  const cycleId = isEdit ? null : formCycleId;
  useEffect(() => {
    setValue('templateIds', []);
    if (cycleId) {
      api.axios
        .get<{ items: (Option & { unit: Option; countries: Option[] })[] }>(
          generateURL(API_REVIEW_CYCLE_TEMPLATES_SHORT_LIST, { id: cycleId }),
        )
        .then(({ data: { items } }) => {
          setValue(
            'templateIds',
            items.map(({ id }) => id as number),
          );
          setTemplates(
            items
              .map(({ unit, countries, ...rest }) => ({
                ...rest,
                unit: unit.name,
                countries: countries.map(({ name }) => name),
              }))
              .sort((a, b) => a.name.localeCompare(b.name))
              .sort((a, b) => a.unit.localeCompare(b.unit)),
          );
        });
    }
  }, [cycleId, setValue]);

  const isEndDateAligned = (v: string) => dayjs(v).isAfter(startAt);
  const isDateFormatted = (v: string) => dayjs(v).isValid();

  const isEndDateInFuture = (v: string) => dayjs(v).isAfter(Date.now());

  useEffect(() => {
    if (dayjs(endAt).isAfter(Date.now())) {
      clearErrors('endAt');
    }

    dayjs(startAt).isBefore(endAt);
  }, [startAt, endAt, clearErrors]);

  const onSubmitSuccess = useCallback<SuccessCallback<CycleFormFields>>(
    ({ payload: { name } }, dispatch) => {
      const message = isEdit
        ? formatMessage(commonMessages.updated)
        : formatMessage(commonMessages.entityCreated, {
            entityName: name,
          });
      batch(() => {
        dispatch(showToast({ severity: 'success', message }));
        dispatch(cycleGetList());
      });
      onCancel();
    },
    [formatMessage, isEdit, onCancel],
  );

  const onSubmitCatch = useMemo<CatchCallback>(
    () => handleResponseErrors<CycleFormFields>(setError),
    [setError],
  );

  const onRemoveSelection = useCallback(
    (id: number) => {
      setValue(
        'templateIds',
        currentTemplates?.filter((templateId: number) => templateId !== id),
      );
    },
    [currentTemplates, setValue],
  );

  const submitPipeline = useActionPipeline(onSubmitSuccess, onSubmitCatch);
  const onSubmit: SubmitHandler<CycleFormFields> = useCallback(
    (data) => {
      const mainAction = isEdit ? cycleUpdate : cycleCreate;
      submitPipeline(mainAction(formatCycleData(data, isEdit)));
    },
    [isEdit, submitPipeline],
  );
  const onSubmitClick = useMemo(
    () => handleSubmit(onSubmit),
    [handleSubmit, onSubmit],
  );
  const clearTemplateValue = useCallback(() => {
    setValue('templateIds', []);
  }, [setValue]);
  return {
    form,
    isEndDateAligned,
    isEndDateInFuture,
    isDateFormatted,
    cycleId,
    options: {
      templates,
      reviewCycles,
    },
    onSubmitClick,
    clearTemplateValue,
    onRemoveSelection,
  };
}
