import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_CAREER_ROADMAP } from 'constants/api';
import { storeName } from '../config';
import { RoadmapDepartmentsList } from '../interfaces';

export const getRoadmapDepartments = createAsyncThunk(
  `${storeName}/departments`,
  (_, thunkAPI) =>
    api.axios
      .get<RoadmapDepartmentsList>(API_CAREER_ROADMAP)
      .then((res) => res.data)
      .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
