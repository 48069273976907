import { RequestGoalFormValues } from 'pages/FeedbackRequests/interfaces';

export default function formatRequestGoalHeader(
  goalHeader: RequestGoalFormValues,
): RequestGoalFormValues {
  return {
    ...goalHeader,
    subgoals: goalHeader.subgoals.map((sg) => {
      if (sg.comment) {
        return {
          ...sg,
          comment: sg.comment.trim(),
        };
      }
      return sg;
    }),
  };
}
