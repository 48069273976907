import { Edge, MarkerType } from 'reactflow';
import { pick } from 'lodash-es';

export default class EdgeNormalizer {
  static defaultEdgeConfig = {
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 14,
      height: 14,
    },
    type: 'step',
    sourceHandle: null,
    targetHandle: null,
  };

  static reduce(edges: Edge[]): Edge[] {
    return edges.map((edge) => pick(edge, ['id', 'source', 'target']));
  }

  static expand(edges: Edge[]): Edge[] {
    return edges.map((edge) => ({
      ...EdgeNormalizer.defaultEdgeConfig,
      ...edge,
    }));
  }
}
