import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_COUNTRY_STATS } from 'constants/api';
import qs from 'query-string';
import { storeName } from '../config';
import { CountryResponse } from '../interfaces/CountryStats';

export const updateCountries = createAsyncThunk(
  `${storeName}/updateCountries`,
  (data: { unitId: number | null }) => {
    const { unitId } = data;
    const url = `${API_COUNTRY_STATS}?${qs.stringify(
      { unitId },
      { skipNull: true },
    )}`;

    return api.axios
      .get(url)
      .then((res) => (res.data as CountryResponse)?.items || [])
      .catch(() => [])
      .then((res) =>
        res
          .map((item) => ({ id: item.country.id, name: item.country.name }))
          .sort((a, b) => a.name.localeCompare(b.name)),
      );
  },
);
