import api from 'api';
import { Pagination, Changelog } from 'store/interfaces';
import qs from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { OKR_OBJECTIVE_AUDIT_LOGS } from 'constants/api';
import { generateURL } from 'helpers';
import { useParams, useLocation } from 'react-router-dom';

type State = {
  items: Changelog[];
  pagination: Pagination;
};

const LOAD_LIMIT = 10;

export default function useObjectiveChangelog() {
  const location = useLocation();
  const { id, reportingPeriod } = useParams();

  const searchParams = new URLSearchParams(location.search);
  const bindingId = searchParams.get('bindingId');

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState<State>({
    items: [],
    pagination: { limit: 0, page: 0, total: 0 },
  });

  const fetch = useCallback(() => {
    setLoading(true);
    return api.axios
      .get<State>(
        `${generateURL(OKR_OBJECTIVE_AUDIT_LOGS, {
          reportingPeriodId: String(reportingPeriod),
          objectiveId: String(bindingId || id),
        })}?${qs.stringify({
          page: currentPage,
          limit: LOAD_LIMIT,
        })}`,
      )
      .then(({ data: { items, pagination } }) => setData({ items, pagination }))
      .finally(() => setLoading(false));
  }, [currentPage, id, reportingPeriod, bindingId]);

  useEffect(() => {
    fetch();
  }, [fetch, currentPage]);

  const fetchPage = useCallback(
    (page: number) => setCurrentPage(page),
    [setCurrentPage],
  );

  return {
    loading,
    fetchPage,
    data,
  };
}
