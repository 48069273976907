import { SvgIconProps } from '@mui/material/SvgIcon';

export default function CheckboxMarkedIcon(props: SvgIconProps) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="17"
        height="17"
        rx="3.5"
        fill="currentColor"
      />
      <path
        d="M7.22119 13.5L3 9.2151L4.12453 8.0736L7.25835 11.2547L13.9126 4.5L15 5.60378L7.22119 13.5Z"
        fill="white"
      />
      <rect
        x="0.5"
        y="0.5"
        width="17"
        height="17"
        rx="3.5"
        stroke="currentColor"
      />
    </svg>
  );
}
