import { AppRouteComponentProps } from 'components/AppRoutes';
import PageContainer from 'components/PageContainer';
import { usePageAccessible } from 'hooks';
import CommonGoalsForm from './CommonGoalsForm';

export default function CommonGoalsEdit(props: AppRouteComponentProps) {
  const hasAccess = usePageAccessible(props);

  return (
    <PageContainer hasAccess={hasAccess}>
      <CommonGoalsForm />
    </PageContainer>
  );
}
