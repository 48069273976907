import { RootState } from 'store';

export const selectUsers = (state: RootState) => state.okrTeamOverview.users;
export const selectUsersPagination = (state: RootState) =>
  state.okrTeamOverview.usersPagination;
export const selectUsersLoading = (state: RootState) =>
  state.okrTeamOverview.usersLoading;

export const selectOkrTeamOverviewFilters = (state: RootState) =>
  state.okrTeamOverview.filters;
