import { RootState } from 'store';

export const selectUserMgmtUsers = (state: RootState) =>
  state.userManagement.users;

export const selectUserMgmtFilters = (state: RootState) =>
  state.userManagement.filters;

export const selectUserMgmtPagination = (state: RootState) =>
  state.userManagement.pagination;

export const selectUserMgmtLoading = (state: RootState) =>
  state.userManagement.loading;

export const selectRoles = (state: RootState) => state.userManagement.roles;

export const selectIsRolesUpdating = (state: RootState) =>
  state.userManagement.updating;

export const selectLastChange = (state: RootState) =>
  state.userManagement.lastChange;
