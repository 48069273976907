import { useCallback } from 'react';
import { useScorecardData } from 'hooks';
import { useScorecardContext } from 'contexts';
import { useAppDispatch } from 'store';
import { moderateFeedback } from 'store/scorecards';

export default function useSaveFeedback(
  feedbackGoalId: number,
  feedbackSubgoalId: number,
  saveSubgoal = false,
) {
  const { id, store } = useScorecardContext();
  const dispatch = useAppDispatch();

  const saveGoalFeedback = useCallback(
    (paraphrasedText: string) => {
      const data = saveSubgoal
        ? {
            id: feedbackGoalId,
            subgoals: [
              {
                id: feedbackSubgoalId,
                commentFinalized: paraphrasedText.trim(),
              },
            ],
          }
        : {
            id: feedbackGoalId,
            commentFinalized: paraphrasedText.trim(),
          };

      return dispatch(
        moderateFeedback({
          store,
          id,
          data,
        }),
      );
    },
    [dispatch, feedbackGoalId, feedbackSubgoalId, id, saveSubgoal, store],
  );
  const { loading: isSaving } = useScorecardData();

  return {
    isSaving,
    saveGoalFeedback,
  };
}
