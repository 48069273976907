import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_CAREER_ROADMAP } from 'constants/api';
import type { RootState } from 'store';
import { RoadmapDepartment } from 'store/interfaces';
import { storeName } from '../config';
import { selectDepartmentsList } from '../selectors';

export const updateDepartment = createAsyncThunk<
  RoadmapDepartment,
  { id: number; name: string }
>(`${storeName}/update`, (payload, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;

  const { list } = selectDepartmentsList(state);
  const prevValue = list.find((item) => item.id === payload.id);

  if (!prevValue) {
    return thunkAPI.rejectWithValue('No department selected');
  }

  const newSchemaData = !prevValue.schemaData?.nodes
    ? { nodes: [], edges: [], filters: [] }
    : prevValue.schemaData;

  return api.axios
    .put<RoadmapDepartment>(`${API_CAREER_ROADMAP}/${payload.id}`, {
      name: payload.name,
      schemaData: newSchemaData,
    })
    .then((res) => res.data)
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data));
});
