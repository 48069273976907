import { Box, styled } from '@mui/system';
import { IconButton as MuiIconButton } from '@mui/material';
import palette from 'styles/theme/palette';

export const Wrapper = styled(Box)`
  background-color: ${palette.common.white};
  padding: 16px;
`;

export const ButtonsWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

export const IconButton = styled(MuiIconButton)(({ theme }) => ({
  color: theme.palette.blue[500],
  width: '40px',
  height: '40px',
  '& .MuiSvgIcon-root': {
    fontSize: '1rem',
  },
}));
