import { merge, values, groupBy, mergeWith, isObject } from 'lodash-es';
import {
  KeyResult,
  OkrChangeGroup,
  OkrObjectiveChangeMerged,
} from 'store/interfaces';

const mergeKeyResults = (keyResults: KeyResult[] = []) =>
  values(groupBy(keyResults, 'id')).map((group) =>
    mergeWith({}, ...group, (objValue: KeyResult, srcValue: KeyResult) =>
      isObject(objValue) ? merge(objValue, srcValue) : srcValue,
    ),
  );

// API return two objects dataChangePayload and previousState.
// This method merges them into one.
export default function mergeScorecardUpdateChanges(
  changeGroups: OkrChangeGroup[],
): OkrObjectiveChangeMerged {
  const mergedData = changeGroups.map((item) => ({
    ...item,
    dataChangePayload: {
      ...item.dataChangePayload,
      keyResults: mergeKeyResults(item?.dataChangePayload?.keyResults),
    },
    previousState: item.previousState
      ? {
          ...item.previousState,
          keyResults: mergeKeyResults(item?.previousState?.keyResults),
        }
      : null,
  }));
  // there is only one change group in the array for scorecard update
  const changeGroup = mergedData[0];
  const payloadObjective = changeGroup?.dataChangePayload;
  const previousStateObjective = changeGroup?.previousState;
  const keyResults = payloadObjective?.keyResults;
  const prevKeyResults = previousStateObjective?.keyResults;
  const objective: any = {
    ...payloadObjective,
    type: !previousStateObjective ? 'create' : 'update',
    previousState: { ...previousStateObjective, keyResults: undefined },
    keyResults: [],
  };

  if (prevKeyResults) {
    prevKeyResults.forEach((prevKR: any) => {
      const payloadKeyResultIndex = keyResults?.findIndex(
        (kr: any) => kr?.id === prevKR.id,
      );

      if (
        keyResults &&
        payloadKeyResultIndex !== undefined &&
        payloadKeyResultIndex !== -1
      ) {
        objective.keyResults.push(
          merge(
            {},
            { ...prevKR, payload: prevKR?.previousState },
            keyResults[payloadKeyResultIndex],
          ),
        );
      } else {
        objective.keyResults.push(prevKR);
      }
    });
  }

  if (keyResults) {
    keyResults.forEach((pkr: any) => {
      if (
        objective.keyResults.findIndex((kr: any) => kr.id === pkr.id) === -1
      ) {
        objective.keyResults.push(pkr);
      }
    });
  }

  return objective;
}
