import { createAsyncThunk } from '@reduxjs/toolkit';
import { LastChange, Pagination, UserMgmtItem } from 'store/interfaces';
import api from 'api';
import { RootState } from 'store';
import qs from 'query-string';
import { API_USER_MANAGEMENT_SEARCH } from 'constants/api';
import { storeName } from '../config';

export const getUsers = createAsyncThunk<
  {
    list: UserMgmtItem[];
    pagination: Pagination;
    lastChange: LastChange;
  },
  { loadMore?: boolean }
>(`${storeName}/userList`, async ({ loadMore }, thunkAPI) => {
  const {
    userManagement: {
      filters,
      pagination: { page },
    },
  } = thunkAPI.getState() as RootState;
  const pageToLoad = loadMore ? page + 1 : 1;
  const filtersToLoad =
    filters.roleIds === 0 ? { ...filters, roleIds: null } : filters;
  return api.axios
    .get(
      `${API_USER_MANAGEMENT_SEARCH}?${qs.stringify(
        {
          ...filtersToLoad,
          page: pageToLoad,
          limit: 50,
          includeWorkRecords: true,
        },
        { skipNull: true },
      )}`,
    )
    .then((res) => res.data)
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data));
});
