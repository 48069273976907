import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_CAREER_ROADMAP } from 'constants/api';
import { RoadmapDepartment } from 'store/interfaces';
import { storeName } from '../config';

export const deleteDepartment = createAsyncThunk<number, number>(
  `${storeName}/delete`,
  (payload, thunkAPI) =>
    api.axios
      .delete<RoadmapDepartment>(`${API_CAREER_ROADMAP}/${payload}`)
      .then(() => payload)
      .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
