import { createAsyncThunk } from '@reduxjs/toolkit';
import { ReviewCycleItem } from 'store/interfaces';
import api from 'api';
import { CycleFormFields } from 'pages/ReviewCycles';
import { updateOptions } from 'store/options';
import { API_REVIEW_CYCLE } from 'constants/api';
import { storeName } from '../config';

export const cycleUpdate = createAsyncThunk<ReviewCycleItem, CycleFormFields>(
  `${storeName}/update`,
  async ({ id, ...data }, thunkAPI) =>
    api.axios
      .put<ReviewCycleItem>(`${API_REVIEW_CYCLE}/${id}`, data)
      .then((res) => {
        thunkAPI.dispatch(updateOptions('reviewCycles'));
        return res.data;
      })
      .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
