import { createSlice, isAnyOf, Reducer } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash-es';
import {
  getUsers,
  updateUserRoles,
  changeFilter,
  clearFilters,
  getRoles,
} from './actions';
import {
  storeName,
  initialFilters,
  userManagementInitialState,
} from './config';
import addCommonCases from '../addCommonCases';

export * from './interfaces';
export * from './actions';
export * from './selectors';

const userManagement = createSlice({
  name: storeName,
  initialState: cloneDeep(userManagementInitialState),
  reducers: {},
  extraReducers: (builder) => {
    addCommonCases(builder, userManagementInitialState)
      .addCase(getUsers.fulfilled, (state, action) => {
        Object.assign(state, {
          pagination: action.payload.pagination,
          users:
            action.payload.pagination.page === 1
              ? action.payload.list
              : [...state.users, ...action.payload.list],
          lastChange: action.payload.lastChange,
          loading: false,
          init: true,
        });
      })
      .addCase(changeFilter, (state, { payload: { key, value } }) => {
        Object.assign(state, {
          filters: { ...state.filters, [key]: value },
          init: true,
        });
      })
      .addCase(clearFilters, (state) => {
        Object.assign(state.filters, initialFilters);
      })
      .addCase(getRoles.fulfilled, (state, { payload }) => {
        Object.assign(state, {
          roles: payload.list.sort((a, b) => a.name.localeCompare(b.name)),
        });
      })
      .addCase(updateUserRoles.fulfilled, (state, action) => {
        Object.assign(state, {
          users: state.users.map((user) => {
            if (user.id === action.meta.arg.id) {
              return {
                ...user,
                roles: action.payload.roles,
              };
            }
            return user;
          }),
          loading: false,
        });
      });

    builder.addMatcher(isAnyOf(getUsers.pending), (state) => {
      if (state.pagination.page === 0) {
        state.loading = true;
      }
    });

    builder.addMatcher(isAnyOf(updateUserRoles.pending), (state) => {
      state.updating = true;
    });

    builder.addMatcher(
      isAnyOf(getUsers.fulfilled, getUsers.rejected),
      (state) => {
        state.loading = false;
      },
    );

    builder.addMatcher(
      isAnyOf(updateUserRoles.fulfilled, updateUserRoles.rejected),
      (state) => {
        state.updating = false;
      },
    );
  },
});

export default userManagement.reducer as Reducer<
  typeof userManagementInitialState
>;
