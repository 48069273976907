import { Box, Typography } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'classnames';
import { useGoalTitle, calculateTotalWeight } from 'hooks/GoalsAndSubgoalsForm';
import {
  getNavigationGoalContainerIdName,
  scrollToActiveGoalDetails,
} from 'helpers';
import CustomGoalIcon from 'components/CustomGoalIcon';
import OneLineTextWithTooltip from 'components/OneLineTextWithTooltip';
import MessageWithIcon from 'components/MessageWithIcon';
import { GoalField, GoalTitleProps } from './interfaces';
import {
  GoalListItem,
  TitleText,
  TitleWeight,
  CustomGoalWrapper,
  TitleName,
  TitleWrapper,
  GoalListItemContainer,
} from './GoalsAndSubgoalsForm.styled';
import GoalWeightField from './GoalWeightField.component';

export default function GoalTitle({
  index,
  active,
  onChange,
  navigationSubgoalsListId,
  setActiveSubGoal,
  isCommonGoalEdit,
}: GoalTitleProps) {
  const {
    formState: { errors },
  } = useFormContext<GoalField>();
  const { formatMessage } = useIntl();
  const goalErrorKeys = Object.keys(errors.goals?.[index] || {});
  const weightError = goalErrorKeys.includes('subgoalsWeight');
  const anyOtherError =
    goalErrorKeys.filter((k) => k !== 'subgoalsWeight').length > 0;
  const { title, weight } = useGoalTitle(index);
  const [subgoals = [], isCustom] = useWatch({
    name: [`goals.${index}.subgoals`, `goals.${index}.isCustom`],
  });
  const subgoalsWeight = calculateTotalWeight(subgoals);
  const handleClick = () => {
    onChange();
    if (!active) {
      setActiveSubGoal(null);
    }
    scrollToActiveGoalDetails();
  };
  return (
    <GoalListItemContainer
      id={getNavigationGoalContainerIdName(index)}
      onClick={handleClick}
    >
      <GoalListItem
        className={cx({ active, error: anyOtherError })}
        data-testid="goal-list-item"
      >
        <TitleText className="title" data-testid="goal-list-item-title">
          {isCustom && (
            <CustomGoalWrapper>
              <CustomGoalIcon />
            </CustomGoalWrapper>
          )}
          <TitleWrapper>
            <TitleName>
              <OneLineTextWithTooltip variant="heading4" text={title} />
            </TitleName>
            {!isCommonGoalEdit && (
              <TitleWeight>
                <Typography variant="heading4">{`${weight}%`}</Typography>
              </TitleWeight>
            )}
          </TitleWrapper>
        </TitleText>
        {weightError ? (
          <MessageWithIcon
            messageVariant="error"
            variant="body3"
            iconSize={22}
            height={(theme) => theme.spacing(1.5)}
            mb={(theme) => theme.spacing(0.5)}
            message={formatMessage(
              {
                id: 'pages.reviewCycles.subgoalsWeightsError',
                defaultMessage:
                  'Total subgoals weights is {weight, number, percent} of 100%',
              },
              {
                weight: subgoalsWeight / 100,
              },
            )}
          />
        ) : (
          <Typography
            variant="body3"
            className={cx({ subgoalsCounter: true, isCustom })}
          >
            <FormattedMessage
              id="pages.reviewCycles.goalDetails"
              defaultMessage={`({total, plural, 
            one {# Subgoal}
            other {# Subgoals}
          } {weight, number, percent})`}
              values={{
                total: subgoals.length,
                weight: subgoalsWeight / 100,
              }}
            />
          </Typography>
        )}
        <Box id={navigationSubgoalsListId} />
        {!isCommonGoalEdit && <GoalWeightField index={index} />}
      </GoalListItem>
    </GoalListItemContainer>
  );
}
