import cx from 'classnames';
import { useFormContext } from 'react-hook-form';
import { useSubGoalTitle } from 'hooks/GoalsAndSubgoalsForm';
import { Typography } from '@mui/material';
import { GoalField, SubGoalTitleProps } from './interfaces';
import {
  SubgoalListItem,
  TitleName,
  TitleText,
  TitleWeight,
  TitleWrapper,
} from './GoalsAndSubgoalsForm.styled';
import OneLineTextWithTooltip from '../OneLineTextWithTooltip';

export default function SubGoalTitle({
  index,
  subIndex,
  active,
  onClick,
  isCuSat,
}: SubGoalTitleProps) {
  const {
    formState: { errors },
  } = useFormContext<GoalField>();
  const error = Boolean(errors.goals?.[index]?.subgoals?.[subIndex]);
  const { title, weight } = useSubGoalTitle(index, subIndex);
  return (
    <SubgoalListItem
      className={cx({ active, error, subgoal: true })}
      onClick={onClick}
      data-testid="subgoal-list-item"
    >
      <TitleText className="subgoal-title">
        <TitleWrapper>
          <TitleName>
            <OneLineTextWithTooltip variant="body2" text={title} />
          </TitleName>
          {!isCuSat && (
            <TitleWeight>
              <Typography variant="body2">{`${weight}%`}</Typography>
            </TitleWeight>
          )}
        </TitleWrapper>
      </TitleText>
    </SubgoalListItem>
  );
}
