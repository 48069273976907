import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ArrowTopRightIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path
        d="M3.33301 13.3333V5.33333H10.783L8.38301 2.95L9.33301 2L13.333 6L9.31634 10.0167L8.38301 9.06667L10.783 6.66667H4.66634V13.3333H3.33301Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
