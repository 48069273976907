import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { clearLoginRedirect, readLoginRedirect } from 'helpers';

export default function useLoginRedirect() {
  const navigate = useNavigate();

  useEffect(() => {
    const loginRedirect = readLoginRedirect();
    if (loginRedirect) {
      const {
        location: { pathname, search },
      } = window;
      const currentUrl = pathname + search;
      if (loginRedirect !== currentUrl) {
        navigate(loginRedirect, { replace: true });
      }
      clearLoginRedirect();
    }
  }, [navigate]);
}
