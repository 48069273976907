import PageLoader from 'components/PageLoader';
import { Suspense, lazy } from 'react';
import { AppRouteComponentProps } from 'components/AppRoutes';
import { useLocation } from 'react-router-dom';
import { URL_TEAM_OVERVIEW } from 'constants/urls';

const MyTeamOkrs = lazy(() => import('./MyTeamOkrs.component'));
const MyTeamOverview = lazy(() => import('./TeamOverview.component'));

export default function MyTeamOkrsPage(props: AppRouteComponentProps) {
  const { pathname } = useLocation();
  if (pathname.includes(URL_TEAM_OVERVIEW)) {
    return (
      <Suspense fallback={<PageLoader open />}>
        <MyTeamOverview pageProps={props} />
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<PageLoader open />}>
      <MyTeamOkrs pageProps={props} />
    </Suspense>
  );
}
