import { useIntl } from 'react-intl';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import api from 'api';
import { API_SUPPORT_REQUEST } from 'constants/api';
import { useToast } from 'hooks';
import { SupportFeedbackFormValues } from 'components/SupportFeedback/interfaces';
import { DialogOnClose } from 'components/Dialog';

export default function useSupportFeedbackDialog(onClose: DialogOnClose) {
  const { formatMessage } = useIntl();
  const showMessage = useToast();
  const form = useForm<SupportFeedbackFormValues>({
    defaultValues: {
      summary: '',
      description: '',
    },
  });
  const {
    watch,
    handleSubmit,
    formState: { errors },
  } = form;
  const summary = watch('summary');
  const description = watch('description');
  const isSubmitDisabled =
    !summary || !description || errors.description !== undefined;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = useCallback(
    async (formData: SupportFeedbackFormValues) => {
      setIsSubmitting(true);
      return api.axios
        .post(API_SUPPORT_REQUEST, formData)
        .then(() => {
          onClose({ forceClose: true });
          showMessage({
            severity: 'success',
            message: formatMessage({
              id: 'supportFeedback.thankYou',
              defaultMessage:
                'Thank you for reaching out and providing us with valuable feedback',
            }),
          });
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    },
    [setIsSubmitting, showMessage, onClose, formatMessage],
  );

  const onSubmitClick = useMemo(
    () => handleSubmit(onSubmit),
    [handleSubmit, onSubmit],
  );

  return {
    onSubmitClick,
    isSubmitDisabled,
    isSubmitting,
    form,
  };
}
