import { ReviewCyclesFilters, ReviewCyclesState } from './interfaces';

export const initialFilters: ReviewCyclesFilters = {
  reviewCycleIds: null,
  countryIds: null,
  unitIds: null,
};

export const reviewCyclesInitialState: ReviewCyclesState = {
  items: [],
  loading: false,
  filters: { ...initialFilters },
  pagination: {
    limit: 0,
    page: 1,
    total: 0,
  },
  init: false,
};

export const storeName = 'reviewCycles';
