import { useScorecardContext } from 'contexts';
import { useCallback, useMemo, useState } from 'react';
import { useScorecardData, useScorecardUpdate, useToast } from 'hooks';
import api from 'api';
import { AxiosError, AxiosResponse } from 'axios';
import { FeedbackRequestGoalRO, Scorecard } from 'store/interfaces';
import { Props as MessageProps } from 'react-intl/src/components/message';
import { generateURL } from 'helpers';
import {
  API_SCORECARD_FEEDBACK_REQUEST_RESEND_NOTIFICATION,
  API_SCORECARD_FEEDBACK_RESET,
} from 'constants/api';
import { FEEDBACK_REQUEST_DATE_KEY } from 'constants/app';
import { errorTexts } from 'i18n';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectProfileInfo } from 'store/profile';
import {
  FEEDBACK_REQUEST_STATUS_ACCEPTED,
  FEEDBACK_REQUEST_STATUS_DECLINED,
  FEEDBACK_REQUEST_STATUS_PENDING,
} from 'constants/feedbacks';
import useLocalStorage from 'use-local-storage';

const requestResendNotification = (
  id: number,
): Promise<AxiosResponse<FeedbackRequestGoalRO[]>> =>
  api.axios.post(
    generateURL(API_SCORECARD_FEEDBACK_REQUEST_RESEND_NOTIFICATION, {
      id,
    }),
  );

const resetFeedback = (id: number): Promise<AxiosResponse<Scorecard>> =>
  api.axios.put(
    generateURL(API_SCORECARD_FEEDBACK_RESET, {
      id,
    }),
  );

const getErrorMessage = (
  error: AxiosError<{ description: string }>,
): MessageProps => ({
  ...errorTexts.somethingWentWrongContactSupport,
  values: {
    code: error.message,
    description: error.response?.data.description,
  },
});

export default function useFeedbackActions(
  userId: number,
  goalId: number,
  currentDate: string,
  closeDialog: () => void,
) {
  const { id: applicationUserId } = useSelector(selectProfileInfo);
  const showMessage = useToast();
  const { formatMessage } = useIntl();
  const [requesting, setRequesting] = useState(false);
  const { id: scorecardId } = useScorecardContext();
  const { updateData, setData } = useScorecardUpdate();
  const { feedbackRequests, feedbacks } = useScorecardData();
  const feedback = useMemo(
    () => feedbacks.find((f) => f.user.id === userId)!,
    [feedbacks, userId],
  );
  const userFullName = feedback.user.fullName;
  const feedbackGoalHeader = useMemo(
    () =>
      feedback.feedbackGoalHeaders.find(
        ({ targetScorecardGoal }) => targetScorecardGoal.id === goalId,
      )!,
    [feedback, goalId],
  );

  const hasSubgoalWithoutFeedback = useMemo(
    () =>
      feedbackGoalHeader.feedbackSubgoalHeaders.some(
        ({ assessmentScoreOriginal, commentOriginal }) =>
          assessmentScoreOriginal === null && commentOriginal === null,
      ),
    [feedbackGoalHeader],
  );
  const { id: feedbackRequestId, status: feedbackRequestStatus } = useMemo(
    () => feedbackRequests.find(({ user }) => user.id === userId)!,
    [feedbackRequests, userId],
  );
  const showResendFeedbackButton =
    hasSubgoalWithoutFeedback &&
    feedbackRequestStatus === FEEDBACK_REQUEST_STATUS_PENDING;

  const showResetFeedbackButton =
    (!hasSubgoalWithoutFeedback &&
      feedbackRequestStatus === FEEDBACK_REQUEST_STATUS_ACCEPTED) ||
    feedbackRequestStatus === FEEDBACK_REQUEST_STATUS_DECLINED;

  const key = `${FEEDBACK_REQUEST_DATE_KEY}.${applicationUserId}.${scorecardId}.${feedbackRequestId}`;
  const [lastRequestedDate, setLastRequestedDate] = useLocalStorage(key, '', {
    serializer: (o) => o ?? '',
    parser: (str) => str ?? '',
  });

  const alreadyRequested =
    lastRequestedDate && lastRequestedDate === currentDate;

  const resendFeedbackRequest = useCallback(async () => {
    setRequesting(true);

    return requestResendNotification(feedbackRequestId)
      .then(() => {
        showMessage({
          severity: 'success',
          message: formatMessage(
            {
              id: 'pages.scorecards.resendFeedbackSuccess',
              defaultMessage:
                'Feedback request was successfully resent to {userFullName}',
            },
            {
              userFullName,
            },
          ),
        });
        setLastRequestedDate(currentDate);
      })
      .catch((error) => {
        showMessage({
          severity: 'error',
          message: getErrorMessage(error),
        });
        updateData();
      })
      .finally(() => {
        setRequesting(false);
      });
  }, [
    feedbackRequestId,
    showMessage,
    formatMessage,
    userFullName,
    setLastRequestedDate,
    currentDate,
    updateData,
  ]);

  const resetFeedbackRequest = useCallback(async () => {
    setRequesting(true);

    return resetFeedback(feedback.id)
      .then(({ data }) => {
        showMessage({
          severity: 'success',
          message: formatMessage(
            {
              id: 'pages.scorecards.resetFeedbackSuccess',
              defaultMessage:
                'Feedback request was successfully cleared and resent to {userFullName}',
            },
            {
              userFullName,
            },
          ),
        });
        closeDialog();
        setData(data);
        setLastRequestedDate(currentDate);
      })
      .catch((error) => {
        showMessage({
          severity: 'error',
          message: getErrorMessage(error),
        });
        updateData();
      })
      .finally(() => {
        setRequesting(false);
      });
  }, [
    closeDialog,
    currentDate,
    feedback.id,
    formatMessage,
    setData,
    setLastRequestedDate,
    showMessage,
    updateData,
    userFullName,
  ]);

  return {
    showResetFeedbackButton,
    showResendFeedbackButton,
    requesting,
    alreadyRequested,
    resendFeedbackRequest,
    resetFeedbackRequest,
  };
}
