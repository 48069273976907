import { OkrTeamOverviewFilters, OkrTeamOverviewState } from './interfaces';

export const initialFilters: OkrTeamOverviewFilters = {
  reportingPeriodId: null,
  userName: '',
  countryIds: null,
  unitIds: [],
  careerMentorIds: null,
};

export const okrTeamOverviewInitialState: OkrTeamOverviewState = {
  users: [],
  usersPagination: {
    page: 0,
    limit: 50,
    total: 0,
  },
  totalScore: 0,
  usersLoading: false,
  filters: { ...initialFilters },
  init: false,
};

export const storeName = 'okrTeamOverview';
