import { Typography, useTheme } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { lazy } from 'react';
import { LogoIcon } from 'components/Icons';
import { saveLoginRedirect } from 'helpers';
import { isNotProdEnv } from 'helpers/env';
import { API_LOGIN_SAML } from 'constants/api';
import {
  Background,
  Block,
  Container,
  LoginContainer,
  LogInForm,
  Button,
  ButtonContainer,
} from './SignIn.styled';

const UniversalLogInForm = lazy(() => import('./UniversalLogInForm.component'));

export default function SignIn() {
  const theme = useTheme();
  return (
    <Background>
      <Container>
        <LoginContainer>
          <LogoIcon
            logoColor={theme.palette.common.white}
            textColor={theme.palette.common.white}
            width={200}
          />
        </LoginContainer>
        <LogInForm>
          <Typography variant="h5">
            <FormattedMessage
              id="pages.signIn.header"
              defaultMessage="Sign in"
            />
          </Typography>
          {isNotProdEnv && <UniversalLogInForm />}
          <ButtonContainer>
            <Button
              onClick={saveLoginRedirect}
              variant="contained"
              href={API_LOGIN_SAML}
              color="secondary"
            >
              <FormattedMessage
                id="pages.signIn.button"
                defaultMessage="Sign in with Google"
              />
            </Button>
          </ButtonContainer>
        </LogInForm>
        <Block />
      </Container>
    </Background>
  );
}
