import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useTotalWeight } from 'hooks/GoalsAndSubgoalsForm';
import { useFormContext, useWatch } from 'react-hook-form';
import { validateTotalWeight as validate } from 'helpers';
import { ErrorIcon } from 'components/Icons';
import { GoalField } from './interfaces';
import { TotalGoalsWeightCounterMessage } from './GoalsAndSubgoalsForm.styled';

export default function TotalGoalsWeightCounter() {
  const {
    setValue,
    register,
    formState: { errors },
  } = useFormContext<GoalField>();
  const currentValue = useTotalWeight();
  const weightValue = (useWatch({ name: 'totalWeight' }) || '') as string;
  const totalWeight = parseInt(weightValue, 10) || 0;
  const hasError = typeof errors.totalWeight !== 'undefined';
  useEffect(() => {
    if (currentValue !== totalWeight) {
      setValue('totalWeight', currentValue, { shouldValidate: true });
    }
  }, [currentValue, totalWeight, setValue]);
  return (
    <>
      <TotalGoalsWeightCounterMessage
        hasError={hasError}
        data-testid="total-goal-weight-value"
      >
        {hasError && <ErrorIcon />}
        <FormattedMessage
          id="pages.reviewCycles.footerTotalWeight"
          defaultMessage={`Total Goals Weight (%):<weight>{totalWeight, plural,
            =100 {<correct>{totalWeight}</correct>}
            =0 {<zero>{totalWeight}</zero>}
            other {<error>{totalWeight}</error>}
          }<delimiter>/</delimiter>100</weight>`}
          values={{
            totalWeight,
            weight: (chunks) => <span className="weight">{chunks}</span>,
            error: (chunks) => <span className="error">{chunks}</span>,
            delimiter: (chunks) => <span className="delimiter">{chunks}</span>,
            correct: (chunks) => <span className="correct">{chunks}</span>,
            zero: (chunks) => <span className="zero">{chunks}</span>,
          }}
        />
      </TotalGoalsWeightCounterMessage>
      <input type="hidden" {...register('totalWeight', { validate })} />
    </>
  );
}
