import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import {
  OKR_OBJECTIVES_BY_USER_ID,
  OKR_OWN_OBJECTIVES_LIST,
} from 'constants/api';
import { generateURL } from 'helpers';
import { updateUserTotalScore } from 'store/okrMyTeam';
import { OkrObjectiveListResponse } from '../interfaces';
import { storeName } from '../config';

export const getObjectiveList = createAsyncThunk<
  OkrObjectiveListResponse,
  {
    periodId: string;
    userId?: string;
  }
>(`${storeName}/getObjectiveList`, async ({ periodId, userId }, thunkAPI) => {
  const url = generateURL(
    userId ? OKR_OBJECTIVES_BY_USER_ID : OKR_OWN_OBJECTIVES_LIST,
    { periodId, userId: userId || '' },
  );
  return api.axios
    .get<OkrObjectiveListResponse>(url)
    .then(({ data }) => {
      thunkAPI.dispatch(
        updateUserTotalScore({ userId, totalScore: data.totalScore }),
      );
      return data;
    })
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data));
});
