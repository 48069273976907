import { createAsyncThunk } from '@reduxjs/toolkit';
import { getData } from 'hooks/form/useOptions';
import { mainOptions, storeName } from '../config';
import { Option, OptionsState } from '../interfaces';

export const updateOptions = createAsyncThunk<
  { [k in keyof OptionsState]: Option[] },
  keyof OptionsState | undefined
>(`${storeName}/update`, (name, thunkAPI) => {
  const names = typeof name === 'string' ? [name] : Object.keys(mainOptions);
  return getData(names)
    .then(
      (res) =>
        Object.assign({}, ...res) as unknown as {
          [k in keyof OptionsState]: Option[];
        },
    )
    .catch((e) => thunkAPI.rejectWithValue(e));
});
