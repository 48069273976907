import { useCallback, useEffect, useState } from 'react';

export default function useHasScrolledDistanceToShowStickyHeader() {
  const [hasScrolledDistance, setHasScrolledDistance] = useState(false);
  const callback = useCallback(() => {
    setHasScrolledDistance(window.scrollY > 40);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', callback);

    return () => {
      window.removeEventListener('scroll', callback);
    };
  }, [callback]);

  return {
    hasScrolledDistance,
  };
}
