import { KeyboardArrowDown } from '@mui/icons-material';
import { Box, Stack } from '@mui/system';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { URL_USER_MANAGEMENT } from 'constants/urls';
import { logout, selectProfileInfo } from 'store/profile';
import { batch, useSelector } from 'react-redux';
import { resizeImage } from 'helpers';
import { commonTexts, userManagementTexts } from 'i18n';
import { useUserPermissions } from 'hooks';
import { selectHasDirtyForm } from 'store/uiState';
import { useAppDispatch } from 'store';
import { useIntl } from 'react-intl';
import MailingSettingsButton from 'components/MailingSettings';
import SupportFeedbackButton from 'components/SupportFeedback';
import { Typography } from '@mui/material';
import theme from 'styles/theme';
import { Avatar, HeaderMenuItem, StyledSwitchMenu } from './Header.styled';

export default function UserMenu() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { canManageUsers } = useUserPermissions();
  const hasDirtyForm = useSelector(selectHasDirtyForm);
  const { avatar, fullName } = useSelector(selectProfileInfo);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onLogoutClick = useCallback(() => {
    const action = () => {
      batch(() => {
        dispatch(logout());
      });
    };

    if (!hasDirtyForm) {
      action();
      return;
    }

    // eslint-disable-next-line no-alert
    if (window.confirm(formatMessage(commonTexts.unsavedChangesPrompt))) {
      action();
    }
  }, [dispatch, formatMessage, hasDirtyForm]);

  const openUserManagement = () => {
    navigate(URL_USER_MANAGEMENT);
    handleClose();
  };

  return (
    <Box>
      <Stack
        direction="row"
        id="menu-logo"
        role="presentation"
        onClick={handleClick}
        sx={{ cursor: 'pointer' }}
        alignItems="center"
      >
        <Avatar src={resizeImage(avatar)} />
        <Typography
          sx={{ color: theme.palette.common.black, marginLeft: '8px' }}
          variant="body2"
        >
          {fullName}
        </Typography>
        <KeyboardArrowDown
          sx={{ transform: anchorEl ? 'rotate(180deg)' : 'none' }}
          color="action"
        />
      </Stack>
      <StyledSwitchMenu
        onClose={handleClose}
        anchorEl={anchorEl}
        id="menu-type-switch"
        open={open}
        keepMounted
      >
        <SupportFeedbackButton closeMenu={handleClose} />
        {canManageUsers && (
          <HeaderMenuItem onClick={openUserManagement}>
            {formatMessage(userManagementTexts.title)}
          </HeaderMenuItem>
        )}
        <MailingSettingsButton closeMenu={handleClose} />
        <HeaderMenuItem onClick={onLogoutClick}>
          {formatMessage(commonTexts.logOut)}
        </HeaderMenuItem>
      </StyledSwitchMenu>
    </Box>
  );
}
