import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_CAREER_ROADMAP } from 'constants/api';
import { RoadmapDepartment } from 'store/interfaces';
import { storeName } from '../config';

export const createDepartment = createAsyncThunk<RoadmapDepartment, string>(
  `${storeName}/create`,
  (payload, thunkAPI) =>
    api.axios
      .post<RoadmapDepartment>(API_CAREER_ROADMAP, {
        name: payload,
        schemaData: {
          nodes: [],
          edges: [],
          filters: [],
        },
      })
      .then((res) => res.data)
      .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
