import { createAction } from '@reduxjs/toolkit';
import { Thread } from 'store/comments';
import { storeName } from '../config';

export const updateCommentsThread = createAction<{
  thread: Thread;
  objId: number;
  change: number;
  keyId?: number;
}>(`${storeName}/updateCommentsThread`);
