import { useState, useCallback } from 'react';
import api from 'api';
import {
  API_CUSAT_OPTIONS_PROJECTS_SHORT,
  API_CUSAT_SCORECARD_ALLOCATED_PROJECTS,
} from 'constants/api';
import { Project } from 'store/interfaces';
import { useScorecardContext } from 'contexts';
import { useScorecardUpdate } from 'hooks';

export function useRemoveAllocatedProject() {
  const [isRemoving, setRemoving] = useState(false);
  const { id } = useScorecardContext();
  const { updateData } = useScorecardUpdate();
  const removeProjects = useCallback(
    async (projectIds: number[]) => {
      setRemoving(true);
      return api.axios
        .delete(API_CUSAT_SCORECARD_ALLOCATED_PROJECTS, {
          data: {
            targetScorecardId: id,
            assignedProjectIds: projectIds,
          },
        })
        .then(() => {
          updateData();
        })
        .finally(() => {
          setRemoving(false);
        });
    },
    [updateData, id],
  );
  return {
    isRemoving,
    removeProjects,
  };
}

export function useAddAllocatedProjects() {
  const { id } = useScorecardContext();
  const { updateData } = useScorecardUpdate();
  const [isAdding, setAdding] = useState(false);
  const addProjects = useCallback(
    async (projectIds: number[]) => {
      setAdding(true);
      return api.axios
        .post(API_CUSAT_SCORECARD_ALLOCATED_PROJECTS, {
          targetScorecardId: id,
          projectIds,
        })
        .then(() => {
          updateData();
        })
        .finally(() => {
          setAdding(false);
        });
    },
    [id, updateData],
  );
  return {
    addProjects,
    isAdding,
  };
}

export function useAllocatedProjects() {
  const [data, setData] = useState<Project[]>([]);
  const [isLoading, setLoading] = useState(false);

  const loadProjects = useCallback(() => {
    setLoading(true);
    api.axios
      .get(API_CUSAT_OPTIONS_PROJECTS_SHORT)
      .then(({ data: { items = [] } }) => {
        setData(items);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  return {
    data,
    isLoading,
    loadProjects,
  };
}
