import { createContext, useContext, useMemo, useState, ReactNode } from 'react';

interface OkrDashboardContextValues {
  selectedObjectiveId: number | null;
  setSelectedObjectiveId: (id: number | null) => void;
}

const OkrDashboardContext = createContext<OkrDashboardContextValues>(
  {} as unknown as OkrDashboardContextValues,
);

export function useOkrDashboardContext() {
  return useContext<OkrDashboardContextValues>(OkrDashboardContext);
}

export function OkrDashboardProvider({ children }: { children: ReactNode }) {
  const [selectedObjectiveId, setSelectedObjectiveId] = useState<number | null>(
    null,
  );
  const contextValue = useMemo<OkrDashboardContextValues>(
    () => ({
      selectedObjectiveId,
      setSelectedObjectiveId,
    }),
    [selectedObjectiveId, setSelectedObjectiveId],
  );
  return (
    <OkrDashboardContext.Provider value={contextValue}>
      {children}
    </OkrDashboardContext.Provider>
  );
}
