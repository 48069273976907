import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function EditIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
      <path
        d="M5.84114 18.1614H7.13414L15.5729 9.73432L14.9374 9.07768L14.2799 8.4431L5.84114 16.8702V18.1614ZM4 20V16.0822L15.5508 4.54738C15.9162 4.18246 16.351 4 16.8553 4C17.3596 4 17.7941 4.18246 18.1589 4.54738L19.474 5.86066C19.8247 6.21087 20 6.64478 20 7.16239C20 7.6807 19.8247 8.11496 19.474 8.46517L7.9232 20H4ZM18.1589 7.15188L16.8659 5.86066L18.1589 7.15188ZM15.5729 9.73432L14.9374 9.07768L14.2799 8.4431V8.42104L15.5729 9.73432Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
