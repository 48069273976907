import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_COMMON_GOAL_CATEGORY } from 'constants/api';
import { storeName } from '../config';
import { CommonGoalCategory } from '../interfaces/CommonGoalCategory';

export const getCommonGoalsCategories = createAsyncThunk<{
  items: CommonGoalCategory[];
}>(`${storeName}/getCommonGoalsCategories`, async (data, thunkAPI) =>
  api.axios
    .get<{ items: CommonGoalCategory[] }>(API_COMMON_GOAL_CATEGORY)
    .then((res) => {
      const { items } = res.data;

      return {
        items,
      };
    })
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
