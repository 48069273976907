import { Dispatch, useCallback, useEffect, useState } from 'react';
import api from 'api';
import { useIntl } from 'react-intl';
import { API_MAILING_SETTINGS } from 'constants/api';
import { useToast } from 'hooks';
import { DialogOnClose } from 'components/Dialog';
import { MailingSettingsValues } from 'components/MailingSettings/interfaces';
import { useAppDispatch } from 'store';
import { setMailingSettings } from 'store/profile';
import { User } from 'store/interfaces';

export default function useMailingSettingsDialog(
  onClose: DialogOnClose,
  search: ({ searchText }: { searchText: string }) => Promise<User[]>,
  setSelectedUser: Dispatch<User>,
  email?: string | undefined,
  isEmailEnabled?: boolean | undefined,
) {
  const showMessage = useToast();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (email && isEmailEnabled) {
      setIsLoading(true);
      search({ searchText: email })
        .then((res) => {
          const user = res?.[0];

          if (user) {
            setSelectedUser(res?.[0]);
          } else {
            showMessage({
              severity: 'error',
              message: formatMessage({
                id: 'technicalAdmin.errorEmailMessage',
                defaultMessage: "Can't find user with given email",
              }),
            });
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [
    isEmailEnabled,
    search,
    email,
    showMessage,
    setSelectedUser,
    formatMessage,
  ]);

  const onSubmit = useCallback(
    async (data: MailingSettingsValues) => {
      setIsLoading(true);

      return api.axios
        .put(API_MAILING_SETTINGS, data)
        .then((res) => {
          dispatch(setMailingSettings(res.data));
          onClose();
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [dispatch, onClose],
  );

  return {
    onSubmit,
    isLoading,
  };
}
