import { updateFullData, setFullData } from 'store/scorecards';
import { useScorecardContext } from 'contexts';
import { useAppDispatch } from 'store';
import { useMemo } from 'react';
import { Scorecard } from 'store/interfaces';

export default function useScorecardUpdate() {
  const { store, id } = useScorecardContext();
  const dispatch = useAppDispatch();
  return useMemo(
    () => ({
      updateData: () => dispatch(updateFullData({ store, id })),
      setData: (data: Scorecard) => dispatch(setFullData({ store, data })),
    }),
    [dispatch, id, store],
  );
}
