import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_COMMENT } from 'constants/api';
import { generateURL } from 'helpers';
import { updateCommentsThread } from 'store/okrObjectives';
import { storeName } from '../config';
import { Comment, Thread } from '../interfaces';

export const addComment = createAsyncThunk<
  Comment,
  { thread: Thread; content: string; objId: number; keyId?: number }
>(`${storeName}/addComment`, ({ thread, content, objId, keyId }, thunkAPI) =>
  api.axios
    .post<Comment>(generateURL(API_COMMENT, { threadId: thread.id }), {
      content,
    })
    .then((res) => {
      thunkAPI.dispatch(
        updateCommentsThread({
          thread,
          change: 1,
          objId,
          keyId,
        }),
      );
      return res.data;
    })
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
