import { Chip as MUIChip, Box, styled } from '@mui/material';
import Checkbox from 'components/Checkbox';

export const List = styled('ul')(({ theme }) => ({
  '&[class*="SelectMenuSize"]': {
    padding: 0,
  },
  '& li': {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.375,
    borderColor: theme.palette.grey.A400,
  },
  '&.SelectMenuSize-small li': {
    padding: '5px 10px',
  },
  '&.SelectMenuSize-medium li': {
    padding: '7px 10px',
  },
  '&.SelectMenuSize-large li': {
    padding: '9px 10px',
  },
}));

export const DrowpdownCheckbox = styled(Checkbox)({
  padding: 0,
  margin: '-2px 10px -2px 0',
  '.MuiSvgIcon-root': {
    fontSize: '18px',
  },
});

export const TooltipChip = styled(MUIChip)(({ theme }) => ({
  margin: '4px 4px 4px 0',
  height: '26px',
  fontSize: 16,
  '.MuiChip-deleteIcon': {
    fontSize: 18,
    color: theme.palette.common.black,
  },
}));

export const Wrapper = styled(Box)(({ theme }) => ({
  '.MuiAutocomplete-root': {
    minWidth: '200px',
    '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root':
      {
        paddingRight: '40px',
      },
    '.tag-wrapper': {
      width: '100%',
      paddingRight: '64px',
    },
  },
  '.MuiAutocomplete-root.Mui-focused': {
    '.tag-wrapper': {
      display: 'none',
    },
  },
  'label[data-shrink=false]+.MuiInputBase-formControl .MuiInputBase-input::placeholder':
    {
      opacity: '1!important',
      color: theme.palette.common.black,
    },
  '.MuiOutlinedInput-root.MuiInputBase-adornedStart': {
    paddingLeft: 0,
  },
  '.MuiInputBase-input': {
    zIndex: 1,
  },
  '.MuiAutocomplete-endAdornment': {
    zIndex: 2,
  },
  '.MuiAutocomplete-clearIndicator': {
    marginRight: '10px',
  },
}));

export const TagWrapper = styled(Box)(({ theme }) => ({
  ...theme.typography.body1,
  padding: '8px 12px',
  position: 'absolute',
}));
