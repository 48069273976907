import type { RootState } from 'store';
import { RoadmapDepartmentsList } from './interfaces';

export const selectIsDepartmentDrawerOpen = (state: RootState): boolean =>
  state.roadmap.departments.isDrawerOpen;

export const selectDepartmentsList = (
  state: RootState,
): RoadmapDepartmentsList => state.roadmap.departments.data;

export const selectIsDepartmentsLoading = (state: RootState): boolean =>
  state.roadmap.departments.isLoading;

export const selectIsDepartmentsUpdating = (state: RootState): boolean =>
  state.roadmap.departments.isUpdating;

export const selectIsDepartmentsIdle = (state: RootState): boolean =>
  state.roadmap.departments.isIdle;
