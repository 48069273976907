import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { commonTexts } from 'i18n';
import {
  EmptyStateWrapper,
  EmptyStateContainer,
  EmptyStateImage,
  EmptyStateImageMedium,
} from './EmptyState.styled';
import { EmptyStateProps } from './interfaces';
import { InsertDriveFileOutlinedIcon } from '../Icons';

EmptyStateImage.defaultProps = {
  children: <InsertDriveFileOutlinedIcon />,
};

EmptyStateImageMedium.defaultProps = {
  children: <InsertDriveFileOutlinedIcon />,
};

export default function EmptyState({
  title,
  description,
  children,
  withFilters = false,
  WrapperComponent = EmptyStateWrapper,
  ImageComponent = EmptyStateImage,
}: EmptyStateProps) {
  return (
    <WrapperComponent>
      <EmptyStateContainer>
        <ImageComponent />
        <Typography variant="h7">
          {withFilters ? (
            <FormattedMessage {...commonTexts.noResultWithFilters} />
          ) : (
            title
          )}
        </Typography>
        {description && (
          <Typography variant="body1" mt={0.5}>
            {description}
          </Typography>
        )}
        {children}
      </EmptyStateContainer>
    </WrapperComponent>
  );
}
