import { useSelector } from 'react-redux';
import { selectSummary } from 'store/summary';
import { URL_FEEDBACK_REQUESTS } from 'constants/urls';

export default function useNewItemsCountForPage(pageUrl: string) {
  const summary = useSelector(selectSummary);
  const countForPage: { [pageUrl: string]: number } = {
    [URL_FEEDBACK_REQUESTS]: summary.pendingFeedbackRequests,
  };
  return countForPage[pageUrl] ?? 0;
}
