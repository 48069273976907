import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_COMMON_GOAL_CATEGORY_BY_ID } from 'constants/api';
import { generateURL } from 'helpers';
import { storeName } from '../config';
import { CommonGoalCategory } from '../interfaces/CommonGoalCategory';

export const updateCommonGoalsCategory = createAsyncThunk<
  CommonGoalCategory,
  { name: string; id: number }
>(`${storeName}/updateCommonGoalsCategory`, async ({ id, name }, thunkAPI) =>
  api.axios
    .put<CommonGoalCategory>(
      generateURL(API_COMMON_GOAL_CATEGORY_BY_ID, { id }),
      { name },
    )
    .then((res) => {
      const updatedCategory = res.data;

      return updatedCategory;
    })
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
