import { Box, styled } from '@mui/material';
import { alpha } from '@mui/system/colorManipulator';

export const LoaderHolder = styled(Box)(({ theme }) => ({
  position: 'absolute',
  zIndex: 1000,
  inset: 0,
  background: alpha(theme.palette.common.white, 0.6),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
