/* eslint-disable no-underscore-dangle */
import { useScorecardContext } from 'contexts';
import { useScorecardData } from 'hooks';
import { SelectChangeEvent } from '@mui/material';
import { useCallback } from 'react';
import { useAppDispatch } from 'store';
import { patchScorecard } from 'store/scorecards';

export default function useMultiplierChangeHandler(goalId: number) {
  const dispatch = useAppDispatch();
  const { store, id } = useScorecardContext();
  const { goals, version } = useScorecardData();
  return useCallback(
    async ({ target: { value } }: SelectChangeEvent<number>) => {
      const newValue = typeof value === 'string' ? parseInt(value, 10) : value;
      const goalsArr = goals.map(
        ({ extraMultiplier, id: goalsItemId, subgoals }) => ({
          extraMultiplierId:
            goalsItemId === goalId ? newValue : extraMultiplier?.id || null,
          id: goalsItemId,
          subgoals: subgoals.map((subgoal) => ({ id: subgoal.id })),
        }),
      );

      try {
        await dispatch(
          patchScorecard({
            store,
            id,
            data: { goals: goalsArr, expectedVersion: version },
          }),
        ).unwrap();
      } catch (error: any) {
        //
      }
    },
    [dispatch, goalId, goals, id, store, version],
  );
}
