import { Avatar, Box, styled } from '@mui/material';
import palette from 'styles/theme/palette';

export const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

export const TextContainer = styled(Box)(() => ({
  width: '300px',
  textAlign: 'center',
}));

export const EmptyIcon = styled(Avatar)(() => ({
  '& .MuiAvatar-fallback': {
    width: '24px',
    height: '24px',
    color: palette.grey['500'],
  },

  width: '60px',
  height: '60px',
  background: palette.grey['300'],
  marginBottom: '12px',
}));
