import { Stack, FormControlLabel, Typography } from '@mui/material';
import { Switch } from './Toggle.styled';
import { ToggleProps } from './interfaces';

export default function Toggle({ label, ...props }: ToggleProps) {
  return (
    <FormControlLabel
      sx={{ marginRight: 0, marginLeft: 0, display: 'flex' }}
      control={
        <Switch
          {...props}
          focusVisibleClassName="Mui-focusVisible"
          disableRipple
        />
      }
      label={
        <Stack direction="row" alignItems="center">
          <Typography variant="body2">{label}</Typography>
        </Stack>
      }
    />
  );
}
