import { styled } from '@mui/material/styles';
import { Alert, Snackbar } from '@mui/material';

export const StyledAlert = styled(Alert)(({ theme, severity }) => ({
  width: '100%',
  backgroundColor: theme.palette.common.black,
  whiteSpace: 'pre-wrap',
  padding: '13px 16px',
  '.MuiAlert-message': {
    lineHeight: '22px',
    padding: '4px 0',
    color: theme.palette.common.white,
  },
  '.MuiAlert-icon': {
    color: theme.palette[severity || 'info']?.main,
    padding: '4px 0',
    '.MuiSvgIcon-root': {
      fontSize: '22px',
    },
  },
  '.MuiAlert-action': {
    paddingTop: 0,
    color: theme.palette.common.white,

    '.MuiSvgIcon-root': {
      width: 24,
      height: 24,
    },
  },
}));

export const FeedbackButton = styled('button')({
  background: 'none',
  border: 'none',
  padding: 0,
  color: 'inherit',
  font: 'inherit',
  textDecoration: 'underline',
  cursor: 'pointer',
});

export const StyledSnackbar = styled(Snackbar)({
  maxWidth: '60%',
});
