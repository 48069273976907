import { styled } from '@mui/material';

export const NodeContentStyled = styled('button')(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  margin: '1px',
  padding: '1rem',
  borderRadius: '11px',
  cursor: 'pointer',
  textAlign: 'left',
  border: 'none',
  background: 'transparent',

  '*': {
    cursor: 'pointer',
  },
}));

NodeContentStyled.defaultProps = {
  type: 'button',
};
