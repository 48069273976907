import { Suspense, lazy } from 'react';
import PageLoader from 'components/PageLoader';
import { AppRouteComponentProps } from 'components/AppRoutes';

export * from './interfaces';

const ReviewCyclesComponent = lazy(() => import('./ReviewCycles.component'));

export default function ReviewCycles(props: AppRouteComponentProps) {
  return (
    <Suspense fallback={<PageLoader open />}>
      <ReviewCyclesComponent {...props} />
    </Suspense>
  );
}
