import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_COMMON_GOAL_CATEGORY } from 'constants/api';
import { storeName } from '../config';
import { CommonGoalCategory } from '../interfaces/CommonGoalCategory';

export const createCommonGoalsCategory = createAsyncThunk<
  CommonGoalCategory,
  { name: string }
>(`${storeName}/createCommonGoalsCategory`, (data, thunkAPI) =>
  api.axios
    .post<CommonGoalCategory>(API_COMMON_GOAL_CATEGORY, data)
    .then((res) => res.data)
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data))
    .then((res) => res)
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
