export default function scrollInsideContainer(
  containerSelector: string,
  elementSelector: string,
  offsetDelta: number = 0,
  delay = 50,
) {
  const container = document.querySelector(containerSelector);
  const element = document.querySelector(elementSelector) as HTMLElement;
  setTimeout(() => {
    if (!container || !element) {
      return;
    }

    container.scrollTop = element.offsetTop - offsetDelta;
  }, delay);
}
