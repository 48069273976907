import {
  MeasurementTypes,
  ObjectiveFormFields,
  Statuses,
  Visibilities,
} from 'pages/ObjectiveDetails/interfaces';
import { CreateOkrObjectivePayload } from 'store/okrObjectives';

export default function prepareOkrObjectiveData(
  {
    weight,
    name,
    description,
    visibility,
    keyResults,
    version,
    parentBinding,
  }: ObjectiveFormFields,
  visibilityOptions: { id: number; value: Visibilities }[],
  statusOptions: { id: number; value: Statuses }[],
): CreateOkrObjectivePayload {
  return {
    name,
    description,
    weight,
    visibility:
      visibilityOptions.find((option) => option.id === visibility)?.value || '',
    expectedVersion: version,
    parentBinding: parentBinding?.id ? { id: Number(parentBinding.id) } : null,
    keyResults: keyResults.map((keyResult, index) => ({
      id: keyResult.id ? Number(keyResult.id) : undefined,
      weight: Number(keyResult.weight),
      referenceValue: keyResult.referenceValue
        ? String(keyResult.referenceValue)
        : '0',
      targetValue: keyResult.targetValue ? String(keyResult.targetValue) : '0',
      resultValue: keyResult.resultValue ? String(keyResult.resultValue) : '0',
      attainmentScore: keyResult.autoUpdateAttainmentFromChildBinding
        ? undefined
        : Number(keyResult.attainmentScore),
      name: keyResult.name,
      description: keyResult.description,
      status:
        statusOptions.find((option) => option.id === keyResult.status)?.value ||
        '',
      dueDate: keyResult.dueDate,
      measurementType: keyResult.measurementType as MeasurementTypes,
      autoUpdateAttainmentFromChildBinding:
        keyResult.autoUpdateAttainmentFromChildBinding || false,
      orderRank: index,
      childBinding: keyResult.childBinding?.childTargetUserId
        ? {
            childTargetUserId: Number(
              keyResult.childBinding?.childTargetUserId,
            ),
          }
        : null,
    })),
  };
}
