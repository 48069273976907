import { createAction } from '@reduxjs/toolkit';
import {
  ScorecardsActionStoreArgument,
  ScorecardFilters,
  ScorecardFiltersKeys,
} from 'store/interfaces';

type ChangeFilterPayloadData =
  | {
      key: ScorecardFiltersKeys;
      value: number | string | number[] | null;
    }
  | ScorecardFilters;

export const changeFilter = createAction<{
  store: ScorecardsActionStoreArgument;
  data: ChangeFilterPayloadData;
}>('scorecards/changeFilter');
