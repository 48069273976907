import { CheckboxProps, Checkbox as MUICheckbox } from '@mui/material';
import { CheckboxIcon, CheckboxMarkedIcon } from 'components/Icons';

function Checkbox(props: CheckboxProps) {
  const { disabled } = props;
  return (
    <MUICheckbox
      sx={{
        marginRight: '8px',
      }}
      disableRipple
      icon={<CheckboxIcon />}
      checkedIcon={
        <CheckboxMarkedIcon color={disabled ? 'secondary' : 'primary'} />
      }
      {...props}
    />
  );
}

export default Checkbox;
