import { styled } from '@mui/material';
import { PersonIcon } from 'components/Icons';

export const CustomGoalIconPerson = styled(PersonIcon)(({ theme }) => ({
  display: 'flex',
  fontSize: '1rem',
  '&&': {
    margin: `0 ${theme.spacing(0.2)} 0 0`,
  },
}));
