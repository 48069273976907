import {
  Autocomplete as MUIAutocomplete,
  ListItem as MUIListItem,
  styled,
  Typography,
} from '@mui/material';

export const SearchLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey.A500,
}));

SearchLabel.defaultProps = {
  variant: 'body4',
  gutterBottom: true,
};

export const RequireMark = styled('span')(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: '1rem',
  marginLeft: '4px',
}));

RequireMark.defaultProps = {
  children: '*',
};

export const Autocomplete = styled(MUIAutocomplete)(() => ({
  '&.MuiAutocomplete-root .MuiAutocomplete-input.MuiAutocomplete-input': {
    padding: '6px 8px',
    lineHeight: '20px',
  },
})) as typeof MUIAutocomplete;

export const ListItem = styled(MUIListItem)(({ theme }) => ({
  padding: '12px',
  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.grey.A400}`,
  },
}));
