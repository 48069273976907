import merge from 'lodash.merge';
import { ChangeGroup, GoalChange } from 'store/interfaces';

// API return two objects dataChangePayload and previousState.
// This method merges them into one.
export default function mergeScorecardUpdateChanges(
  changeGroups: ChangeGroup[],
): GoalChange[] {
  // there is only one change group in the array for scorecard update
  const changeGroup = changeGroups[0];
  const goals: any = [];
  const payloadGoals = changeGroup?.dataChangePayload?.goals;
  const previousStateGoals = changeGroup?.previousState?.goals;

  if (previousStateGoals) {
    previousStateGoals.forEach((psg: any) => {
      const payloadGoalIndex = payloadGoals?.findIndex(
        (g: any) => g?.id === psg.id,
      );

      if (payloadGoals && payloadGoalIndex !== -1) {
        goals.push(merge({}, psg, payloadGoals[payloadGoalIndex]));
      } else {
        goals.push(psg);
      }
    });
  }

  if (payloadGoals) {
    payloadGoals.forEach((pg: any) => {
      if (goals.findIndex((g: any) => g.id === pg.id) === -1) {
        goals.push(pg);
      }
    });
  }

  return goals.map((goal: any) => {
    const updatedGoal = merge({}, goal);
    const previousStateSubgoals = updatedGoal.previousState?.subgoals;
    if (previousStateSubgoals) {
      previousStateSubgoals.forEach((subgoal: any) => {
        if (!updatedGoal.payload) {
          updatedGoal.payload = {};
        }

        const payloadSubgoalIndex = updatedGoal.payload?.subgoals?.findIndex(
          (sg: any) => sg?.id === subgoal.id,
        );

        if (updatedGoal.payload.subgoals && payloadSubgoalIndex !== -1) {
          updatedGoal.payload.subgoals[payloadSubgoalIndex] = merge(
            updatedGoal.payload.subgoals[payloadSubgoalIndex],
            subgoal,
          );
        } else {
          if (!updatedGoal.payload.subgoals) {
            updatedGoal.payload.subgoals = [];
          }
          updatedGoal.payload.subgoals.push(subgoal);
        }
      });
    }

    return updatedGoal;
  });
}
