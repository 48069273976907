import { Theme } from '@mui/material';

export default {
  styleOverrides: {
    root({ theme }: { theme: Theme }) {
      return {
        '[class*="SelectMenuSize"] &': {
          fontSize: theme.typography.pxToRem(13),
          lineHeight: 1.3846,
          padding: '8px 12px',
        },
      };
    },
  },
};
