import { TooltipProps } from './interfaces';
import { InfoIcon } from '../Icons';
import Tooltip from './Tooltip.component';

export default function InfoTooltip(props: Omit<TooltipProps, 'children'>) {
  return (
    <Tooltip {...props}>
      <InfoIcon color="info" sx={{ fontSize: 17 }} />
    </Tooltip>
  );
}
