import { styled, Box, IconButton } from '@mui/material';
import Tooltip from 'components/Tooltip';
import { CopyToClipboardIcon } from '../Icons';

export const Email = styled('span')(() => ({
  textDecoration: 'underline',
}));

export const MessageBanner = styled(Box)(({ theme }) => ({
  height: '36px',
  backgroundColor: theme.palette.grey[200],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& .MuiTypography-root ': {
    alignItems: 'center',
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.grey['500'],
  },
}));

export const InlineTooltip = styled(Tooltip)(() => ({
  display: 'inline-block',
  cursor: 'pointer',
}));

export const CopyIcon = styled(CopyToClipboardIcon)(({ theme }) => ({
  marginLeft: '2px',
  marginRight: '4px',
  fontSize: '1rem',
  color: theme.palette.blue[500],
}));

export const SupportFeedbackIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.black,
  '& .MuiSvgIcon-root': {
    fontSize: theme.typography.pxToRem(24),
  },
}));
