import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isToday from 'dayjs/plugin/isToday';

dayjs.extend(utc);
dayjs.extend(isToday);

export default dayjs;

const DMY_FORMAT = 'DD-MM-YYYY';

export function toDMYString(date: Dayjs) {
  return date.format(DMY_FORMAT);
}
