import { AllocatedProject, Scorecard } from 'store/interfaces';
import { SCORECARD_GOAL_TYPE_CUSAT } from 'constants/scorecards';

export function collectAllocatedProjects(
  feedbacks: Scorecard['feedbacks'],
): AllocatedProject[] {
  if (!feedbacks) return [];

  const cusatFeedbacks = feedbacks.filter(
    (feedback) => feedback.type === SCORECARD_GOAL_TYPE_CUSAT,
  );

  return cusatFeedbacks.map((feedback) => ({
    id: feedback.id,
    assessedByRaw: feedback.assessedByRaw,
    commentRaw: feedback.feedbackGoalHeaders[0]?.commentRaw,
    allocatedProjectLastUpdatedAt: feedback.allocatedProjectLastUpdatedAt,
    subgoalFeedbacks:
      feedback.feedbackGoalHeaders[0]?.feedbackSubgoalHeaders || [],
  }));
}
