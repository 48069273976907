import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { DepartmentState } from './department';
import { SubordinateState } from './subordinates';
import { careerMentorsGet } from './actions/careerMentorsGet';
import { SubordinateStoreName } from './subordinates/config';
import { DepartmentStoreName } from './department/config';

type StoreStates = DepartmentState | SubordinateState;
type Stores = SubordinateStoreName | DepartmentStoreName;

export default function addCareerMentorOptionsCases<T extends StoreStates>(
  builder: ActionReducerMapBuilder<T>,
  storeName: Stores,
) {
  return builder.addCase(
    careerMentorsGet.fulfilled,
    (
      state,
      {
        meta: {
          arg: { store },
        },
        payload: { list },
      },
    ) => {
      if (store.indexOf(storeName) > -1) {
        state.careerMentorOptions = list.map(({ id, fullName, email }) => ({
          id: id!,
          email,
          name: fullName,
        }));
      }
    },
  );
}
