import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { OKR_PENDING_KEY_RESULT_DECLINE } from 'constants/api';
import { generateURL } from 'helpers';
import { storeName } from '../config';

export const declineOkrPendingKeyResult = createAsyncThunk(
  `${storeName}/declineOkrPendingKeyResult`,
  ({ periodId, id }: { periodId: string; id: number }) =>
    api.axios
      .put(generateURL(OKR_PENDING_KEY_RESULT_DECLINE, { periodId, id }))
      .then((res) => res.data)
      .catch(() => []),
);
