import { FormattedMessage, useIntl } from 'react-intl';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'components/Dialog';
import { FormProvider } from 'react-hook-form';
import { Button, Grid } from '@mui/material';
import { commonTexts, formTexts } from 'i18n';
import ReactHookFormField from 'components/ReactHookFormField';
import PageLoader from 'components/PageLoader';
import commonMessages from 'i18n/common';
import { SUPPORT_EMAIL } from 'constants/app';
import { usePreventDialogClosing, useSupportFeedbackDialog } from 'hooks';
import { copyToClipboard, isFormDirty } from 'helpers';
import MessageWithIcon from 'components/MessageWithIcon';
import { useEffect } from 'react';
import { SupportFeedbackDialogProps } from './interfaces';
import {
  CopyIcon,
  Email,
  MessageBanner,
  InlineTooltip,
} from './SupportFeedback.styled';

export default function SupportFeedbackDialogComponent({
  onClose,
}: SupportFeedbackDialogProps) {
  const { formatMessage } = useIntl();
  const { onCloseHandler, setPrevent, forceClose } =
    usePreventDialogClosing(onClose);
  const { form, isSubmitDisabled, onSubmitClick, isSubmitting } =
    useSupportFeedbackDialog(onCloseHandler);
  const isDirty = isFormDirty(form.formState);
  useEffect(() => {
    setPrevent(isDirty);
  }, [isDirty, setPrevent]);
  return (
    <Dialog open onClose={onCloseHandler} fullWidth>
      <DialogTitle onClose={onCloseHandler}>
        <FormattedMessage
          id="supportFeedback.title"
          defaultMessage="Help Us improve MARS"
        />
      </DialogTitle>
      <MessageBanner>
        <MessageWithIcon
          message={formatMessage({
            id: 'supportFeedback.hearFeedback',
            defaultMessage: `We'd love to hear your feedback on your experience with MARS`,
          })}
        />
      </MessageBanner>
      <DialogContent>
        <FormProvider {...form}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <ReactHookFormField
                name="summary"
                maxLength={256}
                type="text"
                rules={{
                  required: formatMessage(formTexts.errorRequired),
                }}
                label={formatMessage(formTexts.summary)}
                placeholder={formatMessage({
                  id: 'supportFeedback.addSummary',
                  defaultMessage: 'Add summary',
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <ReactHookFormField
                fieldProps={{
                  multiline: true,
                  rows: 2.5,
                }}
                name="description"
                maxLength={3000}
                type="textarea"
                rules={{
                  validate: (value) => {
                    const minLength = 10;
                    return (
                      (value || '').trim().length >= minLength ||
                      formatMessage(formTexts.errorMinLength, {
                        fieldName: formatMessage(formTexts.description),
                        minLength,
                      })
                    );
                  },
                  required: formatMessage(formTexts.errorRequired),
                }}
                label={formatMessage(formTexts.description)}
                placeholder={formatMessage({
                  id: 'supportFeedback.addDescription',
                  defaultMessage: 'Add description',
                })}
              />
            </Grid>
            <Grid item xs={12} mb={0.5}>
              <FormattedMessage
                id="supportFeedback.directFeedback"
                defaultMessage="If you would like to send us feedback directly or include attachments, please send it to {tooltip} Emails are accepted only from Star accounts."
                values={{
                  tooltip: (
                    <InlineTooltip
                      componentsProps={{
                        tooltip: { style: { marginBottom: '8px' } },
                      }}
                      title={formatMessage(commonMessages.copyToClipboard)}
                      onClick={() => copyToClipboard(SUPPORT_EMAIL)}
                    >
                      <Email>{SUPPORT_EMAIL}</Email>
                      <CopyIcon />
                    </InlineTooltip>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={forceClose} variant="outlined">
          <FormattedMessage {...commonTexts.cancel} />
        </Button>
        <Button
          disabled={isSubmitDisabled}
          onClick={onSubmitClick}
          variant="contained"
        >
          <FormattedMessage {...commonTexts.submit} />
        </Button>
      </DialogActions>
      <PageLoader open={isSubmitting} />
    </Dialog>
  );
}
