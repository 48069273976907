export default function SkillNodeIcon() {
  return (
    <svg
      width="96"
      height="76"
      viewBox="0 0 96 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="6.5" width="95" height="63" rx="11.5" fill="white" />
      <rect x="16" y="22" width="64" height="12" rx="4" fill="#E6E9EF" />
      <rect x="16" y="42" width="24" height="12" rx="4" fill="#768691" />
      <rect x="44" y="42" width="36" height="12" rx="4" fill="#768691" />
      <circle
        cx="48"
        cy="70"
        r="5"
        fill="#2085FF"
        stroke="white"
        strokeWidth="2"
      />
      <circle
        cx="48"
        cy="6"
        r="5"
        fill="#2085FF"
        stroke="white"
        strokeWidth="2"
      />
      <rect x="0.5" y="6.5" width="95" height="63" rx="11.5" stroke="#2085FF" />
    </svg>
  );
}
