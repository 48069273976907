import React, {
  Fragment,
  HTMLAttributes,
  SyntheticEvent,
  useCallback,
  useMemo,
} from 'react';
import {
  AutocompleteRenderGroupParams,
  AutocompleteRenderOptionState,
  ListItemText,
  MenuItem,
} from '@mui/material';
import { UIOption } from 'store/options';
import {
  Checkbox,
  Chip,
  ListSubheader,
} from 'components/Autocomplete/Autocomplete.styled';
import { CloseIcon } from 'components/Icons';
import { AutocompleteProps } from 'components/Autocomplete/interfaces';

export default function useAutocomplete<
  T extends UIOption,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  options,
  groupBy,
  multiple,
  clearValue,
  renderOption: inputRenderOption,
}: Pick<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  'options' | 'multiple' | 'clearValue' | 'renderOption' | 'groupBy'
>) {
  const sortedOptions = useMemo(() => {
    if (!groupBy) {
      return options;
    }

    return [...options].sort((a: T, b: T) =>
      groupBy(a).toString().localeCompare(groupBy(b).toString()),
    );
  }, [groupBy, options]);

  const defaultIsOptionEqualToValue = useCallback(
    (option: T, optionValue: T) => option.id === optionValue.id,
    [],
  );

  const renderTags = useMemo(() => {
    if (!multiple) {
      return undefined;
    }

    return function renderTagFunction(selected: T[]) {
      return (
        <Chip
          label={selected.length}
          onMouseDown={(e: SyntheticEvent) => e.stopPropagation()}
          onDelete={clearValue}
          deleteIcon={<CloseIcon />}
        />
      );
    };
  }, [clearValue, multiple]);

  const renderGroup = useMemo(() => {
    if (!groupBy) {
      return undefined;
    }

    return function renderGroupFunction(params: AutocompleteRenderGroupParams) {
      const { key, group, children } = params;

      return (
        <Fragment key={key}>
          <ListSubheader>{group}</ListSubheader>
          {children}
        </Fragment>
      );
    };
  }, [groupBy]);

  const renderOption = useMemo(() => {
    if (inputRenderOption) return inputRenderOption;

    if (groupBy) {
      return function renderGroupOptionFunction(
        optionProps: HTMLAttributes<HTMLLIElement>,
        option: T,
        { selected }: AutocompleteRenderOptionState,
      ) {
        return (
          <MenuItem {...optionProps} key={option.id || option.name}>
            {multiple && <Checkbox checked={selected} />}
            <ListItemText primary={option.name} />
          </MenuItem>
        );
      };
    }

    return function renderOptionFunction(
      optionProps: HTMLAttributes<HTMLLIElement>,
      option: T,
    ) {
      return (
        <MenuItem {...optionProps} key={option.id || option.name}>
          {option.name}
        </MenuItem>
      );
    };
  }, [groupBy, inputRenderOption, multiple]);

  return {
    sortedOptions,
    defaultIsOptionEqualToValue,
    renderTags,
    renderGroup,
    renderOption,
  };
}
