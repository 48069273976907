import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectOptions } from 'store/options';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo } from 'react';
import {
  cycleGetList,
  CycleTemplate,
  templateCreate,
  templateUpdate,
} from 'store/reviewCycles';
import commonMessages from 'i18n/common';
import { TemplateFormFields } from 'pages/ReviewCycles';
import {
  CatchCallback,
  SuccessCallback,
  useActionPipeline,
  usePrompt,
  useResetAfterSubmit,
  useToast,
} from 'hooks';
import {
  formatTemplateData,
  handleResponseErrors,
  isFormDirty,
  parseTemplateData,
} from 'helpers';
import { generatePath, useNavigate } from 'react-router-dom';
import { commonTexts } from 'i18n';
import { URL_EDIT_SCORECARD_TEMPLATE, URL_REVIEW_CYCLES } from 'constants/urls';

export default function useScorecardTemplateFormValues(
  defaultValues: TemplateFormFields,
  isEdit: boolean,
) {
  const showMessage = useToast();
  const { formatMessage } = useIntl();
  const { units, countries, locations } = useSelector(selectOptions);
  const form = useForm<TemplateFormFields>({
    defaultValues: {
      ...defaultValues,
      entity: 'template',
    },
    mode: 'onChange',
    criteriaMode: 'all',
  });
  const {
    handleSubmit,
    watch,
    getFieldState,
    trigger,
    formState: { errors },
    setError,
    formState,
    setValue,
  } = form;
  const isDirty = isFormDirty(formState);

  useResetAfterSubmit(form);
  usePrompt(formatMessage(commonTexts.unsavedChangesPrompt), isDirty);

  const goals = watch('goals');
  const areGoalsChanged = getFieldState('goals').isDirty;
  const navigate = useNavigate();
  useEffect(() => {
    if (areGoalsChanged) {
      trigger(['name', 'unitId', 'countryIds', 'totalWeight']);
    }
  }, [areGoalsChanged, trigger]);
  const canSubmit = isDirty && goals?.length && !Object.keys(errors).length;
  const onSuccess = useCallback<SuccessCallback<CycleTemplate>>(
    ({ payload }, dispatch) => {
      const { name, id } = payload;
      dispatch(cycleGetList());
      const message = isEdit
        ? formatMessage(commonMessages.changesSavedSuccessfully)
        : formatMessage(commonMessages.entityCreated, {
            entityName: name,
          });
      showMessage({ severity: 'success', message });

      if (isEdit) {
        parseTemplateData(payload).goals.forEach((goal, index) => {
          setValue(`goals.${index}`, goal);
        });
        setValue('name', name); // set trimmed value if applicable
      } else {
        navigate(
          generatePath(`/${URL_REVIEW_CYCLES}/${URL_EDIT_SCORECARD_TEMPLATE}`, {
            cycleId: `${defaultValues.reviewCycleId}`,
            templateId: `${id}`,
          }),
          { replace: true },
        );
      }
    },
    [
      isEdit,
      formatMessage,
      showMessage,
      setValue,
      navigate,
      defaultValues.reviewCycleId,
    ],
  );
  const onCatch = useMemo<CatchCallback>(
    () => handleResponseErrors<TemplateFormFields>(setError),
    [setError],
  );
  const actionPipeline = useActionPipeline(onSuccess, onCatch);
  const onSubmit = useCallback(
    (data: TemplateFormFields) => {
      const mainAction = isEdit ? templateUpdate : templateCreate;
      actionPipeline(
        mainAction(
          formatTemplateData({
            ...data,
          }),
        ),
      );
    },
    [actionPipeline, isEdit],
  );
  const onSubmitClick = useMemo(
    () => handleSubmit(onSubmit),
    [handleSubmit, onSubmit],
  );
  return {
    form,
    units,
    countries,
    locations,
    onSubmitClick,
    formatMessage,
    canSubmit,
  };
}
