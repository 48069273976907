import { RootState } from 'store';

export const selectUsers = (state: RootState) => state.okrMyTeam.users;
export const selectUsersPagination = (state: RootState) =>
  state.okrMyTeam.usersPagination;
export const selectUsersLoading = (state: RootState) =>
  state.okrMyTeam.usersLoading;

export const selectOkrMyTeamFilters = (state: RootState) =>
  state.okrMyTeam.filters;
