export default function getTruncatedText(
  maxLength: number,
  string?: string,
): string {
  if (!string) {
    return '';
  }

  if (string.length <= maxLength) {
    return string;
  }

  return `${string.slice(0, maxLength)}...`;
}
