import { ScorecardStatus } from 'store/interfaces';
import {
  SCORECARD_STATUS_ACTIVE,
  SCORECARD_STATUS_COMPLETED,
  SCORECARD_STATUS_FEEDBACK_COLLECTED,
  SCORECARD_STATUS_FEEDBACK_REQUESTED,
  SCORECARD_STATUS_GOALS_APPROVED,
  SCORECARD_STATUS_GOALS_PREPARED,
  SCORECARD_STATUS_READY_FOR_REVIEW,
  SCORECARD_STATUS_DELETED,
} from 'constants/scorecards';

export default function getScorecardStates(status: ScorecardStatus) {
  return {
    isGoalsPrepared: status === SCORECARD_STATUS_GOALS_PREPARED,
    isFeedbackCollected: status === SCORECARD_STATUS_FEEDBACK_COLLECTED,
    isFeedbackRequested: status === SCORECARD_STATUS_FEEDBACK_REQUESTED,
    isActive: status === SCORECARD_STATUS_ACTIVE,
    isCompleted: status === SCORECARD_STATUS_COMPLETED,
    isReadyForReview: status === SCORECARD_STATUS_READY_FOR_REVIEW,
    isGoalsApproved: status === SCORECARD_STATUS_GOALS_APPROVED,
    isDeleted: status === SCORECARD_STATUS_DELETED,
  };
}
