import { Theme } from '@mui/material';
import shadows from '../shadows';

export default {
  styleOverrides: {
    root({ theme }: { theme: Theme }) {
      return {
        backgroundColor: theme.palette.background.default,
        boxShadow: shadows[0],
      };
    },
  },
};
