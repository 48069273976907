import {
  MiddlewareAPI,
  Dispatch,
  AnyAction,
  PayloadAction,
  isRejectedWithValue,
  isRejected,
} from '@reduxjs/toolkit';
import { showToast } from 'store/toast';
import { errorTexts } from 'i18n';
import isApiError from 'helpers/api/isApiError';
import { formatErrorDescription } from 'helpers/errors';

const ignoredErrorCodes = [412];

function formatError(error: unknown) {
  if (isApiError(error)) {
    return {
      code:
        error.response?.status ||
        error.response?.statusCode ||
        error.message ||
        '-',
      description: error.response?.message || error.description || '-',
    };
  }

  const formattedError = {
    code: '-',
    description: '-',
  };

  formattedError.description = formatErrorDescription(error);

  return formattedError;
}

export default ({ dispatch }: MiddlewareAPI) =>
  (next: Dispatch<AnyAction>) =>
  (action: PayloadAction<unknown>) => {
    if (isRejectedWithValue(action)) {
      const error = formatError(action.payload);

      // "errors" in the payload mean that the payload includes the inline errors from the API.
      // Each of the key in the "errors" object represents a field in the form field.
      // UI should handle the error on its own by highlighting the corresponding input elements
      const ignoreError =
        (action.payload &&
          (action.type === `profile/devLogin/rejected` ||
            (typeof action.payload === 'object' &&
              ('errors' in action.payload ||
                'ignoreErrorHandlingMiddleware' in action.payload)))) ||
        ignoredErrorCodes.includes(Number(error.code));
      if (!ignoreError) {
        dispatch(
          showToast({
            severity: 'error',
            autoHideDuration: null,
            message: {
              ...errorTexts.somethingWentWrongContactSupport,
              values: {
                code: error.code,
                description: error.description,
              },
            },
          }),
        );
      }
    } else if (isRejected(action) && !navigator.onLine) {
      dispatch(
        showToast({
          severity: 'error',
          autoHideDuration: null,
          message: errorTexts.noInternet,
        }),
      );
    }

    return next(action);
  };
