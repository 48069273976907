import { useCallback, useState } from 'react';
import api from 'api';
import { Pagination, User } from 'store/interfaces';
import { API_SCORECARD_RECCOMENDED_REVIEWERS } from 'constants/api';
import { generateURL } from 'helpers';
import { useScorecardContext } from 'contexts';
import qs from 'query-string';
import useInfiniteScroll from 'hooks/useInfiniteScroll';

// to prevent pagination. 100 is the max limit
const UserLimit = 100;

export default function useSuggestedUsers() {
  const [isFetching, setIsFetching] = useState(false);
  const { id } = useScorecardContext();
  const {
    hasMore,
    nextPage,
    limit,
    setValues,
    isInitialLoad,
    isLastPage,
    list: users,
  } = useInfiniteScroll<User>({ limit: UserLimit });

  const loadMore = useCallback(() => {
    if (isInitialLoad) {
      setIsFetching(true);
    }

    api.axios
      .get<{ list: User[]; pagination: Pagination }>(
        `${generateURL(API_SCORECARD_RECCOMENDED_REVIEWERS, {
          id,
        })}?${qs.stringify(
          {
            includeWorkRecords: 1,
            page: nextPage,
            limit,
          },
          { skipNull: true },
        )}`,
      )
      .then(({ data: { list, pagination: newPagination } }) => {
        setValues([...users, ...list], newPagination);
      })
      .finally(() => setIsFetching(false));
  }, [id, isInitialLoad, limit, nextPage, setValues, users]);
  return {
    isFetching,
    users,
    loadMore,
    hasMore,
    isLastPage,
  };
}
