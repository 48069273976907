import type { Node } from 'reactflow';
import type { RootState } from 'store';
import { CanvasFilter, NodeData, UIOption } from 'store/interfaces';

export const selectEditDrawerState = (
  state: RootState,
): Node<NodeData> | null => state.roadmap.common.editDrawer.node;

export const selectIsEditDrawerOpen = (state: RootState): boolean =>
  state.roadmap.common.editDrawer.isOpen;

export const selectDetailsDrawerState = (
  state: RootState,
): Node<NodeData> | null => state.roadmap.common.detailsDrawer.node;

export const selectIsDetailsDrawerOpen = (state: RootState): boolean =>
  state.roadmap.common.detailsDrawer.isOpen;

export const selectIsNewNodeDrawerOpen = (state: RootState): boolean =>
  state.roadmap.common.isNewNodeDrawerOpen;

export const selectIsEditMode = (state: RootState): boolean =>
  state.roadmap.common.isEditMode;

export const selectCurrentFilter = (state: RootState): CanvasFilter | null =>
  state.roadmap.common.currentFilter;

export const selectCurrentSeniority = (state: RootState): UIOption | null =>
  state.roadmap.common.currentSeniority;

export const selectIsFiltersDrawerOpen = (state: RootState): boolean =>
  state.roadmap.common.isFiltersDrawerOpen;
