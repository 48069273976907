import { createSlice, Reducer } from '@reduxjs/toolkit';
import addScorecardsCases from 'store/scorecards/addScorecardsCases';
import { cloneDeep } from 'lodash-es';
import { resetState } from 'store/actions';
import { storeName, initialFilters, ownScorecardInitialState } from './config';

export * from './interfaces';

const scorecards = createSlice({
  name: storeName,
  initialState: cloneDeep(ownScorecardInitialState),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(resetState, (state) => {
      const newState = cloneDeep(ownScorecardInitialState);
      newState.filters = state.filters;
      Object.assign(state, newState);
    });
    addScorecardsCases(builder, storeName, initialFilters);
  },
});

export default scorecards.reducer as Reducer<typeof ownScorecardInitialState>;
