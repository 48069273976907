import { createSlice, Reducer } from '@reduxjs/toolkit';
import { storeName, optionsInitialState } from './config';
import {
  updateOptions,
  updateAssessmentScales,
  updateCountries,
  updateUserScopedUnits,
  updateUserScopedLocations,
  updateUserScopedReviewCycles,
  updateParamTypes,
} from './actions';
import addCommonCases from '../addCommonCases';

export * from './interfaces';
export * from './actions';
export * from './selectors';

const options = createSlice({
  name: storeName,
  initialState: optionsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    addCommonCases(builder, optionsInitialState)
      .addCase(updateOptions.fulfilled, (state, action) => {
        Object.assign(state, action.payload);
      })
      .addCase(updateAssessmentScales.fulfilled, (state, action) => {
        Object.assign(state, { assessmentScales: action.payload });
      })
      .addCase(updateCountries.fulfilled, (state, action) => {
        Object.assign(state, { countries: action.payload });
      })
      .addCase(updateUserScopedUnits.fulfilled, (state, action) => {
        Object.assign(state, { userScopedUnits: action.payload });
      })
      .addCase(updateUserScopedLocations.fulfilled, (state, action) => {
        Object.assign(state, { userScopedLocations: action.payload });
      })
      .addCase(updateUserScopedReviewCycles.fulfilled, (state, action) => {
        Object.assign(state, { userScopedReviewCycles: action.payload });
      })
      .addCase(updateParamTypes.fulfilled, (state, action) => {
        Object.assign(state, { paramTypes: action.payload, isLoading: false });
      })
      .addCase(updateParamTypes.pending, (state) => {
        Object.assign(state, { isLoading: true });
      });
  },
});

export default options.reducer as Reducer<typeof optionsInitialState>;
