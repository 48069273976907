import { createSlice, Reducer } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash-es';
import { updateOkrPendingKeyResults } from './actions';
import { storeName, okrPendingKeyResultsInitialState } from './config';
import addCommonCases from '../addCommonCases';

export * from './interfaces';
export * from './actions';
export * from './selectors';

const reviewCycles = createSlice({
  name: storeName,
  initialState: cloneDeep(okrPendingKeyResultsInitialState),
  reducers: {},
  extraReducers: (builder) => {
    addCommonCases(builder, okrPendingKeyResultsInitialState)
      .addCase(updateOkrPendingKeyResults.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateOkrPendingKeyResults.fulfilled, (state, { payload }) => {
        state.items = payload;
        state.loading = false;
      });
  },
});

export default reviewCycles.reducer as Reducer<
  typeof okrPendingKeyResultsInitialState
>;
