import {
  Chip as MUIChip,
  Checkbox as MUICheckbox,
  ListSubheader as MUIListSubheader,
  styled,
} from '@mui/material';
import Tooltip from 'components/Tooltip';
import { InfoIcon } from 'components/Icons';

export const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  '&&': {
    width: '100%',
    color: theme.palette.common.black,
    fontSize: 'inherit',
  },
}));

StyledTooltip.defaultProps = {
  placement: 'right',
};

export const TooltipWithIcon = styled(Tooltip)(() => ({
  '&&': {
    width: '100%',
    fontSize: 'inherit',
    alignItems: 'center',
    display: 'flex',
    marginLeft: 8,
  },
}));

TooltipWithIcon.defaultProps = {
  placement: 'right',
};

export const Box = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 0.5,
});

export const ListSubheader = styled(MUIListSubheader)({
  '&.empty': {
    fontStyle: 'italic',
    fontSize: '1rem',
  },
});

export const Chip = styled(MUIChip)(({ theme }) => ({
  margin: '-3px 5px -3px -3px',
  height: '26px',
  fontSize: 16,
  '.MuiChip-deleteIcon': {
    fontSize: 18,
    color: theme.palette.common.black,
  },
}));

export const Checkbox = styled(MUICheckbox)({
  padding: 0,
  margin: '-2px 5px -2px 0',
  '.MuiSvgIcon-root': {
    fontSize: '24px',
  },
});

export const ValueHolder = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyItems: 'flex-start',
});

export const SelectPlaceholder = styled('div')(({ theme }) => ({
  color: theme.palette.grey[600],
}));

export const TooltipInfoIcon = styled(InfoIcon)(({ theme }) => ({
  width: 18,
  height: 18,
  color: theme.palette.grey.A500,
}));
