import {
  styled,
  Typography,
  Avatar as MUIAvatar,
  IconButton,
  AppBar as MUIAppBar,
  Chip,
  Toolbar as MUIToolbar,
  Menu,
  MenuItem,
} from '@mui/material';
import { NavLink } from 'react-router-dom';

export const HEADER_HEIGHT = 64;

export const Toolbar = styled(MUIToolbar)(({ theme }) => ({
  width: '100%',
  maxWidth: theme.breakpoints.values.lg,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),

  [theme.breakpoints.up('xs')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const AppBar = styled(MUIAppBar)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const LinkStyled = styled(NavLink)(({ theme }) => ({
  ...theme.typography.h8,
  position: 'relative' as const,
  color: theme.palette.common.black,
  textDecoration: 'none',
  fontStyle: 'normal',
  transition: 'color 0.3s',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  minHeight: '64px',
  maxHeight: '64px',
  boxSizing: 'border-box',
  paddingTop: '2px',
  borderBottom: `2px solid transparent`,
  '&:hover': {
    color: theme.palette.primary.light,
    '.MuiChip-root': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  '&:active': {
    color: theme.palette.primary.dark,
  },
  '&.active': {
    color: theme.palette.primary.main,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    marginBottom: '-2px',
    '.MuiChip-root': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export const Badge = styled(Chip)(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  height: '18px',
  minWidth: '18px',
  padding: '0 6px',
  color: theme.palette.common.white,
  backgroundColor: theme.palette.common.black,
  '.MuiChip-label': {
    fontSize: theme.typography.pxToRem(12),
    padding: 0,
    overflow: 'unset',
  },
}));

export const Links = styled('div')({
  display: 'flex',
  columnGap: '32px',
});

export const UserInfo = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.common.black,
}));

export const UserName = styled(Typography)(({ theme }) => ({
  margin: 'auto 6px auto 8px',
  '&:after': {
    content: '"|"',
    paddingLeft: '13px',
    color: theme.palette.grey.A500,
  },
}));

UserName.defaultProps = {
  variant: 'body5',
};

export const Avatar = styled(MUIAvatar)({
  width: '32px',
  height: '32px',
});

export const LogoutButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.black,
  marginRight: '-8px',
}));

export const StyledSwitchMenu = styled(Menu)(() => ({
  '.MuiPaper-root': {
    marginTop: '16px',
    minWidth: '200px',
  },
}));

export const HeaderMenuItem = styled(MenuItem)(({ theme }) => ({
  border: 'none',
  margin: '8px',
  padding: '5px 12px',
  borderRadius: '6px',

  '&:hover': {
    backgroundColor: theme.palette.grey['300'],
  },
}));
