import { useCallback, useState } from 'react';
import { commonTexts } from 'i18n';
import { useIntl } from 'react-intl';
import { DialogOnClose } from 'components/Dialog';
import usePrompt from './usePrompt';

export default function usePreventDialogClosing(onClose: DialogOnClose) {
  const [prevent, setPrevent] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const message = formatMessage(commonTexts.unsavedChangesPrompt);
  const onCloseHandler = useCallback<DialogOnClose>(
    (event, reason) => {
      if (reason !== 'backdropClick') {
        if (event?.forceClose !== true && prevent) {
          // eslint-disable-next-line no-alert
          const canClose = window.confirm(message);
          if (canClose) {
            onClose(event, reason);
          }
        } else {
          onClose(event, reason);
        }
      }
    },
    [message, onClose, prevent],
  );
  const forceClose = useCallback(
    () => onCloseHandler({ forceClose: true }),
    [onCloseHandler],
  );
  usePrompt(message, prevent);
  return {
    setPrevent,
    onCloseHandler,
    forceClose,
  };
}
