import { CommonGoalsState } from './interfaces';

export const commonGoalsInitialState: CommonGoalsState = {
  items: [],
  loading: false,
  updating: false,
  categories: [],
  loadingCategories: false,
  updatingCategories: false,
  deletingCategories: false,
};

export const storeName = 'commonGoals';
