import { Suspense, lazy } from 'react';
import PageLoader from 'components/PageLoader';
import { AppRouteComponentProps } from 'components/AppRoutes';
import {
  usePageAccessible,
  useEditOrCreateScorecardTemplatePageData,
} from 'hooks';
import { useSelector } from 'react-redux';
import { selectDefaultAssessmentScaleGroupId } from 'store/options';

const EditOrCreateScorecardTemplateComponent = lazy(
  () => import('./EditOrCreateScorecardTemplate.component'),
);

function EditOrCreateScorecardTemplate(
  props: AppRouteComponentProps & { isEdit: boolean },
) {
  const { isEdit } = props;
  const { formValues, reviewCycle, loading, notFound, onBackClick, hasAccess } =
    useEditOrCreateScorecardTemplatePageData(isEdit, usePageAccessible(props));

  return (
    <Suspense fallback={<PageLoader open />}>
      {loading ? (
        <PageLoader open />
      ) : (
        <EditOrCreateScorecardTemplateComponent
          isEdit={isEdit}
          hasAccess={hasAccess}
          notFound={notFound}
          onBackClick={onBackClick}
          reviewCycle={reviewCycle}
          formValues={formValues}
        />
      )}
    </Suspense>
  );
}

export function CreateScorecardTemplate(props: AppRouteComponentProps) {
  const defaultAssessmentScaleId = useSelector(
    selectDefaultAssessmentScaleGroupId,
  );
  if (defaultAssessmentScaleId === '') {
    return <PageLoader open />;
  }

  return <EditOrCreateScorecardTemplate {...props} isEdit={false} />;
}

export function EditScorecardTemplate(props: AppRouteComponentProps) {
  const defaultAssessmentScaleId = useSelector(
    selectDefaultAssessmentScaleGroupId,
  );
  if (defaultAssessmentScaleId === '') {
    return <PageLoader open />;
  }

  return <EditOrCreateScorecardTemplate {...props} isEdit />;
}
