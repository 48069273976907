import { createSlice, isAnyOf, Reducer } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash-es';
import {
  cycleCreate,
  cycleDelete,
  cycleGetList,
  cycleUpdate,
  templateCreate,
  templateUpdate,
  templateDelete,
  duplicateTemplate,
  changeFilter,
  clearFilters,
  publishTemplate,
  changePage,
} from './actions';
import { storeName, initialFilters, reviewCyclesInitialState } from './config';
import addCommonCases from '../addCommonCases';

export * from './interfaces';
export * from './actions';
export * from './selectors';

const reviewCycles = createSlice({
  name: storeName,
  initialState: cloneDeep(reviewCyclesInitialState),
  reducers: {},
  extraReducers: (builder) => {
    addCommonCases(builder, reviewCyclesInitialState)
      .addCase(cycleGetList.fulfilled, (state, action) => {
        Object.assign(state, {
          ...action.payload,
          loading: false,
          init: true,
        });
      })
      .addCase(changeFilter, (state, { payload: { key, value } }) => {
        Object.assign(state, {
          filters: { ...state.filters, [key]: value },
          init: true,
        });
      })
      .addCase(changePage, (state, { payload }) => {
        state.pagination.page = payload;
      })
      .addCase(clearFilters, (state) => {
        Object.assign(state.filters, initialFilters);
      });

    builder.addMatcher(
      isAnyOf(
        cycleCreate.pending,
        cycleGetList.pending,
        cycleDelete.pending,
        cycleUpdate.pending,
        templateCreate.pending,
        templateUpdate.pending,
        templateDelete.pending,
        duplicateTemplate.pending,
        publishTemplate.pending,
      ),
      (state) => {
        state.loading = true;
      },
    );

    builder.addMatcher(
      isAnyOf(
        cycleGetList.fulfilled,
        cycleGetList.rejected,
        cycleCreate.fulfilled,
        cycleCreate.rejected,
        cycleUpdate.fulfilled,
        cycleUpdate.rejected,
        cycleDelete.fulfilled,
        cycleDelete.rejected,
        templateCreate.fulfilled,
        templateCreate.rejected,
        templateUpdate.fulfilled,
        templateUpdate.rejected,
        templateDelete.fulfilled,
        templateDelete.rejected,
        duplicateTemplate.fulfilled,
        duplicateTemplate.rejected,
        publishTemplate.fulfilled,
        publishTemplate.rejected,
      ),
      (state) => {
        state.loading = false;
      },
    );
  },
});

export default reviewCycles.reducer as Reducer<typeof reviewCyclesInitialState>;
