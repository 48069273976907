import { createSlice, Reducer } from '@reduxjs/toolkit';
import addScorecardsCases from 'store/scorecards/addScorecardsCases';
import { cloneDeep } from 'lodash-es';
import { resetState } from 'store/actions';
import {
  storeName,
  directReportScorecardInitialState,
  initialFilters,
} from './config';

export * from './interfaces';

const directReportScorecards = createSlice({
  name: storeName,
  initialState: cloneDeep(directReportScorecardInitialState),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(resetState, (state) => {
      const newState = cloneDeep(directReportScorecardInitialState);
      newState.filters = state.filters;
      Object.assign(state, newState);
    });
    addScorecardsCases(builder, storeName, cloneDeep(initialFilters));
  },
});

export default directReportScorecards.reducer as Reducer<
  typeof directReportScorecardInitialState
>;
