import { useScorecardContext } from 'contexts';
import { useSelector } from 'react-redux';
import { selectOptions } from 'store/options';
import { useCallback, useMemo } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { FeedbackSubGoalHeader } from 'store/interfaces';
import { moderateFeedback } from 'store/scorecards';
import { useAppDispatch } from 'store';

export default function useAssessmentCellValues(
  {
    id,
    assessmentScoreOriginal,
    assessmentScoreFinalized,
  }: FeedbackSubGoalHeader,
  goalId: number,
  assessmentScaleGroupId: number,
) {
  const dispatch = useAppDispatch();
  const { id: scorecardId, store } = useScorecardContext();
  const { assessmentScales } = useSelector(selectOptions);
  const options = useMemo(() => {
    const values = [...assessmentScales[assessmentScaleGroupId]] || [];

    if (assessmentScoreOriginal == null && assessmentScoreFinalized !== null) {
      values.unshift({
        id: null,
        name: '-',
      });
    }

    return values;
  }, [
    assessmentScaleGroupId,
    assessmentScales,
    assessmentScoreFinalized,
    assessmentScoreOriginal,
  ]);

  const originOption = assessmentScoreOriginal
    ? options.find((item) => item.id === assessmentScoreOriginal.id)
    : null;
  const originValue = originOption?.id;
  const originText = originOption?.name || '-';
  const changedValue = assessmentScoreFinalized
    ? options.find((item) => item.id === assessmentScoreFinalized.id)?.id
    : undefined;
  const selectedValue = changedValue || originValue || 0;
  const onAssessmentChange = useCallback(
    ({ target: { value } }: SelectChangeEvent<number>) => {
      const newValue = typeof value === 'string' ? parseInt(value, 10) : value;
      dispatch(
        moderateFeedback({
          id: scorecardId,
          store,
          data: {
            id: goalId,
            subgoals: [{ id, assessmentScoreFinalized: newValue }],
          },
        }),
      );
    },
    [dispatch, scorecardId, store, goalId, id],
  );
  const renderValue = useCallback(
    (value: number | '') => {
      if (value) {
        const selected = options.find((item) => item.id === value);
        if (selected) {
          return selected.name;
        }
      }
      return '-';
    },
    [options],
  );
  const hasChangedValue = changedValue && changedValue !== originValue;
  return {
    originText,
    selectedValue,
    onAssessmentChange,
    renderValue,
    hasChangedValue,
    options,
  };
}
