import { RootState } from 'store';

export const selectFeedbackRequestsList = (state: RootState) =>
  state.feedbackRequests;

export const selectIsLoadingFeedbackRequests = (state: RootState) =>
  state.feedbackRequests.loading;

export const selectFeedbackRequestsPagination = (state: RootState) =>
  state.feedbackRequests.pagination;

export const selectFeedbackRequestsFilters = (state: RootState) =>
  state.feedbackRequests.filters;
