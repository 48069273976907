import { OwnScorecardsFilters, OwnScorecardsState } from './interfaces';

export const storeName = 'scorecardsOwn' as const;

export type OwnScorecardsName = typeof storeName;

export const initialFilters: OwnScorecardsFilters = {
  reviewCycleIds: null,
};

export const ownScorecardInitialState: OwnScorecardsState = {
  items: [],
  loading: false,
  filters: { ...initialFilters },
  init: false,
  pagination: {
    limit: 0,
    page: 1,
    total: 0,
  },
  fullData: {},
};
