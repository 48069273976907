import { createAction } from '@reduxjs/toolkit';
import {
  ScorecardsActionStoreArgument,
  ScorecardFiltersKeys,
} from 'store/interfaces';

export const clearFilters = createAction<{
  store: ScorecardsActionStoreArgument;
  data?: Array<ScorecardFiltersKeys>;
}>(`scorecards/clearFilters`);
