import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_SCORECARD_TEMPLATE_PUBLISH } from 'constants/api';
import { generateURL } from 'helpers';
import { storeName } from '../config';

export const publishTemplate = createAsyncThunk<
  { createdTemplatesCount: number },
  { templateId: number; workRecords: { id: number }[] }
>(`${storeName}/publishTemplate`, async ({ templateId, ...data }, thunkAPI) =>
  api.axios
    .post<{ createdTemplatesCount: number }>(
      generateURL(API_SCORECARD_TEMPLATE_PUBLISH, { id: templateId }),
      data,
    )
    .then((res) => res.data)
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
