import { WorkRecord } from 'store/interfaces';

export default function sortUsers(users: WorkRecord[]): WorkRecord[] {
  return users
    .sort((a, b) => a.user.fullName.localeCompare(b.user.fullName))
    .sort((a, b) => {
      if (a.location?.country?.name && b.location?.country?.name) {
        return a.location?.country?.name.localeCompare(
          b.location?.country?.name,
        );
      }
      return 0;
    })
    .sort((a, b) => a.unit.name.localeCompare(b.unit.name));
}
