import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'pages.scorecards.title',
    defaultMessage: 'My Scorecards',
  },
  editScorecard: {
    id: 'pages.scorecards.editScorecard',
    defaultMessage: 'Edit Scorecard',
  },
  exportScorecard: {
    id: 'pages.scorecards.exportScorecard',
    defaultMessage: 'Export Scorecard',
  },
  deleteScorecard: {
    id: 'pages.scorecards.deleteScorecard',
    defaultMessage: 'Delete Scorecard',
  },
  revertToActive: {
    id: 'pages.scorecards.revertToActive',
    defaultMessage: 'Revert Scorecard to Active State',
  },
  requestFeedback: {
    id: 'pages.scorecards.requestFeedback',
    defaultMessage: 'Request Feedback',
  },
  submitForReview: {
    id: 'pages.scorecards.submitForReview',
    defaultMessage: 'Submit for Review',
  },
  approveGoals: {
    id: 'pages.scorecards.approveGoals',
    defaultMessage: 'Approve Goals',
  },
  shareForReview: {
    id: 'pages.scorecards.shareForReview',
    defaultMessage: 'Share with a Crew Member',
  },
  confirmDeletePendingRequestText: {
    id: 'pages.scorecards.confirmDeletePendingRequestText',
    defaultMessage:
      'Are you sure you want to share the Scorecard for review without receiving feedback from below Reviewers?',
  },
  confirmShareForReviewText: {
    id: 'pages.scorecards.confirmShareForReviewText',
    defaultMessage:
      'Once you share the Scorecard for review new feedback will not be accepted. The assessment score and anonymised feedback will be shared with the Employee.',
  },
  confirmCompleteText: {
    id: 'pages.scorecards.confirmCompleteText',
    defaultMessage:
      'You are about to turn the Scorecard into the final, Completed state. It means that  performance review results were acknowledged by the Employee and no further changes are needed. Are you sure you want to proceed?',
  },
  confirmGoalsPreparedText: {
    id: 'pages.scorecards.confirmGoalsPreparedText',
    defaultMessage:
      "You are about to confirm your goals and request their review from your Career Mentor. After requesting a review, you won't be able to adjust your goals details. To make further updates you need to request your Career Mentor enable you to do this and pass approval flow once again. You still will be able to adjust goals reviewers and select allocated projects in the Customer Satisfaction goal.{br}{br}Are you sure you want to proceed?",
  },
  confirmApproveGoalsText: {
    id: 'pages.scorecards.confirmApproveGoalsText',
    defaultMessage:
      'You are about to approve Scorecard goals for the Employee for the current Review Cycle. Employee shall be notified of this action as a confirmation that you are aligned on the goals structure and details, and Employee can start working towards them. You still will be able to adjust Scorecard goals if required, or enable Employee to make changes and pass approval flow once again.{br}{br}Are you sure you want to proceed?',
  },
  noScorecards: {
    id: 'pages.myTeam.noScorecards',
    defaultMessage: 'No scorecards yet',
  },
  confirmSendForFeedbackText: {
    id: 'pages.scorecards.confirmSendForFeedbackText',
    defaultMessage:
      'Once you request feedback you will no longer be able to edit goals structure, names and descriptions. You still will be able to adjust goals weights and add extra Reviewers.{br}{br}Are you sure you want to proceed?',
  },
  confirmDeleteScorecardText: {
    id: 'pages.scorecards.confirmDeleteScorecardText',
    defaultMessage:
      'You are about to delete Scorecard for {fullName}. Scorecard data, including configured goals, will be permanently deleted.{br}{br}Are you sure you want to proceed?',
  },
  confirmRevertToActiveText: {
    id: 'pages.scorecards.confirmRevertToActiveText',
    defaultMessage:
      'You are about to revert Scorecard back into an Active state that will enable an employee to adjust goals structure and details. {isFeedbackActive, select, true { All previously provided feedbacks will be cleared out and reviewers will need to provide a feedback once again. } other {} } After this action, you will further need to pass Scorecard approval flow with your subordinate. You should be jointly aligned on these steps.{br}{br}Are you sure you want to proceed?',
  },
  sprDescription: {
    id: 'pages.scorecards.sprDescription',
    defaultMessage:
      'Performance Rating Description{br}{br}' +
      '5.0 - Unique, exceptional and world class and Top 1% performer; Score > 125{br}' +
      '4.5 - Exceptional performer with considerable world class achievement during review period; Score 121 - 125{br}' +
      '4.0 - Top performer during review period; Score 116 - 120{br}' +
      '3.5 - Above average performer during period; Score 108 - 115{br}' +
      '3.0 - Medium-to-good performer during review period; Score 98 - 107{br}' +
      '2.5 - Slightly below average performer during period; Score 85 - 97{br}' +
      '2.0 - Below average performance during period; Score 75 - 84{br}' +
      '1.0 - Immediate termination; Score < 75',
  },
  editMyScorecard: {
    id: 'pages.scorecards.editMyScorecard.title',
    defaultMessage: 'Edit My Scorecard',
  },
  myScorecardFor: {
    id: 'pages.scorecards.myScorecardFor',
    defaultMessage: 'My Scorecard for',
  },
  updatedField: {
    id: 'pages.scorecards.updatedField',
    defaultMessage: `<strong>{field}</strong> updated: <strong>{prev}</strong> → <strong>{curr}</strong>`,
  },
  updatedFieldStatus: {
    id: 'pages.scorecards.updatedFieldStatus',
    defaultMessage: `<strong>{field}</strong> updated: <old></old> → <new></new>`,
  },
  updatedParamsField: {
    id: 'pages.scorecards.updatedParamsField',
    defaultMessage: `<strong>''{field}''</strong> setting <strong>{curr}</strong>`,
  },
  updatedSubgoalDescription: {
    id: 'pages.scorecards.updatedSubgoalDescription',
    defaultMessage: 'Subgoal:{br}<i>{name}</i>',
  },
  deletedSubgoalDescription: {
    id: 'pages.scorecards.deletedSubgoalDescription',
    defaultMessage: 'Subgoal <i>{name}</i> deleted',
  },
  createdSubgoalDescription: {
    id: 'pages.scorecards.createdSubgoalDescription',
    defaultMessage: 'Subgoal created:',
  },
  updatedGoalDescription: {
    id: 'pages.scorecards.updatedGoalDescription',
    defaultMessage: 'Goal:{br}<i>{name}</i>',
  },
  deletedGoalDescription: {
    id: 'pages.scorecards.deletedGoalDescription',
    defaultMessage: 'Goal <i>{name}</i> deleted',
  },
  createdGoalDescription: {
    id: 'pages.scorecards.createdGoalDescription',
    defaultMessage: 'Goal created:',
  },
  multiplier: {
    id: 'pages.scorecards.multiplier',
    defaultMessage: 'Multiplier',
  },
  setAsReviewer: {
    id: 'pages.scorecards.setAsReviewer',
    defaultMessage: '{userFullName} added for:',
  },
  deleteAsReviewer: {
    id: 'pages.scorecards.deleteAsReviewer',
    defaultMessage: '{userFullName} removed from:',
  },
  finalizedComment: {
    id: 'pages.scorecards.finalizedComment',
    defaultMessage: 'Finalized Comment',
  },
  assessmentScore: {
    id: 'pages.scorecards.assessmentScore',
    defaultMessage: 'Assessment Score',
  },
  countriesRemovedFromTemplate: {
    id: 'pages.scorecards.countriesRemovedFromTemplate',
    defaultMessage:
      'Following locations were removed from the Template as currently hold no employees for selected Department: {countries}',
  },
  assessmentScoreFrom: {
    id: 'pages.scorecards.assessmentScoreFrom',
    defaultMessage: 'Assessment Score from {firstName} {lastName}',
  },
  savedComment: {
    id: 'pages.scorecards.savedComment',
    defaultMessage: 'Saved Comment',
  },
  savedCommentFrom: {
    id: 'pages.scorecards.savedCommentFrom',
    defaultMessage: 'Saved Comment from {firstName} {lastName}',
  },
  updatedComment: {
    id: 'pages.scorecards.updatedComment',
    defaultMessage: 'Updated Comment',
  },
  updatedCommentFrom: {
    id: 'pages.scorecards.updatedCommentFrom',
    defaultMessage: 'Updated Comment from {firstName} {lastName}',
  },
  noReviewers: {
    id: 'pages.scorecards.noReviewers',
    defaultMessage: "Ooops. It's no one here!",
  },
  restoreScorecardTitle: {
    id: 'pages.scorecards.restoreScorecardTitle',
    defaultMessage: 'Restore Scorecard for {name}',
  },
  restoreScorecardText: {
    id: 'pages.scorecards.restoreScorecardText',
    defaultMessage:
      'You are about to restore previously deleted Scorecard for {name}. After that action it will become visible for this person and their People Manager and will be in Active state.{br}{br}Are sure you want to proceed?',
  },
  restoreScorecardToast: {
    id: 'pages.scorecards.restoreScorecardToast',
    defaultMessage: 'Scorecard for {name} was successfully restored.',
  },
  versionLockPopupTitle: {
    id: 'pages.scorecards.versionLockPopupTitle',
    defaultMessage: "Can't save Scorecard",
  },
  versionLockPopupContent: {
    id: 'pages.scorecards.versionLockPopupContent',
    defaultMessage:
      "This Scorecard was edited by {name} at {date}.{br}We can't apply your changes as it will overwrite the previous one.{br}Please contact {name} to agree on updates or open this Scorecard in a new tab and move your changes there.",
  },
  revertToActiveNoCM: {
    id: 'pages.scorecards.revertToActiveNoCM',
    defaultMessage:
      'This employee doesn’t have an assigned Career Mentor. Scorecard can’t be reverted to Active state',
  },
  feedbackWasRejected: {
    id: 'pages.scorecards.feedbackWasRejected',
    defaultMessage: 'Feedback request was rejected.',
  },
});
