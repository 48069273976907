/* eslint-disable no-underscore-dangle */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ScorecardChangeMultiplierDto,
  Scorecard,
  ScorecardsActionStoreArgument,
} from 'store/interfaces';
import api from 'api';
import { API_SCORECARD } from 'constants/api';
import { GoalField } from 'components/GoalsAndSubgoalsForm/interfaces';
import { showOLDialog } from 'store/optimisticLocking';
import dayjs from 'dayjs';
import { updateFullData } from './updateFullData';

export const patchScorecard = createAsyncThunk<
  Scorecard,
  {
    id: number;
    data: ScorecardChangeMultiplierDto | GoalField;
    store: ScorecardsActionStoreArgument;
  }
>(`scorecards/patchScorecard`, async ({ id, data, store }, thunkAPI) =>
  api.axios
    .patch<Scorecard>(`${API_SCORECARD}/${id}`, data)
    .then((res) => {
      thunkAPI.dispatch(updateFullData({ id, store }));
      return res.data;
    })
    .catch((e) => {
      // optimistic locking error handling
      if (e.response?.status === 412) {
        thunkAPI.dispatch(
          showOLDialog({
            type: 'scorecard',
            params: {
              name: e.response?.data?.__meta?.lastActivity?.actor.fullName,
              date: dayjs(
                e.response?.data?.__meta?.lastActivity?.doneAt,
              ).format('HH:mm DD.MM.YYYY'),
            },
          }),
        );
      }

      return thunkAPI.rejectWithValue({
        ...e.response?.data,
        response: {
          status: e.response?.status,
        },
      });
    }),
);
