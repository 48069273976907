import { useScorecardData, useUserPermissions } from 'hooks';
import { ScorecardStatus } from 'store/interfaces';
import { getScorecardStates } from 'helpers';
import { useCallback } from 'react';
import usePagePermissions from './usePagePermissions';

export function useScorecardPermissionsFroStatusGetter() {
  const {
    hasFullAccess,
    isDepartmentOverviewPage,
    isOwnScorecardsPage,
    isMyTeamPage,
  } = usePagePermissions();
  const { canDeleteScorecards, canExportScorecards } = useUserPermissions();

  return useCallback(
    (status: ScorecardStatus) => {
      const {
        isGoalsPrepared,
        isActive,
        isFeedbackCollected,
        isFeedbackRequested,
        isReadyForReview,
        isGoalsApproved,
        isCompleted,
      } = getScorecardStates(status);

      const canShare =
        hasFullAccess && (isFeedbackCollected || isFeedbackRequested);
      const canEdit =
        isActive ||
        (hasFullAccess &&
          (isGoalsPrepared ||
            isGoalsApproved ||
            isFeedbackRequested ||
            isFeedbackCollected ||
            isReadyForReview));
      const canRequestFeedback = hasFullAccess && isGoalsApproved;
      const canSubmitForReview = isActive && isOwnScorecardsPage;
      const canComplete = hasFullAccess && isReadyForReview;
      const canApproveGoals = hasFullAccess && (isActive || isGoalsPrepared);
      const canEditReviewers = isActive || isGoalsPrepared || isGoalsApproved;
      const canEditAllocatedProjects =
        isActive ||
        isGoalsPrepared ||
        isGoalsApproved ||
        (hasFullAccess && !isCompleted);
      const canDelete =
        (isDepartmentOverviewPage || isMyTeamPage) &&
        canDeleteScorecards &&
        (isActive || isGoalsPrepared || isGoalsApproved);
      const canRevertToActive =
        hasFullAccess &&
        (isGoalsPrepared ||
          isGoalsApproved ||
          isFeedbackRequested ||
          isFeedbackCollected);
      return {
        canExportScorecards:
          canExportScorecards &&
          (isFeedbackRequested ||
            isFeedbackCollected ||
            isReadyForReview ||
            isCompleted),
        canEdit,
        canRequestFeedback,
        canShare,
        canSubmitForReview,
        canComplete,
        canApproveGoals,
        canEditReviewers,
        canEditAllocatedProjects,
        canDelete,
        canRevertToActive,
      };
    },
    [
      canExportScorecards,
      hasFullAccess,
      canDeleteScorecards,
      isDepartmentOverviewPage,
      isMyTeamPage,
      isOwnScorecardsPage,
    ],
  );
}
export function useScorecardPermissions() {
  const { status } = useScorecardData();

  return useScorecardPermissionsFroStatusGetter()(status);
}
