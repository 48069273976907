import { AppRouteComponentProps } from 'components/AppRoutes';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectProfileInfo } from 'store/profile';
import { ROLE_EMPLOYEE } from 'constants/roles';

export default function usePageAccessible({
  currentRoute: { roles, permissions, allPermissionsRequired = true },
}: AppRouteComponentProps) {
  const { roles: userRoles, permissions: userPermissions } =
    useSelector(selectProfileInfo);

  return useMemo(() => {
    if (!roles?.length && !permissions?.length) {
      return true;
    }
    if (!userRoles?.find((role) => role.alias === ROLE_EMPLOYEE)) {
      return false;
    }
    // This is temp. later we're gonna use permissions only.
    const isEnabledByRole = roles?.some((requiredRole) =>
      userRoles?.find((role) => role.alias === requiredRole),
    );
    const isEnabledByPermission = allPermissionsRequired
      ? permissions?.every((requiredPermission) =>
          userPermissions?.find(
            (permission) => permission.name === requiredPermission,
          ),
        )
      : permissions?.some((requiredPermission) =>
          userPermissions?.find(
            (permission) => permission.name === requiredPermission,
          ),
        );

    return Boolean(isEnabledByRole || isEnabledByPermission);
  }, [roles, permissions, userRoles, allPermissionsRequired, userPermissions]);
}
