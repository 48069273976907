import { useHeaderMenuLinks } from 'hooks';
import { Links, AppBar, Toolbar } from './Header.styled';
import HeaderLink from './HeaderLink.component';
import MenuSwitch from './MenuSwitch';
import UserMenu from './UserMenu.component';

export default function Header() {
  const accessibleRoutes = useHeaderMenuLinks();

  return (
    <AppBar>
      <Toolbar data-testid="header-tool-bar">
        <MenuSwitch />
        <Links>
          {accessibleRoutes.map(({ path, title }) =>
            !title ? null : <HeaderLink key={path} path={path} title={title} />,
          )}
        </Links>
        <UserMenu />
      </Toolbar>
    </AppBar>
  );
}
