import PageLoader from 'components/PageLoader';
import { Suspense, lazy } from 'react';
import { AppRouteComponentProps } from 'components/AppRoutes';

const ObjectiveDetails = lazy(() => import('./ObjectiveDetails.component'));

export default function ObjectiveDetailsPage(props: AppRouteComponentProps) {
  return (
    <Suspense fallback={<PageLoader open />}>
      <ObjectiveDetails pageProps={props} />
    </Suspense>
  );
}
