import {
  UnpackNestedValue,
  UseFormReturn,
} from 'react-hook-form/dist/types/form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { useEffect } from 'react';

export default function useResetAfterSubmit<T extends FieldValues>({
  formState: { isSubmitSuccessful, submitCount },
  reset,
}: UseFormReturn<T>) {
  useEffect(() => {
    if (submitCount && isSubmitSuccessful) {
      reset({} as UnpackNestedValue<any>, { keepValues: true });
    }
  }, [isSubmitSuccessful, reset, submitCount]);
}
