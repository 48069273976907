import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectDefaultAssessmentScaleGroupId,
  selectOptions,
} from 'store/options';
import { useIntl } from 'react-intl';
import { TemplateFormFields } from 'pages/ReviewCycles';
import { formTexts } from 'i18n';
import api from 'api';
import { API_REVIEW_CYCLE, API_SCORECARD_TEMPLATE } from 'constants/api';
import { CycleTemplate, ReviewCycleItem } from 'store/reviewCycles';
import { parseParamTypes, parseTemplateData } from 'helpers';
import { REVIEW_CYCLES_STATUS_PUBLISHED } from 'constants/reviewCycles';

export default function useEditOrCreateScorecardTemplatePageData(
  isEdit: boolean,
  isPageAccessible: boolean,
) {
  const { cycleId, templateId } = useParams();
  const navigate = useNavigate();
  const parsedCycleId = +cycleId!;
  const parsedTemplateId = +templateId!;
  const [reviewCycle, setReviewCycle] = useState<ReviewCycleItem | null>(null);
  const [loading, setLoading] = useState(true);
  const { formatMessage } = useIntl();
  const [notFound, setNotFound] = useState<boolean>(false);
  const [hasAccessForThisStatus, setHasAccessForThisStatus] =
    useState<boolean>(true);

  const defaultAssessmentScaleId = useSelector(
    selectDefaultAssessmentScaleGroupId,
  );
  const { paramTypes, isLoading: paramTypesLoading } =
    useSelector(selectOptions);
  const {
    allowScorecardComment,
    allowScorecardOwnerEdits,
    selfAssessmentSetup,
  } = parseParamTypes(paramTypes, true);

  const defaultFormValues: TemplateFormFields = useMemo(() => {
    const subgoal = {
      name: formatMessage(formTexts.untitledSubgoal),
      description: '',
      orderRank: 0,
      weight: 0,
      assessmentScaleGroupId: defaultAssessmentScaleId,
    };
    const goal = {
      name: formatMessage(formTexts.untitledGoal),
      description: '',
      weight: 0,
      orderRank: 0,
      allowScorecardOwnerEdits: allowScorecardOwnerEdits!,
      allowScorecardComment: allowScorecardComment!,
      selfAssessmentSetup,
      subgoals: [subgoal],
    };
    return {
      name: '',
      unitId: '',
      countryIds: [],
      description: '',
      reviewCycleId: +cycleId!,
      goals: [goal],
    };
  }, [
    formatMessage,
    defaultAssessmentScaleId,
    allowScorecardOwnerEdits,
    allowScorecardComment,
    selfAssessmentSetup,
    cycleId,
  ]);

  const [formValues, setFormValues] =
    useState<TemplateFormFields>(defaultFormValues);

  useEffect(() => {
    if (!isEdit) {
      setFormValues(defaultFormValues);
    }
  }, [isEdit, defaultFormValues]);
  useEffect(() => {
    setLoading(true);

    if (!isEdit) {
      api.axios
        .get(`${API_REVIEW_CYCLE}/${parsedCycleId}`)
        .then(({ data }) => {
          setReviewCycle(data);
        })
        .catch(() => {
          setNotFound(true);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      Promise.all([
        api.axios.get(`${API_REVIEW_CYCLE}/${parsedCycleId}`),
        api.axios.get(`${API_SCORECARD_TEMPLATE}/${parsedTemplateId}`),
      ])
        .then(([reviewCycleResponse, templateResponse]) => {
          const reviewCycleData = reviewCycleResponse.data;
          const templateData = templateResponse.data;

          if (templateData.status === REVIEW_CYCLES_STATUS_PUBLISHED) {
            setHasAccessForThisStatus(false);
            return;
          }

          setReviewCycle(reviewCycleData);

          setFormValues({
            ...parseTemplateData(templateData as CycleTemplate),
            reviewCycleId: reviewCycleData.id,
          });
        })
        .catch(() => {
          setNotFound(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [parsedCycleId, setLoading, isEdit, parsedTemplateId]);

  const onBackClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return {
    formValues,
    reviewCycle,
    loading: loading || paramTypesLoading,
    notFound,
    onBackClick,
    hasAccess: isPageAccessible && hasAccessForThisStatus,
  };
}
