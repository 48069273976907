import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_CAREER_ROADMAP } from 'constants/api';
import { EdgeNormalizer } from 'helpers';
import { RoadmapDepartment } from 'store/interfaces';
import { NodeNormalizer } from 'helpers/roadmap';
import { storeName } from '../config';

export const getCanvasData = createAsyncThunk<RoadmapDepartment, string>(
  `${storeName}/get`,
  (payload, thunkAPI) =>
    api.axios
      .get<RoadmapDepartment>(`${API_CAREER_ROADMAP}/${payload}`)
      .then((res) => {
        const { nodes, edges, filters } = res.data.schemaData;
        return {
          ...res.data,
          schemaData: {
            filters,
            nodes: NodeNormalizer.validateNodes(nodes),
            edges: EdgeNormalizer.expand(edges),
          },
        };
      })
      .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
