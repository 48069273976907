import { createAsyncThunk } from '@reduxjs/toolkit';
import { Pagination, ReviewCycleItem } from 'store/interfaces';
import api from 'api';
import { RootState } from 'store';
import qs from 'query-string';
import { API_REVIEW_CYCLE_FULL_LIST } from 'constants/api';
import { storeName } from '../config';

export const cycleGetList = createAsyncThunk<{
  items: ReviewCycleItem[];
  pagination: Pagination;
}>(`${storeName}/list`, async (data, thunkAPI) => {
  const {
    reviewCycles: {
      filters,
      pagination: { page },
    },
  } = thunkAPI.getState() as RootState;
  return api.axios
    .get(
      `${API_REVIEW_CYCLE_FULL_LIST}?${qs.stringify(
        { ...filters, page },
        { skipNull: true },
      )}`,
    )
    .then((res) => res.data)
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data));
});
