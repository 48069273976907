import { DepartmentState } from './interfaces';

export const storeName = 'roadmapDepartment';

export const roadmapDepartmentInitialState: DepartmentState = {
  isDrawerOpen: false,
  isIdle: true,
  isLoading: true,
  isUpdating: false,
  data: {
    list: [],
    pagination: {
      limit: 0,
      page: 0,
      total: 0,
    },
  },
};
