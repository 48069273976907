import { useFieldArray, useFormContext } from 'react-hook-form';
import { GoalField } from 'components/GoalsAndSubgoalsForm/interfaces';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectDefaultAssessmentScaleGroupId } from 'store/options';
import { useIntl } from 'react-intl';
import { formTexts } from 'i18n';
import { scrollToActiveSubgoalDetails } from 'helpers';
import useTotalWeight from './useTotalWeight';

export default function useGoalValues(index: number) {
  const { control, clearErrors, trigger } = useFormContext<GoalField>();
  const { formatMessage } = useIntl();
  const name = `goals.${index}`;
  const defaultAssessmentScaleId = useSelector(
    selectDefaultAssessmentScaleGroupId,
  );
  const subGoalsName = `${name}.subgoals` as `goals.${number}.subgoals`;
  const {
    fields: subgoals,
    append: appendSubGoal,
    remove: removeSubgoal,
  } = useFieldArray({
    control,
    name: subGoalsName,
    keyName: 'key',
  });
  const totalWeight = useTotalWeight(index);
  const addSubGoal = useCallback(async () => {
    if (totalWeight !== 100) {
      await trigger(`goals.${index}.subgoalsWeight`);
    }
    const newSubgoal = {
      name: formatMessage(formTexts.untitledSubgoal),
      description: '',
      assessmentScaleGroupId: defaultAssessmentScaleId,
      orderRank: subgoals.length,
      weight: 0,
    };
    appendSubGoal(newSubgoal);
    scrollToActiveSubgoalDetails(index, subgoals.length);
  }, [
    totalWeight,
    formatMessage,
    defaultAssessmentScaleId,
    subgoals.length,
    appendSubGoal,
    index,
    trigger,
  ]);
  const deleteSubgoal = useCallback(
    (idx: number) => {
      const errorPrefix =
        `${subGoalsName}.${idx}` as `goals.${number}.subgoals.${number}`;
      clearErrors([
        `${errorPrefix}.name`,
        `${errorPrefix}.description`,
        `${errorPrefix}.weight`,
        `${errorPrefix}.assessmentScaleGroupId`,
      ]);
      removeSubgoal(idx);
    },
    [clearErrors, removeSubgoal, subGoalsName],
  );
  return {
    name,
    subgoals,
    addSubGoal,
    deleteSubgoal,
  };
}
