import { useMemo } from 'react';
import { SeniorityType } from 'constants/roadmap';
import { useIntl } from 'react-intl';
import { UIOption } from 'store/options';

export function useSeniorityOptions(): UIOption[] {
  const { formatMessage } = useIntl();

  return useMemo(
    () => [
      {
        id: SeniorityType.Trainee,
        name: formatMessage({
          id: 'pages.roadmap.seniority.trainee',
          defaultMessage: 'Trainee',
        }),
      },
      {
        id: SeniorityType.Junior,
        name: formatMessage({
          id: 'pages.roadmap.seniority.junior',
          defaultMessage: 'Junior',
        }),
      },
      {
        id: SeniorityType.Middle,
        name: formatMessage({
          id: 'pages.roadmap.seniority.middle',
          defaultMessage: 'Middle',
        }),
      },
      {
        id: SeniorityType.Senior,
        name: formatMessage({
          id: 'pages.roadmap.seniority.senior',
          defaultMessage: 'Senior',
        }),
      },
      {
        id: SeniorityType.Manager,
        name: formatMessage({
          id: 'pages.roadmap.seniority.manager',
          defaultMessage: 'Manager',
        }),
      },
    ],
    [formatMessage],
  );
}

export function useSeniorityMap(): Record<string, string> {
  const { formatMessage } = useIntl();
  return useMemo(
    () => ({
      [SeniorityType.Trainee]: formatMessage({
        id: 'pages.roadmap.seniority.trainee',
        defaultMessage: 'Trainee',
      }),
      [SeniorityType.Junior]: formatMessage({
        id: 'pages.roadmap.seniority.junior',
        defaultMessage: 'Junior',
      }),
      [SeniorityType.Middle]: formatMessage({
        id: 'pages.roadmap.seniority.middle',
        defaultMessage: 'Middle',
      }),
      [SeniorityType.Senior]: formatMessage({
        id: 'pages.roadmap.seniority.senior',
        defaultMessage: 'Senior',
      }),
      [SeniorityType.Manager]: formatMessage({
        id: 'pages.roadmap.seniority.manager',
        defaultMessage: 'Manager',
      }),
    }),
    [formatMessage],
  );
}
