import { useRef, useState, useEffect, ReactNode } from 'react';
import Tooltip from './Tooltip.component';

export default function OverflowTip({
  children,
  width,
}: {
  children: ReactNode;
  width?: number;
}) {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (textElementRef?.current) {
      setIsOverflow(
        textElementRef.current.scrollWidth > textElementRef.current.clientWidth,
      );
    }
  }, [textElementRef]);
  return (
    <Tooltip title={children} disableHoverListener={!isOverflowed}>
      <span
        ref={textElementRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: width || 'auto',
          display: 'inline-block',
        }}
      >
        {children}
      </span>
    </Tooltip>
  );
}
