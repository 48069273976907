import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import { KeyboardArrowDownIcon, KeyboardArrowUpIcon } from 'components/Icons';
import { useState, useRef, useCallback } from 'react';
import { SuperButtonMenuItem, SuperButtonProps } from './interfaces';
import { Loader, MainButton, ButtonGroup } from './SuperButton.styled';

export default function SuperButton<T extends SuperButtonMenuItem>(
  props: SuperButtonProps<T>,
) {
  const {
    options,
    onClick = () => {},
    selected = 0,
    title,
    renderMenuItem,
    loading = false,
    disabled,
    dataTestId,
    popperProps: { sx: popperStyles, ...popperProps } = {},
    ...groupProps
  } = props;
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(selected);

  const getOptionText = useCallback(
    (option: string | T) => (typeof option === 'string' ? option : option.name),
    [],
  );

  const handleClick = () => {
    onClick(selectedIndex);
  };

  const handleMenuItemClick = (index: number) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const hideMenu = () => {
    setOpen(false);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const renderIcon = () => {
    if (loading) {
      return <Loader size={20} color="inherit" />;
    }
    if (open) {
      return <KeyboardArrowUpIcon fontSize="small" />;
    }
    return <KeyboardArrowDownIcon fontSize="small" />;
  };

  return (
    <ButtonGroup ref={anchorRef} disabled={disabled || loading} {...groupProps}>
      {title ? (
        <MainButton onClick={handleToggle}>{title}</MainButton>
      ) : (
        <MainButton onClick={handleClick}>
          {getOptionText(options[selectedIndex])}
        </MainButton>
      )}
      <Button fullWidth={false} onClick={handleToggle} data-testid={dataTestId}>
        {renderIcon()}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-start"
        className={`SelectMenuSize-${groupProps.size}`}
        sx={{
          zIndex: 9999,
          minWidth: '325px',
          maxWidth: '325px',
          ...popperStyles,
        }}
        {...popperProps}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  sx={{ maxHeight: 'calc(100vh - 300px)', overflowY: 'scroll' }}
                  id="split-button-menu"
                  autoFocusItem
                >
                  {options.map((option, index) =>
                    renderMenuItem ? (
                      renderMenuItem(option, index, hideMenu)
                    ) : (
                      <MenuItem
                        key={typeof option === 'string' ? option : option.id}
                        selected={index === selectedIndex}
                        onClick={() => handleMenuItemClick(index)}
                      >
                        {getOptionText(option)}
                      </MenuItem>
                    ),
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </ButtonGroup>
  );
}
