import { defineMessages } from 'react-intl';

export default defineMessages({
  actual: {
    id: 'common.actual',
    defaultMessage: 'Actual',
  },
  all: {
    id: 'common.all',
    defaultMessage: 'All',
  },
  apply: {
    id: 'common.apply',
    defaultMessage: 'Apply',
  },
  none: {
    id: 'common.none',
    defaultMessage: 'None',
  },
  brandName: {
    id: 'common.brandName',
    defaultMessage: 'MARS',
  },
  add: {
    id: 'common.add',
    defaultMessage: 'Add',
  },
  edit: {
    id: 'common.edit',
    defaultMessage: 'Edit',
  },
  delete: {
    id: 'common.delete',
    defaultMessage: 'Delete',
  },
  dashDefaultValue: {
    id: 'common.dashDefaultValue',
    defaultMessage: '-',
  },
  remove: {
    id: 'common.remove',
    defaultMessage: 'Remove',
  },
  deleted: {
    id: 'common.deleted',
    defaultMessage: 'Deleted',
  },
  create: {
    id: 'common.create',
    defaultMessage: 'Create',
  },
  updated: {
    id: 'common.updated',
    defaultMessage: 'Updated',
  },
  success: {
    id: 'common.success',
    defaultMessage: 'Success',
  },
  save: {
    id: 'common.save',
    defaultMessage: 'Save',
  },
  saveChanges: {
    id: 'common.saveChanges',
    defaultMessage: 'Save Changes',
  },
  cancel: {
    id: 'common.cancel',
    defaultMessage: 'Cancel',
  },
  complete: {
    id: 'common.complete',
    defaultMessage: 'Complete',
  },
  department: {
    id: 'common.department',
    defaultMessage: 'Department',
  },
  location: {
    id: 'common.location',
    defaultMessage: 'Location',
  },
  description: {
    id: 'common.description',
    defaultMessage: 'Description',
  },
  submit: {
    id: 'common.submit',
    defaultMessage: 'Submit',
  },
  resetAllFilters: {
    id: 'common.resetAllFilters',
    defaultMessage: 'Reset All Filters',
  },
  entityCreated: {
    id: 'common.entityCreated',
    defaultMessage: '{entityName} has been created',
  },
  publish: {
    id: 'common.publish',
    defaultMessage: 'Publish',
  },
  published: {
    id: 'common.published',
    defaultMessage: '{templateName} has been published',
  },
  alreadyPublishedForUser: {
    id: 'common.alreadyPublishedForUser',
    defaultMessage:
      'Publish aborted. Please remove the following employees from the list as Scorecards were already published. {userNames}',
  },
  noMatches: {
    id: 'common.noMatches',
    defaultMessage: 'No matches',
  },
  noResultWithFilters: {
    id: 'common.noResultWithFilters',
    defaultMessage: 'No result matches your criteria',
  },
  total: {
    id: 'common.total',
    defaultMessage: 'Total',
  },
  score: {
    id: 'common.score',
    defaultMessage: 'Score',
  },
  spr: {
    id: 'common.spr',
    defaultMessage: 'SPR',
  },
  weight: {
    id: 'common.weight',
    defaultMessage: 'Weight',
  },
  'status.goals_prepared': {
    id: 'common.status.goals_prepared',
    defaultMessage: 'Goals Prepared',
  },
  'status.goals_approved': {
    id: 'common.status.goals_approved',
    defaultMessage: 'Goals Approved',
  },
  'status.feedback_collected': {
    id: 'common.feedback_collected',
    defaultMessage: 'Feedback Collected',
  },
  'status.feedback_requested': {
    id: 'common.feedback_requested',
    defaultMessage: 'Feedback Requested',
  },
  'status.ready_for_review': {
    id: 'common.ready_for_review',
    defaultMessage: 'Ready for Review',
  },
  'status.active': {
    id: 'common.active',
    defaultMessage: 'Active',
  },
  'status.completed': {
    id: 'common.completed',
    defaultMessage: 'Completed',
  },
  'status.submitted': {
    id: 'common.status.submitted',
    defaultMessage: 'Submitted',
  },
  'status.finalized': {
    id: 'common.status.finalized',
    defaultMessage: 'Finalized',
  },
  'status.deleted': {
    id: 'common.status.deleted',
    defaultMessage: 'Deleted',
  },
  listOfRecipients: {
    id: 'common.listOfRecipients',
    defaultMessage: 'List of recipients',
  },
  addPeople: {
    id: 'common.addPeople',
    defaultMessage: 'Add people',
  },
  confirm: {
    id: 'common.confirm',
    defaultMessage: 'Confirm',
  },
  subgoals: {
    id: 'common.subgoals',
    defaultMessage: 'Subgoals',
  },
  noDescription: {
    id: 'common.noDescription',
    defaultMessage: 'No description',
  },
  feedback: {
    id: 'common.feedback',
    defaultMessage: 'Feedback',
  },
  selfAssessment: {
    id: 'common.selfAssessment',
    defaultMessage: 'Self-assessment',
  },
  selfAssessmentScore: {
    id: 'common.selfAssessmentScore',
    defaultMessage: 'Self Assessment Score',
  },
  today: {
    id: 'common.today',
    defaultMessage: 'Today',
  },
  refreshPage: {
    id: 'common.refreshPage',
    defaultMessage: 'Refresh the Page',
  },
  warning: {
    id: 'common.warning',
    defaultMessage: 'Warning',
  },
  close: {
    id: 'common.close',
    defaultMessage: 'Close',
  },
  copyToClipboard: {
    id: 'common.copyToClipboard',
    defaultMessage: 'Click to copy to clipboard',
  },
  home: {
    id: 'common.home',
    defaultMessage: 'Home',
  },
  unsavedChangesPrompt: {
    id: 'common.unsavedChangesPrompt',
    defaultMessage:
      'Are you sure you want to leave this page? You have unsaved data, and it will be lost.',
  },
  searchEmployee: {
    id: 'common.searchEmployee',
    defaultMessage: 'Search Employee',
  },
  comments: {
    id: 'common.comments',
    defaultMessage: 'Comments',
  },
  comment: {
    id: 'common.comment',
    defaultMessage: 'Comment',
  },
  position: {
    id: 'common.position',
    defaultMessage: 'Position',
  },
  careerMentor: {
    id: 'common.careerMentor',
    defaultMessage: 'Career Mentor',
  },
  back: {
    id: 'common.back',
    defaultMessage: 'Back',
  },
  changesSavedSuccessfully: {
    id: 'common.changesSavedSuccessfully',
    defaultMessage: 'Changes saved successfully',
  },
  goalDescription: {
    id: 'common.goalDescription',
    defaultMessage: 'Goal description',
  },
  scorecardStatus: {
    id: 'common.scorecardStatus',
    defaultMessage: 'Scorecard status',
  },
  enabled: {
    id: 'common.enabled',
    defaultMessage: 'Enabled',
  },
  disabled: {
    id: 'common.disabled',
    defaultMessage: 'Disabled',
  },
  notDefined: {
    id: 'common.notDefined',
    defaultMessage: 'Not defined',
  },
  reviewCycleShort: {
    id: 'common.reviewCycleShort',
    defaultMessage: 'RC',
  },
  allStarLocations: {
    id: 'common.allStarLocations',
    defaultMessage: 'All Star Locations',
  },
  allStarDepartments: {
    id: 'common.allStarDepartments',
    defaultMessage: 'All Star Departments',
  },
  history: {
    id: 'common.history',
    defaultMessage: 'History',
  },
  openChangelog: {
    id: 'pages.scorecards.openChangelog',
    defaultMessage: 'Open Change Log',
  },
  viewMode: {
    id: 'common.viewMode',
    defaultMessage: 'View Mode',
  },
  restore: {
    id: 'common.restore',
    defaultMessage: 'Restore',
  },
  logOut: {
    id: 'common.logOut',
    defaultMessage: 'Log Out',
  },
  decline: {
    id: 'common.decline',
    defaultMessage: 'Decline',
  },
  accept: {
    id: 'common.accept',
    defaultMessage: 'Accept',
  },
  due: {
    id: 'common.due',
    defaultMessage: 'Due',
  },
  subscribe: {
    id: 'common.subscribe',
    defaultMessage: 'Subscribe',
  },
  unsubscribe: {
    id: 'common.unsubscribe',
    defaultMessage: 'Unsubscribe',
  },
  editedAt: {
    id: 'common.editedAt',
    defaultMessage: 'Edited at',
  },
  rejected: {
    id: 'common.rejected',
    defaultMessage: 'Rejected',
  },
});
