import { IntlFormatters } from 'react-intl';
import { formTexts } from 'i18n';
import {
  CycleFormFields,
  TemplateFormFields,
} from 'pages/ReviewCycles/interfaces';
import { CycleTemplate, CycleTemplateDTO } from 'store/reviewCycles/interfaces';
import {
  ALLOW_SCORECARD_OWNER_EDITS_PARAMETER_TYPE,
  CALCULATE_SELF_ASSESSMENT_PARAMETER_TYPE,
  ALLOW_SCORECARD_COMMENT_PARAMETER_TYPE,
  ENABLE_SELF_ASSESSMENT_PARAMETER_TYPE,
} from 'constants/scorecards';
import { SelfAssessmentSetups, isCuSatGoal, parseParams } from 'helpers';

const weightRegex = /^\d{1,2}%?$/;

export const validateWeight = (
  value: string | number,
  formatMessage: IntlFormatters['formatMessage'],
) => {
  if (typeof value === 'string') {
    const trimmed = (value || '').trim();
    if (!trimmed) {
      return formatMessage(formTexts.errorRequired);
    }
    if (trimmed !== '100%' && trimmed !== '100' && !weightRegex.test(trimmed)) {
      return formatMessage(formTexts.errorPattern);
    }
  } else if (Number.isNaN(value) || value > 100 || value < 0) {
    return formatMessage(formTexts.errorPattern);
  }

  return true;
};

export const validateTotalWeight = (value: string | number | undefined) => {
  const parsedValue = value ? parseInt(value as string, 10) || 0 : 0;
  return parsedValue !== 100 ? 'error' : true;
};

export const defaultCycleValues: CycleFormFields = {
  name: '',
  endAt: null,
  startAt: null,
  templateIds: [],
  cycleId: '',
};

export function formatCycleData(data: CycleFormFields, isEdit: boolean) {
  if (isEdit) {
    const { id, name, startAt, endAt } = data;
    return { id, name, startAt, endAt };
  }
  const { cycleId, ...result } = data;
  return result;
}

export function formatGoalsData(
  goals: TemplateFormFields['goals'],
  isCommonGoals: boolean,
) {
  return goals.map((goal, goalIndex) => {
    const isCuSat = isCuSatGoal(goal);
    const { weight: goalWeight } = goal;
    if (isCuSat) {
      const { id, fromGoalTemplateId } = goal;
      return {
        orderRank: goalIndex + 1,
        weight:
          typeof goalWeight === 'string'
            ? parseInt(goalWeight, 10)
            : goalWeight,
        id,
        fromGoalTemplateId,
      };
    }
    const {
      name,
      description,
      subgoalsWeight,
      subgoals,
      type,
      allowScorecardOwnerEdits,
      allowScorecardComment,
      selfAssessmentSetup,
      ...goalFields
    } = goal;
    return {
      ...goalFields,
      name: name?.trim(),
      description: description?.trim(),
      weight: isCommonGoals
        ? undefined
        : Number.parseInt(goalWeight as string, 10),
      orderRank: goalIndex + 1,
      params: [
        {
          alias: ALLOW_SCORECARD_OWNER_EDITS_PARAMETER_TYPE,
          value: allowScorecardOwnerEdits,
        },
        {
          alias: CALCULATE_SELF_ASSESSMENT_PARAMETER_TYPE,
          value: selfAssessmentSetup === SelfAssessmentSetups.calculate,
        },
        {
          alias: ALLOW_SCORECARD_COMMENT_PARAMETER_TYPE,
          value: allowScorecardComment,
        },
        {
          alias: ENABLE_SELF_ASSESSMENT_PARAMETER_TYPE,
          value:
            selfAssessmentSetup === SelfAssessmentSetups.request ||
            selfAssessmentSetup === SelfAssessmentSetups.calculate,
        },
      ],
      subgoals: subgoals.map(
        (
          {
            params,
            type: subGoalType,
            weight: subgoalWeight = 0,
            name: subgoalName,
            description: subgoalDescription,
            ...subgoalFields
          },
          subgoalIndex,
        ) => ({
          ...subgoalFields,
          assessmentScaleGroup: undefined,
          name: subgoalName?.trim(),
          description: subgoalDescription?.trim(),
          orderRank: subgoalIndex + 1,
          weight: Number.parseInt(subgoalWeight as string, 10),
        }),
      ),
    };
  }) as CycleTemplateDTO['goals'];
}

export function formatTemplateData({
  name: templateName,
  goals,
  totalWeight,
  entity,
  ...templateFields
}: TemplateFormFields): CycleTemplateDTO {
  return {
    ...templateFields,
    name: templateName?.trim(),
    goals: formatGoalsData(goals, false),
  };
}

export function parseTemplateData({
  countries,
  unit,
  goals,
  params: scorecardParams,
  ...templateData
}: CycleTemplate): TemplateFormFields {
  return {
    ...templateData,
    countryIds: countries.map(({ id }) => id),
    unitId: unit.id,
    goals: goals.map(({ subgoals, description, params, ...goalFields }) => {
      const {
        allowScorecardOwnerEdits,
        allowScorecardComment,
        selfAssessmentSetup,
      } = parseParams(params);
      return {
        ...goalFields,
        allowScorecardOwnerEdits: allowScorecardOwnerEdits ?? false,
        allowScorecardComment: allowScorecardComment ?? false,
        selfAssessmentSetup,
        description: description ?? undefined,
        subgoals: subgoals.map(
          ({
            assessmentScaleGroup,
            params: subgoalParams,
            ...subgoalFields
          }) => ({
            ...subgoalFields,
            assessmentScaleGroupId: assessmentScaleGroup?.id || '',
          }),
        ),
      };
    }),
  };
}
