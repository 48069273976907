import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { OKR_TEAM_OVERVIEW_USERS } from 'constants/api';
import { generateURL } from 'helpers';
import qs from 'query-string';
import { OkrUserRecordsResponse } from 'store/okrMyTeam';
import { RootState } from 'store';
import { storeName } from '../config';

export const getUserList = createAsyncThunk<
  OkrUserRecordsResponse,
  {
    page: number;
    limit: number;
  }
>(`${storeName}/getUserList`, async ({ page, limit }, thunkAPI) => {
  const {
    okrTeamOverview: { filters },
  } = thunkAPI.getState() as RootState;
  const url = `${generateURL(OKR_TEAM_OVERVIEW_USERS, {
    periodId: filters.reportingPeriodId as string,
  })}?${qs.stringify(
    {
      page,
      limit,
      ...filters,
      reportingPeriodId: null,
    },
    { skipNull: true, skipEmptyString: true },
  )}`;
  return api.axios
    .get<OkrUserRecordsResponse>(url)
    .then(({ data }) => data)
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data));
});
