import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash-es';
import { resetState } from './actions';

export default function addCommonCases<T>(
  builder: ActionReducerMapBuilder<T>,
  initialState: T,
  additionalAction?: () => void,
) {
  return builder.addCase(resetState, (state) => {
    Object.assign(state, cloneDeep(initialState));
    if (typeof additionalAction === 'function') {
      additionalAction();
    }
  });
}
