import { commonTexts } from 'i18n';

interface OwnProps {
  check: boolean;
  func: () => void;
}

export default function withUnsavedConfirm({ check, func }: OwnProps) {
  if (check) {
    // eslint-disable-next-line no-alert
    if (window.confirm(commonTexts.unsavedChangesPrompt.defaultMessage)) {
      func();
    }
  } else {
    func();
  }
}
