import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { formTexts } from 'i18n';
import { getLengthOfInvisibleChars } from 'helpers';

export default function useNotEmptyValidation() {
  const { formatMessage } = useIntl();

  return useCallback(
    (value = '') => {
      if (typeof value === 'number') return true;

      const lengthOfInvisibleChars = getLengthOfInvisibleChars(value);
      const onlyInvisibleCharsInString =
        lengthOfInvisibleChars === value.length;
      const isValid = value.trim() !== '' && !onlyInvisibleCharsInString;

      return isValid || formatMessage(formTexts.errorRequired);
    },
    [formatMessage],
  );
}
