import { getObjectiveDefaultFields, transformObjectiveResponse } from 'helpers';
import { ObjectiveFormFields } from 'pages/ObjectiveDetails/interfaces';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'store';
import { OkrObjectiveResponse, selectObjectiveById } from 'store/okrObjectives';
import useStaticOptions from './useOkrStaticOptions';

export default function useOkrObjectiveDefaultValues(
  id?: string,
  reportingPeriodEndDate?: string,
) {
  const { reportingPeriod } = useParams();
  const { visibilityOptions, statusOptions } = useStaticOptions();
  const objectiveData: OkrObjectiveResponse | null = useSelector((state) =>
    selectObjectiveById(state as RootState, Number(id)),
  );
  const defaultEmptyValues = useMemo(
    () => getObjectiveDefaultFields(reportingPeriodEndDate),
    [reportingPeriodEndDate],
  );
  const defaultValues: ObjectiveFormFields = useMemo(() => {
    if (!objectiveData) {
      return defaultEmptyValues;
    }

    return transformObjectiveResponse(
      objectiveData,
      reportingPeriod as string,
      visibilityOptions,
      statusOptions,
    );
  }, [
    defaultEmptyValues,
    objectiveData,
    reportingPeriod,
    statusOptions,
    visibilityOptions,
  ]);

  return defaultValues;
}
