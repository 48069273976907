/* eslint-disable no-underscore-dangle */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  Scorecard,
  ScorecardAssessmentAction,
  ScorecardStores,
  ScorecardsActionStoreArgument,
} from 'store/interfaces';
import api from 'api';
import { API_SCORECARD_APPLY } from 'constants/api';
import { dayjs, generateURL } from 'helpers';
import { showOLDialog } from 'store/optimisticLocking';
import { RootState } from 'store';
import { updateFullData } from './updateFullData';
import { selectListData } from '../selectors';

export const changeStatus = createAsyncThunk<
  Scorecard,
  {
    id: number;
    store: ScorecardsActionStoreArgument;
    action: ScorecardAssessmentAction;
  }
>('scorecards/changeStatus', async ({ id, action, store }, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;

  const scorecardData = selectListData(store as ScorecardStores, id)(state);
  const expectedVersion = scorecardData?.version;
  return api.axios
    .patch<Scorecard>(generateURL(API_SCORECARD_APPLY, { id }), {
      action,
      expectedVersion,
    })
    .then(({ data }) => {
      thunkAPI.dispatch(updateFullData({ id, store }));
      return data;
    })
    .catch((e) => {
      // optimistic locking error handling
      if (e.response?.status === 412) {
        thunkAPI.dispatch(
          showOLDialog({
            type: 'scorecard',
            params: {
              name: e.response?.data?.__meta?.lastActivity?.actor.fullName,
              date: dayjs(
                e.response?.data?.__meta?.lastActivity?.doneAt,
              ).format('HH:mm DD.MM.YYYY'),
            },
          }),
        );
      }
      return thunkAPI.rejectWithValue(e.response?.data);
    });
});
