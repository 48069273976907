import { Theme } from '@mui/material';
import { TooltipComponentsPropsOverrides } from '@mui/material/Tooltip';

declare module '@mui/material/Tooltip' {
  interface TooltipComponentsPropsOverrides {
    variant?: 'dark' | 'light';
  }
}

export default {
  defaultProps: {
    arrow: true,
  },
  styleOverrides: {
    tooltip({
      theme,
      variant = 'dark',
    }: {
      theme: Theme;
    } & TooltipComponentsPropsOverrides) {
      const styles = {
        ...theme.typography.body2,
        padding: '8px 12px',
        ul: {
          marginBlockStart: 16,
          marginBlockEnd: 16,
          marginInlineStart: 0,
          marginInlineEnd: 0,
          paddingInlineStart: 24,
        },
      };
      if (variant === 'dark') {
        Object.assign(styles, { backgroundColor: theme.palette.grey.A500 });
      }
      if (variant === 'light') {
        Object.assign(styles, {
          backgroundColor: theme.palette.common.white,
          color: theme.palette.common.black,
          boxShadow: theme.shadows[26],
        });
      }
      return styles;
    },
    arrow({
      theme,
      variant = 'dark',
    }: {
      theme: Theme;
    } & TooltipComponentsPropsOverrides) {
      return {
        color:
          variant === 'light'
            ? theme.palette.common.white
            : theme.palette.grey.A500,
      };
    },
  },
};
