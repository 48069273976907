export enum UserMgmtRoleAlias {
  employee = 'employee',
  peoplePartner = 'people-partner',
  revopsManager = 'revops-manager',
  director = 'director',
  careerMentor = 'career-mentor',
  technicalAdmin = 'technical-admin',
  scorecardTemplateEditor = 'scorecard-template-editor',
  careerRoadmapEditor = 'career-roadmap-editor',
  admin = 'admin',
  departmentManager = 'department-manager',
}
