import ContentLoader from 'components/ContentLoader';
import { LoaderHolder } from './ConfirmationDialog.styled';

export default function Loader() {
  return (
    <LoaderHolder>
      <ContentLoader />
    </LoaderHolder>
  );
}
