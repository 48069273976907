import { LogoProps } from '../interfaces';

export default function LogoOkrsIcon({ textColor, width = 62 }: LogoProps) {
  return (
    <svg width={width} viewBox="0 0 62 18" fill="none">
      <path
        d="M0.224 9.008C0.224 4.28 3.416 0.727999 8.168 0.727999C12.896 0.727999 16.112 4.28 16.112 9.008C16.112 13.736 12.896 17.288 8.168 17.288C3.416 17.288 0.224 13.736 0.224 9.008ZM14.048 9.008C14.048 5.264 11.744 2.504 8.168 2.504C4.568 2.504 2.288 5.264 2.288 9.008C2.288 12.728 4.568 15.512 8.168 15.512C11.744 15.512 14.048 12.728 14.048 9.008ZM28.8956 17L22.8236 9.8L21.2156 11.624V17H19.2236V0.991999H21.2156V9.272L28.2956 0.991999H30.7916L24.1196 8.6L31.3676 17H28.8956ZM42.9016 17L38.8216 10.64H35.6296V17H33.6376V0.991999H40.0696C42.9976 0.991999 45.0856 2.864 45.0856 5.816C45.0856 8.696 43.1176 10.28 40.9336 10.472L45.2536 17H42.9016ZM43.0216 5.816C43.0216 4.016 41.7256 2.768 39.8296 2.768H35.6296V8.888H39.8296C41.7256 8.888 43.0216 7.616 43.0216 5.816ZM47.0186 15.464L47.9546 14.168C48.7226 15.056 50.1866 15.824 51.7706 15.824C53.5466 15.824 54.5066 15.032 54.5066 13.904C54.5066 12.632 53.0906 12.224 51.5306 11.84C49.5626 11.384 47.3306 10.856 47.3306 8.456C47.3306 6.656 48.8906 5.12 51.6506 5.12C53.6186 5.12 55.0106 5.864 55.9466 6.8L55.1066 8.048C54.4106 7.208 53.1386 6.584 51.6506 6.584C50.0666 6.584 49.0826 7.304 49.0826 8.336C49.0826 9.464 50.4026 9.824 51.9146 10.184C53.9306 10.64 56.2586 11.216 56.2586 13.784C56.2586 15.728 54.6986 17.288 51.6986 17.288C49.8026 17.288 48.1946 16.712 47.0186 15.464Z"
        fill={textColor}
      />
    </svg>
  );
}
