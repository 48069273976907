import { ReactNode, SyntheticEvent, useMemo } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select as MUISelect,
  useFormControl,
} from '@mui/material';
import classNames from 'classnames';
import { CloseIcon } from 'components/Icons';
import { renderOptions } from 'helpers';
import { SelectOption, SelectProps } from './interfaces';
import { Chip, ValueHolder, SelectPlaceholder } from './Select.styled';

// After Chrome's update the select didn't fire the onBlur event on FormControlContext.
// To fix this we fire this event on select onClose
function SelectWrapper<P extends SelectOption, T = unknown>({
  children,
  ...props
}: SelectProps<P, T>) {
  const formControl = useFormControl();
  return (
    <MUISelect {...props} onClose={() => formControl?.onBlur?.({} as any)}>
      {children}
    </MUISelect>
  );
}

export default function Select<P extends SelectOption, T = unknown>({
  MenuProps = {},
  inputProps = {},
  error,
  children,
  helperText,
  size = 'medium',
  options = [],
  groupBy,
  renderValue,
  placeholder,
  fullWidth,
  label,
  required,
  clearValue,
  ...props
}: SelectProps<P, T>) {
  const { value, multiple, variant = 'outlined' } = props;
  const defaultRenderValue = useMemo(() => {
    if (multiple) {
      return function renderMultipleSelect(selected: T): ReactNode {
        const arr = Array.isArray(selected) ? selected : [];
        return (
          <ValueHolder>
            {arr.length > 0 && (
              <Chip
                label={arr.length}
                onMouseDown={(e: SyntheticEvent) => e.stopPropagation()}
                onDelete={clearValue}
                deleteIcon={<CloseIcon />}
              />
            )}
            {label}
          </ValueHolder>
        );
      };
    }

    if (!value && placeholder) {
      return function renderPlaceholder(): ReactNode {
        return <SelectPlaceholder>{placeholder}</SelectPlaceholder>;
      };
    }

    const item = options.find(({ id }) => id === value);

    if (item?.tooltipWithIcon) {
      return function renderForTooltipWithIcon(): ReactNode {
        return item.name;
      };
    }

    return undefined;
  }, [multiple, value, placeholder, options, clearValue, label]);

  if (!options.length) {
    return null;
  }
  const { MenuListProps = {} } = MenuProps;
  const className = classNames(MenuProps.className, `SelectMenuSize-${size}`);

  const inputClassName = classNames(inputProps.className, `inputSize-${size}`);
  return (
    <FormControl error={error} fullWidth={fullWidth}>
      {label ? (
        <InputLabel shrink variant={variant} required={required}>
          {label}
        </InputLabel>
      ) : null}
      <SelectWrapper
        {...props}
        MenuProps={{
          ...MenuProps,
          MenuListProps: {
            ...MenuListProps,
            disablePadding: true,
          },
          className,
        }}
        inputProps={{ ...inputProps, className: inputClassName }}
        renderValue={renderValue || defaultRenderValue}
        displayEmpty={props.displayEmpty || multiple}
      >
        {renderOptions(options, value, multiple, groupBy)}
      </SelectWrapper>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
