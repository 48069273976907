import { UIOption } from 'store/options/interfaces/UIOption';
import palette from '../palette';

declare module '@mui/material/Autocomplete' {
  interface AutocompletePropsSizeOverrides {
    large: true;
  }
}

export default {
  defaultProps: {
    disablePortal: true,
    disableClearable: true,
    blurOnSelect: true,
    getOptionLabel: (option: UIOption | string) =>
      typeof option === 'string' ? option : option.name,
    isOptionEqualToValue: (option: UIOption, value: '' | number) =>
      option.id === value,
  },
  styleOverrides: {
    root: {
      '.MuiOutlinedInput-root': {
        padding: 0,
        background: palette.common?.white,
      },
      '.MuiAutocomplete-endAdornment': {
        top: 'calc(50% - 10px)',
        '.MuiIconButton-root': {
          padding: 0,
          float: 'left',
        },
        '.MuiSvgIcon-root': {
          fontSize: '1.375rem',
        },
      },
      '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon ': {
        ' .MuiFormControl-root': {
          paddingRight: 0,
        },
      },
    },
    hasPopupIcon: {
      '.MuiFormControl-root.MuiAutocomplete-inputRoot': {
        paddingRight: 0,
      },
    },
  },
};
