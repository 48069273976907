import { useCallback, useState } from 'react';
import qs from 'query-string';
import { WorkRecord } from 'store/interfaces';
import api from 'api';
import { API_SCORECARD_TEMPLATE_AVAIL_RECORDS } from 'constants/api';
import { generateURL } from 'helpers';
import useToast from 'hooks/useToast';
import useActionPipeline, {
  CatchCallback,
  SuccessCallback,
} from 'hooks/useActionPipeline';
import { useIntl } from 'react-intl';
import { commonTexts } from 'i18n';
import { publishTemplate } from 'store/reviewCycles';
import { sortUsers } from 'helpers/users';

export default function useAddRecipientsValues(
  id: number,
  onClose: () => void,
  name?: string,
) {
  const [selectedUsers, setSelectedUsers] = useState<WorkRecord[]>([]);
  const { formatMessage } = useIntl();
  const [errors, setErrors] = useState<string[]>([]);

  const onSearch = useCallback(
    (query: string, selected: WorkRecord[]) =>
      api.axios
        .get<{
          list: { wr: WorkRecord; available: boolean }[];
        }>(
          `${generateURL(API_SCORECARD_TEMPLATE_AVAIL_RECORDS, {
            id,
          })}?${qs.stringify({
            limit: 100,
            page: 1,
            userSearchName: query,
          })}`,
        )
        .then((res) =>
          res.data.list
            .map(({ wr, available }) => ({
              ...wr,
              available,
            }))
            .filter(({ id: userId }) => !selected.find((u) => u.id === userId)),
        ),
    [id],
  );
  const addUser = useCallback(
    (wr: WorkRecord) => {
      setSelectedUsers([...selectedUsers, wr]);
    },
    [selectedUsers],
  );
  const removeUser = useCallback(
    (userId?: number) => {
      setSelectedUsers(selectedUsers.filter((u) => u.id !== userId));
    },
    [selectedUsers],
  );

  const showMessage = useToast();
  const onPublishSuccess = useCallback<SuccessCallback>(() => {
    showMessage({
      severity: 'success',
      message: formatMessage(commonTexts.published, {
        templateName: name,
      }),
    });
    onClose();
  }, [formatMessage, name, onClose, showMessage]);
  const onPublishCatch = useCallback<CatchCallback>(
    ({ payload }) => {
      const { errors: resErrors } = payload || {};
      if (resErrors) {
        if (resErrors.workRecords) {
          const userNames = Object.keys(resErrors.workRecords).reduce(
            (users: string[], key) => {
              const {
                user: { fullName },
                unit,
                location,
              } = selectedUsers[Number(key)];
              return [
                ...users,
                `${fullName}, ${location?.country?.name}, ${unit.name}`,
              ];
            },
            [],
          );
          showMessage({
            severity: 'error',
            message: {
              ...commonTexts.alreadyPublishedForUser,
              values: {
                userNames: (
                  <ul>
                    {userNames.map((userLine) => (
                      <li>{userLine}</li>
                    ))}
                  </ul>
                ),
              },
            },
            autoHideDuration: null,
          });
          return null;
        }
        setErrors(
          Object.values(resErrors).reduce((acc: string[], obj) => {
            acc.push(...Object.values(obj));
            return acc;
          }, []),
        );
      }

      return null;
    },
    [selectedUsers, showMessage],
  );
  const actionPipeline = useActionPipeline(onPublishSuccess, onPublishCatch);
  const onPublish = useCallback(() => {
    actionPipeline(
      publishTemplate({
        templateId: id,
        workRecords: selectedUsers.map((u) => ({ id: u.id })),
      }),
    );
  }, [actionPipeline, id, selectedUsers]);

  return {
    recipients: sortUsers(selectedUsers),
    submitDisabled: !selectedUsers.length,
    onPublish,
    onSearch,
    addUser,
    removeUser,
    total: selectedUsers.length,
    errors,
    loading: false,
  };
}
