import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { HEADER_HEIGHT } from 'components/Header';

export const Layout = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  paddingTop: HEADER_HEIGHT,
});
