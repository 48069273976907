import { TextFieldProps } from '@mui/material';
import TextField from 'components/TextField';
import { ChangeEvent, KeyboardEvent, useCallback } from 'react';
import { Wrapper } from './GrowingTextField.styled';

export default function GrowingTextField({
  inputProps = {},
  onChange = () => {},
  ...props
}: TextFieldProps) {
  const onKeyDownHandler = useCallback((e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }, []);
  const replaceNewLines = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const {
        target: { value },
      } = e;
      const newValue = value.replaceAll('\n', ' ');
      e.target.value = newValue;
      onChange(e);
    },
    [onChange],
  );

  return (
    <Wrapper>
      <TextField
        {...props}
        inputProps={inputProps}
        onKeyDown={onKeyDownHandler}
        type="text"
        multiline
        onChange={replaceNewLines}
        hideCounter
      />
    </Wrapper>
  );
}
