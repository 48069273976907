import type { Node } from 'reactflow';
import type { CanvasData, NodeData } from 'store/interfaces';
import { NodeTypesEnum, SeniorityType } from 'constants/roadmap';
import { cloneDeep, isString, omit, pick } from 'lodash-es';
import EdgeNormalizer from './edgeNormalizer';

export default class NodeNormalizer {
  static readonly NODE_FIELDS: string[] = [
    'id',
    'type',
    'data',
    'width',
    'style',
    'height',
    'zIndex',
    'position',
    'positionAbsolute',
    'parentNode',
    'expandParent',
    'sourcePosition',
    'targetPosition',
    'dragHandle',
  ];

  private node: Node<NodeData>;

  constructor(node: Node<NodeData>) {
    this.node = cloneDeep(node);
  }

  deleteUnusedData = (): void => {
    this.node = pick(this.node, NodeNormalizer.NODE_FIELDS) as Node<NodeData>;
    this.node.dragHandle = `.handler-${this.node.id}`;

    if (!this.node.expandParent) {
      delete this.node.parentNode;
    }
  };

  normalizeGroupNode = (): void => {
    this.node.zIndex = 0;
    this.node = omit(this.node, ['parentNode', 'expandParent']);
  };

  normalizeRootNode = (): void => {
    this.node.zIndex = 4;
    this.node = omit(this.node, 'style');
  };

  normalizeSkillNode = (): void => {
    this.node.zIndex = 4;
    this.node = omit(this.node, 'style');
  };

  static normalize(node: Node<NodeData>): Node<NodeData> {
    const normalizer = new NodeNormalizer(node);
    normalizer.deleteUnusedData();

    switch (normalizer.node.type) {
      case NodeTypesEnum.ROOT:
        normalizer.normalizeRootNode();
        break;

      case NodeTypesEnum.SKILL:
        normalizer.normalizeSkillNode();
        break;

      case NodeTypesEnum.GROUP:
        normalizer.normalizeGroupNode();
        break;

      default:
        throw new Error('Unknown node type');
    }

    return normalizer.node;
  }

  static normalizeCanvasState({ nodes, edges }: CanvasData): CanvasData {
    return {
      nodes: nodes.map(NodeNormalizer.normalize),
      edges: EdgeNormalizer.reduce(edges),
    };
  }

  static validateNodes(nodes: Node<NodeData>[]): Node<NodeData>[] {
    const parentNodes = new Set<string>();

    nodes.forEach((item) => {
      if (item.type === NodeTypesEnum.GROUP) {
        parentNodes.add(item.id);
      }
    });

    return nodes.map<Node<NodeData>>((node) => {
      let copy = cloneDeep(node);
      if (copy.parentNode && !parentNodes.has(copy.parentNode)) {
        copy = omit(node, ['parentNode', 'expandParent']);
      }

      // TODO: Migration to static seniority levels. Delete this after we migrate production schema.
      if (isString(copy.data.resources?.[0])) {
        copy.data.resources = copy.data.resources?.map((resource) => ({
          title: resource as unknown as string,
          link: resource as unknown as string,
        }));
      }

      // TODO: Migration to static seniority levels. Delete this after we migrate production schema.
      if (Array.isArray(copy.data.seniority)) {
        return {
          ...copy,
          data: { ...copy.data, seniority: SeniorityType.Trainee },
        };
      }

      return copy;
    });
  }
}
