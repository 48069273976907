import { useAppDispatch } from 'store';
import { useCallback, useEffect } from 'react';
import { showToast } from 'store/toast';
import { errorTexts } from 'i18n';
import { formatErrorDescription, isStackFromExtension } from 'helpers/errors';
import { AlertColor } from '@mui/material';

export default function useErrorHandler() {
  const dispatch = useAppDispatch();

  const handleError = useCallback(
    ({ code, description }: { code: string; description: string }) => {
      const messageConfig = {
        severity: 'error' as AlertColor,
        autoHideDuration: null,
        message: {
          ...errorTexts.somethingWentWrongContactSupport,
          values: {
            code,
            description,
          },
        },
      };
      if (!navigator.onLine) {
        Object.assign(messageConfig.message, errorTexts.noInternet);
      }
      dispatch(showToast(messageConfig));
    },
    [dispatch],
  );

  useEffect(() => {
    const unhandledRejectionListener = (event: PromiseRejectionEvent) => {
      if (isStackFromExtension(event.reason?.stack)) return;

      if (event.reason?.name === 'AxiosError') {
        const { data, status } = event.reason?.response || {};
        const { response, description } = data;
        if (response) {
          const { statusCode, message } = response;
          handleError({
            code: statusCode,
            description: message,
          });
        } else {
          handleError({
            code: status,
            description,
          });
        }
      } else {
        handleError({
          code: event.type,
          description: formatErrorDescription(event.reason),
        });
      }
    };
    window.addEventListener('unhandledrejection', unhandledRejectionListener);

    const unhandledErrorListener = (event: ErrorEvent) => {
      // In some cases Safari returns null for event.error and
      // event.filename is empty if error is from an extension
      if (isStackFromExtension(event.error?.stack) || event.filename === '')
        return;

      handleError({
        code: '-',
        description: formatErrorDescription(event),
      });
    };
    window.addEventListener('error', unhandledErrorListener);

    return () => {
      window.removeEventListener(
        'unhandledrejection',
        unhandledRejectionListener,
      );
      window.removeEventListener('error', unhandledErrorListener);
    };
  }, [handleError]);
}
