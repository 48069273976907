import { Theme } from '@mui/material';

export default {
  styleOverrides: {
    root({ theme }: { theme: Theme }) {
      return {
        padding: '7px 0',
        marginRight: 5,
        '&:hover': {
          color: theme.palette.primary.light,
        },
        '&:pressed': {
          color: theme.palette.primary.dark,
        },
        '&.MuiCheckbox-colorError': {
          color: theme.palette.error.main,
        },
        '& .MuiSvgIcon-root': {
          fontSize: '1.125rem',
        },
      };
    },
  },
};
