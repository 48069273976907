import type { CanvasState } from './interfaces';

export const storeName = 'roadmapCanvas';

export const roadmapCanvasInitialState: CanvasState = {
  isLoading: true,
  isUpdating: false,
  currentDepartment: null,
  data: {
    filters: [],
    nodes: [],
    edges: [],
  },
};
