import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useDialogState, useUserPermissions } from 'hooks';
import isNotProdEnv from 'helpers/env/isNotProdEnv';
import { useAppDispatch } from 'store';
import { getMailingSettings, selectMailingSettings } from 'store/profile';
import { HeaderMenuItem } from 'components/Header';
import TechnicalAdminDialog from './MailingSettingsDialog.component';

export default function MailingSettingsButton({
  closeMenu,
}: {
  closeMenu?: () => void;
}) {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { canDevDebug } = useUserPermissions();

  const mailingSettings = useSelector(selectMailingSettings);
  const [isDialogOpen, onItemClick, onDialogClose] = useDialogState();
  const isButtonShown = canDevDebug && isNotProdEnv;

  const onOpen = () => {
    closeMenu?.();
    onItemClick();
  };

  useEffect(() => {
    if (isButtonShown) {
      dispatch(getMailingSettings());
    }
  }, [dispatch, isButtonShown]);

  if (!isButtonShown) {
    return null;
  }

  return (
    <>
      <HeaderMenuItem onClick={onOpen}>
        {formatMessage({
          id: 'technicalAdmin.redirectNotification',
          defaultMessage: 'Redirect Email Notification',
        })}
      </HeaderMenuItem>
      {isDialogOpen && (
        <TechnicalAdminDialog
          email={mailingSettings?.redirectToEmail}
          isEmailEnabled={mailingSettings?.enabled}
          onClose={onDialogClose}
        />
      )}
    </>
  );
}
