import { MenuItem, Typography } from '@mui/material';
import { useCallback } from 'react';
import { ArrowDropDownIcon } from '../Icons';
import { GroupedMenuItemProps, SuperButtonMenuItem } from './interfaces';
import {
  CollapseMenuItem,
  MenuItemSummary,
  MenuItemDetails,
} from './SuperButton.styled';

export default function GroupedMenuItem<T extends SuperButtonMenuItem>({
  name,
  options,
  onMenuItemClick = () => {},
  renderSubItem,
  ...accordionProps
}: GroupedMenuItemProps<T>) {
  const getOptionText = useCallback(
    (option: string | T) => (typeof option === 'string' ? option : option.name),
    [],
  );

  return (
    <CollapseMenuItem {...accordionProps} disableGutters>
      <MenuItemSummary expandIcon={<ArrowDropDownIcon color="inherit" />}>
        <Typography variant="body2">{name}</Typography>
      </MenuItemSummary>
      <MenuItemDetails>
        {options.map((option, index) =>
          renderSubItem ? (
            renderSubItem(option, index)
          ) : (
            <MenuItem
              key={`${name}-${getOptionText(option)}`}
              onClick={() => onMenuItemClick(option, index)}
            >
              {getOptionText(option)}
            </MenuItem>
          ),
        )}
      </MenuItemDetails>
    </CollapseMenuItem>
  );
}
