import type { Node } from 'reactflow';
import type { RootState } from 'store';
import {
  NodeData,
  CanvasFilter,
  CanvasDataWithFilters,
} from 'store/interfaces';
import { CanvasState } from './interfaces';

export const selectIsCanvasLoading = (state: RootState): boolean =>
  state.roadmap.canvas.isLoading;

export const selectIsCanvasUpdating = (state: RootState): boolean =>
  state.roadmap.canvas.isUpdating;

export const selectCanvasData = (state: RootState): CanvasDataWithFilters =>
  state.roadmap.canvas.data;

export const selectCanvasFiltersData = (state: RootState): CanvasFilter[] =>
  state.roadmap.canvas.data.filters ?? [];

export const selectCanvasNodes = (state: RootState): Node<NodeData>[] =>
  state.roadmap.canvas.data.nodes;

export const selectCanvasDepartment = (
  state: RootState,
): CanvasState['currentDepartment'] => state.roadmap.canvas.currentDepartment;
