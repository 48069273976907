import { createSlice, Reducer } from '@reduxjs/toolkit';
import {
  addScorecardsCases,
  addCareerMentorOptionsCases,
} from 'store/scorecards/index';
import { cloneDeep } from 'lodash-es';
import { resetState } from 'store/actions';
import {
  storeName,
  subordinateScorecardInitialState,
  initialFilters,
} from './config';

export * from './interfaces';

const subordinateScorecards = createSlice({
  name: storeName,
  initialState: cloneDeep(subordinateScorecardInitialState),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(resetState, (state) => {
      const newState = cloneDeep(subordinateScorecardInitialState);
      newState.filters = state.filters;
      Object.assign(state, newState);
    });
    addCareerMentorOptionsCases(builder, storeName);
    addScorecardsCases(builder, storeName, cloneDeep(initialFilters));
  },
});

export default subordinateScorecards.reducer as Reducer<
  typeof subordinateScorecardInitialState
>;
