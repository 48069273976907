import { Props } from 'react-intl/src/components/message';
import { ExtraMultiplier } from 'store/interfaces';
import { myTeamTexts } from 'i18n';

export default function getExtraMultiplierTooltip(
  item: ExtraMultiplier,
): Props | null {
  switch (item.value) {
    case 0.7:
      return myTeamTexts.extraMultiplierTooltip07;
    case 0.9:
      return myTeamTexts.extraMultiplierTooltip09;
    case 1.1:
      return myTeamTexts.extraMultiplierTooltip11;
    case 1.3:
      return myTeamTexts.extraMultiplierTooltip13;
    default:
      return null;
  }
}
