import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectFeedbackDialogState, toggleFeedbackDialog } from 'store/profile';
import { useAppDispatch } from 'store';
import { useCallback } from 'react';
import { HeaderMenuItem } from 'components/Header';
import SupportFeedbackDialogComponent from './SupportFeedbackDialog.component';
import { DialogOnClose } from '../Dialog';

export default function SupportFeedbackButton({
  closeMenu,
}: {
  closeMenu?: () => void;
}) {
  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();
  const open = useSelector(selectFeedbackDialogState);
  const onOpen = useCallback(() => {
    closeMenu?.();
    dispatch(toggleFeedbackDialog(true));
  }, [dispatch, closeMenu]);
  const onClose = useCallback<DialogOnClose>(() => {
    dispatch(toggleFeedbackDialog(false));
  }, [dispatch]);

  return (
    <>
      <HeaderMenuItem onClick={onOpen}>
        {formatMessage({
          id: 'supportFeedback.sendFeedback',
          defaultMessage: 'Send Feedback',
        })}
      </HeaderMenuItem>
      {open && <SupportFeedbackDialogComponent onClose={onClose} />}
    </>
  );
}
