import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CycleTemplate,
  DuplicateTemplateResponse,
} from 'store/reviewCycles/interfaces';
import api from 'api';
import { API_SCORECARD_TEMPLATE_DUPLICATE } from 'constants/api';
import { generateURL } from 'helpers';
import { storeName } from '../config';

export const duplicateTemplate = createAsyncThunk<
  CycleTemplate,
  DuplicateTemplateResponse
>(`${storeName}/duplicateTemplate`, async ({ templateId, ...data }, thunkAPI) =>
  api.axios
    .post<CycleTemplate>(
      generateURL(API_SCORECARD_TEMPLATE_DUPLICATE, { id: templateId }),
      data,
    )
    .then((res) => res.data)
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
