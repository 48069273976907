import { createAsyncThunk } from '@reduxjs/toolkit';
import { getIsLoggedIn } from 'helpers';
import { storeName } from '../config';
import { profileRefreshTokens } from './profileRefreshTokens';

export const profileInit = createAsyncThunk(
  `${storeName}/init`,
  async (data, thunkAPI) => {
    const params = new URLSearchParams(window.location.search);
    const hasSession = params.get('session');

    if (!getIsLoggedIn() && !hasSession) {
      return thunkAPI.rejectWithValue(false);
    }

    thunkAPI.dispatch(profileRefreshTokens());
    return true;
  },
);
