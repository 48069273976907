import { ComponentsProps } from '@mui/material/styles/props';
import { CheckIcon, InfoIcon, WarningIcon } from 'components/Icons';

export default {
  defaultProps: {
    variant: 'filled',
    iconMapping: {
      error: <InfoIcon />,
      info: <InfoIcon />,
      success: <CheckIcon />,
      warning: <WarningIcon />,
    },
  } as ComponentsProps['MuiAlert'],
  styleOverrides: {
    root: {
      padding: '8px',
    },
    message: {
      fontSize: '14px',
      lineHeight: '20px',
      padding: 0,
    },
    icon: {
      marginRight: '10px',
      padding: 0,
      '.MuiSvgIcon-root': {
        fontSize: '20px',
      },
    },
  },
};
