import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { OKR_PENDING_KEY_RESULT_ACCEPT } from 'constants/api';
import { generateURL } from 'helpers';
import { storeName } from '../config';

export const acceptOkrPendingKeyResult = createAsyncThunk(
  `${storeName}/acceptOkrPendingKeyResult`,
  ({
    periodId,
    id,
    objectiveId,
  }: {
    periodId: string;
    id: number;
    objectiveId: number;
  }) =>
    api.axios
      .put(generateURL(OKR_PENDING_KEY_RESULT_ACCEPT, { periodId, id }), {
        objectiveId,
      })
      .then((res) => res.data)
      .catch(() => []),
);
