import { styled } from '@mui/material';

export const NodeGroupContent = styled('div')(({ theme }) => ({
  display: 'block',
  width: '100%',
  height: '100%',
  padding: '1rem 1.5rem',
  borderRadius: '5px',
  border: `1px solid ${theme.palette.grey[500]}`,

  '&:hover': {
    border: `1px solid ${theme.palette.common.black}`,
  },
}));
