import { combineReducers } from '@reduxjs/toolkit';

import canvasReducer from './canvas';
import commonReducer from './common';
import departmentsReducer from './departments';

export * from './canvas';
export * from './common';
export * from './departments';

const reducer = combineReducers({
  canvas: canvasReducer,
  common: commonReducer,
  departments: departmentsReducer,
});

export default reducer;
