import { memo } from 'react';
import { Box } from '@mui/system';
import palette from 'styles/theme/palette';
import { Stack, Typography } from '@mui/material';
import { type NodeProps, NodeResizer, useNodeId, useNodes } from 'reactflow';
import { type NodeData } from 'store/interfaces';
import { isEqual } from 'lodash-es';
import { useDispatch } from 'react-redux';
import { openDetailsDrawer } from 'store/roadmap';
import { NodeGroupContent } from './NodeGroup.styled';
import NodeMenu from '../NodeMenu.component';
import Handlers from '../Handlers';
import DragHandler from '../DragHandler.component';

function NodeGroup({
  id,
  data,
  selected,
  sourcePosition,
  targetPosition,
  isConnectable,
}: NodeProps<NodeData>) {
  const dispatch = useDispatch();

  const nodeId = useNodeId() || id;
  const nodes = useNodes<NodeData>();

  const handleOpenDrawer = (): void => {
    const node = nodes.find((item) => item.id === nodeId);
    if (node) {
      dispatch(openDetailsDrawer(node));
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
        cursor: 'pointer',
      }}
    >
      {isConnectable && (
        <>
          <DragHandler />
          <NodeMenu />
          <NodeResizer
            minWidth={200}
            minHeight={100}
            isVisible={selected}
            color={palette.secondary.main}
          />
        </>
      )}

      <NodeGroupContent onClick={handleOpenDrawer}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography sx={{ textAlign: 'left' }} variant="subtitle1">
            {data.title}
          </Typography>
        </Stack>
      </NodeGroupContent>

      <Handlers
        edges={data.edges}
        sourcePosition={sourcePosition}
        targetPosition={targetPosition}
        isConnectable={isConnectable}
      />
    </Box>
  );
}

NodeGroup.displayName = 'NodeGroup';

export default memo(
  NodeGroup,
  (prev, next) =>
    isEqual(prev.data, next.data) &&
    prev.targetPosition === next.targetPosition &&
    prev.isConnectable === next.isConnectable &&
    prev.sourcePosition === next.sourcePosition &&
    prev.selected === next.selected,
);
