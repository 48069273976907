import { Box, styled } from '@mui/material';

export const EmptyStateWrapper = styled(Box)(({ theme }) => ({
  flex: '1 1 auto',
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '4px',
  padding: theme.spacing(4),
  marginBottom: '45px',
  '.MuiAccordionDetails-root &': {
    marginBottom: 0,
  },
}));

export const EmptyStateItemWrapper = styled(EmptyStateWrapper)(({ theme }) => ({
  borderRadius: 0,
  padding: theme.spacing(1),
  marginBottom: 0,
}));

export const EmptyStateContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '.MuiButton-root': {
    marginTop: '30px',
  },
});

export const EmptyStateImage = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey['300'],
  width: '176px',
  height: '176px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(2),
  '.MuiSvgIcon-root': {
    fontSize: '48px',
    color: theme.palette.grey['500'],
  },
}));

export const EmptyStateImageSmall = styled(EmptyStateImage)(({ theme }) => ({
  width: '59px',
  height: '59px',
  borderRadius: '50%',
  marginBottom: theme.spacing(1),
}));

export const EmptyStateImageMedium = styled(EmptyStateImage)(({ theme }) => ({
  width: '100px',
  height: '100px',
  marginBottom: theme.spacing(1.5),
  '.MuiSvgIcon-root': {
    fontSize: '24px',
  },
}));
