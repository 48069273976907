import { useMemo } from 'react';
import { useScorecardData } from 'hooks';
import { GoalFeedback } from 'store/interfaces';

const compareFeedback = (a: GoalFeedback, b: GoalFeedback): number =>
  new Date(b.feedbackGoalHeader.createdAt).getTime() -
  new Date(a.feedbackGoalHeader.createdAt).getTime();

export default function useGoalFeedbacksFromScorecard(
  goalId: number,
  subgoalId: number,
  excludeFeedbackWithEmptyComment: boolean = false,
): {
  goalFeedbacks: GoalFeedback[];
  subgoalFeedbacks: GoalFeedback[];
} {
  const { feedbacks: scorecardFeedbacks, workRecord } = useScorecardData();

  return useMemo(() => {
    let goalSelfAssessment: GoalFeedback | null = null;
    let subgoalSelfAssessment: GoalFeedback | null = null;
    const goalFeedbacks: GoalFeedback[] = [];
    const subgoalFeedbacks: GoalFeedback[] = [];

    scorecardFeedbacks.forEach(({ feedbackGoalHeaders, ...rest }) => {
      const targetGoal = feedbackGoalHeaders.find(
        ({ targetScorecardGoal }) => targetScorecardGoal.id === goalId,
      );

      if (!targetGoal) return;

      const targetSubgoal = targetGoal.feedbackSubgoalHeaders?.find(
        ({ targetScorecardSubgoal }) => targetScorecardSubgoal.id === subgoalId,
      );

      const goalFeedback = {
        ...rest,
        isSelfAssessment: false,
        feedbackGoalHeader: {
          ...targetGoal,
          goalId: targetGoal.id,
        },
      } as GoalFeedback;
      const subgoalFeedback = {
        ...rest,
        isSelfAssessment: false,
        feedbackGoalHeader: {
          ...rest,
          ...targetSubgoal,
          goalId: targetGoal.id,
        },
      } as unknown as GoalFeedback;

      if (rest.user && rest.user.id === workRecord.user.id) {
        goalFeedback.isSelfAssessment = true;
        subgoalFeedback.isSelfAssessment = true;
        goalSelfAssessment = goalFeedback;
        subgoalSelfAssessment = subgoalFeedback;
      } else {
        goalFeedbacks.push(goalFeedback);
        subgoalFeedbacks.push(subgoalFeedback);
      }
    });

    const sortedFeedbacks: GoalFeedback[] = goalFeedbacks.sort(compareFeedback);
    const sortedSubgoalFeedbacks: GoalFeedback[] =
      subgoalFeedbacks.sort(compareFeedback);

    if (goalSelfAssessment) {
      sortedFeedbacks.unshift(goalSelfAssessment);
    }
    if (subgoalSelfAssessment) {
      sortedSubgoalFeedbacks.unshift(subgoalSelfAssessment);
    }

    if (excludeFeedbackWithEmptyComment) {
      return {
        goalFeedbacks: sortedFeedbacks.filter(
          ({ feedbackGoalHeader: { commentFinalized, commentOriginal } }) =>
            commentFinalized || commentOriginal,
        ),
        subgoalFeedbacks: sortedSubgoalFeedbacks.filter(
          ({ feedbackGoalHeader: { commentFinalized, commentOriginal } }) =>
            commentFinalized || commentOriginal,
        ),
      };
    }
    return {
      goalFeedbacks: sortedFeedbacks,
      subgoalFeedbacks: sortedSubgoalFeedbacks,
    };
  }, [
    excludeFeedbackWithEmptyComment,
    goalId,
    scorecardFeedbacks,
    subgoalId,
    workRecord.user.id,
  ]);
}
