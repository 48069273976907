import { Container, Divider, Typography } from '@mui/material';
import { useFetch } from 'hooks';
import { useIntl } from 'react-intl';
import { VERSION, BRANCH, COMMIT } from 'constants/appInfo';
import { API_VERSION } from 'constants/api';
import { Stack } from '@mui/system';
import { useMemo } from 'react';
import Tooltip from 'components/Tooltip';
import { VersionsWrapper, VersionInfo, Progress } from './VersionsInfo.styled';

export default function VersionsInfo() {
  const { formatMessage } = useIntl();
  const { data, status } = useFetch<{ api_version: string }>(API_VERSION);
  const backendVersionData = useMemo(() => {
    if (data?.api_version) {
      const [version, branchCommit] = data.api_version.split('(');
      return {
        version,
        branchCommit: branchCommit.replace(')', ''),
      };
    }
    return {};
  }, [data?.api_version]);

  return (
    <Container sx={{ marginTop: 'auto' }} maxWidth="lg">
      <Stack direction="row" alignItems="center">
        <Typography variant="body3">
          {formatMessage({
            id: 'common.versions',
            defaultMessage: 'Versions:',
          })}
        </Typography>
        <VersionsWrapper>
          <Tooltip title={`${BRANCH} ${COMMIT}`}>
            <VersionInfo variant="subheadingSmall">
              {formatMessage({
                id: 'common.uiVersion',
                defaultMessage: 'UI',
              })}
              <span>{VERSION || 'local'}</span>
            </VersionInfo>
          </Tooltip>
          <Divider orientation="vertical" flexItem />
          <Tooltip title={backendVersionData.branchCommit}>
            <VersionInfo variant="subheadingSmall">
              {formatMessage({
                id: 'common.apiVersion',
                defaultMessage: 'API',
              })}
              {status === 'loading' ? (
                <Progress size={14} />
              ) : (
                <span>{backendVersionData.version}</span>
              )}
            </VersionInfo>
          </Tooltip>
        </VersionsWrapper>
      </Stack>
    </Container>
  );
}
