import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ChatIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <path
        d="M1.66699 18.3333V3.33333C1.66699 2.875 1.83019 2.48264 2.15658 2.15625C2.48296 1.82986 2.87533 1.66666 3.33366 1.66666H16.667C17.1253 1.66666 17.5177 1.82986 17.8441 2.15625C18.1705 2.48264 18.3337 2.875 18.3337 3.33333V13.3333C18.3337 13.7917 18.1705 14.184 17.8441 14.5104C17.5177 14.8368 17.1253 15 16.667 15H5.00033L1.66699 18.3333ZM4.29199 13.3333H16.667V3.33333H3.33366V14.2708L4.29199 13.3333Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
