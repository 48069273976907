const generateURL = (
  url: string,
  params: { [key: string | number]: string | number },
) =>
  Object.keys(params).reduce(
    (acc: string, key: string) => acc.replace(`{${key}}`, String(params[key])),
    url,
  );

export default generateURL;
