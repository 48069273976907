import { useEffect, useRef, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { summaryGet } from 'store/summary';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';
import { selectToken } from 'store/profile';
import { API_WEBSOCKET_PATH, WEBSOCKET_NAMESPACE } from 'constants/api';
import { getFeedbackRequestsList } from 'store/feedbackRequests';
import { usePagePermissions } from 'hooks/scorecards';

const USER_SUMMARY_OWN_UPDATED = 'user-summary:own:updated';
const USR_SUBSCRIBE_MESSAGE = 'usr-subscribe';
const CONNECT = 'connect';
const NOTIFICATION = 'notification';

export default function useSocketIo() {
  const dispatch = useAppDispatch();
  const token = useSelector(selectToken);
  const { isFeedbackRequestsPage } = usePagePermissions();
  const socket = useRef<Socket>();
  const [socketId, setSocketId] = useState<string>();

  useEffect(() => {
    if (token) {
      socket.current = io(WEBSOCKET_NAMESPACE, {
        path: API_WEBSOCKET_PATH,
        transports: ['websocket'],
      });
    } else {
      socket.current = undefined;
    }

    function onConnect() {
      socket.current?.emit(USR_SUBSCRIBE_MESSAGE);
      setSocketId(socket.current?.id);
    }

    socket.current?.on(CONNECT, onConnect);

    return () => {
      socket.current?.off(CONNECT, onConnect);
      socket.current?.disconnect();
    };
  }, [token]);

  useEffect(() => {
    function onNotification({
      metadata: { type },
    }: {
      metadata: { type: string };
    }) {
      if (type === USER_SUMMARY_OWN_UPDATED) {
        dispatch(summaryGet());
        if (isFeedbackRequestsPage) {
          dispatch(getFeedbackRequestsList({}));
        }
      }
    }

    socket.current?.on(NOTIFICATION, onNotification);

    return () => {
      socket.current?.off(NOTIFICATION, onNotification);
    };
  }, [isFeedbackRequestsPage, dispatch, socketId]);
}
