import {
  DatePicker as MUIDatePicker,
  PickersDayProps,
  PickersDay,
} from '@mui/x-date-pickers';
import { dayjs } from 'helpers';
import classNames from 'classnames';
import { CalendarMonthIcon } from 'components/Icons';
import { Dayjs } from 'dayjs';
import { MouseEventHandler, useState } from 'react';
import { Box } from '@mui/material';
import {
  PickersDayValueContainer,
  DatePickerInput,
  IconOnlyWrapper,
} from './DatePicker.styled';
import { DatePickerProps } from './interfaces';

function renderPickerDay<T extends Dayjs>(
  date: T,
  selectedDates: Array<T | null>,
  pickersDayProps: PickersDayProps<T>,
) {
  const dateObj = dayjs(date);
  const dayValue = dateObj.date();
  const isWeekend = dateObj.day() === 0 || dateObj.day() === 6;

  return (
    <PickersDay
      {...pickersDayProps}
      className={classNames(pickersDayProps.className, {
        'MuiPickersDay-weekend': isWeekend,
      })}
    >
      <PickersDayValueContainer>{dayValue}</PickersDayValueContainer>
    </PickersDay>
  );
}

function DatePicker({
  onChange,
  size,
  error,
  helperText,
  disabled,
  required,
  fullWidth,
  iconOnly,
  components = {},
  ...rest
}: DatePickerProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    setIsOpen((open) => !open);
    setAnchorEl(event.currentTarget);
  };

  return (
    <MUIDatePicker
      {...rest}
      onChange={(val) => onChange(val ? dayjs(val).utc().format() : val)}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      renderDay={renderPickerDay}
      components={{
        ...components,
        OpenPickerIcon: CalendarMonthIcon,
      }}
      PopperProps={{
        anchorEl,
        placement: 'bottom-start',
      }}
      PaperProps={{
        sx: () => ({
          '.PrivatePickersSlideTransition-root': {
            minHeight: 'unset',
            '&>div[role="grid"]': {
              mb: 1,
            },
          },
        }),
      }}
      renderInput={(params) => {
        const datePickerInput = (
          <DatePickerInput
            {...params}
            onClick={handleClick}
            error={error || params.error}
            helperText={helperText}
            required={required}
            size={size}
            fullWidth={fullWidth}
          />
        );
        return iconOnly ? (
          <IconOnlyWrapper>
            {datePickerInput}
            <Box onClick={handleClick}>{params.InputProps?.endAdornment}</Box>
          </IconOnlyWrapper>
        ) : (
          datePickerInput
        );
      }}
    />
  );
}

DatePicker.defaultProps = {
  views: ['year', 'month', 'day'],
  reduceAnimations: true,
  showDaysOutsideCurrentMonth: true,
};

export default DatePicker;
