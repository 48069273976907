import { Typography } from '@mui/material';
import { Wrapper, EmptyIcon, TextContainer } from './DialogEmptyState.styled';
import { DialogEmptyStateProps } from './interfaces';

export default function DialogEmptyState({
  title,
  description,
}: DialogEmptyStateProps) {
  return (
    <Wrapper>
      <EmptyIcon />
      {!description ? (
        <TextContainer>
          <Typography variant="body2">{title}</Typography>
        </TextContainer>
      ) : (
        <>
          <Typography variant="heading4">{title}</Typography>
          <Typography variant="body1">{description}</Typography>
        </>
      )}
    </Wrapper>
  );
}
