import {
  cycleGetList,
  CycleTemplate,
  duplicateTemplate,
} from 'store/reviewCycles';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectOptions } from 'store/options';
import { useForm } from 'react-hook-form';
import { FormFields } from 'pages/ReviewCycles/DuplicateDialog/interfaces';
import commonMessages from 'i18n/common';
import { useCallback, useMemo } from 'react';
import {
  CatchCallback,
  SuccessCallback,
  useActionPipeline,
  useToast,
} from 'hooks';
import { handleResponseErrors } from 'helpers';

export default function useDuplicateValues(
  onClose: () => void,
  template: CycleTemplate,
  reviewCycleId: number,
) {
  const { formatMessage } = useIntl();
  const templateId = template.id as number;
  const { unit } = template;

  const { units, countries, reviewCycles } = useSelector(selectOptions);

  const form = useForm<FormFields>({
    defaultValues: {
      unitId: unit.id,
      countryIds: [],
      name: template.name,
      reviewCycleId,
    },
  });
  const { handleSubmit, setError } = form;
  const showMessage = useToast();

  const onSubmitSuccess = useCallback<SuccessCallback<FormFields>>(
    ({ payload: { name } }, dispatch) => {
      dispatch(cycleGetList());
      showMessage({
        severity: 'success',
        message: formatMessage(commonMessages.entityCreated, {
          entityName: name,
        }),
      });
      onClose();
    },
    [formatMessage, onClose, showMessage],
  );

  const onCatch = useMemo<CatchCallback>(
    () => handleResponseErrors<FormFields>(setError),
    [setError],
  );

  const actionPipeline = useActionPipeline(onSubmitSuccess, onCatch);

  const onSubmit = useCallback(
    (data: FormFields) => {
      actionPipeline(duplicateTemplate({ ...data, templateId }));
    },
    [actionPipeline, templateId],
  );
  const onSubmitClick = useMemo(
    () => handleSubmit(onSubmit),
    [handleSubmit, onSubmit],
  );
  return {
    form,
    units,
    countries,
    reviewCycles,
    onSubmitClick,
  };
}
