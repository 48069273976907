import { ComponentsProps } from '@mui/material/styles/props';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h7: true;
    h8: true;
    body1: true;
    body2: true;
    body3: true;
    body4: true;
    body5: true;
    body6: true;
    subtitle3: true;
    heading1: true;
    'heading1.1': true;
    'heading1.2': true;
    heading2: true;
    heading3: true;
    heading4: true;
    heading5: true;
    heading7: true;
    subheadingSmall: true;
    subheadingMedium: true;
  }
}

export default {
  defaultProps: {
    variant: 'body1',
    variantMapping: {
      h7: 'h6',
      h8: 'h6',
      subtitle3: 'h6',
      body1: 'p',
      body2: 'p',
      body3: 'p',
      body4: 'p',
      body5: 'p',
      body6: 'p',
      heading1: 'h1',
      'heading1.1': 'h1',
      'heading1.2': 'h1',
      heading2: 'h2',
      heading3: 'h3',
      heading4: 'h4',
      heading5: 'h5',
      heading7: 'h4',
      subheadingSmall: 'h6',
      subheadingMedium: 'h6',
    },
  } as ComponentsProps['MuiTypography'],
};
