import type { Node } from 'reactflow';
import { NodeTypesEnum } from 'constants/roadmap';
import { nanoid } from 'nanoid';
import { cloneDeep, omit } from 'lodash-es';
import NodeNormalizer from './nodeNormalizer';

export default class NewNode {
  static readonly NODE_POSITION_OFFSET = 150;

  private node: Node | null = null;

  createBaseNode = (): void => {
    this.node = {
      id: nanoid(),
      type: NodeTypesEnum.SKILL,
      data: {
        title: '',
        edges: {
          isTargetVisible: true,
          isSourceVisible: true,
        },
      },
      position: {
        x: NewNode.NODE_POSITION_OFFSET,
        y: NewNode.NODE_POSITION_OFFSET,
      },
    };

    this.node.dragHandle = `.handler-${this.node.id}`;
  };

  createGroupNode = (): void => {
    this.node!.type = NodeTypesEnum.GROUP;
    this.node!.style = { width: 500, height: 500 };
  };

  createSkillNode = (): void => {
    this.node!.type = NodeTypesEnum.SKILL;
    this.node!.expandParent = true;
  };

  createRootNode = (): void => {
    this.node!.type = NodeTypesEnum.ROOT;
    this.node!.expandParent = true;
  };

  get = (): Node => {
    if (!this.node) {
      throw new Error(
        'Looks like you forgot to call "create" or "override" methods before getting the node data',
      );
    }
    return NodeNormalizer.normalize(this.node);
  };

  static override = (overrideData: Partial<Node>): NewNode => {
    const result = NewNode.create(overrideData?.type as NodeTypesEnum);

    result.node = cloneDeep({
      ...result.node,
      ...omit(overrideData, 'id'),
    }) as Node;

    result.node.position.x += NewNode.NODE_POSITION_OFFSET;
    result.node.position.y += NewNode.NODE_POSITION_OFFSET;

    return result;
  };

  static create = (type = NodeTypesEnum.SKILL): NewNode => {
    const node = new NewNode();
    node.createBaseNode();

    switch (type) {
      case NodeTypesEnum.ROOT:
        node.createRootNode();
        break;

      case NodeTypesEnum.SKILL:
        node.createSkillNode();
        break;

      case NodeTypesEnum.GROUP:
        node.createGroupNode();
        break;

      default:
        node.createBaseNode();
        break;
    }

    return node;
  };
}
