import { createContext, PropsWithChildren, useContext, useState } from 'react';

export const VIEW_TYPE_STANDARD = 'standard';
export const VIEW_TYPE_EXTENDED = 'extended';
export const VIEW_TYPE_EXPANDABLE = 'expandable';

export type ViewType =
  | typeof VIEW_TYPE_STANDARD
  | typeof VIEW_TYPE_EXTENDED
  | typeof VIEW_TYPE_EXPANDABLE;
type SetViewType = (viewType: ViewType) => void;

const ViewTypeStateContext = createContext<ViewType>(VIEW_TYPE_STANDARD);

const ViewTypeUpdaterContext = createContext<SetViewType>(() => {});

export function useViewTypeStateContext() {
  return useContext<ViewType>(ViewTypeStateContext);
}

export function useViewTypeUpdaterContext() {
  return useContext<SetViewType>(ViewTypeUpdaterContext);
}

export function ViewTypeProvider({ children }: PropsWithChildren<{}>) {
  const [type, setType] = useState<ViewType>(VIEW_TYPE_STANDARD);
  return (
    <ViewTypeStateContext.Provider value={type}>
      <ViewTypeUpdaterContext.Provider value={setType}>
        {children}
      </ViewTypeUpdaterContext.Provider>
    </ViewTypeStateContext.Provider>
  );
}
