import { createSlice, Reducer } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash-es';
import { setHasDirtyForm, setHeaderMode } from './actions';
import { storeName, uiStateInitialState } from './config';
import addCommonCases from '../addCommonCases';

export * from './interfaces';
export * from './actions';
export * from './selectors';

const uiStateSlice = createSlice({
  name: storeName,
  initialState: cloneDeep(uiStateInitialState),
  reducers: {},
  extraReducers(builder) {
    addCommonCases(builder, uiStateInitialState)
      .addCase(setHasDirtyForm, (state, action) => {
        Object.assign(state, {
          hasDirtyForm: action.payload,
        });
      })
      .addCase(setHeaderMode, (state, action) => {
        Object.assign(state, {
          headerMode: action.payload,
        });
      });
  },
});

export default uiStateSlice.reducer as Reducer<typeof uiStateInitialState>;
