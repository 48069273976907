import { Theme } from '@mui/material';

export default {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      padding: 20,
      fontSize: theme.typography.pxToRem(14),
      '.MuiDialogTitle-root+&': {
        paddingTop: 20,
      },
    }),
  },
};
