import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function CopyToClipboardIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M9.92927 17.4299C9.38039 17.4299 8.91358 17.2564 8.52883 16.9096C8.14484 16.5621 7.95285 16.1405 7.95285 15.6448V5.78507C7.95285 5.28933 8.14484 4.86771 8.52883 4.52022C8.91358 4.17341 9.38039 4 9.92927 4H18.0236C18.5725 4 19.0389 4.17341 19.4229 4.52022C19.8076 4.86771 20 5.28933 20 5.78507V15.6448C20 16.1405 19.8076 16.5621 19.4229 16.9096C19.0389 17.2564 18.5725 17.4299 18.0236 17.4299H9.92927ZM9.92927 15.6448H18.0236V5.78507H9.92927V15.6448ZM5.97642 21C5.42754 21 4.96073 20.8266 4.57599 20.4798C4.192 20.1323 4 19.7107 4 19.2149V7.46304H5.97642V19.2149H16.1646V21H5.97642ZM9.92927 5.78507V15.6448V5.78507Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
