import { useEffect } from 'react';

export default function useHideTooltipsDuringScroll(
  scrollableElementClassName: string,
) {
  useEffect(() => {
    const scrollableElements = [
      document.querySelector(scrollableElementClassName),
    ];

    scrollableElements.forEach((element) => {
      element?.addEventListener('scroll', () => {
        const tooltip = document.querySelector<HTMLElement>("[role='tooltip']");
        if (tooltip) {
          tooltip.style.display = 'none';
        }
      });
    });
  });
}
