import { Theme } from '@mui/material';

export default {
  styleOverrides: {
    root({ theme }: { theme: Theme }) {
      return {
        marginRight: theme.spacing(1),
        padding: theme.spacing(0),
        textTransform: 'unset' as const,
        minHeight: '38px' as const,
      };
    },
  },
};
