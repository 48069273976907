import { Suspense, lazy } from 'react';
import PageLoader from 'components/PageLoader';
import { AppRouteComponentProps } from 'components/AppRoutes';

const FeedbackRequestsComponent = lazy(
  () => import('./FeedbackRequests.component'),
);

export default function FeedbackRequests(props: AppRouteComponentProps) {
  return (
    <Suspense fallback={<PageLoader open />}>
      <FeedbackRequestsComponent {...props} />
    </Suspense>
  );
}
