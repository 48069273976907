import { useSelector } from 'react-redux';
import { selectToast } from 'store/toast/selectors';
import { useAppDispatch } from 'store';
import { hideToast } from 'store/toast';
import { FormattedMessage } from 'react-intl';
import { useCallback, ReactNode } from 'react';
import { toggleFeedbackDialog } from 'store/profile';
import { StyledAlert, StyledSnackbar, FeedbackButton } from './Toast.styled';

function Toast() {
  const dispatch = useAppDispatch();
  const { open, severity, message, autoHideDuration, hideOnClickAway } =
    useSelector(selectToast);
  const handleClose = useCallback(() => dispatch(hideToast()), [dispatch]);
  const openFeedbackDialog = useCallback(() => {
    dispatch(toggleFeedbackDialog(true));
    handleClose();
  }, [dispatch, handleClose]);
  const { values = {}, ...restProps } =
    typeof message !== 'string' ? message : {};

  const mergedValues = {
    ...values,
    br: '\n',
    feedbackButton: (chunks: ReactNode[]) => (
      <FeedbackButton onClick={openFeedbackDialog}>{chunks}</FeedbackButton>
    ),
  };

  return (
    <StyledSnackbar
      open={open}
      autoHideDuration={autoHideDuration || 1000 * 1000}
      onClose={handleClose}
      key={`${message}`}
      ClickAwayListenerProps={{
        onClickAway: hideOnClickAway ? handleClose : () => null,
      }}
    >
      <StyledAlert onClose={handleClose} severity={severity}>
        {typeof message === 'string' ? (
          message
        ) : (
          <FormattedMessage {...restProps} values={mergedValues} />
        )}
      </StyledAlert>
    </StyledSnackbar>
  );
}

export default Toast;
