import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_SCORECARD_PARAM_TYPE_OPTIONS } from 'constants/api';
import { storeName } from '../config';
import { ParamTypeOption } from '../interfaces/ParamTypeOption';

export const updateParamTypes = createAsyncThunk<ParamTypeOption[]>(
  `${storeName}/updateParamTypes`,
  () =>
    api.axios
      .get(API_SCORECARD_PARAM_TYPE_OPTIONS)
      .then((res) => res.data?.list || [])
      .catch(() => []),
);
