export enum OkrReviewCycleStatuses {
  IN_PROGRESS = 'in_progress',
  DRAFT = 'draft',
  CLOSED = 'closed',
  DELETED = 'deleted',
}

export interface OkrReviewCycle {
  id: number;
  name: string;
  startAt: string;
  endAt: string;
  status: OkrReviewCycleStatuses;
}
