import { TEMP_LINK } from 'constants/app';

export const setTempLink = (link?: string) => {
  const {
    location: { pathname, search },
  } = window;
  localStorage.setItem(TEMP_LINK, link || pathname + search);
};

export const getTempLink = () => localStorage.getItem(TEMP_LINK);

export const clearTempLink = () => localStorage.removeItem(TEMP_LINK);
