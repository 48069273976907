import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_USER_SUMMARY_OWN } from 'constants/api';
import { SummaryGetResponse } from '../interfaces';
import { storeName } from '../config';

export const summaryGet = createAsyncThunk<SummaryGetResponse>(
  `${storeName}/get`,
  async (data, thunkApi) =>
    api.axios
      .get<SummaryGetResponse>(`${API_USER_SUMMARY_OWN}`)
      .then((res) => res.data)
      .catch((e) => thunkApi.rejectWithValue(e.response?.data)),
);
