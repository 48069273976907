import { TextField as MUITextField, TextFieldProps } from '@mui/material';
import classNames from 'classnames';
import React, { MouseEventHandler, forwardRef, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { MaxLengthText, TextFieldContainer } from './TextField.styled';

const TextField = forwardRef<
  HTMLElement,
  TextFieldProps & {
    hideCounter?: boolean;
    onClick?: MouseEventHandler<HTMLDivElement>;
    withoutTooltip?: boolean;
  }
>(
  (
    {
      size = 'medium',
      InputProps = {},
      inputProps = {},
      sx,
      selectOnFocus,
      hideCounter,
      onClick,
      withoutTooltip,
      ...props
    },
    ref,
  ): JSX.Element => {
    const { multiline, onFocus, onBlur, value, disabled } = props;
    const { maxLength } = inputProps;
    const inputClassName = classNames(inputProps.className, {
      [`inputSize-${size}`]: !multiline,
    });
    const className = classNames(InputProps.className, {
      [`inputSize-${size}`]: multiline,
    });
    const onFocusCallback = useCallback(
      (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (selectOnFocus) {
          e.currentTarget.select();
        }
        if (onFocus) onFocus(e);
      },
      [onFocus, selectOnFocus],
    );
    const onBlurCallback = useCallback(
      (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (onBlur) onBlur(e);
      },
      [onBlur],
    );

    const withCounter = !disabled && !hideCounter && multiline && maxLength;

    let textFieldProps: Record<string, string | boolean> = {};
    if (withoutTooltip) {
      textFieldProps = { ...textFieldProps, title: '' };
    }

    return (
      <TextFieldContainer
        onClick={onClick}
        sx={sx}
        className={classNames({ withCounter })}
      >
        <MUITextField
          {...(props as TextFieldProps)}
          {...textFieldProps}
          maxRows={multiline ? 4 : undefined}
          className={className}
          InputProps={{
            ...InputProps,
            className,
            inputRef: ref,
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            ...inputProps,
            className: inputClassName,
            placeholder: inputProps.placeholder || props.placeholder,
          }}
          onFocus={onFocusCallback}
          onBlur={onBlurCallback}
        />
        {withCounter && (
          <MaxLengthText>
            <FormattedMessage
              id="form.textAreaLength"
              defaultMessage="{length}/{maxLength}"
              values={{ maxLength, length: ((value as string) || '').length }}
            />
          </MaxLengthText>
        )}
      </TextFieldContainer>
    );
  },
);

TextField.defaultProps = {
  hideCounter: false,
};

export default TextField;
