import { Suspense, lazy, useEffect } from 'react';
import PageLoader from 'components/PageLoader';
import { AppRouteComponentProps } from 'components/AppRoutes';
import { useAppDispatch } from 'store';
import { careerMentorsGet } from 'store/scorecards';
import { storeName } from 'store/scorecards/department/config';
import { usePageAccessible } from 'hooks';
import { updateUserScopedUnits } from 'store/options/actions/updateUserScopedUnits';
import { updateUserScopedLocations } from 'store/options/actions/updateUserScopedLocations';

const DepartmentOverviewComponent = lazy(
  () => import('./DepartmentOverview.component'),
);

export default function DepartmentOverview(props: AppRouteComponentProps) {
  const dispatch = useAppDispatch();
  const hasAccess = usePageAccessible(props);

  useEffect(() => {
    if (!hasAccess) return;
    dispatch(updateUserScopedUnits());
    dispatch(updateUserScopedLocations());
    dispatch(careerMentorsGet({ store: storeName }));
  }, [dispatch, hasAccess]);

  return (
    <Suspense fallback={<PageLoader open />}>
      <DepartmentOverviewComponent {...props} />
    </Suspense>
  );
}
