import { UserManagementFilters, UserManagementState } from './interfaces';

export const initialFilters: UserManagementFilters = {
  userSearchName: null,
  roleIds: 0,
};

export const userManagementInitialState: UserManagementState = {
  users: [],
  roles: [],
  loading: false,
  updating: false,
  filters: { ...initialFilters },
  pagination: {
    limit: 0,
    page: 0,
    total: 0,
  },
  lastChange: {
    doneAt: '',
    actor: {
      fullName: '',
    },
  },
  init: false,
};

export const storeName = 'userManagement';
