import { FormattedMessage } from 'react-intl';
import { useNewItemsCountForPage } from 'hooks';
import { LinkStyled, Badge } from './Header.styled';
import { HeaderLinkProps } from './interfaces';

export default function HeaderLink({ path, title }: HeaderLinkProps) {
  const newItemsCount = useNewItemsCountForPage(path);
  return (
    <LinkStyled to={path} key={path}>
      <FormattedMessage {...title} />{' '}
      {newItemsCount > 0 && <Badge label={newItemsCount} />}
    </LinkStyled>
  );
}
