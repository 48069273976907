import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { GoalField } from 'components/GoalsAndSubgoalsForm/interfaces';

export default function useErrorClearing(index: number, goalIndex?: number) {
  const {
    clearErrors,
    watch,
    formState: { errors },
  } = useFormContext<GoalField>();
  const isSubgoal = typeof goalIndex !== 'undefined';
  const allItems = watch(isSubgoal ? `goals.${goalIndex}.subgoals` : 'goals');
  const fieldValue = useWatch<GoalField>({
    name: isSubgoal
      ? `goals.${goalIndex}.subgoals.${index}.name`
      : `goals.${index}.name`,
  });
  const error = isSubgoal
    ? errors?.goals?.[goalIndex]?.subgoals?.[index]?.name
    : errors?.goals?.[index]?.name;
  const hasUniqueError = error?.type === 'uniqueName';
  const trimmed = typeof fieldValue === 'string' ? fieldValue.trim() : '';
  const isNameUnique =
    (Array.isArray(allItems) ? allItems : []).findIndex(
      (item, idx) => item?.name?.trim() === trimmed && index !== idx,
    ) < 0;

  // Happens only after the backend returns invalid response for a valid input
  const errorGoalsKeys = Object.keys(errors?.goals || {});
  const isEmptyErrorObjectWithOnlyRefField =
    errorGoalsKeys.length === 1 && errorGoalsKeys[0] === 'ref';

  useEffect(() => {
    if (hasUniqueError && isNameUnique) {
      const errorName = isSubgoal
        ? (`goals.${goalIndex}.subgoals.${index}.name` as `goals.${number}.subgoals.${number}.name`)
        : (`goals.${index}.name` as `goals.${number}.name`);
      clearErrors(errorName);
    }
  }, [clearErrors, goalIndex, hasUniqueError, index, isNameUnique, isSubgoal]);

  useEffect(() => {
    if (isEmptyErrorObjectWithOnlyRefField) {
      clearErrors('goals');
    }
  }, [clearErrors, isEmptyErrorObjectWithOnlyRefField]);
}
