import { RadioGroup, styled } from '@mui/material';

export const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  color: theme.palette.common.black,

  '& .MuiFormControlLabel-labelPlacementEnd': {
    marginTop: '2px',
    marginBottom: '2px',
  },
  '& .MuiFormControlLabel-labelPlacementTop': {
    marginLeft: '16px',
  },
  '& .MuiFormControlLabel-label': {
    fontSize: '14px',
    lineHeight: '20px',
    paddingTop: '2px',
  },
  '& .MuiFormControlLabel-root:last-of-type': {
    marginRight: 0,
  },
  '& .MuiRadio-root': {
    padding: 0,
    paddingTop: '2px',
    '&  span': {
      color: theme.palette.grey.A400,
    },
    marginRight: 0,
  },
}));
