import { createAsyncThunk } from '@reduxjs/toolkit';
import { DeleteSuccessResponse } from 'store/interfaces';
import api from 'api';
import { API_SCORECARD_TEMPLATE } from 'constants/api';
import { storeName } from '../config';

export const templateDelete = createAsyncThunk<
  DeleteSuccessResponse,
  { id: number }
>(`${storeName}/deleteTemplate`, async ({ id }, thunkAPI) =>
  api.axios
    .delete(`${API_SCORECARD_TEMPLATE}/${id}`)
    .then((res) => res.data)
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
