import { okrTexts } from 'i18n';
import { StatusColorBox } from 'pages/ObjectiveDetails/ObjectiveDetails.styled';
import {
  MeasurementTypes,
  Visibilities,
  Statuses,
} from 'pages/ObjectiveDetails/interfaces';
import { useIntl } from 'react-intl';

export default function useStaticOptions() {
  const { formatMessage } = useIntl();
  const visibilityOptions = [
    {
      name: formatMessage(okrTexts.default),
      id: 0,
      value: Visibilities.DEFAULT,
    },
    {
      name: formatMessage(okrTexts.impactTeam),
      id: 1,
      value: Visibilities.IMPACT_TEAM,
    },
    {
      name: formatMessage(okrTexts.ascendTeam),
      id: 2,
      value: Visibilities.ASCEND_TEAM,
    },
  ];
  const statusOptions = [
    {
      name: formatMessage(okrTexts.notStarted),
      id: 1,
      value: Statuses.NOT_STARTED,
      color: '#DBDEE1',
      startAdornment: <StatusColorBox color="#DBDEE1" />,
    },
    {
      name: formatMessage(okrTexts.onTrack),
      id: 2,
      value: Statuses.ON_TRACK,
      color: '#B9DCFF',
      startAdornment: <StatusColorBox color="#B9DCFF" />,
    },
    {
      name: formatMessage(okrTexts.atRisk),
      id: 4,
      value: Statuses.AT_RISK,
      color: '#FFE9B1',
      startAdornment: <StatusColorBox color="#FFE9B1" />,
    },
    {
      name: formatMessage(okrTexts.completed),
      id: 6,
      value: Statuses.COMPLETED,
      color: '#A4ECE4',
      startAdornment: <StatusColorBox color="#A4ECE4" />,
    },
    {
      name: formatMessage(okrTexts.blocked),
      id: 5,
      value: Statuses.BLOCKED,
      color: '#FFC4B1',
      startAdornment: <StatusColorBox color="#FFC4B1" />,
    },
    {
      name: formatMessage(okrTexts.exceeded),
      id: 7,
      value: Statuses.EXCEEDED,
      color: '#76D2C8',
      startAdornment: <StatusColorBox color="#76D2C8" />,
    },
    {
      name: formatMessage(okrTexts.postponed),
      id: 3,
      value: Statuses.POSTPONED,
      color: `repeating-linear-gradient(
        135deg,
        #DBDEE1,
        #DBDEE1 6px,
        transparent 6px,
        transparent 12px
      )`,
      startAdornment: (
        <StatusColorBox
          color={`repeating-linear-gradient(
        135deg,
        #DBDEE1,
        #DBDEE1 6px,
        transparent 6px,
        transparent 12px
      )`}
        />
      ),
    },
  ];
  const mesuarementTypeOptions = [
    {
      label: formatMessage(okrTexts.currency),
      id: MeasurementTypes.CURRENCY,
    },
    {
      label: formatMessage(okrTexts.percentage),
      id: MeasurementTypes.PERCENTAGE,
    },
    {
      label: formatMessage(okrTexts.numerical),
      id: MeasurementTypes.NUMERICAL,
    },
    {
      label: formatMessage(okrTexts.text),
      id: MeasurementTypes.TEXT,
    },
  ];

  return {
    visibilityOptions,
    mesuarementTypeOptions,
    statusOptions,
  };
}
