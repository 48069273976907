import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { validateTotalWeight as validate } from 'helpers/reviewCycles';
import { useTotalWeight } from 'hooks/GoalsAndSubgoalsForm';
import { GoalField } from './interfaces';

export default function GoalWeightField({ index }: { index: number }) {
  const {
    setValue,
    register,
    getFieldState,
    formState: { submitCount },
  } = useFormContext<GoalField>();
  const fieldName =
    `goals.${index}.subgoalsWeight` as `goals.${number}.subgoalsWeight`;
  const currentValue = useTotalWeight(index);
  const fieldValue = useWatch({ name: fieldName }) || '';
  const formValue = parseInt(fieldValue, 10);
  const shouldValidate = submitCount > 0 || getFieldState('goals').isDirty;
  useEffect(() => {
    if (currentValue !== formValue) {
      setValue(fieldName, currentValue, { shouldValidate });
    }
  }, [currentValue, formValue, setValue, fieldName, shouldValidate]);
  return (
    <input
      type="hidden"
      {...register(fieldName, { validate, deps: [`goals.${index}.subgoals`] })}
    />
  );
}
