import { LinkIt } from 'react-linkify-it';
import { linkRegex } from 'constants/regexes';
import { Link } from '@mui/material';
import { TextWithLinksProps } from './interfaces/TextWithLinksProps';

function LinkComponent({
  link,
  text,
  dataTestId,
}: {
  link: string;
  text: string;
  dataTestId?: string;
}) {
  return (
    <Link
      target="_blank"
      rel="noreferrer"
      href={link.startsWith('http') ? link : `https://${link}`}
      data-testid={dataTestId}
    >
      {text}
    </Link>
  );
}

export default function TextWithLinks({
  children,
  originalText,
  dataTestId,
}: TextWithLinksProps) {
  return (
    <span>
      <LinkIt
        regex={linkRegex}
        component={(match, key) => {
          if (match.endsWith('...') && originalText) {
            const startOfLink = match.slice(0, -3); // cut ...
            const startOfLinkRegex = `${startOfLink
              .replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
              .replace(/\/$/, '')}\\S*`;
            const originalLink = originalText.match(startOfLinkRegex)![0];

            return <LinkComponent link={originalLink} text={match} key={key} />;
          }

          if (match.endsWith('...')) return <span key={match}>{match}</span>;

          return (
            <LinkComponent
              link={match}
              text={match}
              key={key}
              dataTestId={dataTestId}
            />
          );
        }}
      >
        {children}
      </LinkIt>
    </span>
  );
}

LinkComponent.defaultProps = {
  dataTestId: 'link',
};
