import { useMemo } from 'react';
import { useCategorizedFeedbacks } from 'hooks';

// This custom hook counts the number of feedbacks associated with a specific subgoal
// with a Text Assessment Scale that has a finalized comment. It checks if the 'commentFinalized'
// field exists (not empty) for the subgoal in the feedbacks and returns the count of such feedbacks.
export default function useTextScaleFeedbacksCount(subgoalId: number): number {
  const feedbacks = useCategorizedFeedbacks();

  return useMemo(
    () =>
      feedbacks.filter((feedback) =>
        feedback.feedbackGoalHeaders.some((goalHeader) =>
          goalHeader.feedbackSubgoalHeaders?.some(
            (subgoalHeader) =>
              subgoalHeader.targetScorecardSubgoal.id === subgoalId &&
              subgoalHeader.commentFinalized,
          ),
        ),
      ).length,
    [feedbacks, subgoalId],
  );
}
