import { createSlice, Reducer } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash-es';
import { resetState } from 'store/actions';
import {
  changeFilter,
  getObjectiveData,
  createOkrObjective,
  editOkrObjective,
  getObjectiveList,
  clearFilters,
  updateCommentsThread,
} from './actions';
import { storeName, okrObjectivesInitialState } from './config';

export * from './interfaces';
export * from './actions';
export * from './selectors';

const objectives = createSlice({
  name: storeName,
  initialState: cloneDeep(okrObjectivesInitialState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetState, (state) => {
        const newState = cloneDeep(okrObjectivesInitialState);
        newState.filters = state.filters;
        Object.assign(state, newState);
      })
      .addCase(changeFilter, (state, { payload: { key, value } }) => {
        Object.assign(state, {
          filters: { ...state.filters, [key]: value },
          init: true,
        });
      })
      .addCase(clearFilters, (state) => {
        state.filters = okrObjectivesInitialState.filters;
      })
      .addCase(getObjectiveData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getObjectiveData.fulfilled, (state, { payload }) => {
        const index = state.items.findIndex((item) => item.id === payload.id);
        if (index !== -1) {
          state.items[index] = { ...state.items[index], ...payload };
        } else {
          state.items.push(payload);
        }
        state.loading = false;
      })
      .addCase(getObjectiveData.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createOkrObjective.pending, (state) => {
        state.loading = true;
      })
      .addCase(createOkrObjective.fulfilled, (state, { payload }) => {
        state.items = [payload];
        state.loading = false;
      })
      .addCase(createOkrObjective.rejected, (state) => {
        state.loading = false;
      })
      .addCase(editOkrObjective.fulfilled, (state, { payload }) => {
        state.items = state.items.map((item) =>
          item.id === payload.id ? payload : item,
        );
        state.loading = false;
      })
      .addCase(editOkrObjective.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getObjectiveList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getObjectiveList.fulfilled, (state, { payload }) => {
        state.items = payload.objectives;
        state.totalScore = payload.totalScore;
        state.loadedReportingPeriod = payload.reportingPeriod;
        state.user = payload.user;
        state.loading = false;
      })
      .addCase(getObjectiveList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateCommentsThread, (state, { payload }) => {
        state.items = state.items.map((item) => {
          if (item.id === payload.objId) {
            if (!payload.keyId) {
              return {
                ...item,
                commentThread: {
                  ...payload.thread,
                  totalMessages:
                    (item.commentThread || payload.thread).totalMessages +
                    payload.change,
                },
              };
            }
            return {
              ...item,
              keyResults: item.keyResults?.map((keyResult) => {
                if (keyResult.id === payload.keyId) {
                  return {
                    ...keyResult,
                    commentThread: {
                      ...payload.thread,
                      totalMessages:
                        (keyResult.commentThread || payload.thread)
                          .totalMessages + payload.change,
                    },
                  };
                }
                return keyResult;
              }),
            };
          }
          return item;
        });
      });
  },
});

export default objectives.reducer as Reducer<typeof okrObjectivesInitialState>;
