import { useEffect, useState } from 'react';
import api from 'api';
import { Option } from 'store/interfaces';
import nonSortableOptions from 'constants/nonSortableOptions';
import { UIOption } from 'store/options';

export function getData(arr: string[], additionalOptions: UIOption[] = []) {
  return Promise.all<{ [K in typeof arr[number]]: UIOption[] }>(
    arr.map(
      (name) =>
        new Promise((resolve) => {
          api.axios
            .get<{ items: Option[] }>(api.getOptionURL(name))
            .then(({ data }) => {
              const resultArray = [...additionalOptions, ...data.items];
              if (!nonSortableOptions.includes(name)) {
                resultArray.sort((a, b) => a.name.localeCompare(b.name));
              }
              return resolve({ [name]: resultArray });
            });
        }),
    ),
  );
}

export default function useOptions<T extends string>(
  names: T[],
  additionalOptions?: UIOption[],
) {
  const validationString = names.join('|');
  const [options, setOptions] = useState(
    names.reduce((a, k) => ({ ...a, [k]: [] }), {}),
  );
  useEffect(() => {
    getData(validationString.split('|'), additionalOptions).then((res) => {
      setOptions(Object.assign({}, ...res));
    });
  }, [validationString, additionalOptions]);
  return options as Record<T, UIOption[]>;
}
