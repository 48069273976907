import { useSelector } from 'react-redux';
import { Permission, Role, selectProfileInfo } from 'store/profile';
import appRoutes from 'components/AppRoutes/routes';
import { ROLE_EMPLOYEE } from 'constants/roles';
import { useMemo } from 'react';
import { AppRouteItem } from 'components/AppRoutes';

function filterRoutes(
  items: AppRouteItem[],
  roles: Role[],
  permissions: Permission[],
  allPermissionsRequired = true,
) {
  if (!roles.find((role) => role.alias === ROLE_EMPLOYEE)) {
    return [];
  }

  const res: AppRouteItem[] = [];
  items.forEach((item) => {
    const {
      roles: requiredRoles = [],
      permissions: requiredPermissions = [],
      routes = [],
    } = item;

    // This is temp. later we're gonna use permissions only.
    const isEnabledByRole = requiredRoles.some((requiredRole) =>
      roles?.find((role) => role.alias === requiredRole),
    );
    const isEnabledByPermission = allPermissionsRequired
      ? requiredPermissions.every((requiredPermission) =>
          permissions?.find(
            (permission) => permission.name === requiredPermission,
          ),
        )
      : requiredPermissions.some((requiredPermission) =>
          permissions?.find(
            (permission) => permission.name === requiredPermission,
          ),
        );

    if (isEnabledByRole || isEnabledByPermission) {
      res.push({
        ...item,
        routes: filterRoutes(
          routes,
          roles,
          permissions,
          item.allPermissionsRequired,
        ),
      });
    }
  });

  return res;
}

export default function useAccessibleRoutes() {
  const { roles, permissions } = useSelector(selectProfileInfo);

  return useMemo<AppRouteItem[]>(
    () => filterRoutes(appRoutes, roles, permissions),
    [roles, permissions],
  );
}
