import { Theme } from '@mui/material';
import { alpha } from '@mui/system/colorManipulator';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import palette from '../palette';

export default {
  styleOverrides: {
    root({ theme }: { theme: Theme }) {
      return {
        ...theme.typography.heading5,
        textTransform: 'none',
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&:focus': {
          boxShadow: 'none',
        },
        '&:focus-visible, &:focus-visible + .MuiButtonGroup-grouped': {
          boxShadow: `0 0 0 2px ${palette.blue[300]}`,
        },
        '&.Mui-disabled': {
          color: theme.palette.grey['500'],
          borderColor: theme.palette.grey['300'],
        },
      };
    },
    sizeSmall: {
      padding: '6px 16px',
      '&[class*="outlined"]': {
        padding: '4px 14px',
      },
      minWidth: 32,
      '&>.MuiSvgIcon-root:only-child, .MuiLoadingButton-loadingIndicatorCenter+.MuiSvgIcon-root':
        {
          marginLeft: -11,
          marginRight: -11,
        },
    },
    sizeMedium: {
      minWidth: 36,
      padding: '8px 16px',
      '&[class*="outlined"]': {
        padding: '6px 14px',
      },
      '&>.MuiSvgIcon-root:only-child, .MuiLoadingButton-loadingIndicatorCenter+.MuiSvgIcon-root':
        {
          marginLeft: -9,
          marginRight: -9,
        },
    },
    sizeLarge: {
      minWidth: 40,
      padding: '10px 16px',
      '&[class*="outlined"]': {
        padding: '8px 14px',
      },
      '&>.MuiSvgIcon-root:only-child, .MuiLoadingButton-loadingIndicatorCenter+.MuiSvgIcon-root':
        {
          marginLeft: -7,
          marginRight: -7,
        },
    },
    textPrimary({ theme }: { theme: Theme }) {
      return {
        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.light, 0.1),
        },
        '&:active': {
          backgroundColor: alpha(theme.palette.primary.dark, 0.2),
        },
      };
    },
    outlinedPrimary({ theme }: { theme: Theme }) {
      return {
        borderColor: theme.palette.primary.light,
        '&, &.Mui-disabled': {
          borderWidth: 2,
        },
        '&:hover': {
          backgroundColor: theme.palette.primary.light,
          borderColor: theme.palette.primary.light,
          color: theme.palette.common.white,
          borderWidth: 2,
        },
        '&:active': {
          backgroundColor: theme.palette.primary.dark,
          color: theme.palette.common.white,
        },
      };
    },
    containedPrimary({ theme }: { theme: Theme }) {
      return {
        '&:hover': {
          backgroundColor: theme.palette.primary.light,
        },
        '&.Mui-disabled': {
          background: theme.palette.grey['300'],
        },
        '&:active': {
          backgroundColor: theme.palette.primary.dark,
          color: theme.palette.common.white,
        },
      };
    },
    textSecondary({ theme }: { theme: Theme }) {
      return {
        color: theme.palette.grey['1000'],
        '&:hover': {
          backgroundColor: theme.palette.grey['200'],
        },
        '&:active': {
          backgroundColor: theme.palette.grey['300'],
        },
      };
    },
    outlinedSecondary({ theme }: { theme: Theme }) {
      return {
        '&, &.Mui-disabled': {
          borderWidth: 2,
        },
        '&:hover': {
          backgroundColor: theme.palette.secondary.light,
          borderColor: theme.palette.secondary.light,
          color: theme.palette.common.white,
          borderWidth: 2,
        },
        '&:active': {
          backgroundColor: theme.palette.secondary.dark,
          color: theme.palette.common.white,
        },
      };
    },
    containedSecondary({ theme }: { theme: Theme }) {
      return {
        '&:hover': {
          backgroundColor: theme.palette.secondary.light,
        },
        '&.Mui-disabled': {
          background: theme.palette.grey['300'],
        },
        '&:active': {
          backgroundColor: theme.palette.secondary.dark,
          color: theme.palette.common.white,
        },
      };
    },
    textError({ theme }: { theme: Theme }) {
      return {
        '&:hover': {
          backgroundColor: alpha(theme.palette.error.light, 0.1),
        },
        '&:active': {
          backgroundColor: alpha(theme.palette.error.dark, 0.2),
        },
      };
    },
    outlinedError({ theme }: { theme: Theme }) {
      return {
        '&, &.Mui-disabled': {
          borderWidth: 2,
        },
        '&:hover': {
          backgroundColor: theme.palette.error.light,
          borderColor: theme.palette.error.light,
          color: theme.palette.common.white,
          borderWidth: 2,
        },
        '&:active': {
          backgroundColor: theme.palette.error.dark,
          color: theme.palette.common.white,
        },
      };
    },
    containedError({ theme }: { theme: Theme }) {
      return {
        '&:hover': {
          backgroundColor: theme.palette.error.light,
        },
        '&.Mui-disabled': {
          background: theme.palette.grey['300'],
        },
        '&:active': {
          backgroundColor: theme.palette.error.dark,
          color: theme.palette.common.white,
        },
      };
    },
  } as ComponentsOverrides<Theme>['MuiButton'],
};
