export default {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    outlined: {
      '.MuiButtonGroup-grouped:not(:first-of-type)': {
        marginLeft: -2,
      },
    },
  },
};
