import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { commonTexts, scorecardsTexts } from 'i18n';
import { usePreventDialogClosing } from 'hooks';
import { selectOLDialog } from 'store/optimisticLocking/selectors';
import { OLDialogParams, hideOLDialog } from 'store/optimisticLocking';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from './OptimisticLockingDialog.styled';
import { DialogContent, DialogTitle, DialogActions } from '../Dialog';

const types = {
  scorecard: {
    title: <FormattedMessage {...scorecardsTexts.versionLockPopupTitle} />,
    getContent: (params: OLDialogParams) => (
      <FormattedMessage
        {...scorecardsTexts.versionLockPopupContent}
        values={{
          ...params,
          br: <br />,
        }}
      />
    ),
  },
};

export default function OptimisticLockingDialog() {
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(hideOLDialog());
  };
  const { onCloseHandler } = usePreventDialogClosing(onClose);
  const { open, type, params } = useSelector(selectOLDialog);

  const { title, getContent } = types[type];
  const content = getContent(params);

  return (
    <Dialog fullWidth open={open!} onClose={onCloseHandler}>
      <DialogTitle onClose={onClose}>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          data-testid="cancel-button"
        >
          <FormattedMessage {...commonTexts.cancel} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
