import api from 'api';
import { API_SCORECARD_TEMPLATE_RECIPIENTS } from 'constants/api';
import debounce from 'debounce';
import { generateURL } from 'helpers';
import qs from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Pagination, WorkRecord } from 'store/interfaces';

const SEARCH_MIN_LENGTH = 2;

export default function useViewRecipientsValues(
  id: number,
  searchValue: string,
) {
  const debouncedSetFilter = useMemo(
    () => debounce((fn: (v: string) => void, val: string) => fn(val), 300),
    [],
  );
  const [filterString, setFilterString] = useState('');
  const [recipients, setRecipients] = useState<WorkRecord[]>([]);
  const [total, setTotal] = useState(0);
  const [currentTotal, setCurrentTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const requestWorkRecords = useCallback(
    (overwriteCurrentPage?: number) =>
      api.axios.get<{
        list: { workRecord: WorkRecord }[];
        pagination: Pagination;
      }>(
        `${generateURL(API_SCORECARD_TEMPLATE_RECIPIENTS, {
          id,
        })}?${qs.stringify({
          page: overwriteCurrentPage || currentPage,
          limit: 30,
          userSearchName: filterString,
        })}`,
      ),
    [currentPage, filterString, id],
  );
  const loadMore = useCallback(() => {
    if (recipients.length === 0) {
      setLoading(true);
    }
    setCurrentPage(currentPage + 1);
    requestWorkRecords().then((res) => {
      setRecipients([
        ...recipients,
        ...res.data.list.map(({ workRecord }) => workRecord),
      ]);
      setTotal(res.data.pagination.total);
      setCurrentTotal(res.data.pagination.total);
      setLoading(false);
    });
  }, [currentPage, recipients, requestWorkRecords]);
  useEffect(() => {
    loadMore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const newStr = searchValue.toLowerCase().trim();
    if (newStr !== filterString) {
      debouncedSetFilter.clear();
      debouncedSetFilter(setFilterString, newStr);
    }
  }, [searchValue, filterString, debouncedSetFilter]);
  useEffect(() => {
    if (filterString.length > SEARCH_MIN_LENGTH || filterString.length === 0) {
      setLoading(true);
      requestWorkRecords(1).then((res) => {
        setRecipients(res.data.list.map(({ workRecord }) => workRecord));
        setCurrentTotal(res.data.pagination.total);
        setLoading(false);
        setCurrentPage(2);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterString]);

  return {
    recipients,
    submitDisabled: true,
    onPublish: () => {},
    total,
    currentTotal,
    errors: [],
    loading,
    loadMore,
    hasMore: recipients.length < currentTotal,
  };
}
