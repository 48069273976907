import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function LinkDividerIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 664 34" {...props}>
      <line
        y1="16.5"
        x2="282"
        y2="16.5"
        stroke="currentColor"
        strokeDasharray="6 6"
      />
      <path
        d="M309.333 17.6667V20.3334C309.333 21.2556 309.008 22.0417 308.358 22.6917C307.708 23.3417 306.922 23.6667 305.999 23.6667C305.077 23.6667 304.291 23.3417 303.641 22.6917C302.991 22.0417 302.666 21.2556 302.666 20.3334V17.6667H303.999V20.3334C303.999 20.889 304.194 21.3612 304.583 21.7501C304.972 22.139 305.444 22.3334 305.999 22.3334C306.555 22.3334 307.027 22.139 307.416 21.7501C307.805 21.3612 307.999 20.889 307.999 20.3334V17.6667H309.333ZM306.666 19.6667H305.333V14.3334H306.666V19.6667ZM309.333 16.3334H307.999V13.6667C307.999 13.1112 307.805 12.639 307.416 12.2501C307.027 11.8612 306.555 11.6667 305.999 11.6667C305.444 11.6667 304.972 11.8612 304.583 12.2501C304.194 12.639 303.999 13.1112 303.999 13.6667V16.3334H302.666V13.6667C302.666 12.7445 302.991 11.9584 303.641 11.3084C304.291 10.6584 305.077 10.3334 305.999 10.3334C306.922 10.3334 307.708 10.6584 308.358 11.3084C309.008 11.9584 309.333 12.7445 309.333 13.6667V16.3334Z"
        fill="currentColor"
      />
      <path
        d="M319.092 21V11.662H320.254V19.964H324.594V21H319.092ZM326.705 13.104C326.327 13.104 326.005 12.782 326.005 12.39C326.005 11.998 326.327 11.69 326.705 11.69C327.097 11.69 327.419 11.998 327.419 12.39C327.419 12.782 327.097 13.104 326.705 13.104ZM326.187 21V14.238H327.237V21H326.187ZM333.895 21V16.576C333.895 15.386 333.293 15.008 332.383 15.008C331.557 15.008 330.773 15.512 330.381 16.058V21H329.331V14.238H330.381V15.218C330.857 14.658 331.781 14.07 332.789 14.07C334.203 14.07 334.945 14.784 334.945 16.254V21H333.895ZM341.648 21L339.212 17.962L338.092 19.068V21H337.042V11.662H338.092V17.836L341.648 14.238H342.978L339.982 17.304L342.992 21H341.648ZM343.564 20.104L344.11 19.348C344.558 19.866 345.412 20.314 346.336 20.314C347.372 20.314 347.932 19.852 347.932 19.194C347.932 18.452 347.106 18.214 346.196 17.99C345.048 17.724 343.746 17.416 343.746 16.016C343.746 14.966 344.656 14.07 346.266 14.07C347.414 14.07 348.226 14.504 348.772 15.05L348.282 15.778C347.876 15.288 347.134 14.924 346.266 14.924C345.342 14.924 344.768 15.344 344.768 15.946C344.768 16.604 345.538 16.814 346.42 17.024C347.596 17.29 348.954 17.626 348.954 19.124C348.954 20.258 348.044 21.168 346.294 21.168C345.188 21.168 344.25 20.832 343.564 20.104ZM355.977 21.168C354.983 21.168 354.479 20.594 354.479 19.586V15.162H353.359V14.238H354.479V12.39H355.529V14.238H356.901V15.162H355.529V19.362C355.529 19.866 355.753 20.23 356.215 20.23C356.509 20.23 356.789 20.104 356.929 19.95L357.237 20.734C356.971 20.986 356.593 21.168 355.977 21.168ZM361.338 21.168C359.28 21.168 357.992 19.572 357.992 17.612C357.992 15.652 359.28 14.07 361.338 14.07C363.396 14.07 364.684 15.652 364.684 17.612C364.684 19.572 363.396 21.168 361.338 21.168ZM361.338 20.23C362.78 20.23 363.578 18.998 363.578 17.612C363.578 16.24 362.78 15.008 361.338 15.008C359.896 15.008 359.084 16.24 359.084 17.612C359.084 18.998 359.896 20.23 361.338 20.23Z"
        fill="currentColor"
      />
      <line
        x1="382"
        y1="16.5"
        x2="664"
        y2="16.5"
        stroke="currentColor"
        strokeDasharray="6 6"
      />
    </SvgIcon>
  );
}
