import { createSlice, isAnyOf, type Reducer } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash-es';
import addCommonCases from 'store/addCommonCases';
import { roadmapDepartmentInitialState, storeName } from './config';
import {
  getRoadmapDepartments,
  updateDepartment,
  deleteDepartment,
  createDepartment,
} from './actions';

export * from './interfaces';
export * from './actions';
export * from './selectors';

const roadmap = createSlice({
  name: storeName,
  initialState: cloneDeep(roadmapDepartmentInitialState),
  reducers: {
    toggleDepartmentDrawer: (state) => {
      state.isDrawerOpen = !state.isDrawerOpen;
    },
  },
  extraReducers: (builder) => {
    addCommonCases(builder, roadmapDepartmentInitialState)
      .addCase(getRoadmapDepartments.pending, (state) => {
        state.isLoading = true;
        state.isIdle = false;
      })
      .addCase(getRoadmapDepartments.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getRoadmapDepartments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(updateDepartment.fulfilled, (state, action) => {
        state.isUpdating = false;
        state.data.list = state.data.list.map((item) =>
          item.id === action.payload.id ? action.payload : item,
        );
      })
      .addCase(deleteDepartment.fulfilled, (state, action) => {
        state.isUpdating = false;
        state.data.list = state.data.list.filter(
          (item) => item.id !== action.payload,
        );
      })
      .addCase(createDepartment.fulfilled, (state, action) => {
        state.isUpdating = false;
        state.data.list = [...state.data.list, action.payload];
      });

    builder.addMatcher(
      isAnyOf(
        updateDepartment.pending,
        deleteDepartment.pending,
        createDepartment.pending,
      ),
      (state) => {
        state.isUpdating = true;
      },
    );

    builder.addMatcher(
      isAnyOf(
        updateDepartment.rejected,
        deleteDepartment.rejected,
        createDepartment.rejected,
      ),
      (state) => {
        state.isUpdating = false;
      },
    );
  },
});

export const { toggleDepartmentDrawer } = roadmap.actions;

export default roadmap.reducer as Reducer<typeof roadmapDepartmentInitialState>;
