import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'pages.reviewCycles.title',
    defaultMessage: 'Review Cycles',
  },
  publishTemplate: {
    id: 'pages.reviewCycles.publishTemplate',
    defaultMessage: 'Publish Template',
  },
  publishTemplateTitle: {
    id: 'pages.reviewCycles.publishTemplateTitle',
    defaultMessage: 'Publish {name} Template',
  },
  publishNoResult: {
    id: 'pages.reviewCycles.publishNoResult',
    defaultMessage:
      'There is no employee from the specified departments in the selected location.',
  },
  noNewRecipients: {
    id: 'pages.reviewCycles.noNewRecipients',
    defaultMessage: 'No New Recipients selected',
  },
  searchToAddRecipient: {
    id: 'pages.reviewCycles.searchToAddRecipient',
    defaultMessage: 'Search to add a Recipient',
  },
  searchToAddOrRemovePeople: {
    id: 'pages.reviewCycles.searchToAddOrRemovePeople',
    defaultMessage: 'Search to add or remove people',
  },
  searchPeople: {
    id: 'pages.reviewCycles.searchPeople',
    defaultMessage: 'Search people',
  },
  singleTitle: {
    id: 'pages.reviewCycles.singleTitle',
    defaultMessage: 'Review Cycle',
  },
  manageCommonGoals: {
    id: 'pages.reviewCycles.manageCommonGoals',
    defaultMessage: 'Manage Common Goals',
  },
  commonGoals: {
    id: 'pages.reviewCycles.commonGoals',
    defaultMessage: 'Common Goals',
  },
  commonGoalsCategory: {
    id: 'pages.reviewCycles.commonGoalsCategory',
    defaultMessage: 'Common Goals Category',
  },
  createNewCategory: {
    id: 'pages.reviewCycles.createNewCategory',
    defaultMessage: 'Create a new category',
  },
  renameCategory: {
    id: 'pages.reviewCycles.RenameCategory',
    defaultMessage: 'Rename selected category',
  },
  deleteCategory: {
    id: 'pages.reviewCycles.deleteCategory',
    defaultMessage: 'Delete selected category',
  },
  createNewCategoryModal: {
    id: 'pages.reviewCycles.createNewCategoryModal',
    defaultMessage: 'Create Common Goals Category',
  },
  renameCategoryModal: {
    id: 'pages.reviewCycles.renameCategoryModal',
    defaultMessage: 'Rename Common Goals Category',
  },
  deleteCategoryModal: {
    id: 'pages.reviewCycles.deleteCategoryModal',
    defaultMessage: 'Delete Common Goals Category',
  },
  deleteCategoryMessage: {
    id: 'pages.reviewCycles.deleteCategoryMessage',
    defaultMessage: `You are about to delete {name} common goals category with all Goals inside of it{hasGoals, select, true {: {goals}} other {{br}{br}}}
    Are sure you want to proceed?`,
  },
  createCommonGoalCategoryToast: {
    id: 'pages.reviewCycles.createCommonGoalCategoryToast',
    defaultMessage: '{name} Common Goal Category was successfully created',
  },
  renameCommonGoalCategoryToast: {
    id: 'pages.reviewCycles.renameCommonGoalCategoryToast',
    defaultMessage: '{name} Category was renamed to {newName}',
  },
  deleteCommonGoalCategoryToast: {
    id: 'pages.reviewCycles.deleteCommonGoalCategoryToast',
    defaultMessage:
      '{name} Common Goal Category and all related Goals were deleted',
  },
});
