import { useCallback } from 'react';
import { ToastProps, showToast } from 'store/toast';
import { useAppDispatch } from 'store';

export default function useToast() {
  const dispatch = useAppDispatch();
  return useCallback(
    (props: ToastProps) => {
      dispatch(showToast(props));
    },
    [dispatch],
  );
}
