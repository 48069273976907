import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { formTexts } from 'i18n';

export default function useLengthValidator(
  maxLength: number,
  minLength: number = 0,
  fieldName: string = '',
) {
  const { formatMessage } = useIntl();

  return useCallback(
    (value = '') => {
      if (typeof value === 'number') return true;
      if (value === '') return true;

      if (value.length < minLength) {
        return formatMessage(formTexts.errorMinLength, {
          fieldName,
          minLength,
        });
      }

      if (value.length > maxLength) {
        return formatMessage(formTexts.errorMaxLength, {
          fieldName,
          maxLength,
        });
      }

      return true;
    },
    [fieldName, formatMessage, maxLength, minLength],
  );
}
