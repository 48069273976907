import { createAction } from '@reduxjs/toolkit';
import {
  ScorecardsActionStoreArgument,
  ScorecardFilters,
  ScorecardFiltersKeys,
} from 'store/interfaces';

type RemoveValueFromFilterPayloadData =
  | {
      key: ScorecardFiltersKeys;
      value: number;
    }
  | ScorecardFilters;

export const removeValueFromFilter = createAction<{
  store: ScorecardsActionStoreArgument;
  data: RemoveValueFromFilterPayloadData;
}>('scorecards/removeValueFromFilter');
