import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserMgmtRole } from 'store/interfaces';
import api from 'api';
import { API_ROLES_LIST } from 'constants/api';
import { storeName } from '../config';

export const getRoles = createAsyncThunk<{
  list: UserMgmtRole[];
}>(`${storeName}/rolesList`, async (data, thunkAPI) =>
  api.axios
    .get(API_ROLES_LIST)
    .then((res) => res.data)
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
