import palette from '../palette';

export default {
  styleOverrides: {
    body: {
      backgroundColor: palette.grey['200'],
      minWidth: '1280px',
    },
  },
};
