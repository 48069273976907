import { useScorecardStates } from 'hooks';
import usePagePermissions from './usePagePermissions';

export default function useReviewersConfiguration(isInactive?: boolean) {
  const {
    isGoalsPrepared,
    isActive,
    isFeedbackRequested,
    isFeedbackCollected,
    isCompleted,
    isGoalsApproved,
    isDeleted,
  } = useScorecardStates();
  const { hasFullAccess, isOwnScorecardsPage } = usePagePermissions();

  const showButtonForCM =
    hasFullAccess &&
    (isActive ||
      isGoalsPrepared ||
      isGoalsApproved ||
      isFeedbackRequested ||
      isFeedbackCollected);
  const showButtonForUser =
    isOwnScorecardsPage && (isActive || isGoalsPrepared || isGoalsApproved);
  const showAddButton =
    !isInactive && !isCompleted && (showButtonForUser || showButtonForCM);
  const showReadOnlyReviewerFeedback = isInactive || isCompleted;

  const renderPaletteForUser = isOwnScorecardsPage && !isCompleted;
  const renderPaletteForCM =
    hasFullAccess &&
    (isActive || isGoalsPrepared || isGoalsApproved || isDeleted);
  const renderPalette = renderPaletteForUser || renderPaletteForCM;

  return {
    showAddButton,
    showReadOnlyReviewerFeedback,
    renderPalette,
  };
}
