import { TextField, styled } from '@mui/material';
import blackListProps from 'styles/helpers/blackListProps';

export const SearchField = styled(TextField, {
  shouldForwardProp: blackListProps(['slim']),
})<{ slim: boolean }>(({ slim }) => ({
  '& .MuiInputAdornment-positionStart .MuiSvgIcon-root': {
    width: '24px',
    height: '24px',
  },
  '& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedStart.MuiAutocomplete-input':
    {
      padding: slim ? '6px 8px 6px 0px' : '10px 12px',
      lineHeight: '20px',
    },
}));
