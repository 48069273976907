import { Suspense, lazy } from 'react';
import PageLoader from 'components/PageLoader';
import { AppRouteComponentProps } from 'components/AppRoutes';

const MyScorecardsComponent = lazy(() => import('./MyScorecards.component'));

export default function ReviewCycles(props: AppRouteComponentProps) {
  return (
    <Suspense fallback={<PageLoader open />}>
      <MyScorecardsComponent {...props} />
    </Suspense>
  );
}
