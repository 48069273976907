import {
  KeyResultItem,
  ObjectiveFormFields,
} from 'pages/ObjectiveDetails/interfaces';
import { OkrObjectiveResponse } from 'store/okrObjectives';

export default function transformObjectiveResponse(
  objectiveData: OkrObjectiveResponse,
  periodId: string,
  visibilityOptions: { id: number; value: string }[],
  statusOptions: { id: number; value: string }[],
) {
  return {
    name: objectiveData.name,
    description: objectiveData.description,
    weight: objectiveData.weight || 0,
    label: Number(periodId),
    id: String(objectiveData.id),
    attainment: objectiveData.totalScore || 0,
    keyResultsTotalWeight: 100,
    visibility:
      visibilityOptions.find(
        (option) => option.value === objectiveData.visibility,
      )?.id || 0,
    version: objectiveData.version,
    parentBinding: objectiveData.parentBinding,
    keyResults: objectiveData.keyResults.map(
      (keyResult) =>
        ({
          id: String(keyResult.id),
          name: keyResult.name,
          description: keyResult.description,
          weight: String(keyResult.weight),
          status:
            statusOptions.find((option) => option.value === keyResult.status)
              ?.id || 0,
          attainmentScore: keyResult.attainmentScore || 0,
          agreedAttainmentScore: keyResult.agreedAttainmentScore || null,
          referenceValue: keyResult.referenceValue || 0,
          targetValue: keyResult.targetValue || 0,
          resultValue: keyResult.resultValue || 0,
          measurementType: keyResult.measurementType,
          dueDate: keyResult.dueDate,
          autoUpdateAttainmentFromChildBinding:
            keyResult.autoUpdateAttainmentFromChildBinding,
          childBinding: {
            childTargetUser: keyResult.childBinding?.childTargetUser || null,
            childTargetUserId: keyResult.childBinding?.childTargetUser?.id,
            childObjective: keyResult.childBinding?.childObjective || null,
            available: keyResult.childBinding?.available || false,
          },
        } as KeyResultItem),
    ),
  } as ObjectiveFormFields;
}
