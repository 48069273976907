import { useCallback } from 'react';
import {
  SuccessCallback,
  useActionPipeline,
  useDialogState,
  useToast,
} from 'hooks';
import { useIntl } from 'react-intl';
import { commonTexts, scorecardsTexts } from 'i18n';
import { changeStatus } from 'store/scorecards';
import {
  SCORECARD_ACTION_GOALS_IN_PROGRESS_REVERT,
  SCORECARD_ACTION_GOALS_REDRAFT,
} from 'constants/scorecards';
import { useScorecardContext } from 'contexts';
import { User } from 'store/interfaces';

export default function useRevertToActive(
  isFeedbackRequested: boolean,
  careerMentor: User | null,
) {
  const { id, store } = useScorecardContext();
  const [
    isRevertToActiveDialogActive,
    openRevertToActiveDialog,
    onRevertToActiveDialogClose,
  ] = useDialogState();

  const onRevertToActiveClick = useCallback(() => {
    openRevertToActiveDialog();
  }, [openRevertToActiveDialog]);

  const { formatMessage } = useIntl();
  const showMessage = useToast();
  const onRevertToActiveSuccess = useCallback<SuccessCallback>(() => {
    showMessage({
      severity: 'success',
      message: formatMessage(commonTexts.success),
    });
  }, [showMessage, formatMessage]);

  const revertToActivePipeline = useActionPipeline(
    onRevertToActiveSuccess,
    null,
    onRevertToActiveDialogClose,
  );

  const onRevertToActiveSubmit = useCallback(() => {
    if (careerMentor) {
      revertToActivePipeline(
        changeStatus({
          id,
          store,
          action: isFeedbackRequested
            ? SCORECARD_ACTION_GOALS_IN_PROGRESS_REVERT
            : SCORECARD_ACTION_GOALS_REDRAFT,
        }),
      );
    } else {
      onRevertToActiveDialogClose();
      showMessage({
        severity: 'error',
        autoHideDuration: null,
        message: formatMessage(scorecardsTexts.revertToActiveNoCM),
      });
    }
  }, [
    careerMentor,
    formatMessage,
    id,
    isFeedbackRequested,
    onRevertToActiveDialogClose,
    revertToActivePipeline,
    showMessage,
    store,
  ]);
  return {
    onRevertToActiveClick,
    isRevertToActiveDialogActive,
    onRevertToActiveDialogClose,
    onRevertToActiveSubmit,
  };
}
