import { Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import Tooltip from 'components/Tooltip';
import OneLineTextWithTooltipProps from './intefraces';

export default function OneLineTextWithTooltip({
  text,
  tooltipSx,
  ...props
}: OneLineTextWithTooltipProps) {
  const ref = useRef<HTMLSpanElement>(null);
  const [tooltipEnabled, setTooltipEnabled] = useState(false);

  useEffect(() => {
    const compareSize = () => {
      if (ref.current) {
        const compare = ref.current.scrollWidth > ref.current.clientWidth;

        setTooltipEnabled(compare);
      }
    };
    compareSize();
    window.addEventListener('resize', compareSize);
    return () => window.removeEventListener('resize', compareSize);
  }, [text]);

  return (
    <Tooltip title={text} disableHoverListener={!tooltipEnabled} sx={tooltipSx}>
      <Typography
        {...props}
        ref={ref}
        noWrap
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {text}
      </Typography>
    </Tooltip>
  );
}
