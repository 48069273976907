import { useCallback, useState } from 'react';
import api from 'api';
import { API_SCORECARD_REVIEWERS } from 'constants/api';
import { generateURL } from 'helpers';
import { useScorecardContext } from 'contexts';
import { useScorecardUpdate } from 'hooks/scorecards';

export default function useReviewers() {
  const [updatingReviewers, setUpdatingReviewers] = useState(false);
  const { id } = useScorecardContext();
  const { updateData } = useScorecardUpdate();

  const updateReviewers = useCallback(
    async (reviewerIds: number[], setForAllGoals: boolean, goalId?: number) => {
      setUpdatingReviewers(true);
      const request = {
        targetUserIds: reviewerIds,
      } as any;
      if (setForAllGoals) {
        request.setForAllGoals = setForAllGoals;
      }
      if (goalId !== undefined) {
        request.targetScorecardGoalId = goalId;
      }

      return api.axios
        .patch(generateURL(API_SCORECARD_REVIEWERS, { id }), request)
        .then(() => {
          updateData();
        })
        .finally(() => {
          setUpdatingReviewers(false);
        });
    },
    [id, updateData],
  );

  return {
    updatingReviewers,
    updateReviewers,
  };
}
