import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import {
  OKR_KEY_RESULT_CREATE_COMMENT_THREAD,
  OKR_OBJECTIVE_CREATE_COMMENT_THREAD,
} from 'constants/api';
import { generateURL } from 'helpers';
import { storeName } from '../config';
import { Thread } from '../interfaces';

export const createThread = createAsyncThunk<
  Thread,
  { objectiveId: number; periodId: number; keyResultId?: number }
>(
  `${storeName}/createThread`,
  ({ objectiveId, periodId, keyResultId = '' }, thunkAPI) => {
    const url = keyResultId
      ? OKR_KEY_RESULT_CREATE_COMMENT_THREAD
      : OKR_OBJECTIVE_CREATE_COMMENT_THREAD;
    return api.axios
      .post<Thread>(
        generateURL(url, { objectiveId, keyResultId, periodId }),
        {},
      )
      .then((res) => res.data)
      .catch((e) => thunkAPI.rejectWithValue(e.response?.data))
      .then((res) => res)
      .catch((e) => thunkAPI.rejectWithValue(e.response?.data));
  },
);
