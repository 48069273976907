import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { formTexts } from 'i18n';
import { useSelector } from 'react-redux';
import { selectDefaultAssessmentScaleGroupId } from 'store/options';
import {
  FormGoal,
  FormSubGoal,
} from 'components/GoalsAndSubgoalsForm/interfaces';
import { parseParamTypes } from 'helpers';
import { ParamTypeOption } from 'store/options/interfaces/ParamTypeOption';

export default function useCreateNewGoal(paramTypes: ParamTypeOption[]) {
  const { formatMessage } = useIntl();
  const defaultAssessmentScaleId = useSelector(
    selectDefaultAssessmentScaleGroupId,
  );
  return useCallback(
    (
      orderRank: number,
      isTemplate: boolean,
      defaultValue?: Partial<FormGoal>,
    ) => {
      const {
        allowScorecardComment,
        allowScorecardOwnerEdits,
        selfAssessmentSetup,
      } = parseParamTypes(paramTypes, isTemplate);
      const subgoal = {
        name: formatMessage(formTexts.untitledSubgoal),
        description: '',
        orderRank: 0,
        assessmentScaleGroupId: defaultAssessmentScaleId,
        weight: 0,
      };
      const goalObj = {
        name: formatMessage(formTexts.untitledGoal),
        description: '',
        orderRank,
        subgoals: [subgoal as FormSubGoal],
        weight: 0,
        allowScorecardOwnerEdits,
        allowScorecardComment,
        selfAssessmentSetup,
        ...(defaultValue || {}),
      };
      if (!isTemplate) {
        goalObj.isCustom = true;
      }
      return goalObj as FormGoal;
    },
    [defaultAssessmentScaleId, formatMessage, paramTypes],
  );
}
