import { ListItemText, MenuItem, Stack } from '@mui/material';
import {
  ListSubheader,
  Checkbox,
  StyledTooltip,
  TooltipInfoIcon,
  SelectOption,
  SelectProps,
  TooltipWithIcon,
} from 'components/Select';
import { FormattedMessage } from 'react-intl';
import { formTexts } from 'i18n';

export default function renderOptions<P extends SelectOption, T>(
  options: P[],
  value: T | undefined,
  multiple: SelectProps<P, T>['multiple'],
  groupBy: SelectProps<P, T>['groupBy'],
) {
  if (options.length === 0) {
    return (
      <ListSubheader className="empty" key="no-opts">
        <FormattedMessage {...formTexts.noOptions} />
      </ListSubheader>
    );
  }
  const allValues = [];
  const mappingFunction = (item: P) => {
    const { id, name, disabled = false } = item;
    if (multiple) {
      if (id) {
        allValues.push(id);
        const checked = value && Array.isArray(value) && value.includes(id);
        if (item.tooltip) {
          return (
            <MenuItem value={id} key={id}>
              <StyledTooltip title={item.tooltip}>
                <Checkbox checked={checked} />
                <ListItemText primary={name} />
              </StyledTooltip>
            </MenuItem>
          );
        }
        return (
          <MenuItem value={id} key={id}>
            <Checkbox checked={checked} />
            <ListItemText primary={name} />
          </MenuItem>
        );
      }
      return null;
    }
    if (item.tooltip) {
      return (
        <MenuItem disabled={disabled} key={name} value={id || ''}>
          <StyledTooltip title={item.tooltip}>{name}</StyledTooltip>
        </MenuItem>
      );
    }

    if (item.tooltipWithIcon) {
      return (
        <MenuItem disabled={disabled} key={name} value={id || ''}>
          <Stack direction="row" alignItems="center">
            {name}
            <TooltipWithIcon title={item.tooltipWithIcon} disableInteractive>
              <TooltipInfoIcon />
            </TooltipWithIcon>
          </Stack>
        </MenuItem>
      );
    }

    if (item.startAdornment) {
      return (
        <MenuItem disabled={disabled} key={name} value={id || ''}>
          <Stack direction="row" alignItems="center">
            {item.startAdornment}
            {name}
          </Stack>
        </MenuItem>
      );
    }

    return (
      <MenuItem disabled={disabled} key={name} value={id || ''}>
        {name}
      </MenuItem>
    );
  };
  if (groupBy) {
    const groped = options.reduce((acc: { [key: string]: P[] }, item) => {
      const group = (item[groupBy] || 'none') as string;
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(item);
      return acc;
    }, {});

    const result = Object.keys(groped)
      .sort((a, b) => {
        if (a === 'none') {
          return -1;
        }
        if (b === 'none') {
          return 1;
        }
        return a.localeCompare(b);
      })
      .map((group) => [
        group !== 'none' && <ListSubheader key={group}>{group}</ListSubheader>,
        ...groped[group].map(mappingFunction),
      ]);
    result.unshift();
    return result;
  }
  return options.map(mappingFunction);
}
