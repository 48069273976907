import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'pages.userManagement.title',
    defaultMessage: 'User Management',
  },
  filterByRole: {
    id: 'pages.userManagement.filterByRole',
    defaultMessage: 'Filter by role...',
  },
  name: {
    id: 'pages.userManagement.name',
    defaultMessage: 'Name',
  },
  rolesAssigned: {
    id: 'pages.userManagement.rolesAssigned',
    defaultMessage: 'Roles Assigned',
  },
  allRoles: {
    id: 'pages.userManagement.allRoles',
    defaultMessage: 'All roles',
  },
  availableDepartments: {
    id: 'pages.userManagement.availableDepartments',
    defaultMessage: 'Available departments:',
  },
  availableCountries: {
    id: 'pages.userManagement.AvailableCountries',
    defaultMessage: 'Available locations:',
  },
});
