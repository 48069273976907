import {
  KeyResultItem,
  MeasurementTypes,
} from 'pages/ObjectiveDetails/interfaces';

export default function getKeyResultDefaultFields(
  cycleEnd?: string,
  isNewObjective?: boolean,
): KeyResultItem {
  return {
    uniqueId: Math.random().toString(36).substr(2, 9),
    name: '',
    description: '',
    status: 1,
    dueDate: cycleEnd || new Date().toISOString(),
    attainmentScore: 0,
    weight: isNewObjective ? '100' : '0',
    measurementType: MeasurementTypes.CURRENCY,
    referenceValue: '0',
    targetValue: '0',
    resultValue: '0',
    agreedAttainmentScore: null,
    autoUpdateAttainmentFromChildBinding: false,
    childBinding: null,
  };
}
