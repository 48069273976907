import { Node, useReactFlow } from 'reactflow';
import { NodeData } from 'store/interfaces';
import { useCallback } from 'react';

const ZOOM_IN = 1;
const FOCUS_OFFSET = 0.5;
const FOCUS_DURATION = 300;
const DRAWER_WIDTH = 500;

export default function useFocusNode() {
  const { setViewport } = useReactFlow();

  return useCallback(
    (node?: Node<NodeData> | null) => {
      if (node?.id) {
        const x =
          window.innerWidth * FOCUS_OFFSET -
          DRAWER_WIDTH -
          (node.positionAbsolute?.x ?? 0);

        const y =
          window.innerHeight * FOCUS_OFFSET - (node.positionAbsolute?.y ?? 0);

        setViewport(
          {
            zoom: ZOOM_IN,
            x,
            y,
          },
          { duration: FOCUS_DURATION },
        );
      }
    },
    [setViewport],
  );
}
