import {
  Chip as MUIChip,
  Checkbox as MUICheckbox,
  ListSubheader as MUIListSubheader,
  styled,
} from '@mui/material';

export const List = styled('ul')(({ theme }) => ({
  '&[class*="SelectMenuSize"]': {
    padding: 0,
  },
  '& li': {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.375,
    borderColor: theme.palette.grey.A400,
  },
  '&.SelectMenuSize-small li': {
    padding: '5px 10px',
  },
  '&.SelectMenuSize-medium li': {
    padding: '7px 10px',
  },
  '&.SelectMenuSize-large li': {
    padding: '9px 10px',
  },
}));

export const Chip = styled(MUIChip)(({ theme }) => ({
  margin: '-3px 0 -3px -3px',
  height: '26px',
  fontSize: 16,
  '&.MuiAutocomplete-tag': {
    maxWidth: 'calc(100% - 40px)',
  },
  '.MuiChip-deleteIcon': {
    fontSize: 18,
    color: theme.palette.common.black,
  },
}));

export const ListSubheader = styled(MUIListSubheader)({
  '&.empty': {
    fontStyle: 'italic',
    fontSize: '1rem',
  },
});

export const Checkbox = styled(MUICheckbox)({
  padding: 0,
  margin: '-2px 5px -2px 0',
  '.MuiSvgIcon-root': {
    fontSize: '24px',
  },
});
