import { MeasurementTypes } from 'pages/ObjectiveDetails/interfaces';

export default function getMeasurementPostfix(
  measurementType: MeasurementTypes | null,
) {
  switch (measurementType) {
    case MeasurementTypes.CURRENCY:
      return '$';
    case MeasurementTypes.PERCENTAGE:
      return '%';
    default:
      return '';
  }
}
