import { useScorecardData } from 'hooks';

export default function useIsSelfAssessmentFeedback(feedbackId: number) {
  const {
    feedbacks,
    workRecord: {
      user: { id: userId },
    },
  } = useScorecardData();

  const feedback = feedbacks.find(({ id }) => id === feedbackId);

  return feedback?.user?.id === userId;
}
