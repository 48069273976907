import { FEEDBACK_REQUEST_STATUS_PENDING } from 'constants/feedbacks';
import { FeedbackRequestRO } from 'store/interfaces';

export function collectPendingReviewers(feedbackRequests: FeedbackRequestRO[]) {
  return feedbackRequests
    .filter(({ requestGoals }) =>
      requestGoals.some(
        ({ status }) => status === FEEDBACK_REQUEST_STATUS_PENDING,
      ),
    )
    .map(({ user }) => user);
}
