import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import {
  OKR_MY_TEAM_USERS_DIRECT,
  OKR_MY_TEAM_USERS_FULL,
} from 'constants/api';
import { generateURL } from 'helpers';
import qs from 'query-string';
import { OkrTeamType, OkrUserRecordsResponse } from '../interfaces';
import { storeName } from '../config';

export const getUserList = createAsyncThunk<
  OkrUserRecordsResponse,
  {
    periodId: string;
    type: OkrTeamType;
    page: number;
    limit: number;
  }
>(
  `${storeName}/getUserList`,
  async ({ periodId, type, page, limit }, thunkAPI) => {
    const url = `${generateURL(
      type === OkrTeamType.direct
        ? OKR_MY_TEAM_USERS_DIRECT
        : OKR_MY_TEAM_USERS_FULL,
      { periodId },
    )}?${qs.stringify({
      page,
      limit,
    })}`;
    return api.axios
      .get<OkrUserRecordsResponse>(url)
      .then(({ data }) => data)
      .catch((e) => thunkAPI.rejectWithValue(e.response?.data));
  },
);
