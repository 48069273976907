import { createAsyncThunk } from '@reduxjs/toolkit';
import { ReviewCycleItem } from 'store/interfaces';
import api from 'api';
import { CycleFormFields } from 'pages/ReviewCycles';
import { updateOptions } from 'store/options';
import { API_REVIEW_CYCLE } from 'constants/api';
import { storeName } from '../config';

export const cycleCreate = createAsyncThunk<
  ReviewCycleItem,
  CycleFormFields,
  {
    rejectValue: { errors: any };
  }
>(`${storeName}/add`, async (data, thunkAPI) =>
  api.axios
    .post<ReviewCycleItem>(API_REVIEW_CYCLE, data)
    .then((res) => {
      thunkAPI.dispatch(updateOptions('reviewCycles'));
      return res.data;
    })
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data)),
);
