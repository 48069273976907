import imageSizes from 'constants/imageSizes';
import qs from 'query-string';
import { API_MEDIA } from 'constants/api';

export default function resizeImage(
  src: string | null,
  width: typeof imageSizes[number] = 64,
): string | undefined {
  if (!src) {
    return undefined;
  }
  const { url, query } = qs.parseUrl(src);
  Object.assign(query, { width });
  return qs.stringifyUrl({ url: API_MEDIA + url, query });
}
