import { useWatch } from 'react-hook-form';
import {
  GoalField,
  FormGoal,
  FormSubGoal,
} from 'components/GoalsAndSubgoalsForm/interfaces';

export const calculateTotalWeight = (goals: Array<FormGoal | FormSubGoal>) =>
  goals.length
    ? goals.reduce((acc: number, { weight }) => {
        const goalWeight = Number.parseInt(weight as string, 10);
        if (!Number.isNaN(goalWeight)) {
          return acc + goalWeight;
        }
        return acc;
      }, 0)
    : 0;

export default function useTotalWeight(index?: number) {
  const name: 'goals' | `goals.${number}.subgoals` =
    typeof index !== 'undefined' ? `goals.${index}.subgoals` : 'goals';
  const items = useWatch<GoalField>({ name }) || [];
  return calculateTotalWeight(items as (FormGoal | FormSubGoal)[]);
}
