import Tooltip from 'components/Tooltip';
import { useIntl } from 'react-intl';
import { CustomGoalIconPerson } from './CustomGoalIcon.styled';

export default function CustomGoalIcon() {
  const { formatMessage } = useIntl();
  return (
    <Tooltip
      placement="right"
      title={formatMessage({
        id: 'pages.scorecards.customGoalTooltip',
        defaultMessage:
          'This is a custom goal set specifically for the employee.',
      })}
    >
      <CustomGoalIconPerson />
    </Tooltip>
  );
}
