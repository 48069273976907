import { ToastProps } from './interfaces';

export const storeName = 'toast';

export const toastInitialState: ToastProps = {
  open: false,
  severity: 'success',
  message: '',
  autoHideDuration: 3000,
  hideOnClickAway: true,
};
