import { Theme } from '@mui/material';

export default {
  styleOverrides: {
    root({ theme }: { theme: Theme }) {
      return {
        '&.Mui-disabled': {
          color: theme.palette.text.secondary,
        },
      };
    },
  },
};
