export function isStackFromExtension(stack?: string) {
  return [
    'moz-extension://',
    'chrome-extension://',
    'safari-extension://',
    '@safari-web-extension://',
    'ms-browser-extension://',
    '@webkit-masked-url://hidden/',
  ].some((s) => stack?.includes(s));
}
