import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

export const selectComments = createSelector(
  (state: RootState) => state.comments,
  (data) => data,
);

export const selectCommentStates = createSelector(selectComments, (data) => ({
  loading: data.loading,
  updating: data.updating,
  adding: data.adding,
  editing: data.editing,
  deleting: data.deleting,
}));

export const selectPagination = createSelector(
  selectComments,
  (data) => data.pagination,
);

export const selectThread = createSelector(
  selectComments,
  (data) => data.thread,
);

export const selectSubscription = createSelector(
  selectComments,
  (data) => data.subscription,
);

export const selectCommentsList = createSelector(
  selectComments,
  (data) => data.items,
);

export const selectLastAddedCommentId = createSelector(
  selectComments,
  (data) => data.lastAddedCommentId,
);
