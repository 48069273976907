import { Theme } from '@mui/material';

export default {
  styleOverrides: {
    root({ theme }: { theme: Theme }) {
      return {
        '&.MuiToolbar-root': {
          backgroundColor: theme.palette.background.default,
          display: 'flex',
          justifyContent: 'space-between',
        },
      };
    },
  },
};
