import { Theme } from '@mui/material';

export default {
  styleOverrides: {
    root({ theme }: { theme: Theme }) {
      return {
        fontSize: theme.typography.pxToRem(18),
        lineHeight: 1.3333,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.common.black,
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        padding: '16px 20px',
        '&.withButton': {
          paddingRight: 54,
        },
        '.closeButton': {
          position: 'absolute',
          right: 10,
          top: 13,
          color: 'inherit',
          padding: 6,
        },
        '.MuiSvgIcon-root': {
          fontSize: 18,
        },
        '&+.MuiDialogContent': {
          paddingTop: 20,
        },
      };
    },
  },
};
