import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'pages.departmentOverview.title',
    defaultMessage: 'Department Overview',
  },
  activeEmployees: {
    id: 'pages.departmentOverview.viewModes.activeEmployees',
    defaultMessage: 'Active Employees',
  },
  inactiveEmployees: {
    id: 'pages.departmentOverview.viewModes.inactiveEmployees',
    defaultMessage: 'Inactive Employees',
  },
  deletedScorecards: {
    id: 'pages.departmentOverview.viewModes.deletedScorecards',
    defaultMessage: 'Deleted Scorecards',
  },
});
