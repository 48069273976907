import { Suspense, lazy } from 'react';
import PageLoader from 'components/PageLoader';
import { AppRouteComponentProps } from 'components/AppRoutes';
import {
  usePageAccessible,
  useEditScorecardPageData,
  useScorecardFormVariant,
} from 'hooks';

const EditScorecardComponent = lazy(() => import('./EditScorecard.component'));

export default function EditScorecard(props: AppRouteComponentProps) {
  const variant = useScorecardFormVariant();

  const { scorecard, loading, notFound, onBackClick, hasAccess } =
    useEditScorecardPageData(usePageAccessible(props), variant);

  return (
    <Suspense fallback={<PageLoader open />}>
      {loading ? (
        <PageLoader open />
      ) : (
        <EditScorecardComponent
          hasAccess={hasAccess}
          notFound={notFound}
          onBackClick={onBackClick}
          scorecard={scorecard!}
          variant={variant}
        />
      )}
    </Suspense>
  );
}
