import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_CAREER_ROADMAP } from 'constants/api';
import type { RootState } from 'store/index';
import { CanvasFilter, RoadmapDepartment } from 'store/interfaces';
import { NodeNormalizer } from 'helpers';
import { storeName } from '../config';
import { selectCanvasDepartment, selectCanvasData } from '../selectors';

export const createNewFilter = createAsyncThunk<
  RoadmapDepartment,
  CanvasFilter
>(`${storeName}/create-filter`, (payload, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;

  const departmentData = selectCanvasDepartment(state);
  const { filters, nodes, edges } = selectCanvasData(state);

  const body = {
    ...NodeNormalizer.normalizeCanvasState({ nodes, edges }),
    filters: [...filters, payload],
  };

  if (!departmentData) {
    return thunkAPI.rejectWithValue('No department selected');
  }

  return api.axios
    .put<RoadmapDepartment>(`${API_CAREER_ROADMAP}/${departmentData.id}`, {
      schemaData: body,
      name: departmentData.name,
    })
    .then((res) => res.data)
    .catch((e) => thunkAPI.rejectWithValue(e.response?.data));
});
