import { SyntheticEvent, useCallback, useState } from 'react';
import { DialogOnClose } from 'components/Dialog';

export default function useDialogState(
  defaultState = false,
  stopPropagation = false,
): [boolean, (e: SyntheticEvent | void) => void, DialogOnClose] {
  const [open, setOpen] = useState(defaultState);
  const onOpen = useCallback(
    (e: SyntheticEvent | void) => {
      const target = e?.target as HTMLElement;

      if (stopPropagation) {
        e?.stopPropagation();
      }
      setOpen(true);
      target?.blur();
    },
    [setOpen, stopPropagation],
  );
  const onClose = useCallback<DialogOnClose>(
    (event, reason) => {
      if (reason !== 'backdropClick') {
        setOpen(false);
      }
    },
    [setOpen],
  );
  return [open, onOpen, onClose];
}
