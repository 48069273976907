import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { SubGoalListProps } from './interfaces';

export default function SubGoalPortal({
  children,
  index,
  navigationSubgoalsListId,
}: SubGoalListProps) {
  const [node, setNode] = useState<Element | null>(null);
  useEffect(() => {
    setNode(document.querySelector(`#${navigationSubgoalsListId}`));
  }, [index, navigationSubgoalsListId]);
  if (node) {
    return createPortal(children, node);
  }
  return null;
}
