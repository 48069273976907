import { useCallback, useMemo } from 'react';
import ReactHookFormField from 'components/ReactHookFormField';
import { UnitedReactHookFormFieldProps } from 'components/ReactHookFormField/interfaces';
import { useIntl } from 'react-intl';
import Tooltip from 'components/Tooltip';
import { usePagePermissions } from 'hooks';
import DisabledFormFieldWithLinks from './DisabledFormFieldWithLinks.component';

export default function FieldHolder(props: UnitedReactHookFormFieldProps) {
  const {
    disabled,
    rules,
    hasLinks,
    label,
    type,
    isCustomGoal,
    sx,
    isTextAssessmentScale,
    tooltipPlacement,
  } = props;
  const { formatMessage } = useIntl();
  const { isOwnScorecardsPage } = usePagePermissions();

  const getTooltipTitle = useCallback((): string | undefined => {
    if (!disabled) {
      return undefined;
    }
    if (isTextAssessmentScale) {
      return formatMessage({
        id: 'pages.scorecards.disabledFieldHelperAssessmentScale',
        defaultMessage:
          "Weight is not applicable for subgoals with 'Text Feedback' assessment scale and set to 0%",
      });
    }

    if (isOwnScorecardsPage) {
      return formatMessage({
        id: 'pages.scorecards.disabledFieldHelperReachManager',
        defaultMessage:
          'You cannot edit Goal details. Please reach up to your Line Manager or Career Mentor',
      });
    }

    return formatMessage(
      {
        id: 'pages.scorecards.disabledFieldHelper',
        defaultMessage: `You cannot edit this field for the {isCustomGoal, select,
            yes {custom}
            other {general}} goal`,
      },
      { isCustomGoal: isCustomGoal ? 'yes' : 'no' },
    );
  }, [
    disabled,
    formatMessage,
    isCustomGoal,
    isOwnScorecardsPage,
    isTextAssessmentScale,
  ]);

  const field = useMemo(
    () => <ReactHookFormField tooltipTitle={getTooltipTitle()} {...props} />,
    [getTooltipTitle, props],
  );

  if (!disabled) {
    return field;
  }

  return (
    <Tooltip
      sx={sx}
      componentsProps={
        label && type !== 'toggle'
          ? {
              tooltip: { style: { marginBottom: '-10px' } },
            }
          : undefined
      }
      placement={tooltipPlacement}
      title={type !== 'radio' && getTooltipTitle()}
    >
      {hasLinks ? (
        <DisabledFormFieldWithLinks
          {...props}
          required={typeof rules?.required !== 'undefined'}
        />
      ) : (
        field
      )}
    </Tooltip>
  );
}
