import { memo } from 'react';
import type { NodeProps } from 'reactflow';
import type { NodeData } from 'store/interfaces';
import { isEqual } from 'lodash-es';
import NodeWrapper from './NodeWrapper';
import NodeMenu from './NodeMenu.component';
import Handlers from './Handlers';
import NodeContent from './NodeContent';
import DragHandler from './DragHandler.component';

function RootNode({
  data,
  type,
  sourcePosition,
  targetPosition,
  isConnectable,
}: NodeProps<NodeData>) {
  return (
    <>
      <NodeWrapper>
        <NodeContent data={data} type={type} />

        {isConnectable && (
          <>
            <DragHandler />
            <NodeMenu />
          </>
        )}
      </NodeWrapper>

      <Handlers
        edges={data.edges}
        sourcePosition={sourcePosition}
        targetPosition={targetPosition}
        isConnectable={isConnectable}
      />
    </>
  );
}

RootNode.displayName = 'RootNode';

export default memo(
  RootNode,
  (prev, next) =>
    isEqual(prev.data, next.data) &&
    prev.targetPosition === next.targetPosition &&
    prev.isConnectable === next.isConnectable &&
    prev.sourcePosition === next.sourcePosition,
);
