export default function getProperty(
  object: any,
  path: string | [],
  defVal = null,
) {
  let arr;
  if (typeof path === 'string') {
    arr = path.replace(/\[/g, '.').replace(/\]/g, '').split('.');
  } else {
    arr = path;
  }

  return arr.reduce((xs, x) => (xs && xs[x] ? xs[x] : defVal), object);
}
