import { useCallback } from 'react';
import {
  SuccessCallback,
  useActionPipeline,
  useDialogState,
  useToast,
} from 'hooks';
import { useIntl } from 'react-intl';
import { commonTexts } from 'i18n';
import { useScorecardContext } from 'contexts';
import {
  changePage,
  deleteScorecard,
  selectPagination,
  selectSlice,
  updateList,
} from 'store/scorecards';
import { batch, useSelector } from 'react-redux';
import { useAppDispatch } from 'store';

export default function useDeleteScorecard() {
  const dispatch = useAppDispatch();
  const {
    id,
    store,
    isArchived: isArchivedContext,
    isDeleted: isDeletedContext,
  } = useScorecardContext();
  const { page } = useSelector(selectPagination(store));
  const { items } = useSelector(selectSlice(store));
  const [
    isDeleteScorecardDialogActive,
    openDeleteScorecardDialog,
    onDeleteScorecardDialogClose,
  ] = useDialogState();

  const onDeleteScorecardClick = useCallback(() => {
    openDeleteScorecardDialog();
  }, [openDeleteScorecardDialog]);

  const { formatMessage } = useIntl();
  const showMessage = useToast();

  const updateListAfterDelete = useCallback(() => {
    const isOneItemOnThePage = items.length === 1;
    batch(() => {
      if (isOneItemOnThePage && page !== 1) {
        dispatch(changePage({ store, page: page - 1 }));
      }
      dispatch(
        updateList({
          store,
          isArchived: isArchivedContext,
          isDeleted: isDeletedContext,
        }),
      );
    });
  }, [
    items.length,
    page,
    store,
    dispatch,
    isArchivedContext,
    isDeletedContext,
  ]);

  const onDeleteScorecardSuccess = useCallback<SuccessCallback>(() => {
    showMessage({
      severity: 'success',
      message: formatMessage(commonTexts.success),
    });
  }, [showMessage, formatMessage]);

  const onDeleteScorecardFinal = useCallback(() => {
    onDeleteScorecardDialogClose();
    updateListAfterDelete();
  }, [onDeleteScorecardDialogClose, updateListAfterDelete]);

  const deleteScorecardPipeline = useActionPipeline(
    onDeleteScorecardSuccess,
    null,
    onDeleteScorecardFinal,
  );

  const onDeleteScorecardSubmit = useCallback(() => {
    deleteScorecardPipeline(deleteScorecard({ id, store }));
  }, [id, deleteScorecardPipeline, store]);
  return {
    onDeleteScorecardClick,
    isDeleteScorecardDialogActive,
    onDeleteScorecardDialogClose,
    onDeleteScorecardSubmit,
  };
}
