import PageLoader from 'components/PageLoader';
import { Suspense, lazy } from 'react';
import { AppRouteComponentProps } from 'components/AppRoutes';

const UserManagement = lazy(() => import('./UserManagement.component'));

export default function UserManagementPage(props: AppRouteComponentProps) {
  return (
    <Suspense fallback={<PageLoader open />}>
      <UserManagement pageProps={props} />
    </Suspense>
  );
}
