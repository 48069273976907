import { Theme } from '@mui/material';

export default {
  defaultProps: {
    variant: 'outlined' as const,
    shape: 'rounded' as const,
  },
  styleOverrides: {
    root({ theme }: { theme: Theme }) {
      return {
        '.MuiPaginationItem-root': {
          borderColor: theme.palette.grey.A400,
          color: theme.palette.grey.A500,
          fontSize: theme.typography.pxToRem(14),
          lineHeight: 1.4286,
          height: 'unset',
          padding: '3px',
          minWidth: '28px',
          '&.Mui-selected': {
            borderColor: theme.palette.blue['100'],
            backgroundColor: theme.palette.blue['100'],
            color: theme.palette.common.black,
            pointerEvents: 'none',
          },
        },
      };
    },
  },
};
