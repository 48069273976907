import { AppRouteItem } from 'components/AppRoutes';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { HeaderMode, selectHeaderMode } from 'store/uiState';
import useAccessibleRoutes from './useAccessibleRoutes';

export default function useHeaderMenuLinks() {
  const headerMode = useSelector(selectHeaderMode);
  const isOKRs = headerMode === HeaderMode.OKRs;
  const routes = useAccessibleRoutes();

  return useMemo<AppRouteItem[]>(
    () =>
      routes.filter((route) =>
        route.title && isOKRs
          ? route.path.includes('okr')
          : !route.path.includes('okr'),
      ),
    [isOKRs, routes],
  );
}
