import { Theme } from '@mui/material';
import { BackdropComponentsPropsOverrides } from '@mui/material/Backdrop';

declare module '@mui/material/Backdrop' {
  interface BackdropComponentsPropsOverrides {
    variant?: 'dark' | 'light';
  }
}
export default {
  styleOverrides: {
    root({
      theme,
      variant = 'dark',
    }: {
      theme: Theme;
    } & BackdropComponentsPropsOverrides) {
      const styles = {
        zIndex: 9999,
        '.MuiCircularProgress-svg': {
          pointerEvents: 'none',
          color:
            variant === 'dark'
              ? theme.palette.common.white
              : theme.palette.primary.main,
        },
      };

      if (variant === 'light') {
        Object.assign(styles, { backgroundColor: 'unset' });
      }

      return styles;
    },
  },
};
