import { SvgIconProps } from '@mui/material/SvgIcon';

export default function CheckboxIcon(props: SvgIconProps) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="white" />
      <rect x="0.5" y="0.5" width="17" height="17" rx="3.5" stroke="#CCD3DF" />
    </svg>
  );
}
