import { Dispatch, useEffect, useState } from 'react';
import api from 'api';

type Status = 'loading' | 'success' | 'error';

interface State<T> {
  data?: T;
  error?: any;
  status: Status;
}

async function fetchData<T>(
  url: string,
  abortController: AbortController,
  setSate: Dispatch<State<T>>,
) {
  try {
    const { data } = await api.axios.get<T>(url, {
      signal: abortController.signal,
    });

    setSate({
      data,
      status: 'success',
    });
  } catch (error) {
    setSate({
      error,
      status: 'error',
    });
  }
}

export default function useFetch<T>(url: string): State<T> {
  const [state, setState] = useState<State<T>>({ status: 'loading' });

  useEffect(() => {
    const abortController = new AbortController();

    fetchData(url, abortController, setState);

    return () => {
      abortController.abort();
    };
  }, [setState, url]);

  return state;
}
