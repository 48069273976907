import { ObjectiveFormFields } from 'pages/ObjectiveDetails/interfaces';
import getKeyResultDefaultFields from './getKeyResultDefaultFields';

export default function getObjectiveDefaultFields(
  cycleEnd?: string,
): ObjectiveFormFields {
  const defaultKeyResult = getKeyResultDefaultFields(cycleEnd, true);
  return {
    name: '',
    description: '',
    label: 0,
    visibility: 0,
    attainment: 0,
    weight: 0,
    keyResults: [defaultKeyResult],
    keyResultsTotalWeight: 0,
    parentBinding: null,
  };
}
