import { useMemo } from 'react';
import useAccessibleRoutes from './useAccessibleRoutes';

export default function useRootRedirect() {
  const accessibleRoutes = useAccessibleRoutes();
  return useMemo(
    () => `/${accessibleRoutes[0]?.path || ''}`,
    [accessibleRoutes],
  );
}
