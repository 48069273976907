import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function CheckedRadioIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fill="currentColor"
        d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z"
      />
      <path
        fill="#ffffff"
        d="M8.465 8.465C9.37 7.56 10.62 7 12 7C14.76 7 17 9.24 17 12C17 13.38 16.44 14.63 15.535 15.535C14.63 16.44 13.38 17 12 17C9.24 17 7 14.76 7 12C7 10.62 7.56 9.37 8.465 8.465Z"
      />
    </SvgIcon>
  );
}
