import palette from './palette';

type ExtendedShadows = [
  'none',
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
];

declare module '@mui/material/styles' {
  interface Theme {
    shadows: ExtendedShadows;
  }
}

export default [
  `0px 3px 9px ${palette.grey.A500}40`, // 25 default shadow
  '0px 3px 9px rgba(139, 148, 172, 0.25)', // 26 white tooltip shadow
];
