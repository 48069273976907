import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { API_CAREER_ROADMAP } from 'constants/api';
import type { RootState } from 'store/index';
import { CanvasData, RoadmapDepartment } from 'store/interfaces';
import { storeName } from '../config';
import { selectCanvasFiltersData, selectCanvasDepartment } from '../selectors';

export const updateRoadmap = createAsyncThunk<RoadmapDepartment, CanvasData>(
  `${storeName}/save`,
  (payload, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    const filters = selectCanvasFiltersData(state);
    const departmentData = selectCanvasDepartment(state);

    const body = { ...payload, filters };

    if (!departmentData) {
      return thunkAPI.rejectWithValue('No department selected');
    }

    return api.axios
      .put<RoadmapDepartment>(`${API_CAREER_ROADMAP}/${departmentData.id}`, {
        schemaData: body,
        name: departmentData.name,
      })
      .then((res) => res.data)
      .catch((e) => thunkAPI.rejectWithValue(e.response?.data));
  },
);
