import { CommentsState } from './interfaces';

export const commentsInitialState: CommentsState = {
  items: [],
  loading: false,
  updating: false,
  adding: false,
  editing: false,
  deleting: false,
  thread: null,
  subscription: null,
  lastAddedCommentId: null,
  pagination: {
    currentCursor: null,
    nextCursor: null,
    pageSize: 30,
    previousCursor: null,
  },
};

export const storeName = 'comments';
