import { FormState } from 'react-hook-form/dist/types/form';
import { FieldValues } from 'react-hook-form/dist/types/fields';

export default function isFormDirty({
  isDirty,
  dirtyFields,
  isSubmitSuccessful,
}: FormState<FieldValues>): boolean {
  return isDirty && Object.keys(dirtyFields).length > 0 && !isSubmitSuccessful;
}
